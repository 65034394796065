import UniversityChoiceModel from '../../models/UniversityChoiceModel';

// University data for the signup
const universityData: UniversityChoiceModel[] = [
  {
    email: '@aber.ac.uk',
    name: 'Aberystwyth University',
  },
  {
    email: '@aru.ac.uk',
    name: 'Anglia Ruskin University',
  },
  {
    email: '@aub.ac.uk',
    name: 'Arts University Bournemouth',
  },
  {
    email: '@aston.ac.uk',
    name: 'Aston University',
  },
  {
    email: '@bangor.ac.uk',
    name: 'Bangor University',
  },
  {
    email: '@bathspa.ac.uk',
    name: 'Bath Spa University',
  },
  {
    email: '@bbk.ac.uk',
    name: 'Birkbeck College',
  },
  {
    email: '@birmingham.ac.uk',
    name: 'Birmingham City University',
  },
  {
    email: '@bishopg.ac.uk',
    name: 'Bishop Grosseteste University',
  },
  {
    email: '@bournemouth.ac.uk',
    name: 'Bournemouth University',
  },
  {
    email: '@brunel.ac.uk',
    name: 'Brunel University',
  },
  {
    email: '@bucks.ac.uk',
    name: 'Buckinghamshire New University',
  },
  {
    email: '@canterbury.ac.uk',
    name: 'Canterbury Christ Church University',
  },
  {
    email: '@cardiff.ac.uk',
    name: 'Cardiff University',
  },
  {
    email: '@cardiffmet.ac.uk',
    name: 'Cardiff Metropolitan University',
  },

  {
    email: '@outlook.cardiffmet.ac.uk',
    name: 'Cardiff Metropolitan University (Outlook)',
  },
  {
    email: '@cssd.ac.uk',
    name: 'Central School of Speech and Drama',
  },
  {
    email: '@city.ac.uk',
    name: 'City University',
  },
  {
    email: '@cdd.ac.uk',
    name: 'Conservatoire for Dance and Drama',
  },
  {
    email: '@courtauld.ac.uk',
    name: 'Courtauld Institute of Art',
  },
  {
    email: '@coventry.ac.uk',
    name: 'Coventry University',
  },
  {
    email: '@cranfield.ac.uk',
    name: 'Cranfield University',
  },
  {
    email: '@dmu.ac.uk',
    name: 'De Montfort University',
  },
  {
    email: '@edgehill.ac.uk',
    name: 'Edge Hill University',
  },
  {
    email: '@napier.ac.uk',
    name: 'Edinburgh Napier University',
  },
  {
    email: '@falmouth.ac.uk',
    name: 'Falmouth University',
  },
  {
    email: '@gcu.ac.uk',
    name: 'Glasgow Caledonian University',
  },
  {
    email: '@gold.ac.uk',
    name: 'Goldsmiths, University of London',
  },
  {
    email: '@gsmd.ac.uk',
    name: 'Guildhall School of Music and Drama',
  },
  {
    email: '@harper-adams.ac.uk',
    name: 'Harper Adams University',
  },
  {
    email: '@hw.ac.uk',
    name: 'Heriot-Watt University',
  },
  {
    email: '@heythrop.ac.uk',
    name: 'Heythrop College',
  },
  {
    email: '@imperial.ac.uk',
    name: 'Imperial College of Science, Technology and Medicine',
  },
  {
    email: '@icr.ac.uk',
    name: 'Institute of Cancer Research',
  },
  {
    email: '@ucl.ac.uk',
    name: 'Institute of Education',
  },
  {
    email: '@kcl.ac.uk',
    name: "King's College London",
  },
  {
    email: '@kingston.ac.uk',
    name: 'Kingston University',
  },
  {
    email: '@leedsbeckett.ac.uk',
    name: 'Leeds Beckett University',
  },
  {
    email: '@leeds-art.ac.uk',
    name: 'Leeds College of Art',
  },
  {
    email: '@lcm.ac.uk',
    name: 'Leeds College of Music',
  },
  {
    email: '@leedstrinity.ac.uk',
    name: 'Leeds Trinity University',
  },
  {
    email: '@hope.ac.uk',
    name: 'Liverpool Hope University',
  },
  {
    email: '@lipa.ac.uk',
    name: 'Liverpool Institute of Performing Arts',
  },
  {
    email: '@ljmu.ac.uk',
    name: 'Liverpool John Moores University',
  },
  {
    email: '@london.edu',
    name: 'London Business School',
  },
  {
    email: '@londonmet.ac.uk',
    name: 'London Metropolitan University',
  },
  {
    email: '@lse.ac.uk',
    name: 'London School of Economics and Political Science',
  },
  {
    email: '@lshtm.ac.uk',
    name: 'London School of Hygiene & Tropical Medicine',
  },
  {
    email: '@lsbu.ac.uk',
    name: 'London South Bank University',
  },
  {
    email: '@lboro.ac.uk',
    name: 'Loughborough University',
  },
  {
    email: '@mmu.ac.uk',
    name: 'Manchester Metropolitan University',
  },
  {
    email: '@marjon.ac.uk',
    name: 'Plymouth Marjon University',
  },
  {
    email: '@mdx.ac.uk',
    name: 'Middlesex University',
  },
  {
    email: '@newman.ac.uk',
    name: 'Newman University',
  },
  {
    email: '@nscd.ac.uk',
    name: 'Northern School of Contemporary Dance',
  },
  {
    email: '@nua.ac.uk',
    name: 'Norwich University of the Arts',
  },
  {
    email: '@ntu.ac.uk',
    name: 'Nottingham Trent University',
  },
  {
    email: '@brookes.ac.uk',
    name: 'Oxford Brookes University',
  },
  {
    email: '@plymouthart.ac.uk',
    name: 'Plymouth College of Art',
  },
  {
    email: '@qmu.ac.uk',
    name: 'Queen Margaret University',
  },
  {
    email: '@qmul.ac.uk',
    name: 'Queen Mary and Westfield College',
  },
  {
    email: '@qub.ac.uk',
    name: "Queen's University Belfast",
  },
  {
    email: '@ravensbourne.ac.uk',
    name: 'Ravensbourne University',
  },
  {
    email: '@rgu.ac.uk',
    name: 'Robert Gordon University',
  },
  {
    email: '@roehampton.ac.uk',
    name: 'Roehampton University',
  },
  {
    email: '@bruford.ac.uk',
    name: 'Rose Bruford College',
  },
  {
    email: '@ram.ac.uk',
    name: 'Royal Academy of Music',
  },
  {
    email: '@rau.ac.uk',
    name: 'Royal Agricultural College',
  },
  {
    email: '@rca.ac.uk',
    name: 'Royal College of Art',
  },
  {
    email: '@rcm.ac.uk',
    name: 'Royal College of Music',
  },
  {
    email: '@rcn.ac.uk',
    name: 'Royal College of Nursing',
  },
  {
    email: '@royalholloway.ac.uk',
    name: 'Royal Holloway and Bedford New College',
  },
  {
    email: '@rncm.ac.uk',
    name: 'Royal Northern College of Music',
  },
  {
    email: '@rvc.ac.uk',
    name: 'Royal Veterinary College',
  },
  {
    email: '@soas.ac.uk',
    name: 'School of Oriental and African Studies',
  },
  {
    email: '@shu.ac.uk',
    name: 'Sheffield Hallam University',
  },
  {
    email: '@solent.ac.uk',
    name: 'Southampton Solent University',
  },
  {
    email: '@sgul.ac.uk',
    name: "St George's Hospital Medical School",
  },
  {
    email: '@stmarys-belfast.ac.uk',
    name: "St Mary's University College, Belfast",
  },
  {
    email: '@stmarys.ac.uk',
    name: "St Mary's University, Twickenham",
  },
  {
    email: '@staffs.ac.uk',
    name: 'Staffordshire University',
  },
  {
    email: '@stran.ac.uk',
    name: 'Stranmills University College',
  },
  {
    email: '@swansea.ac.uk',
    name: 'Swansea University',
  },
  {
    email: '@tees.ac.uk',
    name: 'Teesside University',
  },
  {
    email: '@open.ac.uk',
    name: 'The Open University',
  },
  {
    email: '@trinitylaban.ac.uk',
    name: 'Trinity Laban',
  },
  {
    email: '@ulster.ac.uk',
    name: 'Ulster University',
  },
  {
    email: '@ucb.ac.uk',
    name: 'University College Birmingham',
  },
  {
    email: '@ucl.ac.uk',
    name: 'University College London',
  },
  {
    email: '@uca.ac.uk',
    name: 'University College for the Creative Arts',
  },
  {
    email: '@abdn.ac.uk',
    name: 'University of Aberdeen',
  },
  {
    email: '@abertay.ac.uk',
    name: 'University of Abertay Dundee',
  },
  {
    email: '@bath.ac.uk',
    name: 'University of Bath',
  },
  {
    email: '@beds.ac.uk',
    name: 'University of Bedfordshire',
  },
  {
    email: '@birmingham.ac.uk',
    name: 'University of Birmingham',
  },
  {
    email: '@bolton.ac.uk',
    name: 'University of Bolton',
  },
  {
    email: '@bradford.ac.uk',
    name: 'University of Bradford',
  },
  {
    email: '@brighton.ac.uk',
    name: 'University of Brighton',
  },
  {
    email: '@bristol.ac.uk',
    name: 'University of Bristol',
  },
  {
    email: '@buckingham.ac.uk',
    name: 'University of Buckingham',
  },
  {
    email: '@cam.ac.uk',
    name: 'University of Cambridge',
  },
  {
    email: '@uclan.ac.uk',
    name: 'University of Central Lancaster',
  },
  {
    email: '@chester.ac.uk',
    name: 'University of Chester',
  },
  {
    email: '@chi.ac.uk',
    name: 'University of Chichester',
  },
  {
    email: '@cumbria.ac.uk',
    name: 'University of Cumbria',
  },
  {
    email: '@derby.ac.uk',
    name: 'University of Derby',
  },
  {
    email: '@dundee.ac.uk',
    name: 'University of Dundee',
  },
  {
    email: '@dur.ac.uk',
    name: 'University of Durham',
  },
  {
    email: '@uea.ac.uk',
    name: 'University of East Anglia',
  },
  {
    email: '@uel.ac.uk',
    name: 'University of East London',
  },
  {
    email: '@ed.ac.uk',
    name: 'University of Edinburgh',
  },
  {
    email: '@essex.ac.uk',
    name: 'University of Essex',
  },
  {
    email: '@exeter.ac.uk',
    name: 'University of Exeter',
  },
  {
    email: '@gla.ac.uk',
    name: 'University of Glasgow',
  },
  {
    email: '@glos.ac.uk',
    name: 'University of Gloucestershire',
  },
  {
    email: '@gre.ac.uk',
    name: 'University of Greenwich',
  },
  {
    email: '@herts.ac.uk',
    name: 'University of Hertfordshire',
  },
  {
    email: '@hud.ac.uk',
    name: 'University of Huddersfield',
  },
  {
    email: '@hull.ac.uk',
    name: 'University of Hull',
  },
  {
    email: '@keele.ac.uk',
    name: 'University of Keele',
  },
  {
    email: '@kent.ac.uk',
    name: 'University of Kent',
  },
  {
    email: '@lancaster.ac.uk',
    name: 'University of Lancaster',
  },
  {
    email: '@leeds.ac.uk',
    name: 'University of Leeds',
  },
  {
    email: '@le.ac.uk',
    name: 'University of Leicester',
  },
  {
    email: '@lincoln.ac.uk',
    name: 'University of Lincoln',
  },
  {
    email: '@liverpool.ac.uk',
    name: 'University of Liverpool',
  },
  {
    email: '@london.ac.uk',
    name: 'University of London',
  },
  {
    email: '@manchester.ac.uk',
    name: 'University of Manchester',
  },
  {
    email: '@ncl.ac.uk',
    name: 'University of Newcastle Upon Tyne',
  },
  {
    email: '@northampton.ac.uk',
    name: 'University of Northampton',
  },
  {
    email: '@northumbria.ac.uk',
    name: 'University of Northumbria',
  },
  {
    email: '@nottingham.ac.uk',
    name: 'University of Nottingham',
  },
  {
    email: '@oxford.ac.uk',
    name: 'University of Oxford',
  },
  {
    email: '@plymouth.ac.uk',
    name: 'University of Plymouth',
  },
  {
    email: '@port.ac.uk',
    name: 'University of Portsmouth',
  },
  {
    email: '@reading.ac.uk',
    name: 'University of Reading',
  },
  {
    email: '@salford.ac.uk',
    name: 'University of Salford',
  },
  {
    email: '@sheffield.ac.uk',
    name: 'University of Sheffield',
  },
  {
    email: '@southwales.ac.uk',
    name: 'University of South-Wales',
  },
  {
    email: '@southampton.ac.uk',
    name: 'University of Southampton',
  },
  {
    email: '@st-andrews.ac.uk',
    name: 'University of St Andrews',
  },
  {
    email: '@stir.ac.uk',
    name: 'University of Stirling',
  },
  {
    email: '@strath.ac.uk',
    name: 'University of Strathclyde',
  },
  {
    email: '@sunderland.ac.uk',
    name: 'University of Sunderland',
  },
  {
    email: '@surrey.ac.uk',
    name: 'University of Surrey',
  },
  {
    email: '@sussex.ac.uk',
    name: 'University of Sussex',
  },
  {
    email: '@wales.ac.uk',
    name: 'University of Wales, Trinity Saint David',
  },
  {
    email: '@warwick.ac.uk',
    name: 'University of Warwick',
  },
  {
    email: '@uwl.ac.uk',
    name: 'University of West London',
  },
  {
    email: '@uws.ac.uk',
    name: 'University of West of Scotland',
  },
  {
    email: '@westminster.ac.uk',
    name: 'University of Westminster',
  },
  {
    email: '@winchester.ac.uk',
    name: 'University of Winchester',
  },
  {
    email: '@wlv.ac.uk',
    name: 'University of Wolverhampton',
  },
  {
    email: '@worcester.ac.uk',
    name: 'University of Worcester',
  },
  {
    email: '@york.ac.uk',
    name: 'University of York',
  },
  {
    email: '@arts.ac.uk',
    name: 'University of the Arts London',
  },
  {
    email: '@uhi.ac.uk',
    name: 'University of the Highlands and Islands',
  },
  {
    email: '@uwe.ac.uk',
    name: 'University of the West of England',
  },
  {
    email: '@glyndwr.ac.uk',
    name: 'Wrexham Glyndwr University',
  },
  {
    email: '@writtle.ac.uk',
    name: 'Writtle College',
  },
  {
    email: '@yorksj.ac.uk',
    name: 'York St John University',
  },
];

export default universityData;
