import moment from 'moment';
import { db } from './firebase_services';
import { Fade, Grid, Paper, Slide, Typography } from '@mui/material';
import { primaryColor } from '../assets/styles/styles';
import theme from '../assets/styles/theme';

export function executeScroll(ref: any) {
  if (ref.current !== null && ref !== null) {
    ref!.current.scrollIntoView({ behavior: 'smooth' });
  }
}

//Simple email check
export function checkValidEmail(input: string) {
  // eslint-disable-next-line no-control-regex
  return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/.test(
    input
  );
}

export function registerInterest(
  fullName: string,
  setFullNameError: Function,
  emailAddress: string,
  setEmailAddressError: Function,
  recieveUpdates: boolean,
  setDone: Function
) {
  //Need to save to firebase 'interest_users'

  let error = false;

  if (fullName === '' || fullName.length < 2 || fullName.length > 50) {
    setFullNameError(
      'You must enter a valid full name between 2 and 50 characters length.'
    );
    error = true;
  } else {
    setFullNameError('');
  }

  if (emailAddress === '' || !checkValidEmail(emailAddress.toLowerCase())) {
    setEmailAddressError(
      'You must enter a valid email address e.g. Jenny@routebuddies.co.uk'
    );
    error = true;
  } else {
    setEmailAddressError('');
  }

  if (!error) {
    setDone(true);
    db.ref('/new_data/user_interests/').push({
      fullName,
      emailAddress,
      recieveUpdates,
    });
  }
}

export function sendContactMessage(
  fullName: string,
  setFullNameError: Function,
  emailAddress: string,
  setEmailAddressError: Function,
  message: string,
  setMessageError: Function,
  setDone: Function
) {
  //Need to save to firebase 'interest_users'

  let error = false;

  if (fullName === '' || fullName.length < 2 || fullName.length > 50) {
    setFullNameError(
      'You must enter a valid full name between 2 and 50 characters length.'
    );
    error = true;
  } else {
    setFullNameError('');
  }

  if (emailAddress === '' || !checkValidEmail(emailAddress.toLowerCase())) {
    setEmailAddressError(
      'You must enter a valid email address e.g. Jenny@routebuddies.co.uk'
    );
    error = true;
  } else {
    setEmailAddressError('');
  }

  if (message.length < 10 || message.length > 400) {
    setMessageError(
      'You must enter a message between 10-400 characters length.'
    );
    error = true;
  } else {
    setMessageError('');
  }

  if (!error) {
    setDone(true);
    db.ref('/new_data/contact_messages/').push({
      fullName,
      emailAddress,
      message,
    });
  }
}

export function registerClubInterest(
  fullName: string,
  setFullNameError: Function,
  clubName: string,
  setClubNameError: Function,
  phoneNumber: string,
  setPhoneNumberError: Function,
  emailAddress: string,
  setEmailAddressError: Function,
  setDone: Function
) {
  //Need to save to firebase 'interest_users'

  let error = false;

  if (fullName === '' || fullName.length < 2 || fullName.length > 50) {
    setFullNameError(
      'You must enter a valid full name between 2 and 50 characters length.'
    );
    error = true;
  } else {
    setFullNameError('');
  }

  if (emailAddress === '' || !checkValidEmail(emailAddress.toLowerCase())) {
    setEmailAddressError(
      'You must enter a valid email address e.g. Jenny@routebuddies.co.uk'
    );
    error = true;
  } else {
    setEmailAddressError('');
  }

  if (clubName === '' || clubName.length < 2 || clubName.length > 50) {
    setClubNameError(
      'You must enter a valid club name between 2 and 50 characters length.'
    );
    error = true;
  } else {
    setClubNameError('');
  }

  if (
    phoneNumber === '' ||
    (phoneNumber.length !== 10 && phoneNumber.length !== 11)
  ) {
    setPhoneNumberError('You must enter a valid UK mobile phone number');
    error = true;
  } else {
    setPhoneNumberError('');
  }

  if (!error) {
    setDone(true);
    db.ref('/new_data/club_interests/').push({
      fullName,
      emailAddress,
      clubName,
      phoneNumber,
    });
  }
}
export function registerVenueInterest(
  fullName: string,
  setFullNameError: Function,
  venueName: string,
  setVenueNameError: Function,
  venueLocations: string[],
  setVenueLocationError: Function,
  phoneNumber: string,
  setPhoneNumberError: Function,
  emailAddress: string,
  setEmailAddressError: Function,
  setDone: Function
) {
  //Need to save to firebase 'interest_users'

  let error = false;

  if (fullName === '' || fullName.length < 2 || fullName.length > 50) {
    setFullNameError(
      'You must enter a valid full name between 2 and 50 characters length.'
    );
    error = true;
  } else {
    setFullNameError('');
  }

  if (emailAddress === '' || !checkValidEmail(emailAddress.toLowerCase())) {
    setEmailAddressError(
      'You must enter a valid email address e.g. Jenny@routebuddies.co.uk'
    );
    error = true;
  } else {
    setEmailAddressError('');
  }

  if (venueName === '' || venueName.length < 2 || venueName.length > 50) {
    setVenueNameError(
      'You must enter a valid venue name between 2 and 50 characters length.'
    );
    error = true;
  } else {
    setVenueNameError('');
  }

  if (venueLocations.length === 0) {
    setVenueLocationError(
      'You must enter at least one venue location. Type and hit "Enter" to write your own.'
    );
    error = true;
  } else {
    setVenueLocationError('');
  }

  if (
    phoneNumber === '' ||
    (phoneNumber.length !== 10 && phoneNumber.length !== 11)
  ) {
    setPhoneNumberError('You must enter a valid UK mobile phone number');
    error = true;
  } else {
    setPhoneNumberError('');
  }

  if (!error) {
    setDone(true);
    db.ref('/new_data/email_send').push({
      type: 'VENUE_INTEREST',
      emailTo: 'contact@routebuddies.com',
      emailFrom: emailAddress,
      data: {
        date: getCurrentDateTime(),
        venueName: venueName,
        venueLocations: venueLocations.map((val, index) =>
          index === venueLocations.length ? val : val + ', '
        ),
        name: fullName,
        phoneNumber: phoneNumber,
        emailAddress: emailAddress,
      },
    });
    db.ref('/new_data/venue_interests/').push({
      fullName,
      emailAddress,
      venueName,
      phoneNumber,
    });
  }
}

// Get current date time is used to get the current date and time, frequently used in data storage.
export function getCurrentDateTime() {
  let time = new Date();
  var offset = time.getTimezoneOffset();
  offset = Math.abs(offset / 60);
  time.setHours(time.getHours() + offset);

  return time.toUTCString();
}

// If the app is open...
export function isAppOpen(admin: boolean) {
  const date = moment(Date.parse(getCurrentDateTime()));

  if (
    (((date.day() === 5 && date.hour() > 17) ||
      date.day() === 6 ||
      (date.day() === 7 && date.hour() < 6)) &&
      date > moment(new Date('2023/02/02'))) ||
    admin
  ) {
    return true;
  } else {
    return false;
  }
}

interface FeatureItem {
  title: string;
  description: string;
  imgSrc: string;
  index: number;
  featureAnimation: boolean;
}

export function createFeatureItem({
  title,
  description,
  imgSrc,
  index,
  featureAnimation,
}: FeatureItem) {
  return (
    <Fade
      in={featureAnimation}
      easing={{ enter: theme.transitions.easing.easeInOut }}
      timeout={{
        enter: 200,
      }}
      style={{ transitionDelay: `${index * 70}ms` }}
    >
      <Grid
        container
        item
        xs={11}
        sm={6}
        md={6}
        lg={3}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          marginTop: {
            xs: 10,
            sm: index >= 2 ? 7 : 0,
            md: index >= 2 ? 7 : 0,
            lg: 0,
          },
          maxWidth: { xs: '350px', sm: '300px', md: '300px', lg: '350px' },
        }}
        style={{ transitionDelay: `${index * 70}ms` }}
      >
        <Paper
          style={{
            padding: 16,
            paddingTop: 27,
            height: '100%',
            minHeight: '140px',
            boxShadow: '3px box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;',
          }}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ height: '100%' }}
          >
            <Grid
              container
              item
              xs={12}
              justifyContent={'center'}
              sx={{ marginTop: -9 }}
            >
              <Grid
                item
                container
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  height: 70,
                  width: 70,
                  marginTop: -1,
                  backgroundColor: primaryColor,
                  borderRadius: 500,
                }}
              >
                <img
                  src={imgSrc}
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 40,
                    height: 'auto',
                  }}
                />
              </Grid>
            </Grid>

            {/* Content */}
            <Typography
              variant={'h5'}
              color={'primary'}
              style={{
                fontFamily: 'UrbanistBold',
                marginTop: '5px',
                fontWeight: 800,
                textAlign: 'center',
              }}
            >
              {title}
            </Typography>
            <Typography
              variant={'body1'}
              style={{
                fontFamily: 'Urbanist',
                color: 'rgba(50,50,50)',
                fontWeight: 400,
              }}
              textAlign={'center'}
            >
              {description}
            </Typography>
          </Grid>
        </Paper>
      </Grid>
    </Fade>
  );
}

interface Section5Item {
  title: string;
  description: any;
  imgSrc: any;
  imgStyle: any;
  index: number;
  animation: boolean;
}

export function createSection5Item({
  title,
  description,
  imgSrc,
  imgStyle,
  index,
  animation,
}: Section5Item) {
  return (
    <Fade
      in={animation}
      easing={{ enter: theme.transitions.easing.easeInOut }}
      timeout={{
        enter: 200,
      }}
      style={{ transitionDelay: `${index * 70}ms` }}
    >
      <Grid
        item
        container
        xs={12}
        sm={4}
        flexDirection={'column'}
        justifyContent={'space-evenly'}
        alignItems={'center'}
        sx={{ marginBottom: { xs: 2, sm: 0 } }}
      >
        <img src={imgSrc} style={imgStyle} />
        <Typography
          variant={'h5'}
          color={'secondary'}
          textAlign={'center'}
          style={{ fontFamily: 'Urbanist', marginBottom: 10 }}
        >
          {title}
        </Typography>
        <Typography
          variant={'body1'}
          color={'secondary'}
          textAlign={'center'}
          style={{ marginBottom: 10 }}
        >
          {description}
        </Typography>
      </Grid>
    </Fade>
  );
}
