import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { watchAuthState } from '../../../services/login_services';
import { useAppDispatch } from '../../../store/store';
import { Helmet } from 'react-helmet';
import { PageType, setCurrentPage } from '../../../store/slices/general.slice';
import { useSelector } from 'react-redux';
import {
  CommunityUsersGrid,
  UserGrid,
} from '../../components/community_admin/DataGrid';
import { getCommunityUsers } from '../../../store/thunks/enterprise.users.thunks';
import { primaryColor } from '../../../assets/styles/styles';
import { toast } from 'react-toastify';
import {
  CheckRounded,
  CloseRounded,
  ContentCopy,
  Mail,
  MailOutline,
} from '@mui/icons-material';
import '../../../index.css';
import { CommunityRequestAppModel } from '../../../models/CommunityRequestModel';
import moment from 'moment';
import {
  checkValidEmail,
  getCurrentDateTime,
} from '../../../services/home_services';
import {
  auth,
  db,
  sendNotificationToTopic,
} from '../../../services/firebase_services';
import { setRequestList } from '../../../store/slices/enterprise.requests.slice';
import { sendEmail } from '../../../services/email_services';
import { addToUserCommmunities } from '../../../services/general_services';
import {
  setDeleteUser,
  setUserList,
} from '../../../store/slices/enterprise.users.slice';
import UserDataModel from '../../../models/UserDataModel';
import { createEvent } from '../../../services/event_services';
import { CreatorEnum } from '../../../models/GroupModel';
import { EventType } from '../../../models/EventEnum';

export function CommunityAdminUsers() {
  const [currentInfoSection, setCurrentInfoSection] = useState(1);
  const [loggedIn, setLoggedIn] = useState(false);
  const { currentPage } = useSelector((state: any) => state.GeneralSlice);
  const { selectedCommunity, userData, allCoalitions } = useSelector(
    (state: any) => state.EnterpriseSlice
  );
  const { userList, userListLoading, deleteUser } = useSelector(
    (state: any) => state.EnterpriseUserSlice
  );

  const { requestList, requestListLoading, requestListError } = useSelector(
    (state: any) => state.EnterpriseRequestSlice
  );

  const [searchValue, setSearchValue] = useState('');

  const [filteredList, setFilteredList] = useState([]);

  const [showInviteModal, setShowInviteModal] = useState(false);

  const [adminEmails, setAdminEmails] = useState<string[]>([]);
  const [adminEmailsError, setAdminEmailsError] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  useEffect(() => {
    watchAuthState(navigate, 'other', setLoggedIn);
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage(PageType.ENTERPRISE_USERS));
  }, []);

  useEffect(() => {
    if (selectedCommunity && currentPage === PageType.ENTERPRISE_USERS) {
      dispatch(getCommunityUsers({ id: selectedCommunity.communityId }));
    } else {
    }
  }, [selectedCommunity, currentPage]);

  useEffect(() => {
    setFilteredList(userList);
  }, [userList]);
  return (
    <Grid container item xs={12} sx={{ flex: 1, height: '100%' }}>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>

      <Grid
        container
        item
        xs={12}
        spacing={2}
        sx={{
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 2,
          flex: 1,
        }}
        alignItems={'center'}
      >
        <Grid
          item
          container
          alignItems={'center'}
          xs={!requestList || requestList.length === 0 ? 0 : 12}
          sx={{
            borderRadius: 2,
            overflowX: 'scroll',
            display: !requestList || requestList.length === 0 ? 'none' : 'flex',
            flex: 1,
          }}
        >
          <Grid item container xs={12}>
            <Typography
              variant="h6"
              fontWeight={'bold'}
              style={{
                fontSize: 12,
                color: 'rgba(0,0,0,0.7)',
                paddingBottom: 2,
              }}
            >
              REQUESTS
            </Typography>
          </Grid>

          <div style={{ display: 'flex', gap: 10 }}>
            {requestList.map((val: CommunityRequestAppModel) => {
              return (
                <Tooltip
                  title={moment(
                    val.creationDate ? val.creationDate : new Date()
                  ).from(moment(getCurrentDateTime()))}
                  placement="left"
                >
                  <Card
                    variant="outlined"
                    style={{
                      marginBottom: 10,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      maxWidth: '190px',
                    }}
                  >
                    <CardContent
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        paddingBottom: 0,
                        flex: 1,
                      }}
                    >
                      <Grid item container xs={12} justifyContent={'center'}>
                        <Grid item container xs={12} justifyContent={'center'}>
                          <Zoom zoomMargin={0}>
                            <img
                              src={
                                val.selfieUrl
                                  ? val.selfieUrl
                                  : '/static/avatar.svg'
                              }
                              style={{
                                padding: 0,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                height: 140,
                                width: 140,
                                borderRadius: 400,
                                marginBottom: 2,
                              }}
                            />
                          </Zoom>
                        </Grid>
                        <Grid item container xs={12} flexDirection={'column'}>
                          <Typography
                            variant="h5"
                            component="div"
                            fontSize={18}
                            fontWeight={'bolder'}
                            textAlign={'center'}
                          >
                            {val.user.firstName + ' ' + val.user.lastName}
                          </Typography>
                          <Typography
                            sx={{ mb: 1.5, fontSize: 13 }}
                            color="text.secondary"
                            textAlign={'center'}
                          >
                            {val.user.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions
                      style={{
                        justifyContent: 'flex-end',
                        flex: 1,
                        paddingTop: 0,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          db.ref(
                            '/new_data/community_data/requests/' +
                              selectedCommunity.communityId +
                              '/' +
                              val.user.id
                          ).remove();

                          let updatedList = requestList.filter(
                            (val2: CommunityRequestAppModel) => {
                              if (val.user.id !== val2.user.id) {
                                return val2;
                              }
                            }
                          );

                          dispatch(setRequestList(updatedList));

                          sendNotificationToTopic(
                            val.user.id,
                            'Request Denied',
                            'An admin of ' +
                              selectedCommunity.name +
                              ' has rejected your request to join.',
                            {
                              notificationType: 'COMM_REQ_DENIED',
                              communityId: selectedCommunity.communityId,
                            }
                          );

                          toast.info('Member request denied');
                        }}
                      >
                        <CloseRounded />
                      </IconButton>
                      <IconButton
                        style={{ backgroundColor: 'green' }}
                        onClick={() => {
                          db.ref(
                            '/new_data/community_data/requests/' +
                              selectedCommunity.communityId +
                              '/' +
                              val.user.id
                          ).remove();
                          sendNotificationToTopic(
                            val.user.id + '-USER',
                            'Welcome Aboard',
                            'An admin of ' +
                              selectedCommunity.name +
                              ' has accepted your request to join.',
                            {
                              notificationType: 'COMM_REQ_ACCEPTED',
                              communityId: selectedCommunity.communityId,
                            }
                          );

                          createEvent(
                            val.user.id,
                            selectedCommunity.communityId,
                            CreatorEnum.COMMUNITY,
                            EventType.COMMUNITY_JOINED,
                            selectedCommunity.communityId
                          );

                          addToUserCommmunities(
                            selectedCommunity.communityId,
                            val.user.id,
                            () => {},
                            () => {}
                          );
                          let updatedList = requestList.filter(
                            (val2: CommunityRequestAppModel) => {
                              if (val.user.id !== val2.user.id) {
                                return val2;
                              }
                            }
                          );

                          dispatch(setRequestList(updatedList));

                          dispatch(setUserList([{ ...val.user }, ...userList]));

                          if (allCoalitions) {
                            for (let i = 0; i < allCoalitions.length; i++) {
                              // Remove user reference for coalition!

                              let id = allCoalitions[i].coalitionId;

                              db.ref(
                                `/new_data/user_data/user_coalitions/${val.user.id}/${id}`
                              ).set({
                                coalitionId: id,
                                joinDate: getCurrentDateTime(),
                                active: true,
                              });

                              db.ref(
                                `/new_data/coalition_data/coalition_users/${id}/${val.user.id}`
                              ).set(true);
                            }
                          }

                          db.ref('/new_data/email_send').push({
                            type: 'COMMUNITY_REQUEST_ACCEPTED',
                            emailTo: val.user.email,
                            emailFrom: 'noreply@routebuddies.com',
                            data: {
                              date: getCurrentDateTime(),
                              communityId: selectedCommunity.communityId,
                              communityName: selectedCommunity.name,
                              name: val.user.firstName,
                            },
                          });

                          toast.info('Member request accepted');
                        }}
                      >
                        <CheckRounded style={{ color: 'white' }} />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Tooltip>
              );
            })}
          </div>
        </Grid>
        <Grid
          item
          container
          xs={requestList && requestList.length > 0 ? 12 : 12}
          alignItems={'center'}
          justifyContent={'center'}
          alignContent={'center'}
          sx={{ height: '100%' }}
        >
          {!userList || userListLoading ? (
            <CircularProgress />
          ) : userList.length > 0 ? (
            <Grid item container>
              <Grid item container xs={12}>
                <Typography
                  variant="h6"
                  fontWeight={'bold'}
                  style={{
                    fontSize: 12,
                    color: 'rgba(0,0,0,0.7)',
                    paddingBottom: 2,
                  }}
                >
                  MEMBERS
                </Typography>
              </Grid>
              <UserGrid rows={filteredList} />
            </Grid>
          ) : (
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              spacing={2}
            >
              <Grid item>
                <img src={'/static/people.svg'} style={{ maxWidth: 200 }} />
              </Grid>
              <Grid item>
                <Typography style={{ textAlign: 'center', maxWidth: 380 }}>
                  Ready to build a safer community? Copy your invite link below
                  to send to members.
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            container
            xs={12}
            sm={9}
            flexDirection={'row'}
            alignItems={'center'}
            sx={{ paddingTop: 2 }}
            spacing={2}
          >
            <Grid item xs={6}>
              <Alert
                className="alertWindow"
                severity="warning"
                icon={<MailOutline />}
                style={{
                  marginBottom: 10,
                  maxWidth: 450,
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  marginTop: 20,
                }}
                onClick={() => {
                  setShowInviteModal(true);
                }}
              >
                Send Email Invite
              </Alert>
            </Grid>

            <Grid item xs={6}>
              <Alert
                className="alertWindow"
                severity="info"
                icon={<ContentCopy />}
                style={{
                  marginBottom: 10,
                  maxWidth: 450,
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  marginTop: 20,
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `routebuddies.com/?appTrigger=true&id=${selectedCommunity.communityId}&link=communities`
                  );
                  toast.success('Invite link copied to clipboard');
                }}
              >
                Copy Invite Link
              </Alert>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Delete user */}
      <Modal
        open={deleteUser}
        onClose={() => {
          dispatch(setDeleteUser(undefined));
        }}
        aria-labelledby="Remove Member"
        aria-describedby="Are you sure you'd like to remove this member?"
      >
        <Grid
          container
          xs={12}
          sx={{ height: '100vh' }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid
            item
            container
            xs={10}
            sx={{
              backgroundColor: 'white',
              margin: 'auto',
              padding: '35px',
              borderRadius: '10px',
            }}
          >
            <Grid container flexDirection={'column'}>
              {/* Title */}
              <Grid
                item
                container
                flexDirection={'column'}
                style={{
                  borderBottom: '0.5px solid rgba(200,200,200,0.4)',
                }}
              >
                <Typography
                  variant={'h4'}
                  fontWeight={'800'}
                  sx={{ marginBottom: 1 }}
                >
                  Remove Member
                </Typography>
                <Typography
                  variant={'body1'}
                  fontWeight={'400'}
                  style={{ marginBottom: '15px' }}
                >
                  Are you sure you'd like to remove this member from your
                  community?
                </Typography>
              </Grid>

              <Grid
                item
                container
                justifyContent={'flex-end'}
                spacing={2}
                paddingTop={5}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      dispatch(setDeleteUser(undefined));
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>{' '}
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      if (selectedCommunity && deleteUser && deleteUser.id) {
                        // Remove all references to community for user
                        db.ref(
                          '/new_data/community_data/admins/' +
                            selectedCommunity.communityId +
                            '/' +
                            deleteUser.id
                        ).remove();

                        db.ref(
                          '/new_data/community_data/community_users/' +
                            selectedCommunity.communityId +
                            '/' +
                            deleteUser.id +
                            '/active'
                        ).set(false);

                        db.ref(
                          '/new_data/community_data/community_users/' +
                            selectedCommunity.communityId +
                            '/' +
                            deleteUser.id +
                            '/active'
                        ).set(false);

                        db.ref(
                          '/new_data/user_data/user_communities/' +
                            deleteUser.id +
                            '/' +
                            selectedCommunity.communityId +
                            '/active'
                        ).set(false);
                        dispatch(
                          setUserList(
                            userList.filter(
                              (val: UserDataModel) => val.id !== deleteUser.id
                            )
                          )
                        );

                        createEvent(
                          deleteUser.id,
                          selectedCommunity.communityId,
                          CreatorEnum.COMMUNITY,
                          EventType.COMMUNITY_KICKED,
                          selectedCommunity.communityId
                        );

                        dispatch(setDeleteUser(undefined));

                        if (allCoalitions) {
                          for (let i = 0; i < allCoalitions.length; i++) {
                            // Remove user reference for coalition!

                            let id = allCoalitions[i].coalitionId;

                            db.ref(
                              `/new_data/user_data/user_coalitions/${deleteUser.id}/${id}/active`
                            ).set(false);

                            db.ref(
                              `/new_data/coalition_data/coalition_users/${id}/${deleteUser.id}`
                            ).remove();
                          }
                        }

                        toast.success('User deleted');
                      }
                    }}
                  >
                    Yes, Delete
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>

      <Modal
        open={showInviteModal}
        onClose={() => {
          setShowInviteModal(false);
          setAdminEmails([]);
          setAdminEmailsError('');
        }}
        aria-labelledby="Invite members"
        aria-describedby="Send an email invite directly to your team."
      >
        <Grid
          container
          xs={12}
          sx={{ height: '100vh' }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid
            item
            container
            xs={10}
            sx={{
              backgroundColor: 'white',
              margin: 'auto',
              padding: '35px',
              borderRadius: '10px',
            }}
          >
            <Grid container flexDirection={'column'}>
              {/* Title */}
              <Grid
                item
                container
                flexDirection={'column'}
                style={{
                  borderBottom: '0.5px solid rgba(200,200,200,0.4)',
                }}
              >
                <Typography
                  variant={'h4'}
                  fontWeight={'800'}
                  sx={{ marginBottom: 1 }}
                >
                  Invite Member(s)
                </Typography>
                <Typography
                  variant={'body1'}
                  fontWeight={'400'}
                  style={{ marginBottom: '15px' }}
                >
                  Ready to grow your community? Just type in your members email
                  addresses to invite them!
                </Typography>
              </Grid>

              <Grid
                item
                container
                justifyContent={'flex-end'}
                spacing={2}
                paddingTop={5}
              >
                <Grid item container flexDirection={'column'}>
                  <Typography
                    variant="h6"
                    fontWeight={'bold'}
                    style={{ fontSize: 12, color: 'rgba(0,0,0,0.7)' }}
                  >
                    MEMBER EMAIL(S)*
                  </Typography>

                  <FormControl fullWidth>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      multiple={true}
                      options={[]}
                      value={adminEmails}
                      sx={{ flex: 1, border: 'none' }}
                      freeSolo
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Enter Email(s)"
                        />
                      )}
                      onChange={(event: any, newValue: string[]) => {
                        if (newValue !== null) {
                          setAdminEmails(newValue);
                        }
                      }}
                    />
                  </FormControl>

                  {adminEmailsError !== '' && (
                    <Typography
                      variant={'body2'}
                      color={'primary'}
                      style={{ marginBottom: '10px' }}
                    >
                      {adminEmailsError}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setShowInviteModal(false);
                      setAdminEmails([]);
                      setAdminEmailsError('');
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>{' '}
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    disabled={adminEmails.length === 0}
                    onClick={() => {
                      let numErrors = 0;
                      if (adminEmails.length === 0) {
                        numErrors += 1;
                        setAdminEmailsError(
                          'You must enter at least one email. (Type & press "Enter")'
                        );
                      } else {
                        for (let i = 0; i < adminEmails.length; i++) {
                          let email = adminEmails[i];

                          if (email === '') {
                            setAdminEmailsError(`Emails must not be empty.`);
                            numErrors += 1;
                          } else if (email.length > 40) {
                            setAdminEmailsError(
                              'Emails must be less than 40 characters.'
                            );
                            numErrors += 1;
                          } else if (!checkValidEmail(email)) {
                            setAdminEmailsError(
                              'Email(s) provided are invalid.'
                            );
                            numErrors += 1;
                          } else {
                            setAdminEmailsError('');
                          }
                        }
                      }

                      if (numErrors === 0) {
                        setShowInviteModal(false);

                        for (let i = 0; i < adminEmails.length; i++) {
                          let email = adminEmails[i];
                          toast.info(
                            email +
                              ' has been invited, look out for their request.'
                          );

                          db.ref('/new_data/email_send').push({
                            type: 'COMMUNITY_INVITE',
                            emailTo: email,
                            emailFrom: 'noreply@routebuddies.com',
                            data: {
                              date: getCurrentDateTime(),
                              communityId: selectedCommunity.communityId,
                              communityName: selectedCommunity.name,
                              name:
                                userData.firstName + ' ' + userData.lastName,
                            },
                          });
                        }
                        setAdminEmails([]);
                        setAdminEmailsError('');
                      }
                    }}
                  >
                    Invite
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
}
