import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';

import enGB from 'date-fns/locale/en-GB';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { ElectricalServicesSharp, Place } from '@mui/icons-material';
import { isDivideBy, useTools } from '../../../services/general_services';
import { PageType, setCurrentPage } from '../../../store/slices/general.slice';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getCurrentDateTime } from '../../../services/home_services';
import { toast } from 'react-toastify';
import {
  CreatorEnum,
  JourneyModel,
  RepeatDataModel,
} from '../../../models/GroupModel';
import { useSelector } from 'react-redux';
import { db } from '../../../services/firebase_services';
import { RepeatType } from '../../../models/RecurrenceModel';
import {
  getAllCoalitions,
  getAllCommunities,
} from '../../../store/thunks/admin.thunks';
import { CoalitionModel } from '../../../models/CoalitionModel';
import { CommunityModel } from '../../../models/CommunityModel';
import { primaryColor } from '../../../assets/styles/styles';

export function RouteBuddiesNewActivityScreen() {
  const { dispatch, navigate } = useTools();
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [desc, setDesc] = useState('');
  const [descError, setDescError] = useState('');
  const [origin, setOrigin] = useState<any>(undefined);
  const [originError, setOriginError] = useState<any>('');
  const [originFocused, setOriginFocused] = useState(false);
  const [dest, setDest] = useState<any>(undefined);
  const [destError, setDestError] = useState<any>('');
  const [destFocused, setDestFocused] = useState(false);
  const [activityDate, setActivityDate] = useState<any>(new Date());
  const [activityDateError, setActivityDateError] = useState('');
  const [activityTime, setActivityTime] = useState<any>(new Date());

  const [startTime, setStartTime] = useState<any>(new Date());
  const [activityTimeError, setActivityTimeError] = useState('');

  const [originHovered, setOriginHovered] = useState(false);

  const [destHovered, setDestHovered] = useState(false);

  const [titleFocused, setTitleFocused] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const [repeatSpec, setRepeatSpec] = useState('0');
  const [repeatValue, setRepeatValue] = useState(15);
  const [repeatValueError, setRepeatValueError] = useState('');
  const [repeatType, setRepeatType] = useState(RepeatType.MINUTES);

  const [allDay, setAllDay] = useState(false);

  const [repeatStartTime, setRepeatStartTime] = useState<any>(new Date());
  const [repeatEndTime, setRepeatEndTime] = useState<any>(new Date());
  const [repeatDateError, setRepeatDateError] = useState('');

  const [repeatDays, setRepeatDays] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [repeatDaysError, setRepeatDaysError] = useState('');

  const [endValue, setEndValue] = useState('Never');
  const [dateToEnd, setDateToEnd] = useState<any>(new Date());
  const [dateToEndError, setDateToEndError] = useState<any>('');

  enum ActivityType {
    COALITION = 'COALITION',
    COMMUNITY = 'COMMUNITY',
  }

  const [activityType, setActivityType] = useState(ActivityType.COALITION);
  const [chosenCommunity, setChosenCommunity] = useState<
    CommunityModel | undefined
  >(undefined);
  const [chosenCoalition, setChosenCoaltion] = useState<
    CoalitionModel | undefined
  >(undefined);

  const handleEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndValue((event.target as HTMLInputElement).value);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setRepeatSpec(event.target.value as string);
  };
  const { selectedCommunity } = useSelector(
    (state: any) => state.EnterpriseSlice
  );

  const { admin } = useSelector((state: any) => state.GeneralSlice);

  const { allCoalitions, allCommunities } = useSelector(
    (state: any) => state.AdminSlice
  );

  function getMinutes(date1: Date, date2: Date) {
    //@ts-ignore
    var diff = Math.abs(date1 - date2);

    return Math.floor(diff / 1000 / 60);
  }
  const timeSlots = [
    { text: '15 minutes', val: 15 },
    { text: '30 minutes', val: 30 },
    { text: '45 minutes', val: 45 },
    { text: '1 hour', val: 60 },
  ];

  function validateLocationData() {
    let numErrors = 0;

    if (!origin) {
      setOriginError('You must set a starting point for this activity.');
      numErrors += 1;
    } else {
      setOriginError('');
    }

    if (!dest) {
      setDestError('You must set a destination for this activity.');
      numErrors += 1;
    } else {
      setDestError('');
    }

    if (title === '') {
      setTitleError('Title must not be empty');
      numErrors += 1;
    } else if (title.length > 50 || title.length < 2) {
      setTitleError('Title length must be between 2-50 characters.');
      numErrors += 1;
    } else if (!/^[A-Za-z]*/.test(title)) {
      setTitleError('Title must be text only.');
    } else {
      setTitleError('');
    }

    if (desc !== '' && (desc.length > 350 || desc.length < 2)) {
      setDescError('Description length must be between 2-350 characters.');
      numErrors += 1;
    } else if (!/^[A-Za-z]*/.test(desc)) {
      setDescError('Description must be text only.');
    } else {
      setDescError('');
    }

    if (numErrors === 0) {
      setPageNumber(2);
    } else {
      toast.error('Errors within activity data.');
    }
  }

  function removeEmpty(obj: any) {
    let newObj: any = {};
    Object.keys(obj).forEach(key => {
      if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
      else if (obj[key] !== undefined) newObj[key] = obj[key];
    });
    return newObj;
  }
  async function validateDetailsData() {
    let numErrors = 0;

    if (
      moment(getCurrentDateTime()).format('YYYY/MM/DD') ===
      moment(activityDate).format('YYYY/MM/DD')
    ) {
      // Check if time is before?

      let newDate: Date = activityDate;

      if (repeatSpec.toString() !== '1') {
        newDate.setHours(activityTime.getHours());
        newDate.setMinutes(activityTime.getMinutes());
        newDate.setSeconds(activityTime.getSeconds());
      } else {
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
      }

      if (
        repeatSpec.toString() !== '1' &&
        moment(new Date()).isAfter(moment(newDate))
      ) {
        setActivityTimeError('Your activity must take place in the future.');
        setActivityDateError('');
        numErrors += 1;
      } else {
        setActivityTimeError('');
      }
    } else if (moment(getCurrentDateTime()).isAfter(moment(activityDate))) {
      setActivityDateError('Your activity must take place in the future.');
      setActivityTimeError('');
      numErrors += 1;
    } else {
      setActivityDateError('');
    }

    if (repeatSpec.toString() === '1') {
      if (getMinutes(repeatStartTime, repeatEndTime) < repeatValue) {
        setRepeatDateError(
          'Start and end time too short (' + repeatValue + ' minutes minimum)'
        );
        numErrors += 1;
      } else {
        setRepeatDateError('');
      }

      if (
        endValue === 'ON_DATE' &&
        moment(dateToEnd).isSameOrBefore(moment(activityDate))
      ) {
        setDateToEndError('End date must be after initial activity date.');
        numErrors += 1;
      } else {
        setDateToEndError('');
      }

      if (repeatDays.length === 0) {
        setRepeatDaysError('You must select at least one day.');
      } else {
        setRepeatDaysError('');
      }
    }

    if (numErrors === 0) {
      let newDate: Date = activityDate;

      if (repeatSpec.toString() !== '1') {
        newDate.setHours(activityTime.getHours());
        newDate.setMinutes(activityTime.getMinutes());
        newDate.setSeconds(activityTime.getSeconds());
      }

      if (activityType === ActivityType.COALITION && chosenCoalition) {
        let trip: JourneyModel = {
          active: true,
          creationDate: getCurrentDateTime(),
          groupId: '',
          origin: origin,
          destination: dest,
          journeyState: {
            currentIndex: 0,
            stages: [{ dateTime: getCurrentDateTime(), stage: 'WFU' }],
          },
          waypointOrder: [],
          creatorId: chosenCoalition.coalitionId,
          creatorType: CreatorEnum.COALITION,
          title: title,
          description: desc,
          date: newDate.toISOString(),
          communityId: chosenCoalition.coalitionId,
        };
        let repeatData: RepeatDataModel | undefined = undefined;
        if (repeatSpec.toString() === '1') {
          repeatData = {
            repeatValue,
            repeatAllDay: allDay,
            repeatDays: repeatDays,
            repeatStartTime: allDay ? undefined : repeatStartTime,
            repeatEndTime: allDay ? undefined : repeatEndTime,
            repeatEndDate: endValue === 'ON_DATE' ? dateToEnd : undefined,
          };

          repeatData = JSON.parse(JSON.stringify(repeatData));

          trip = { ...trip, repeatData };

          let ref = await db
            .ref(
              '/new_data/coalition_data/repeat_activities/' +
                chosenCoalition?.coalitionId
            )
            .push(trip);

          if (ref !== null && ref.key !== null) {
            trip = { ...trip, groupId: ref.key };
            await db
              .ref(
                '/new_data/coalition_data/repeat_activities/' +
                  chosenCoalition.coalitionId +
                  '/' +
                  ref.key
              )
              .set(trip);
            toast.success('Successfully created activity.');
            window.scrollTo(0, 0);
            navigate('/admin/activities');
            dispatch(setCurrentPage(PageType.ADMIN_ACTIVITIES));
          }
        } else {
          let ref = await db
            .ref(
              '/new_data/coalition_data/activities/' +
                chosenCoalition?.coalitionId
            )
            .push(trip);

          if (ref !== null && ref.key !== null) {
            trip = { ...trip, groupId: ref.key };
            await db
              .ref(
                '/new_data/coalition_data/activities/' +
                  chosenCoalition.coalitionId +
                  '/' +
                  ref.key
              )
              .set(trip);
            toast.success('Successfully created activity.');
            window.scrollTo(0, 0);
            navigate('/admin/activities');
            dispatch(setCurrentPage(PageType.ADMIN_ACTIVITIES));
          }
        }
      } else if (activityType === ActivityType.COMMUNITY && chosenCommunity) {
        let trip: JourneyModel = {
          active: true,
          creationDate: getCurrentDateTime(),
          groupId: '',
          origin: origin,
          destination: dest,
          journeyState: {
            currentIndex: 0,
            stages: [{ dateTime: getCurrentDateTime(), stage: 'WFU' }],
          },
          waypointOrder: [],
          creatorId: chosenCommunity.communityId,
          creatorType: CreatorEnum.COMMUNITY,
          title: title,
          description: desc,
          date: newDate.toISOString(),
          communityId: chosenCommunity.communityId,
        };
        let repeatData: RepeatDataModel | undefined = undefined;
        if (repeatSpec.toString() === '1') {
          repeatData = {
            repeatValue,
            repeatAllDay: allDay,
            repeatDays: repeatDays,
            repeatStartTime: allDay ? undefined : repeatStartTime,
            repeatEndTime: allDay ? undefined : repeatEndTime,
            repeatEndDate: endValue === 'ON_DATE' ? dateToEnd : undefined,
          };

          repeatData = JSON.parse(JSON.stringify(repeatData));

          trip = { ...trip, repeatData };

          let ref = await db
            .ref(
              '/new_data/community_data/repeat_activities/' +
                chosenCoalition?.coalitionId
            )
            .push(trip);

          if (ref !== null && ref.key !== null) {
            trip = { ...trip, groupId: ref.key };
            await db
              .ref(
                '/new_data/community_data/repeat_activities/' +
                  chosenCommunity.communityId +
                  '/' +
                  ref.key
              )
              .set(trip);
            toast.success('Successfully created activity.');
            window.scrollTo(0, 0);
            navigate('/admin/activities');
            dispatch(setCurrentPage(PageType.ADMIN_ACTIVITIES));
          }
        } else {
          let ref = await db
            .ref(
              '/new_data/community_data/activities/' +
                chosenCommunity.communityId
            )
            .push(trip);

          if (ref !== null && ref.key !== null) {
            trip = { ...trip, groupId: ref.key };
            await db
              .ref(
                '/new_data/community_data/activities/' +
                  chosenCommunity.communityId +
                  '/' +
                  ref.key
              )
              .set(trip);
            toast.success('Successfully created activity.');
            window.scrollTo(0, 0);
            navigate('/admin/activities');
            dispatch(setCurrentPage(PageType.ADMIN_ACTIVITIES));
          }
        }
      }
    } else {
      toast.error('Errors within activity data.');
    }
  }

  useEffect(() => {
    dispatch(setCurrentPage(PageType.ADMIN_NEW_ACTIVITY));

    if (admin) {
      dispatch(getAllCoalitions());
      dispatch(getAllCommunities());
    }
  }, []);

  useEffect(() => {
    if (allCommunities && allCommunities.length > 0) {
      setChosenCommunity(allCommunities[0]);
    }
  }, [allCommunities]);
  useEffect(() => {
    if (allCoalitions && allCoalitions.length > 0) {
      setChosenCoaltion(allCoalitions[0]);
    }
  }, [allCoalitions]);
  return (
    <Grid container item xs={12}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <Helmet>
          <meta
            name="title"
            content="RouteBuddies | The Community Safety App | Safer Together"
          />
          <meta
            name="description"
            content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
          />
          <meta
            name="keywords"
            content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
          />
          <meta name="robots" content="index, follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
          <meta name="author" content="RouteBuddies Limited." />
        </Helmet>

        <Grid
          container
          item
          xs={12}
          spacing={2}
          sx={{
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 2,
          }}
          alignItems={'flex-start'}
        >
          {pageNumber === 0 && (
            <Grid
              item
              container
              xs={12}
              alignItems={'flex-start'}
              flexDirection={'column'}
            >
              <Grid item container flexDirection={'column'}>
                <Typography
                  variant={'h4'}
                  fontWeight={'800'}
                  sx={{ marginBottom: 1 }}
                >
                  New Trip
                </Typography>
                <Typography variant={'body1'} fontWeight={'400'}>
                  Let's get a new activity created. Is this a coalition trip or
                  community trip?
                </Typography>
              </Grid>
              <Grid item container xs={12} spacing={2} paddingTop={5}>
                <Grid item container xs={12} sm={6} sx={{ marginTop: 2 }}>
                  <Typography
                    variant="h6"
                    fontWeight={'bold'}
                    style={{
                      fontSize: 12,
                      color: 'rgba(0,0,0,0.7)',
                      marginBottom: 10,
                    }}
                  >
                    TYPE*
                  </Typography>
                  <FormControl fullWidth>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={activityType}
                      name="radio-buttons-group"
                      onChange={(val: any) => {
                        setActivityType(val.target.value);
                      }}
                    >
                      <FormControlLabel
                        value={ActivityType.COALITION}
                        control={<Radio />}
                        label="Coalition"
                        style={{ fontSize: 16 }}
                      />
                      <FormControlLabel
                        value={ActivityType.COMMUNITY}
                        control={<Radio />}
                        label="Community"
                        style={{ fontSize: 16 }}
                      />
                    </RadioGroup>
                  </FormControl>
                  {titleError !== '' && (
                    <Typography variant={'body2'} color={'primary'}>
                      {titleError}
                    </Typography>
                  )}

                  {activityType === ActivityType.COALITION && (
                    <>
                      <Grid item container sx={{ marginTop: 2 }}>
                        <Typography
                          variant="h6"
                          fontWeight={'bold'}
                          style={{ fontSize: 12, color: 'rgba(0,0,0,0.7)' }}
                        >
                          CHOOSE COALITION
                        </Typography>
                      </Grid>
                      {allCoalitions && chosenCoalition ? (
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={chosenCoalition.coalitionId}
                            onChange={(val: any) => {
                              let coalition = allCoalitions.find(
                                (val2: any) => val2.id === val.target.value
                              );
                              if (coalition) {
                                setChosenCoaltion(coalition);
                              }
                            }}
                          >
                            {allCoalitions.map((val: CoalitionModel) => {
                              return (
                                <MenuItem
                                  value={val.coalitionId}
                                  title={val.name}
                                >
                                  {val.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      ) : (
                        <CircularProgress />
                      )}
                    </>
                  )}

                  {activityType === ActivityType.COMMUNITY && (
                    <>
                      <Grid item container sx={{ marginTop: 2 }}>
                        <Typography
                          variant="h6"
                          fontWeight={'bold'}
                          style={{ fontSize: 12, color: 'rgba(0,0,0,0.7)' }}
                        >
                          CHOOSE COMMUNITY
                        </Typography>
                      </Grid>
                      {allCommunities && chosenCommunity ? (
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={chosenCommunity.communityId}
                            onChange={(val: any) => {
                              let community = allCommunities.find(
                                (val2: any) => val2.id === val.target.value
                              );
                              if (community) {
                                setChosenCommunity(community);
                              }
                            }}
                          >
                            {allCommunities.map((val: CommunityModel) => {
                              return (
                                <MenuItem
                                  value={val.communityId}
                                  title={val.name}
                                >
                                  {val.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      ) : (
                        <CircularProgress />
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
          {pageNumber === 1 && (
            <Grid
              item
              container
              xs={12}
              alignItems={'flex-start'}
              flexDirection={'column'}
            >
              <Grid item container flexDirection={'column'}>
                <Typography
                  variant={'h4'}
                  fontWeight={'800'}
                  sx={{ marginBottom: 1 }}
                >
                  New Community Trip
                </Typography>
                <Typography variant={'body1'} fontWeight={'400'}>
                  Let's get setup with a new trip, just let us know some of the
                  basics below.
                </Typography>
              </Grid>
              <Grid item container xs={12} spacing={2} paddingTop={5}>
                <Grid item container xs={12} sm={6} sx={{ marginTop: 2 }}>
                  <Typography
                    variant="h6"
                    fontWeight={'bold'}
                    style={{ fontSize: 12, color: 'rgba(0,0,0,0.7)' }}
                  >
                    NAME*
                  </Typography>
                  <TextField
                    value={title}
                    variant="standard"
                    multiline
                    onChange={val => {
                      setTitle(val.target.value);
                    }}
                    placeholder="Enter Title (required)"
                    sx={{
                      marginTop: 2,
                      marginBottom: 2,
                      width: '100%',
                    }}
                  />
                  {titleError !== '' && (
                    <Typography variant={'body2'} color={'primary'}>
                      {titleError}
                    </Typography>
                  )}

                  <Grid item container sx={{ marginTop: 2 }}>
                    <Typography
                      variant="h6"
                      fontWeight={'bold'}
                      style={{ fontSize: 12, color: 'rgba(0,0,0,0.7)' }}
                    >
                      DESCRIPTION
                    </Typography>
                  </Grid>
                  <TextField
                    value={desc}
                    variant="standard"
                    multiline
                    onChange={val => {
                      setDesc(val.target.value);
                    }}
                    inputProps={{ style: { height: 150 } }} // font size of input text
                    placeholder="Enter Description"
                    sx={{
                      marginTop: 2,
                      marginBottom: 2,
                      width: '100%',
                    }}
                  />
                  {descError !== '' && (
                    <Typography variant={'body2'} color={'primary'}>
                      {descError}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  justifyContent={'flex-start'}
                  alignContent={'flex-start'}
                >
                  <Grid item container sx={{ marginTop: 2 }}>
                    <Typography
                      variant="h6"
                      fontWeight={'bold'}
                      style={{ fontSize: 12, color: 'rgba(0,0,0,0.7)' }}
                    >
                      STARTING POINT*
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    flexDirection={'row'}
                    alignItems={'center'}
                    sx={{
                      borderBottom: originFocused
                        ? '2px solid #cd0438'
                        : originHovered
                        ? '2px solid black'
                        : '1px solid rgba(0, 0, 0, 0.42)',
                      marginBottom: '16px',
                    }}
                  >
                    <Place />
                    <ReactGoogleAutocomplete
                      apiKey={'AIzaSyCJHE2Z2mJ5J55bJjl6lcFyCnC8mkW_zPM'}
                      options={{
                        types: ['geocode', 'establishment'],
                      }}
                      defaultValue={origin ? origin.address : ''}
                      onPlaceSelected={place => {
                        setOrigin({
                          address: place.formatted_address,
                          lat: place.geometry.location.lat(),
                          lng: place.geometry.location.lng(),
                        });
                      }}
                      placeholder={'Enter Starting Point (required)'}
                      className="MuiInputBase-input MuiOutlinedInput-input css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input"
                      style={{
                        flex: 1,
                        fontFamily: 'Urbanist',
                        width: '100%',
                        paddingBottom: '7px',
                      }}
                      onMouseOver={() => {
                        setOriginHovered(true);
                      }}
                      onMouseLeave={() => {
                        setOriginHovered(false);
                      }}
                      onFocus={() => {
                        setOriginFocused(true);
                      }}
                      onBlur={() => {
                        setOriginFocused(false);
                      }}
                    />
                  </Grid>

                  {originError !== '' && (
                    <Typography variant={'body2'} color={'primary'}>
                      {originError}
                    </Typography>
                  )}
                  <Grid item container sx={{ marginTop: 2 }}>
                    <Typography
                      variant="h6"
                      fontWeight={'bold'}
                      style={{ fontSize: 12, color: 'rgba(0,0,0,0.7)' }}
                    >
                      ENDING POINT*
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    flexDirection={'row'}
                    alignItems={'center'}
                    sx={{
                      borderBottom: destFocused
                        ? '2px solid #cd0438'
                        : destHovered
                        ? '2px solid black'
                        : '1px solid rgba(0, 0, 0, 0.42)',
                    }}
                  >
                    <Place />
                    <ReactGoogleAutocomplete
                      apiKey={'AIzaSyCJHE2Z2mJ5J55bJjl6lcFyCnC8mkW_zPM'}
                      defaultValue={dest ? dest.address : ''}
                      onPlaceSelected={place => {
                        setDest({
                          address: place.formatted_address,
                          lat: place.geometry.location.lat(),
                          lng: place.geometry.location.lng(),
                        });
                      }}
                      placeholder={'Enter Destination (required)'}
                      options={{
                        types: ['geocode', 'establishment'],
                      }}
                      className="MuiInputBase-input MuiOutlinedInput-input css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input"
                      style={{
                        flex: 1,
                        fontFamily: 'Urbanist',
                        width: '100%',
                        marginBottom: 4,
                      }}
                      onFocus={() => {
                        setDestFocused(true);
                      }}
                      onBlur={() => {
                        setDestFocused(false);
                      }}
                      onMouseOver={() => {
                        setDestHovered(true);
                      }}
                      onMouseLeave={() => {
                        setDestHovered(false);
                      }}
                    />
                  </Grid>

                  {destError !== '' && (
                    <Typography variant={'body2'} color={'primary'}>
                      {destError}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
          {pageNumber === 2 && (
            <>
              <Grid
                item
                container
                xs={12}
                alignItems={'flex-start'}
                spacing={2}
              >
                <Grid item container xs={12} flexDirection={'column'}>
                  <Typography
                    variant={'h4'}
                    fontWeight={'800'}
                    sx={{ marginBottom: 1 }}
                  >
                    New Community Trip
                  </Typography>
                  <Typography variant={'body1'} fontWeight={'400'}>
                    Let's get setup with a new trip, just let us know some of
                    the basics below.
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={12} spacing={2}>
                  <Grid item container xs={12} sm={6}>
                    <DatePicker
                      label={
                        repeatSpec.toString() === '1'
                          ? 'Start Date'
                          : 'Activity Date'
                      }
                      value={activityDate}
                      onChange={newValue => setActivityDate(newValue)}
                      sx={{
                        marginBottom: activityDateError ? 1 : 0,
                        width: '100%',
                      }}
                    />
                    {activityDateError !== '' && (
                      <Typography variant={'body2'} color={'primary'}>
                        {activityDateError}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item container xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Repeat
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={repeatSpec}
                        label="Repeat"
                        onChange={handleChange}
                      >
                        <MenuItem value={0}>One-Off</MenuItem>
                        <MenuItem value={1}>Repeat</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {repeatSpec.toString() === '0' && (
                    <Grid item container xs={12} sm={6}>
                      <TimePicker
                        label="Activity Time"
                        value={activityTime}
                        onChange={newValue => setActivityTime(newValue)}
                        sx={{
                          marginBottom: activityDateError ? 1 : 0,
                          width: '100%',
                        }}
                      />
                      {activityTimeError !== '' && (
                        <Typography
                          variant={'body2'}
                          color={'primary'}
                          paddingBottom={0}
                        >
                          {activityTimeError}
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {repeatSpec.toString() === '1' && (
                <Grid
                  item
                  container
                  sx={{
                    borderTop: '1px solid rgba(0, 0, 0, 0.22)',
                    marginTop: 2,
                  }}
                  spacing={1}
                >
                  <Grid
                    item
                    container
                    flexDirection={'row'}
                    alignContent={'center'}
                    alignItems={'center'}
                    gap={1}
                  >
                    <Grid item container>
                      <Typography
                        variant="h6"
                        fontWeight={'bold'}
                        style={{ fontSize: 12, color: '#000' }}
                      >
                        REPEAT EVERY
                      </Typography>{' '}
                    </Grid>
                    <Grid item container xs={12} paddingTop={2} spacing={1}>
                      {timeSlots.map(val => {
                        let selected = repeatValue === val.val;
                        return (
                          <Grid
                            item
                            container
                            xs={3}
                            height={70}
                            justifyContent={'center'}
                            alignItems={'center'}
                          >
                            <Button
                              sx={{
                                height: '100%',
                                width: '100%',
                                border: '1px solid #cd0438',
                                borderRadius: 5,
                                backgroundColor: selected
                                  ? primaryColor
                                  : 'white',
                              }}
                              disabled={selected}
                              onClick={() => {
                                setRepeatValue(val.val);
                              }}
                            >
                              <Typography
                                sx={{
                                  padding: 0,
                                  margin: 0,
                                  color: selected ? 'white' : primaryColor,
                                }}
                              >
                                {val.text}
                              </Typography>
                            </Button>
                          </Grid>
                        );
                      })}
                    </Grid>

                    {repeatValueError !== '' && (
                      <Typography variant={'body2'} color={'primary'}>
                        {repeatValueError}
                      </Typography>
                    )}
                  </Grid>
                  {(repeatType === RepeatType.MINUTES ||
                    repeatType === RepeatType.HOURS) && (
                    <Grid
                      item
                      container
                      flexDirection={'row'}
                      alignContent={'center'}
                      alignItems={'center'}
                      gap={1}
                    >
                      <Checkbox
                        checked={allDay}
                        onClick={() => {
                          setAllDay(!allDay);
                        }}
                      />{' '}
                      <Typography
                        variant="h6"
                        fontWeight={'bold'}
                        style={{ fontSize: 12, color: '#000' }}
                      >
                        ALL DAY?
                      </Typography>
                    </Grid>
                  )}
                  {allDay && (
                    <Grid item container xs={12} sm={6}>
                      <Typography
                        color={'error'}
                        fontSize={15}
                        sx={{ width: '90%' }}
                      >
                        This event will run every {repeatValue} minutes,
                        starting at {moment(activityTime).format('HH:mma')}
                      </Typography>
                    </Grid>
                  )}
                  {(repeatType === RepeatType.MINUTES ||
                    repeatType === RepeatType.HOURS) &&
                    !allDay && (
                      <Grid
                        item
                        container
                        flexDirection={'row'}
                        alignContent={'flex-start'}
                        alignItems={'center'}
                        gap={1}
                        xs={12}
                        sm={6}
                      >
                        <Grid item container>
                          <Typography
                            variant="h6"
                            fontWeight={'bold'}
                            style={{ fontSize: 12, color: '#000' }}
                          >
                            BETWEEN TIMES:
                          </Typography>
                        </Grid>
                        <TimePicker
                          label="Start Time"
                          value={repeatStartTime}
                          onChange={newValue => setRepeatStartTime(newValue)}
                          sx={{
                            marginBottom: activityDateError ? 1 : 0,
                            width: '100px',
                          }}
                        />
                        <Typography>-</Typography>

                        <TimePicker
                          label="End Time"
                          value={repeatEndTime}
                          onChange={newValue => setRepeatEndTime(newValue)}
                          sx={{
                            marginBottom: activityDateError ? 1 : 0,
                            width: '100px',
                          }}
                        />
                        {repeatDateError !== '' && (
                          <Grid item container>
                            <Typography variant={'body2'} color={'primary'}>
                              {repeatDateError}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  <Grid
                    item
                    container
                    xs={12}
                    sm={6}
                    flexDirection={'column'}
                    alignItems={'flex-start'}
                  >
                    <Typography
                      variant="h6"
                      fontWeight={'bold'}
                      style={{ fontSize: 12, color: '#000' }}
                    >
                      ON DAYS:
                    </Typography>
                    <Grid container item>
                      {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((val, index) => {
                        return (
                          <Grid
                            item
                            boxShadow={1}
                            container
                            justifyContent={'center'}
                            alignItems={'center'}
                            style={{
                              width: 30,
                              height: 30,
                              marginRight: 5,
                              borderRadius: 20,
                              backgroundColor: repeatDays.includes(index + 1)
                                ? primaryColor
                                : 'rgba(200,200,200)',
                              color: repeatDays.includes(index + 1)
                                ? 'rgba(255,255,255)'
                                : 'black',
                              fontSize: 14,
                              fontWeight: repeatDays.includes(index + 1)
                                ? '800'
                                : '500',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              if (repeatDays.includes(index + 1)) {
                                setRepeatDays(
                                  repeatDays.filter(
                                    (val: any, index2: number) =>
                                      val !== index + 1
                                  )
                                );
                              } else {
                                setRepeatDays([...repeatDays, index + 1]);
                              }
                            }}
                          >
                            {val}
                          </Grid>
                        );
                      })}

                      {repeatDaysError !== '' && (
                        <Typography
                          variant={'body2'}
                          color={'primary'}
                          sx={{ marginTop: 2 }}
                        >
                          {repeatDaysError}
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      item
                      container
                      flexDirection={'column'}
                      paddingTop={2}
                    >
                      <Typography
                        variant="h6"
                        fontWeight={'bold'}
                        style={{ fontSize: 12, color: '#000' }}
                      >
                        ENDS:
                      </Typography>{' '}
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={endValue}
                          onChange={handleEndChange}
                        >
                          <FormControlLabel
                            value="Never"
                            control={<Radio />}
                            label="Never"
                          />
                          <Grid container item flexDirection={'row'}>
                            <FormControlLabel
                              value="ON_DATE"
                              control={<Radio />}
                              label="On:"
                            />
                            {endValue === 'ON_DATE' && (
                              <DatePicker
                                label="Activity Date"
                                value={dateToEnd}
                                onChange={newValue => setDateToEnd(newValue)}
                                sx={{
                                  marginBottom: activityDateError ? 1 : 0,
                                  width: '160px',
                                }}
                              />
                            )}
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                      {dateToEndError !== '' && (
                        <Typography variant={'body2'} color={'primary'}>
                          {dateToEndError}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          )}
          <Grid
            item
            container
            xs={12}
            justifyContent={'flex-end'}
            sx={{ marginBottom: 5 }}
          >
            {pageNumber > 0 && (
              <Button
                onClick={() => {
                  setPageNumber(pageNumber - 1);
                }}
                color="inherit"
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(0,0,0,0.6)',
                  color: 'white',
                  marginRight: 2,
                }}
              >
                Back
              </Button>
            )}
            <Button
              onClick={() => {
                if (pageNumber === 0) {
                  if (
                    (activityType === ActivityType.COALITION &&
                      chosenCoalition === undefined) ||
                    (activityType === ActivityType.COMMUNITY &&
                      chosenCommunity === undefined)
                  ) {
                    window.alert('You must select a community/coalition.');
                  } else {
                    setPageNumber(pageNumber + 1);
                  }
                } else if (pageNumber === 1) {
                  validateLocationData();
                } else {
                  validateDetailsData();
                }
              }}
              variant="contained"
            >
              {pageNumber < 2 ? 'Next' : 'Create Activity'}
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
}
