import { Autocomplete, Button, Card, Slide, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';

// Imports; Material UI
import { CheckCircleRounded } from '@mui/icons-material';

import { Grid, useTheme } from '@mui/material';

import { useNavigate } from 'react-router';
import '../../index.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { registerVenueInterest } from '../../services/home_services';
import { isMobile } from 'react-device-detect';
import PhoneNumberInput from '../components/PhoneNumberInput';
import useAnalyticsEventTracker from '../../services/analytics_services';
import { Helmet } from 'react-helmet';
import { RoundedButton } from '../components/RoundedButton';
import { primaryColor } from '../../assets/styles/styles';
import { useTools } from '../../services/general_services';
import { PageType, setCurrentPage } from '../../store/slices/general.slice';
import Features from '../components/Features';
import AppAppBar from '../components/AppAppBar';
import Testimonials from '../components/Testimonials';
import FAQ from '../components/FAQ';
import { ukCities } from '../../assets/data/cityData';
import FAQVSNI from '../components/FAQVSNI';
import VSNIFeatures from '../components/VSNIFeatures';
import TestimonialsVSNI from '../components/TestimonialsVSNI';
import { VSNIAnnouncement } from '../components/VSNIAnnouncement';

export default function VSNI({}: any) {
  const localStyles = {
    centerVert: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      height: '200px',
    },
  };
  const navigate = useNavigate();
  const theme = useTheme();

  const [loggedIn, setLoggedIn] = useState(false);

  const [initialAnimationStart, setInitialAnimation] = useState(false);

  const info_sections = [
    {
      title: 'Employee Security',
      content:
        "Enhance employees' sense of security & well-being during their commutes to work.",
      icon: (
        <img
          src={'/static/secure.png'}
          style={{
            height: 116,
            width: 'auto',
          }}
        />
      ),
    },
    {
      title: 'Enhanced Retention',
      content:
        'Foster loyalty and reduce turnover rates with a commitment to employee well-being.',
      icon: (
        <img
          src={'/static/retention.png'}
          style={{
            height: 116,
            width: 'auto',
          }}
        />
      ),
    },
    {
      title: 'Improved Morale',
      content:
        'Boost morale by prioritizing employee safety, creating a positive work environment.',
      icon: (
        <img
          src={'/static/morale.png'}
          style={{
            height: 116,
            width: 'auto',
          }}
        />
      ),
    },
    {
      title: 'Reduced Absenteeism',
      content:
        'Address safety concerns to minimize absences and increase productivity.',
      icon: (
        <img
          src={'/static/absentee.png'}
          style={{
            height: 116,
            width: 'auto',
          }}
        />
      ),
    },
    {
      title: 'Positive Reputation',
      content:
        'Enhance brand reputation by showcasing a commitment to employee safety.',
      icon: (
        <img
          src={'/static/reputation.png'}
          style={{
            height: 116,
            width: 'auto',
          }}
        />
      ),
    },
    {
      title: 'Crisis Management',
      content:
        'Effectively respond to emergencies with real-time data on employee safety incidents.',
      icon: (
        <img
          src={'/static/crisis.png'}
          style={{
            height: 116,
            width: 'auto',
          }}
        />
      ),
    },
  ];

  // Pre-reg fields
  const [fullName, setFullName] = useState('');
  const [venueName, setVenueName] = useState('');
  const [venueLocations, setVenueLocations] = useState<string[]>([]);
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [fullNameError, setFullNameError] = useState('');
  const [emailAddressError, setEmailAddressError] = useState('');
  const [venueNameError, setVenueNameError] = useState('');
  const [venueLocationError, setVenueLocationError] = useState('');

  //Has user signed up?
  const [done, setDone] = useState(false);

  const regRef: any = useRef(null);
  const infoRef: any = useRef(null);
  const gaEventTracker = useAnalyticsEventTracker('Venues');

  const { dispatch } = useTools();
  useEffect(() => {
    dispatch(setCurrentPage(PageType.ENTERPRISE));
    setInitialAnimation(true);
  }, []);
  function renderForm(type: string) {
    return (
      <>
        {!done ? (
          <Grid
            container
            item
            xs={type === 'large' ? 11 : 12}
            sm={type === 'large' ? 10 : 12}
            md={type === 'large' ? 6 : 12}
            spacing={2}
            justifyContent={type === 'large' ? 'center' : 'flex-start'}
          >
            <Grid
              item
              xs={12}
              sm={6}
              style={type === 'large' ? { paddingBottom: 5 } : {}}
              justifyContent={'center'}
            >
              <TextField
                value={fullName}
                label="Full Name"
                placeholder={'Enter full name'}
                style={{ width: '100%' }}
                onChange={(e: any) => setFullName(e.target.value)}
              ></TextField>
              {fullNameError !== '' && (
                <Typography variant={'body2'} color={'primary'}>
                  {fullNameError}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={type === 'large' ? { paddingBottom: 10 } : {}}
              justifyContent={'center'}
            >
              <TextField
                value={emailAddress}
                label="Email Address"
                placeholder={'Enter email address'}
                style={{ width: '100%' }}
                onChange={(e: any) => setEmailAddress(e.target.value)}
              ></TextField>
              {emailAddressError !== '' && (
                <Typography variant={'body2'} color={'primary'}>
                  {emailAddressError}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={type === 'large' ? { paddingBottom: 10 } : {}}
              justifyContent={'center'}
            >
              <TextField
                value={venueName}
                label={'Organisation Name'}
                placeholder={'Enter organisation name'}
                style={{ width: '100%' }}
                onChange={(e: any) => setVenueName(e.target.value)}
              ></TextField>
              {venueNameError !== '' && (
                <Typography variant={'body2'} color={'primary'}>
                  {venueNameError}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={type === 'large' ? { paddingBottom: 10 } : {}}
              justifyContent={'center'}
            >
              <PhoneNumberInput
                value={phoneNumber}
                placeholder={'Enter phone number'}
                style={{ width: '100%' }}
                onChange={(e: any) => setPhoneNumber(e.target.value)}
              />
              {phoneNumberError !== '' && (
                <Typography variant={'body2'} color={'primary'}>
                  {phoneNumberError}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={type === 'large' ? { paddingBottom: 10 } : {}}
              justifyContent={'center'}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                multiple={true}
                options={ukCities}
                value={venueLocations}
                sx={{ flex: 1, border: 'none' }}
                freeSolo
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder="Enter Location(s)"
                    label="Enter Location(s)"
                  />
                )}
                onChange={(event: any, newValue: string[]) => {
                  if (newValue !== null) {
                    setVenueLocations(newValue);
                  }
                }}
              />
              {venueLocationError !== '' && (
                <Typography variant={'body2'} color={'primary'}>
                  {venueLocationError}
                </Typography>
              )}
            </Grid>

            <Grid container item xs={12} sm={6} justifyContent={'flex-start'}>
              <Button
                onClick={() => {
                  gaEventTracker('interest registered');

                  registerVenueInterest(
                    fullName,
                    setFullNameError,
                    venueName,
                    setVenueNameError,
                    venueLocations,
                    setVenueLocationError,
                    phoneNumber,
                    setPhoneNumberError,
                    emailAddress,
                    setEmailAddressError,
                    setDone
                  );
                }}
                variant={'contained'}
                style={
                  type === 'large'
                    ? {
                        width: '100%',
                        height: 60,
                        marginBottom: 60,
                      }
                    : { width: '100%' }
                }
              >
                Request Interest
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            item
            xs={12}
            flexDirection={'column'}
            justifyContent={'space-evenly'}
            alignItems={'center'}
            sx={{ paddingTop: 5, paddingBottom: 5 }}
          >
            <CheckCircleRounded
              style={{ color: 'green', fontSize: 50, marginBottom: 15 }}
            />
            <Typography
              variant={'body2'}
              textAlign={'center'}
              style={{
                fontFamily: 'Urbanist',
                marginBottom: 10,
                color: theme.palette.primary.dark,
                maxWidth: 550,
              }}
            >
              Thank you for getting in touch! We'll get back to you shortly.
            </Typography>
          </Grid>
        )}
      </>
    );
  }
  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>
      <Grid item sx={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
        <AppAppBar />
      </Grid>

      {/* Section 1 */}
      <Grid container item xs={12} overflow={'hidden'}>
        <Slide timeout={500} in={initialAnimationStart} direction="down">
          <Grid
            container
            style={{
              backgroundColor: theme.palette.secondary.main,
              height: 'calc(100vh - 56px)',
              maxWidth: '100vw',
              backgroundImage: `url(${'/static/city_red.png'})`,
              backgroundSize: 'cover',
              backgroundPosition: 'right center',
            }}
            justifyContent={'center'}
            alignItems={'center'}
            xs={12}
          >
            {' '}
            <Grid
              container
              style={{
                backgroundColor: 'rgba(0,0,0,0.4)',

                maxWidth: '100vw',
                minHeight: '700px',
              }}
              justifyContent={'center'}
              alignItems={'center'}
              xs={12}
            >
              <Grid
                item
                container
                justifyContent={'center'}
                alignItems={'flex-start'}
                xs={12}
                padding={5}
              >
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={'flex-start'}
                  alignItems={'flex-start'}
                  flexDirection={'column'}
                  sx={{ marginTop: '-20px', display: 'block', paddingTop: 5 }}
                >
                  <Grid>
                    <Typography
                      variant="h6"
                      color="inherit"
                      component="div"
                      style={{
                        fontFamily: 'UrbanistBold',
                        fontSize: 34,
                        color: 'white',
                        textAlign: 'left',
                      }}
                      sx={{
                        marginTop: {
                          sm: 10,
                          md: 0,
                        },
                      }}
                    >
                      The Venue Safety Net is here!
                    </Typography>
                    <Typography
                      variant={'body1'}
                      color={'secondary'}
                      textAlign={'left'}
                      style={
                        isMobile
                          ? {
                              fontFamily: 'Urbanist',
                              maxWidth: '400px',
                              marginTop: 5,
                              marginBottom: 30,
                            }
                          : {
                              fontFamily: 'Urbanist',
                              maxWidth: '400px',
                              marginTop: 5,
                            }
                      }
                    >
                      The Venue Safety Net Initiative is a collection of
                      verified establishments dedicated to safeguarding their
                      staff.
                    </Typography>
                    <RoundedButton
                      defaultColor={'#fff'}
                      defaultBackgroundColor={'transparent'}
                      hoverColor={'#cd0438'}
                      hoverBackgroundColor={'#fff'}
                      onClick={() => {
                        gaEventTracker('learn_more_scroll');
                        const sectionElement =
                          document.getElementById('features');
                        const offset = 128;
                        if (sectionElement) {
                          const targetScroll =
                            sectionElement.offsetTop - offset;
                          sectionElement.scrollIntoView({ behavior: 'smooth' });
                          window.scrollTo({
                            top: targetScroll,
                            behavior: 'smooth',
                          });
                        }
                      }}
                      width={180}
                      style={{ marginTop: 25 }}
                    >
                      Learn more
                    </RoundedButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Slide>
      </Grid>
      <VSNIAnnouncement />
      <Grid item container>
        <VSNIFeatures />
      </Grid>
      <div id="features" />
      <Grid
        container
        item
        xs={12}
        justifyContent={'center'}
        ref={infoRef}
        sx={{ paddingTop: 5, backgroundColor: primaryColor }}
      >
        <div id="benefits" />
        <Grid
          container
          item
          xs={12}
          md={10}
          spacing={2}
          style={{
            padding: '15px',
            paddingTop: 30,
            paddingBottom: 30,
            color: theme.palette.primary.dark,
          }}
        >
          <Grid
            item
            md={12}
            xs={12}
            container
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid item container xs={12} flexDirection={'column'}>
              <Typography component="h2" variant="h4" color={'white'}>
                Benefits
              </Typography>
              <Typography
                variant="body1"
                color={'white'}
                sx={{ mb: { xs: 2, sm: 4 } }}
              >
                Learn how you & your staff can benefit from being a part of our
                safety net.
              </Typography>
            </Grid>
            <Grid container item xs={12} spacing={6}>
              {info_sections.map(val => {
                return (
                  <Grid item xs={12} md={4} container>
                    <Card sx={{ width: '100%' }}>
                      <Grid
                        item
                        xs={12}
                        padding={2}
                        flexDirection={'column'}
                        container
                      >
                        <Grid container justifyContent={'center'}>
                          {val.icon}
                        </Grid>

                        <Typography
                          variant={'h5'}
                          color={'inherit'}
                          style={{
                            textAlign: 'center',
                            marginBottom: 10,
                            fontWeight: 'bold',
                            fontSize: 20,
                            marginTop: 15,
                            color: 'rgba(0,0,0,0.8)',
                          }}
                        >
                          {val.title}
                        </Typography>
                        <Typography
                          variant={'body1'}
                          color={'inherit'}
                          textAlign={'center'}
                          fontSize={14}
                          style={{
                            color: 'rgba(0,0,0,0.8)',
                          }}
                        >
                          {val.content}
                        </Typography>
                      </Grid>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>

            <RoundedButton
              defaultColor={primaryColor}
              defaultBackgroundColor={'white'}
              hoverColor={'white'}
              hoverBackgroundColor={primaryColor}
              onClick={() => {
                gaEventTracker('learn_more_scroll');
                const sectionElement = document.getElementById('signup');
                const offset = 128;
                if (sectionElement) {
                  const targetScroll = sectionElement.offsetTop - offset;
                  sectionElement.scrollIntoView({ behavior: 'smooth' });
                  window.scrollTo({
                    top: targetScroll,
                    behavior: 'smooth',
                  });
                }
              }}
              style={{ marginTop: 30 }}
              width={190}
            >
              Join the safety net
            </RoundedButton>
          </Grid>
        </Grid>
      </Grid>

      <Testimonials />
      <FAQVSNI />

      {/* <Grid
        container
        item
        xs={12}
        justifyContent={'center'}
        sx={{
          paddingTop: 5,
          backgroundColor: primaryColor,
          paddingBottom: 10,
        }}
      >
        <Grid item container xs={12} justifyContent={'center'}>
          <Typography
            variant={'h5'}
            color={'white'}
            style={{
              fontFamily: 'Urbanist',
              fontWeight: 800,
              marginBottom: 10,
            }}
          >
            Testimonials:
          </Typography>
        </Grid>

        <Grid
          xs={12}
          sm={10}
          md={8}
          lg={8}
          sx={{ marginBottom: { xs: 5, sm: 0 } }}
        >
          <Carousel />
        </Grid>
        <Grid item container xs={12} justifyContent={'center'}>
          <RoundedButton
            defaultColor={'white'}
            defaultBackgroundColor={primaryColor}
            hoverColor={primaryColor}
            hoverBackgroundColor={'white'}
            onClick={() => {
              window.scrollTo(0, 0)
navigate('/news');
              dispatch(setCurrentPage(PageType.NEWS));
            }}
            width={200}
          >
            See More
          </RoundedButton>
        </Grid>
      </Grid> */}

      <Grid item container xs={12} justifyContent={'center'} ref={regRef}>
        <Grid
          container
          item
          xs={11}
          alignItems={'center'}
          flexDirection={'column'}
          style={{ padding: '15px' }}
        >
          <div id="signup" />
          <Grid
            item
            container
            xs={11}
            sm={6}
            flexDirection={'column'}
            alignItems={'center'}
            alignContent={'center'}
          >
            <Typography
              variant={'h5'}
              color={'primary'}
              style={{
                fontFamily: 'Urbanist',
                marginBottom: 10,
                marginTop: 20,
                fontWeight: 'bold',
              }}
              textAlign={'left'}
              align={'left'}
            >
              Let's Chat
            </Typography>
            <Typography
              variant={'body2'}
              textAlign={'center'}
              style={{
                fontFamily: 'Urbanist',
                marginBottom: 10,
                color: theme.palette.primary.dark,
                maxWidth: 550,
              }}
              align="left"
            >
              Interested in joining? Let us know down below and we'll get in
              touch shortly.
            </Typography>
          </Grid>
        </Grid>
        {renderForm('large')}
      </Grid>
    </>
  );
}
