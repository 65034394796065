import { CssBaseline, ThemeProvider } from '@mui/material';
import AppRoutes from './views/pages/AppRoutes';
import AppTheme from './assets/styles/theme';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import './index.css';
const TRACKING_ID = 'G-TX8KPFV1JZ'; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);
mapboxgl.accessToken =
  'pk.eyJ1IjoiamJsdW5kZWxsIiwiYSI6ImNraTg0dHJhdTAyY2cycW81YXB2Ynd1dDUifQ.LZNgaUEjQSby0QbtTdtTSQ';
function App() {
  return (
    <div>
      <ThemeProvider theme={AppTheme}>
        <CssBaseline />
        <AppRoutes theme={AppTheme} mapboxgl={mapboxgl} />
      </ThemeProvider>
    </div>
  );
}

export default App;
