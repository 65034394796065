import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Newspaper } from '@mui/icons-material';

export default function MediaCard({
  imgSrc,
  title,
  desc,
  onClick,
  source,
}: any) {
  return (
    <Card sx={{ width: '100%' }}>
      <div
        style={{
          position: 'absolute',
          padding: '10px',
          margin: 5,
          borderRadius: 100,
          backgroundColor: '#fff',
          display: 'flex',
        }}
      >
        <Newspaper style={{ fontSize: 15, marginRight: 5 }} />
        <Typography
          variant={'body1'}
          style={{ fontSize: 10, fontFamily: 'UrbanistBold' }}
        >
          {source}
        </Typography>
      </div>
      <CardMedia sx={{ height: 200 }} image={imgSrc} title={title} />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          style={{
            maxWidth: '90%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontFamily: 'UrbanistBold',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          style={{
            fontFamily: 'Urbanist',
          }}
        >
          {desc}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          onClick={() => {
            onClick();
          }}
        >
          Read Article
        </Button>
      </CardActions>
    </Card>
  );
}
