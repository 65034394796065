import { createAsyncThunk } from '@reduxjs/toolkit';
import UserDataModel from '../../models/UserDataModel';
import { db } from '../../services/firebase_services';
import {
  CommentAppModel,
  CommentModel,
  CreatorModel,
  LikeAppModel,
  LikeModel,
  PostAppModel,
} from '../../models/PostModel';
import { getCurrentDateTime } from '../../services/home_services';
import {
  CommunityRequestAppModel,
  CommunityRequestDataModel,
} from '../../models/CommunityRequestModel';
import { getActivityById } from './community.thunks';
import {
  JourneyAppModel,
  UserActivityReferenceModel,
} from '../../models/GroupModel';

// Get user data function lets us retreieve the user data (For logged in user)using a user ID.
export const getCommunityUsers = createAsyncThunk(
  'GetCommunityUsers',
  async ({ id }: any): Promise<UserDataModel[] | undefined> => {
    try {
      let user_communities = db.ref(
        '/new_data/community_data/community_users/' + id
      );

      let users: any = (
        await user_communities
          .orderByChild('active')
          .equalTo(true)
          .once('value')
      ).val();

      let user_list: UserDataModel[] = [];

      if (users !== null) {
        let data: any[] = Object.values(users);

        if (data.length) {
          // Loop through and collate data of

          for (let i = 0; i < data.length; i++) {
            let obj: any = data[i];

            if (obj.userId) {
              let user = db.ref('/new_data/user_data/users/' + obj.userId);

              let userObj = await user.once('value');

              if (userObj && userObj.val() !== null) {
                let objToAdd: any = {
                  id: userObj.val().userId,
                  ...userObj.val(),
                  joinDate: obj.joinDate,
                };

                user_list.push(objToAdd);
              }
            }
          }
        } else {
        }
      }

      return user_list;
    } catch (err: any) {
      // Go to error page if error occurs
      // goToErrorPage(navigate);
    }
  }
);

export const getCommunityRequests = createAsyncThunk(
  'GetCommunityRequests',
  async ({
    community,
  }: any): Promise<CommunityRequestAppModel[] | undefined> => {
    try {
      let comm_requests = db.ref(
        '/new_data/community_data/requests/' + community.communityId
      );

      let comm_data: CommunityRequestDataModel[] | null = (
        await comm_requests.once('value')
      ).val();
      let req_list: CommunityRequestAppModel[] = [];

      if (comm_data !== null) {
        const comm_list = Object.values(comm_data);
        for (let i = 0; i < comm_list.length; i++) {
          let val = comm_list[i];

          let user_req = db.ref('/new_data/user_data/users/' + val.userId);
          let user_data: UserDataModel | null = (
            await user_req.once('value')
          ).val();

          if (user_data === null) {
            throw 'Internal server error.';
          }
          let request: CommunityRequestAppModel = {
            user: user_data,
            creationDate: val.creationDate,
            community: community,
            selfieUrl: val.selfieUrl,
          };

          req_list.push(request);
        }
      }

      return req_list;
    } catch (err: any) {
      // Go to error page if error occurs
      // goToErrorPage(navigate);
    }
  }
);

export const getUserById = createAsyncThunk(
  'GetUser',
  async ({ id }: any): Promise<UserDataModel | undefined> => {
    try {
      let user = db.ref('/new_data/user_data/users/' + id);

      let userObj = await user.once('value');

      if (userObj && userObj.val() !== null) {
        let objToAdd: any = {
          id: userObj.val().userId,
          ...userObj.val(),
        };
        return objToAdd;
      }
    } catch (err: any) {
      // Go to error page if error occurs
      // goToErrorPage(navigate);
    }
  }
);

export const getUserData = createAsyncThunk(
  'GetUser',
  async ({ id }: any): Promise<UserDataModel | undefined> => {
    try {
      let user = db.ref('/new_data/user_data/users/' + id);

      let userObj = await user.once('value');

      if (userObj && userObj.val() !== null) {
        let objToAdd: any = {
          id: userObj.val().userId,
          ...userObj.val(),
        };
        return objToAdd;
      }
    } catch (err: any) {
      // Go to error page if error occurs
      // goToErrorPage(navigate);
    }
  }
);

export const getCommunityPosts = createAsyncThunk(
  'GetCommunityPosts',
  async ({ id, numLoaded }: any): Promise<PostAppModel[] | undefined> => {
    try {
      let postsDb = db.ref('/new_data/community_data/posts/' + id);

      let posts: any = await postsDb.once('value');

      let postList: any[] = [];

      if (postList) {
      }
      return postList;
    } catch (err: any) {
      // Go to error page if error occurs
      // goToErrorPage(navigate);
    }
  }
);

export const likePost = createAsyncThunk(
  'LikePost',
  async ({
    post,
    userId,
    userData,
    communityId,
  }: {
    post: PostAppModel;
    userId: string;
    userData: UserDataModel;
    communityId: string;
  }): Promise<PostAppModel | undefined> => {
    try {
      let creationDate = getCurrentDateTime();
      let newPost: PostAppModel = post;

      let likeObj: LikeModel = { creatorId: communityId, creationDate, id: '' };
      let pushedRef = await db
        .ref('/new_data/community_data/posts/' + post.id + '/likes/')
        .push(likeObj);
      if (pushedRef && pushedRef.key) {
        // Setup likewith it's ID
        likeObj = { ...likeObj, id: pushedRef.key! };
        await db
          .ref(
            '/new_data/community_data/posts/' +
              communityId +
              '/' +
              post.id +
              '/likes/' +
              pushedRef.key
          )
          .set(likeObj);

        let likeCreatorObj: CreatorModel = {
          id: userData.id,
          firstName: userData.firstName,
          selfieImageUrl: userData.selfieImageUrl
            ? userData.selfieImageUrl
            : '',
        };

        let likeAppObj: LikeAppModel = {
          id: '',
          creationDate: '',
          creator: likeCreatorObj,
        };

        if (newPost.likes) {
          newPost = { ...newPost, likes: [...newPost.likes, likeAppObj] };
        } else {
          newPost = { ...newPost, likes: [likeAppObj] };
        }
      }

      return newPost;
    } catch (err) {
      return;
    }

    return;
  }
);

export const unlikePost = createAsyncThunk(
  'UnlikePost',
  async ({
    post,
    like,
    communityId,
  }: {
    post: PostAppModel;
    like: LikeAppModel;
    userData: UserDataModel;
    communityId: string;
  }): Promise<PostAppModel | undefined> => {
    try {
      let newPost: PostAppModel = {
        ...post,
        likes: post.likes?.filter((val: LikeAppModel) => val.id !== like.id),
      };

      await db
        .ref(
          '/new_data/community_data/posts/' +
            communityId +
            '/' +
            post.id +
            '/likes/' +
            like.id
        )
        .remove();

      return newPost;
    } catch (err) {
      return;
    }
  }
);

export const commentPost = createAsyncThunk(
  'CommentPost',
  async ({
    post,
    userData,
    communityId,
    content,
  }: {
    post: PostAppModel;
    userData: UserDataModel;
    communityId: string;
    content: string;
  }): Promise<PostAppModel | undefined> => {
    try {
      let creationDate = getCurrentDateTime();
      let newPost: PostAppModel = post;

      let commentObj: CommentModel = {
        content: content,
        creatorId: communityId,
        creationDate,
        id: '',
        public: true,
      };
      let pushedRef = await db
        .ref('/new_data/community_data/posts/' + post.id + '/comments/')
        .push(commentObj);
      if (pushedRef && pushedRef.key) {
        // Setup likewith it's ID
        commentObj = { ...commentObj, id: pushedRef.key! };
        await db
          .ref(
            '/new_data/community_data/posts/' +
              communityId +
              '/' +
              post.id +
              '/comments/' +
              pushedRef.key
          )
          .set(commentObj);

        let commentCreatorObj: CreatorModel = {
          id: userData.id,
          firstName: userData.firstName,
          selfieImageUrl: userData.selfieImageUrl
            ? userData.selfieImageUrl
            : '',
        };

        let commentAppObj: CommentAppModel = {
          id: '',
          creationDate: creationDate,
          creator: commentCreatorObj,
          content,
        };

        if (newPost.comments) {
          newPost = {
            ...newPost,
            comments: [...newPost.comments, commentAppObj],
          };
        } else {
          newPost = { ...newPost, comments: [commentAppObj] };
        }
      }

      return newPost;
    } catch (err) {
      return;
    }

    return;
  }
);

export const isUserCommunityAdmin = createAsyncThunk(
  'CheckUserAdminStatus',
  async ({
    userId,
    communityId,
  }: {
    userId: string;
    communityId: string;
  }): Promise<boolean> => {
    let admin = false;

    let journeyObjDb = db.ref(
      `/new_data/community_data/admins/${communityId}/${userId}`
    );
    let journey: any = await journeyObjDb.once('value');
    let journeyObj = journey.val();

    if (journeyObj && journeyObj !== null) {
      admin = true;
    }

    return admin;
  }
);

export const getUserJourneysForCommunity = createAsyncThunk(
  'GetUserJourneysForCommunity',
  async ({
    userId,
    communityId,
  }: {
    userId: string;
    communityId: string;
  }): Promise<any> => {
    let list: JourneyAppModel[] = [];

    let journeyObjDb = db.ref(
      `/new_data/user_activities/${userId}/${communityId}`
    );
    let journey: any = (await journeyObjDb.once('value')).val();

    if (journey !== null) {
      let inner_list: UserActivityReferenceModel[] = Object.values(journey);

      for (let i = 0; i < inner_list.length; i++) {
        let activity = await getActivityById(
          inner_list[i].activityId,
          communityId
        );

        if (activity && i !== inner_list.length - 1) {
          list.push(activity);
        } else if (i === inner_list.length) {
          return [...list, activity];
        }
      }
    }
  }
);
