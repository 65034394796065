import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Collapse,
  Fade,
  IconButton,
  Slide,
  TextField,
  Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';

// Imports; Material UI
import {
  ArrowBack,
  CheckCircleRounded,
  ChevronLeft,
  ChevronRight,
  DirectionsWalk,
  DirectionsWalkRounded,
  ExpandMore,
  Favorite,
  FingerprintRounded,
  MoreVert,
  PersonSearch,
  PlayArrowRounded,
  RememberMe,
  Share,
  ToggleOnRounded,
} from '@mui/icons-material';

import { Checkbox, Grid, useTheme } from '@mui/material';

import { db } from '../../services/firebase_services';
import NavBar from '../components/NavBar';
import { useNavigate, useParams } from 'react-router';
import '../../index.css';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { watchAuthState } from '../../services/login_services';
import {
  executeScroll,
  isAppOpen,
  registerInterest,
} from '../../services/home_services';
import { isMobile } from 'react-device-detect';
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
} from '@mui/lab';
import useAnalyticsEventTracker from '../../services/analytics_services';
// import Countdown from "react-countdown/dist/LegacyCountdown";
import React from 'react';
import ReactDOM from 'react-dom';
import Countdown from 'react-countdown';
import { TypeAnimation } from 'react-type-animation';
import { red } from '@mui/material/colors';
import { primaryColor } from '../../assets/styles/styles';
import { Helmet } from 'react-helmet';
import { PageType, setCurrentPage } from '../../store/slices/general.slice';
import { useDispatch } from 'react-redux';

export default function Blogs({}: any) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { blogId } = useParams();

  const [loggedIn, setLoggedIn] = useState(false);

  const [blogs, setBlogs] = useState([
    {
      blogId: 'routebuddies-nq64-safer-streets',
      blogName: 'RouteBuddies x NQ64: Making nightlife safer in Cardiff.',
      blogImage:
        'https://nq64.co.uk/wp-content/uploads/2021/11/nq64_cardiff_main_header-scaled.jpg',
      authorImage:
        'https://i2-prod.walesonline.co.uk/incoming/article26210611.ece/ALTERNATES/s615d/0_ALR_WOL_100223buddiesJPG.jpg',
      authorName: 'Jack Blundell',
      blogContent: [
        {
          title: 'Introduction',
          content:
            "In a city as vibrant and bustling as Cardiff, it's no surprise that bars and clubs are a popular destination for thousands of people each week. Among them is NQ64, an arcade bar that has quickly become a hotspot for dates and socializing. With such a large number of people gathering in one place, safety is always a top priority. That's why NQ64 has partnered with RouteBuddies to introduce original solutions to help their customers get home safely.",
        },
        {
          content:
            'To better understand the current safety initiatives within NQ64, we asked them about their practices for keeping their customers safe. The response was reassuring. NQ64 is partnered with the nighttime radio, which is connected to the police, street pastors, and other door staff in Cardiff, giving them support if needed. Their door staff are in place, and security is present during busy periods, allowing them to identify and stop any people who are too intoxicated or a danger to other customers.',
        },
        {
          title: '"What safety measures do you have in place?"',
          content:
            "But NQ64 doesn't stop there. Security will never kick a person out alone - the whole group will be removed in its entirety so that nobody is put at risk. The same thing happens if one person is too intoxicated to enter - the whole group is denied entry. This not only helps to keep everyone safe, but it also encourages groups to look after each other.",
        },
        {
          content:
            "NQ64 also has a strict no harassment policy in place, although it is unspoken. Bar staff will often monitor the status of vulnerable customers and keep an eye out for any customers making others uncomfortable. NQ64 creates a comfortable and open environment, whereby the staff will always start a conversation with people they think might be in danger, just to check that they're okay.",
        },
        {
          title: '"Do staff undergo training?"',
          content:
            "All bar staff undertake inductions to identify and handle harassment. Door staff and security are also trained to look out for harassment, as well as a range of potential dangers to other customers. It's clear that NQ64 takes the safety of its customers seriously.",
        },
        {
          title: '"What about when somebody leaves the venue?"',
          content:
            "But what happens after customers leave the venue? NQ64 uses the 'safety bus' initiative in Cardiff to help lone customers get a chaperone. The nighttime radio is also used in the same way as the safety bus initiative. While both initiatives are good, NQ64 acknowledged that they can lack the communication needed to put customers' minds at ease in times when a person is in real danger and can be unreliable at times.",
        },
        {
          content:
            "NQ64 door staff sometimes assist people who are too intoxicated and need to get to a taxi, but they addressed that leaving lone vulnerable customers with a male in a position of power is something they'd like to avoid. That's why NQ64 is considering providing body cameras to their door staff for their own safety as well as the safety of their customers.",
        },
        {
          title: 'What does NQ64 think of RouteBuddies?',
          content:
            "When asked about the RouteBuddies initiative, NQ64 expressed enthusiasm, saying that it's a great idea, and the more there are, the better it is for vulnerable adults in the city. 'It definitely feels like the city is less safe than it was five years ago, so more needs to be done in that department.'",
        },
        {
          title: "The 'Safer Together Pledge'",
          content:
            "NQ64 has agreed to join RouteBuddies 'Safer Together Pledge,' committing themselves to doing whatever they can to improve the safety of their customers during their stay and after they leave the venue with the help of RouteBuddies. During this partnership, RouteBuddies hopes to introduce cutting-edge solutions to make it easier than ever for NQ64 to support the safety of their customers and receive support in times of need. RouteBuddies will be listening to feedback from their users as well as the NQ64 management team to shape their system into something that could be adopted by other bars in the nation.",
        },
        {
          title: '',
          content:
            "What does that include? Well - We're keeping our lips sealed right now but we'll be introducing innovative solutions for partnered bars so they can help keep their customers safer, easer than ever. RouteBuddies has a lot in the pipeline, and we can't wait to get some of these new solutions in place in NQ64.",
        },
        {
          title: '',
          content:
            "If your establishment is located in Cardiff and you're passionate about keeping your customers safe, we want to hear from you! Get in touch at contact@routebuddies.com to join the RouteBuddies Safer Together Pledge.",
        },
      ],
      innerBlogImage:
        'https://cdn.pixabay.com/photo/2017/08/06/22/09/architecture-2596881_1280.jpg',
      blogDate: '19th February 2023',
    },
  ]);

  const dispatch = useDispatch();
  const [selectedBlog, setSelectedBlog] = useState<{
    blogId: string;
    blogName: string;
    blogImage: string;
    authorImage: string;
    authorName: string;
    blogContent: any[];
    blogDate: string;
    innerBlogImage: string;
  } | null>(null);
  const [fadeAnimation, setFadeAnimation] = useState(false);
  const [slidenimation, setSlideAnimation] = useState(false);

  useEffect(() => {
    dispatch(setCurrentPage(PageType.BLOGS));
    setFadeAnimation(true);
    setSlideAnimation(true);
  }, []);

  useEffect(() => {
    if (blogId) {
      let blog = blogs.find(val => val.blogId === blogId);
      blog && setSelectedBlog(blog);
    }
  }, [blogId]);

  return (
    <>
      <Helmet>
        <meta name="og:title" content={selectedBlog?.blogName} />
        <meta
          name="description"
          content={selectedBlog?.blogContent[0].content.substring(0, 130)}
        />
        <meta property="og:image" content={'https://i.imgur.com/ryFc8aZ.png'} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="1024" />

        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>
      <NavBar theme={theme} navigate={navigate} loggedIn={loggedIn} />
      <Grid
        xs={12}
        style={{
          height: '80px',
          backgroundImage: `url(${'/static/login-bground.jpg'})`,
        }}
      />
      <Grid
        item
        container
        xs={12}
        justifyContent={'center'}
        style={
          selectedBlog === null
            ? { marginTop: 0, marginBottom: 100 }
            : { marginTop: 0, marginBottom: 100 }
        }
      >
        {selectedBlog === null ? (
          <>
            <Fade in={fadeAnimation} timeout={500}>
              <Grid
                item
                container
                xs={10}
                justifyContent={'flex-start'}
                flexDirection={'column'}
                style={{ borderBottom: '0.5px solid grey', marginBottom: 15 }}
              >
                <Typography
                  variant="h4"
                  fontWeight={'bold'}
                  fontFamily={'Urbanist'}
                  style={{
                    fontFamily: 'UrbanistBold',
                    marginTop: 40,
                    marginBottom: 25,
                    color: primaryColor,
                  }}
                >
                  Our Blogs{'   ✍️'}
                </Typography>
              </Grid>
            </Fade>
            <Slide
              direction="left"
              in={slidenimation}
              timeout={500}
              easing={{ enter: theme.transitions.easing.easeInOut }}
              onEntered={() => {
                setFadeAnimation(true);
              }}
            >
              <Grid item container xs={10} justifyContent={'flex-start'}>
                {blogs.map(
                  (blog: {
                    blogId: string;
                    blogName: string;
                    blogImage: string;
                    authorImage: string;
                    authorName: string;
                    blogContent: any[];
                    blogDate: string;
                    innerBlogImage: string;
                  }) => {
                    return (
                      <Grid item container xs={12} sm={6} md={4}>
                        <Card sx={{ maxWidth: 345, boxShadow: 4 }}>
                          <CardHeader
                            avatar={
                              <Avatar
                                aria-label="recipe"
                                src={blog.authorImage}
                              />
                            }
                            action={
                              <IconButton aria-label="settings">
                                <MoreVert />
                              </IconButton>
                            }
                            title={blog.authorName}
                            subheader={blog.blogDate}
                          />
                          <CardMedia
                            component="img"
                            height="194"
                            image={blog.blogImage}
                            alt={blog.blogName}
                          />
                          <CardContent>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              style={{
                                width: '100%',
                              }}
                            >
                              {blog.blogContent[0].content.substring(0, 130) +
                                '...'}
                            </Typography>
                          </CardContent>
                          <CardActions
                            disableSpacing
                            style={{ justifyContent: 'space-between' }}
                          >
                            <Grid item>
                              <IconButton aria-label="add to favorites">
                                <Favorite />
                              </IconButton>
                              <IconButton aria-label="share">
                                <Share />
                              </IconButton>
                            </Grid>

                            <Button
                              aria-label="share"
                              variant="text"
                              style={{ fontSize: 14 }}
                              onClick={() => {
                                setSelectedBlog(blog);
                              }}
                            >
                              <Typography variant={'body1'}>
                                Read More
                              </Typography>
                              <ChevronRight />
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </Slide>
          </>
        ) : (
          <>
            <div
              style={{
                width: '100%',
                minHeight: '350px',
                backgroundImage: 'url(' + selectedBlog.blogImage + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: 'rgba(0,0,0,0.7)',
                  width: '100%',
                  height: '100%',
                  zIndex: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Grid item xs={10}>
                  <Button
                    color="secondary"
                    variant="text"
                    onClick={() => setSelectedBlog(null)}
                    style={{ position: 'absolute' }}
                  >
                    <ArrowBack style={{ marginRight: 5 }} />
                    {'   '}Return to all blogs
                  </Button>
                  <Typography
                    variant={'h3'}
                    style={{
                      fontFamily: 'Urbanist',
                      padding: '10px',
                      marginTop: '100px',
                      marginBottom: '100px',
                      color: 'white',
                    }}
                  >
                    {selectedBlog.blogName}
                  </Typography>
                </Grid>
              </div>
            </div>
            <Grid container xs={10} justifyContent={'center'}>
              <Grid item container xs={10} justifyContent={'flex-start'}>
                <CardHeader
                  avatar={
                    <Avatar
                      aria-label="recipe"
                      src={selectedBlog.authorImage}
                    />
                  }
                  style={{ color: 'white !important' }}
                  color={'inherit'}
                  title={selectedBlog.authorName}
                  subheader={selectedBlog.blogDate}
                />
              </Grid>

              {selectedBlog.blogContent.map((val, index) => {
                if (index === 2) {
                  return (
                    <Grid item container xs={10} spacing={2}>
                      <Grid item container xs={12} sm={6}>
                        {selectedBlog.blogContent[index].title && (
                          <Typography
                            variant={'h5'}
                            fontWeight={'bold'}
                            style={{
                              fontFamily: 'Urbanist',
                              marginTop: 10,
                              marginBottom: 15,
                            }}
                          >
                            {selectedBlog.blogContent[index].title}
                          </Typography>
                        )}
                        <Typography variant={'body1'}>
                          {selectedBlog.blogContent[index].content}
                        </Typography>
                      </Grid>
                      <Grid item container xs={12} sm={6}>
                        <div
                          style={{
                            width: '100%',
                            height: '250px',
                            backgroundImage:
                              'url(' + selectedBlog.innerBlogImage + ')',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                          }}
                        ></div>
                      </Grid>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={10}>
                      {selectedBlog.blogContent[index].title && (
                        <Typography
                          variant={'h5'}
                          fontWeight={'bold'}
                          style={{
                            fontFamily: 'Urbanist',
                            marginTop: 10,
                            marginBottom: 15,
                          }}
                        >
                          {selectedBlog.blogContent[index].title}
                        </Typography>
                      )}
                      <Typography
                        variant={'body1'}
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        {selectedBlog.blogContent[index].content}
                      </Typography>
                    </Grid>
                  );
                }
              })}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
