import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip, Grid, Avatar } from '@mui/material';
import {
  AutoGraphRounded,
  ChevronRightRounded,
  EditRounded,
  MoreVertRounded,
  PaletteRounded,
} from '@mui/icons-material';

export default function UserCard({ theme, user, setSelectedUser }: any) {
  return (
    <Tooltip title="View Report">
      <Card
        sx={{
          maxWidth: 345,
          height: '100%',
          cursor: 'pointer',
          backgroundColor: theme.palette.secondary.main,
        }}
        onClick={() => {
          setSelectedUser(user);
        }}
      >
        <CardContent>
          <Grid container justifyContent={'space-between'}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              color={theme.palette.text.main}
            >
              {user.firstName + ' ' + user.lastName}
            </Typography>
            <Avatar alt={user.firstName + ' ' + user.lastName}>
              {user.firstName[0]}
            </Avatar>
          </Grid>
          <Typography gutterBottom variant="body1" component="div">
            {user.joinTime}
          </Typography>
          <Typography variant="body2">
            {user.university ? user.university : 'Not a student.'}
          </Typography>
        </CardContent>
        <div className={'flex-grow'} />
      </Card>
    </Tooltip>
  );
}
