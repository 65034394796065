import { Campaign, NotificationAddRounded } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import Marquee from 'react-fast-marquee';
import { useNavigate } from 'react-router';
import { getCurrentDateTime } from '../../services/home_services';

export function VSNIAnnouncement() {
  const navigate = useNavigate();
  return (
    <Marquee style={{ backgroundColor: 'white' }}>
      <Grid
        item
        container
        xs={12}
        sx={{ padding: 2, cursor: 'pointer' }}
        justifyContent={'center'}
        onClick={() => {
          navigate('/VSNI');
        }}
      >
        <Typography
          variant="body2"
          sx={{ fontWeight: 'bold', fontSize: 18, marginRight: '30px' }}
        >
          This months sponsor: Impactive IT Recruitment
        </Typography>
        <Campaign style={{ marginRight: '30px' }} />
        <Typography
          variant="body2"
          sx={{ fontWeight: 'bold', fontSize: 18, marginRight: '30px' }}
        >
          {moment('27/10/2024').isAfter(new Date())
            ? `RouteBuddies 3.0 launches in ${moment('27/10/2024').diff(
                getCurrentDateTime(),
                'days'
              )} days`
            : `RouteBuddies 3.0 is available for download.`}
          RouteBuddies 3.0 launches in
        </Typography>

        <Campaign style={{ marginRight: '30px' }} />
        <Typography
          variant="body2"
          sx={{ fontWeight: 'bold', fontSize: 18, marginRight: '30px' }}
        >
          The Venue Safety Net Initiative has arrived in Cardiff!
        </Typography>

        <Campaign style={{ marginRight: '30px' }} />
        <Typography
          variant="body2"
          sx={{ fontWeight: 'bold', fontSize: 18, marginRight: '30px' }}
        >
          The Venue Safety Net Initiative has arrived in Cardiff!
        </Typography>
        <Campaign style={{ marginRight: '30px' }} />
      </Grid>
    </Marquee>
  );
}
