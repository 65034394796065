import { createAsyncThunk } from '@reduxjs/toolkit';
import UserDataModel from '../../models/UserDataModel';
import { db } from '../../services/firebase_services';
import { CommunityModel } from '../../models/CommunityModel';
import {
  CommentAppModel,
  CommentModel,
  CreatorModel,
  CreatorType,
  LikeAppModel,
  LikeModel,
  PostAppModel,
  PostModel,
  PostType,
} from '../../models/PostModel';
import {
  CreatorEnum,
  JourneyAppModel,
  JourneyModel,
  JourneyUserAppModel,
} from '../../models/GroupModel';
import { getCurrentDateTime } from '../../services/home_services';
import moment from 'moment';
import { CoalitionModel } from '../../models/CoalitionModel';

export const getCommunityPosts = createAsyncThunk(
  'GetCommunityPosts',
  async ({ id, numLoaded }: any): Promise<PostAppModel[] | undefined> => {
    try {
      let postsDb = db.ref('/new_data/community_data/posts/' + id);

      let posts: any = await postsDb.once('value');

      let postList: any[] = [];

      if (postList) {
      }
      return postList;
    } catch (err: any) {
      // Go to error page if error occurs
      // goToErrorPage(navigate);
    }
  }
);

export async function getActivityById(id: string, communityId: string) {
  let activityRef = db.ref(
    '/new_data/community_data/activities/' + communityId + '/' + id
  );

  let activity: any = (await activityRef.once('value')).val();
  let creator: UserDataModel | CoalitionModel | CommunityModel | undefined =
    await getCreatorFromJourney(activity);
  if (creator) {
    let activityToReturn: JourneyAppModel = {
      communityId: activity.communityId,
      active: activity.active,
      creationDate: activity.creationDate,
      groupId: activity.groupId,
      creator: creator,
      creatorType: activity.creatorType,
      origin: activity.origin,
      destination: activity.destination,
      journeyState: activity.journeyState,
      waypointOrder: activity.waypointOrder,
      title: activity.title,
      description: activity.description,
      date: activity.date,
    };
    return activityToReturn;
  }
  return;
}

export const getCommunityActivityById = createAsyncThunk(
  'GetCommunityActivityById',
  async ({
    communityId,
    id,
    numLoaded = 0,
  }: any): Promise<JourneyAppModel | undefined> => {
    try {
      let activity = await getActivityById(id, communityId);
      return activity;
    } catch (err: any) {
      // Go to error page if error occurs
      // goToErrorPage(navigate);
    }
  }
);

export const getUpcomingCommunityActivities = createAsyncThunk(
  'GetUpcomingCommunityActivities',
  async ({
    id,
    startDate,
    endDate,
    allActivities,
    creatorType,
  }: {
    id: string;
    startDate: Date;
    endDate: Date;
    allActivities: JourneyAppModel[];
    creatorType: CreatorEnum;
  }): Promise<{ endDate: Date; activities: JourneyAppModel[] } | undefined> => {
    try {
      let link =
        creatorType === CreatorEnum.COALITION
          ? '/new_data/coalition_data/'
          : '/new_data/community_data/';
      let activitiesDb = db.ref(link + 'activities/' + id);
      let repeatActivitiesDb = db.ref(link + 'repeat_activities/' + id);

      let date = new Date();
      date.setDate(date.getDate() + 14);

      let activities: any = (
        await activitiesDb
          .orderByChild('date')
          .startAt(startDate.toISOString())
          .once('value')
      ).val();

      // Limit repeat activities to trips that are scheduled to start
      let repeatActivities = (
        await repeatActivitiesDb
          .orderByChild('repeatData/repeatEndTime')
          .endAt(endDate.toISOString())
          .once('value')
      ).val();

      let community_activites: JourneyAppModel[] = [];

      if (activities !== null) {
        let list: JourneyModel[] = Object.values(activities);
        for (let i = 0; i < list.length; i++) {
          let activity: JourneyModel = list[i];

          if (
            !allActivities ||
            allActivities.find(val => val.groupId === activity.groupId) ===
              undefined
          ) {
            let creator:
              | UserDataModel
              | CoalitionModel
              | CommunityModel
              | undefined = await getCreatorFromJourney(activity);

            if (creator) {
              let userList = [];
              if (activity.users) {
                for (let j = 0; j < Object.values(activity.users).length; j++) {
                  let curUser: any = Object.values(activity.users)[j];
                  let user = db.ref(
                    '/new_data/user_data/users/' + curUser.userId
                  );
                  //Will listen to the users table and update state automatically
                  //GET ONLY ACTIVE JOURNEYS
                  let userData = (await user.once('value')).val();

                  let journeyUser: JourneyUserAppModel = {
                    user: userData,
                    id: curUser.userId,
                    leaveTime: curUser.leaveTime,
                  };
                  userList.push(journeyUser);
                }
              }
              let activityToReturn: JourneyAppModel = {
                communityId: activity.communityId,
                active: activity.active,
                creationDate: activity.creationDate,
                groupId: activity.groupId,
                creator: creator,
                creatorType: activity.creatorType,
                origin: activity.origin,
                destination: activity.destination,
                journeyState: {
                  currentIndex: activity.journeyState!.currentIndex,
                  stages: Object.values(activity.journeyState!.stages),
                },
                waypointOrder: activity.waypointOrder,
                title: activity.title,
                description: activity.description,
                date: activity.date,
                users: userList,
              };

              community_activites.push(activityToReturn);
            }
          }
        }
      }

      if (repeatActivities !== null) {
        // Repeat activities
        //
        // Starting at the initial 'date' value providied by the activity data,
        // the repeat activities will add up until a specific end date (In this case, 2 days initially)
        // within specific increments.
        //
        // Each repeat activity generated will be a placeholder, and will need to generate
        // a DATE identifier (YYYY-MM-DD) and a TIME identifier (HH:MM)
        //
        // These will indicate where in the database to look-up activity data
        // This data can be used instead of a placeholder, blank activity - Which will be used
        // when no such activity exists yet (A occurence)

        let list: JourneyModel[] = Object.values(repeatActivities);
        list = list.filter(val => val.active);
        // Only show currently active trips...
        list = list.filter(val => {
          if (
            !val.repeatData?.repeatEndDate ||
            moment(val.repeatData.repeatEndDate).isBefore(moment(new Date()))
          ) {
            return val;
          }
        });
        let currentDate = new Date();
        // Loop through currently available repeat trips.
        for (let i = 0; i < list.length; i++) {
          let activity = list[i];

          let creator:
            | UserDataModel
            | CoalitionModel
            | CommunityModel
            | undefined = await getCreatorFromJourney(activity);

          // Do not show repeat activities that are not scheduled yet.
          if (
            ((activity.repeatData &&
              activity.repeatData.repeatEndDate &&
              moment(activity.repeatData.repeatEndDate).isBefore(endDate)) ||
              activity.repeatData) &&
            creator
          ) {
            // Default data for the group...

            let reoccurence: JourneyAppModel = {
              date: activity.date,
              creator: creator,
              creatorType: activity.creatorType,
              active: activity.active,
              repeatData: activity.repeatData,
              reoccurunces: activity.reoccurunces,
              creationDate: activity.creationDate,
              communityId: activity.communityId,
              groupId: activity.groupId,
              origin: activity.origin,
              journeyState: {
                currentIndex: activity.journeyState!.currentIndex,
                stages: Object.values(activity.journeyState!.stages),
              },
              destination: activity.destination,
              title: activity.title,
              description: activity.description,
            };

            // New algorithm.

            let diff = moment(endDate).diff(startDate, 'weeks');
            diff = diff > 0 ? diff : 1;

            let hour_range = 24;

            if (
              activity.repeatData.repeatStartTime &&
              activity.repeatData.repeatEndTime
            ) {
              let val = getNextTimeSlotWithEndTime(
                new Date(),
                new Date(activity.repeatData.repeatStartTime),
                new Date(activity.repeatData.repeatEndTime),

                activity.repeatData.repeatValue
              );

              hour_range = moment(val?.slotEndTime).diff(
                moment(val?.timeSlot),
                'hours'
              );
              hour_range += 1;
            }

            // For each week between search range...
            for (let j = 0; j < diff; j++) {
              //  We now know what time to begin our loops, on the days we would like them.
              let week_date = moment(startDate).add(j, 'weeks');

              // For each day we repeat
              for (let k = 0; k < activity.repeatData.repeatDays.length; k++) {
                let dayNumber = activity.repeatData.repeatDays[k];

                let hourStr = activity.repeatData.repeatAllDay
                  ? '00'
                  : activity.repeatData.repeatStartTime
                  ? moment(activity.repeatData.repeatStartTime)
                      .utc()
                      .format('HH')
                  : '00';
                let minStr = activity.repeatData.repeatAllDay
                  ? '00'
                  : activity.repeatData.repeatStartTime
                  ? moment(activity.repeatData.repeatStartTime)
                      .utc()
                      .format('mm')
                  : '00';

                week_date.utc().set('hour', Number.parseInt(hourStr));
                week_date.utc().set('minute', Number.parseInt(minStr));

                let instance_date = week_date;
                instance_date.set('day', dayNumber);

                // For each hour within day to repeat
                for (let h = 0; h < hour_range; h++) {
                  let repeatId = instance_date.utc().format('yyyy-MM-DD/HH-mm');

                  if (h === 0) {
                  }
                  let reoccurenceDb = await getReoccourence(
                    instance_date.utc().format('yyyy-MM-DD'),
                    instance_date.format('HH-mm'),
                    reoccurence
                  );

                  if (reoccurenceDb !== null) {
                    let userList: JourneyUserAppModel[] = [];
                    for (
                      let j = 0;
                      j < Object.values(reoccurenceDb.users).length;
                      j++
                    ) {
                      let curUser: any = Object.values(reoccurenceDb.users)[j];
                      let user = db.ref(
                        '/new_data/user_data/users/' + curUser.userId
                      );
                      //Will listen to the users table and update state automatically
                      //GET ONLY ACTIVE JOURNEYS
                      let userData = (await user.once('value')).val();

                      let journeyUser: JourneyUserAppModel = {
                        user: userData,
                        id: curUser.userId,
                        leaveTime: curUser.leaveTime,
                      };
                      userList.push(journeyUser);
                    }

                    community_activites.push({
                      ...reoccurence,
                      date: instance_date.utc().toISOString(),
                      users: userList,
                      journeyState: {
                        ...activity.journeyState,
                        currentIndex: activity.journeyState!.currentIndex,
                        stages: Object.values(activity.journeyState!.stages),
                      },
                      repeatId: repeatId,
                    });
                  } else {
                    community_activites.push({
                      ...reoccurence,
                      date: instance_date.utc().toISOString(),
                      repeatId: repeatId,
                      journeyState: {
                        currentIndex: 0,
                        stages: [
                          { stage: 'WFU', dateTime: getCurrentDateTime() },
                        ],
                      },
                      users: [],
                    });
                  }

                  instance_date.utc().add(1, 'hour');
                }
              }
            }
          } else {
          }
          //
          //
        }
      }

      console.log('Returning for ', id, community_activites);
      return { endDate: endDate, activities: community_activites };
    } catch (err: any) {
      // Go to error page if error occurs
      // goToErrorPage(navigate);
    }
  }
);

async function getReoccourence(
  dateStr: string,
  timeStr: string,
  activity: JourneyAppModel
) {
  let location =
    activity.creatorType === 'COMMUNITY' ? 'community_data' : 'coalition_data';

  let id =
    activity.creatorType === 'COMMUNITY'
      ? // @ts-ignore
        activity.creator.communityId
      : // @ts-ignore
        activity.creator.id;

  let reoccurenceRef = db.ref(
    `/new_data/${location}/repeat_activities/${id}/${activity.groupId}/occurences/${dateStr}/${timeStr}`
  );

  let reoccurenceDb = (await reoccurenceRef.once('value')).val();

  return reoccurenceDb;
}

function getNextTimeSlotWithEndTime(
  currentTime: Date,
  startTime: Date,
  endTime: Date,
  repeatValue: number
) {
  const current = moment(currentTime);

  const isDST = current.isDST();

  if (isDST) {
    current.add(1, 'hour');
  }

  let start_today = startTime.setDate(currentTime.getDate());
  let end_today = endTime.setDate(currentTime.getDate());
  let start = moment(start_today).utc();
  let end = moment(end_today).utc();

  // If endTime is earlier in the day than startTime, add a day to end
  if (end.format('HHmm') < start.format('HHmm')) {
    end.add(1, 'day');
  }

  while (start.isSameOrBefore(current)) {
    start.add(repeatValue, 'minutes');
  }

  // Ensure the nextTimeSlot is within the given range
  if (start.isSameOrAfter(end)) {
    return; // No available time slot within the range
  }

  return { timeSlot: start.toDate(), slotEndTime: end.toDate() };
}
export const getAllCommunityActivities = createAsyncThunk(
  'GetAllCommunityActivities',
  async ({
    id,
    numLoaded,
    endDate,
    allActivities,
    coalitionId,
    creator,
  }: any): Promise<
    { activities: JourneyAppModel[]; endDate: Date } | undefined
  > => {
    try {
      let activitiesDb = db.ref('/new_data/community_data/activities/' + id);
      let repeatActivitiesDb = db.ref(
        '/new_data/community_data/repeat_activities/' + id
      );

      endDate = endDate ? endDate : moment(new Date()).add(14, 'd').toDate();

      let activities: any = (
        await activitiesDb
          .orderByChild('date')
          .endAt(endDate.toISOString())
          .once('value')
      ).val();

      // Limit repeat activities to trips that are scheduled to start
      let repeatActivities = (
        await repeatActivitiesDb
          .orderByChild('repeatData/repeatEndTime')
          .endAt(endDate.toISOString())
          .once('value')
      ).val();

      let community_activites: JourneyAppModel[] = [];

      if (activities !== null) {
        let list: JourneyModel[] = Object.values(activities);
        for (let i = 0; i < list.length; i++) {
          let activity: JourneyModel = list[i];

          let creator:
            | UserDataModel
            | CoalitionModel
            | CommunityModel
            | undefined = await getCreatorFromJourney(activity);

          if (creator) {
            let userList = [];
            if (activity.users) {
              for (let j = 0; j < Object.values(activity.users).length; j++) {
                let curUser: any = Object.values(activity.users)[j];
                let user = db.ref(
                  '/new_data/user_data/users/' + curUser.userId
                );
                //Will listen to the users table and update state automatically
                //GET ONLY ACTIVE JOURNEYS
                let userData = (await user.once('value')).val();

                let journeyUser: JourneyUserAppModel = {
                  user: userData,
                  id: curUser.userId,
                  leaveTime: curUser.leaveTime,
                };
                userList.push(journeyUser);
              }
            }
            let activityToReturn: JourneyAppModel = {
              communityId: activity.communityId,
              active: activity.active,
              creationDate: activity.creationDate,
              groupId: activity.groupId,
              creator: creator,
              creatorType: activity.creatorType,
              origin: activity.origin,
              destination: activity.destination,
              journeyState: {
                currentIndex: activity.journeyState!.currentIndex,
                stages: Object.values(activity.journeyState!.stages),
              },
              waypointOrder: activity.waypointOrder,
              title: activity.title,
              description: activity.description,
              date: activity.date,
              users: userList,
            };

            community_activites.push(activityToReturn);
          }
        }
      }

      if (repeatActivities !== null) {
        // Repeat activities
        //
        // Starting at the initial 'date' value providied by the activity data,
        // the repeat activities will add up until a specific end date (In this case, 2 days initially)
        // within specific increments.
        //
        // Each repeat activity generated will be a placeholder, and will need to generate
        // a DATE identifier (YYYY-MM-DD) and a TIME identifier (HH:MM)
        //
        // These will indicate where in the database to look-up activity data
        // This data can be used instead of a placeholder, blank activity - Which will be used
        // when no such activity exists yet (A occurence)

        let list: JourneyModel[] = Object.values(repeatActivities);

        let currentDate = new Date();
        // Loop through currently available repeat trips.
        for (let i = 0; i < list.length; i++) {
          let activity = list[i];

          // Do not show repeat activities that are not scheduled yet.
          if (
            ((activity.repeatData &&
              activity.repeatData.repeatEndDate &&
              moment(activity.repeatData.repeatEndDate).isBefore(endDate)) ||
              activity.repeatData) &&
            creator
          ) {
            // Default data for the group...

            let reoccurence: JourneyAppModel = {
              date: activity.date,
              creator: creator,
              creatorType: activity.creatorType,
              active: activity.active,
              repeatData: activity.repeatData,
              reoccurunces: activity.reoccurunces,
              creationDate: activity.creationDate,
              groupId: activity.groupId,
              journeyState: {
                currentIndex: activity.journeyState!.currentIndex,
                stages: Object.values(activity.journeyState!.stages),
              },
              communityId: activity.communityId,
              origin: activity.origin,
              destination: activity.destination,
              title: activity.title,
              description: activity.description,
            };

            // New algorithm.

            let diff = 2;
            let hour_range = 24;

            if (
              activity.repeatData.repeatStartTime &&
              activity.repeatData.repeatEndTime
            ) {
              let val = getNextTimeSlotWithEndTime(
                new Date(),
                new Date(activity.repeatData.repeatStartTime),
                new Date(activity.repeatData.repeatEndTime),

                activity.repeatData.repeatValue
              );

              hour_range = moment(val?.slotEndTime).diff(
                moment(val?.timeSlot),
                'hours'
              );
              hour_range += 1;
            }

            // For each week between search range...
            for (let j = 0; j < diff; j++) {
              //  We now know what time to begin our loops, on the days we would like them.

              let week_date = moment(endDate).add(-(j + 1), 'weeks');

              if (
                moment(week_date).isAfter(activity.date) &&
                moment(week_date).isBefore(activity.repeatData.repeatEndDate)
              )
                // For each day we repeat
                for (
                  let k = 0;
                  k < activity.repeatData.repeatDays.length;
                  k++
                ) {
                  let dayNumber = activity.repeatData.repeatDays[k];

                  let hourStr = activity.repeatData.repeatAllDay
                    ? '00'
                    : activity.repeatData.repeatStartTime &&
                      activity.repeatData.repeatStartTime >
                        new Date().toISOString()
                    ? moment(new Date()).format('HH')
                    : activity.repeatData.repeatStartTime
                    ? moment(activity.repeatData.repeatStartTime)
                        .utc()
                        .format('HH')
                    : '00';
                  let minStr = activity.repeatData.repeatAllDay
                    ? '00'
                    : activity.repeatData.repeatStartTime
                    ? moment(activity.repeatData.repeatStartTime)
                        .utc()
                        .format('mm')
                    : '00';

                  week_date.utc().set('hour', Number.parseInt('20'));
                  week_date.utc().set('minute', Number.parseInt(minStr));

                  let instance_date = week_date;
                  instance_date.set('day', dayNumber);

                  // For each hour within day to repeat
                  for (let h = 0; h < hour_range; h++) {
                    let repeatId = instance_date
                      .utc()
                      .format('yyyy-MM-DD/HH-mm');

                    if (h === 0) {
                    }
                    let reoccurenceDb = await getReoccourence(
                      instance_date.utc().format('yyyy-MM-DD'),
                      instance_date.format('HH-mm'),
                      reoccurence
                    );

                    if (reoccurenceDb !== null) {
                      let userList: JourneyUserAppModel[] = [];
                      for (
                        let j = 0;
                        j < Object.values(reoccurenceDb.users).length;
                        j++
                      ) {
                        let curUser: any = Object.values(reoccurenceDb.users)[
                          j
                        ];
                        let user = db.ref(
                          '/new_data/user_data/users/' + curUser.userId
                        );
                        //Will listen to the users table and update state automatically
                        //GET ONLY ACTIVE JOURNEYS
                        let userData = (await user.once('value')).val();

                        let journeyUser: JourneyUserAppModel = {
                          user: userData,
                          id: curUser.userId,
                          leaveTime: curUser.leaveTime,
                        };
                        userList.push(journeyUser);
                      }

                      community_activites.push({
                        ...reoccurence,
                        date: instance_date.utc().toISOString(),
                        users: userList,
                        journeyState: {
                          currentIndex: activity.journeyState!.currentIndex,
                          stages: Object.values(
                            reoccurence.journeyState!.stages
                          ),
                        },
                        repeatId: repeatId,
                      });
                    } else {
                      community_activites.push({
                        ...reoccurence,
                        date: instance_date.utc().toISOString(),
                        repeatId: repeatId,
                        users: [],
                      });
                    }

                    instance_date.utc().add(1, 'hour');
                  }
                }
            }
          } else {
          }
          //
          //
        }
      }

      if (coalitionId) {
        // Get coalition trips...
        let coalitionActivitiesDb = db.ref(
          '/new_data/coalition_data/activities/' + coalitionId
        );
        let coalitionRepeatActivitiesDb = db.ref(
          '/new_data/coalition_data/repeat_activities/' + coalitionId
        );

        let date = new Date();
        date.setDate(date.getDate() + 14);

        let coalitionActivities: any = (
          await coalitionActivitiesDb
            .orderByChild('date')
            .endAt(endDate.toISOString())
            .once('value')
        ).val();

        // Limit repeat activities to trips that are scheduled to start
        let coalitionRepeatActivities = (
          await coalitionRepeatActivitiesDb
            .orderByChild('repeatData/repeatEndTime')
            .endAt(endDate.toISOString())
            .once('value')
        ).val();

        if (coalitionActivities !== null) {
          let list: JourneyModel[] = Object.values(coalitionActivities);
          for (let i = 0; i < list.length; i++) {
            let activity: JourneyModel = list[i];

            if (
              !allActivities ||
              allActivities.find(
                (val: any) => val.groupId === activity.groupId
              ) === undefined
            ) {
              let creator:
                | UserDataModel
                | CoalitionModel
                | CommunityModel
                | undefined = await getCreatorFromJourney(activity);

              if (creator) {
                let userList = [];
                if (activity.users) {
                  for (
                    let j = 0;
                    j < Object.values(activity.users).length;
                    j++
                  ) {
                    let curUser: any = Object.values(activity.users)[j];
                    let user = db.ref(
                      '/new_data/user_data/users/' + curUser.userId
                    );
                    //Will listen to the users table and update state automatically
                    //GET ONLY ACTIVE JOURNEYS
                    let userData = (await user.once('value')).val();

                    let journeyUser: JourneyUserAppModel = {
                      user: userData,
                      id: curUser.userId,
                      leaveTime: curUser.leaveTime,
                    };
                    userList.push(journeyUser);
                  }
                }
                let activityToReturn: JourneyAppModel = {
                  communityId: activity.communityId,
                  active: activity.active,
                  creationDate: activity.creationDate,
                  groupId: activity.groupId,
                  creator: creator,
                  creatorType: activity.creatorType,
                  origin: activity.origin,
                  destination: activity.destination,
                  journeyState: {
                    currentIndex: activity.journeyState!.currentIndex,
                    stages: Object.values(activity.journeyState!.stages),
                  },
                  waypointOrder: activity.waypointOrder,
                  title: activity.title,
                  description: activity.description,
                  date: activity.date,
                  users: userList,
                };

                community_activites.push(activityToReturn);
              }
            }
          }
        }

        if (coalitionRepeatActivities !== null) {
          // Repeat activities
          //
          // Starting at the initial 'date' value providied by the activity data,
          // the repeat activities will add up until a specific end date (In this case, 2 days initially)
          // within specific increments.
          //
          // Each repeat activity generated will be a placeholder, and will need to generate
          // a DATE identifier (YYYY-MM-DD) and a TIME identifier (HH:MM)
          //
          // These will indicate where in the database to look-up activity data
          // This data can be used instead of a placeholder, blank activity - Which will be used
          // when no such activity exists yet (A occurence)

          let list: JourneyModel[] = Object.values(coalitionRepeatActivities);

          let currentDate = new Date();
          // Loop through currently available repeat trips.
          for (let i = 0; i < list.length; i++) {
            let activity = list[i];

            let creator:
              | UserDataModel
              | CoalitionModel
              | CommunityModel
              | undefined = await getCreatorFromJourney(activity);

            // Do not show repeat activities that are not scheduled yet.
            if (activity.repeatData && creator) {
              // Default data for the group...
              let reoccurence: JourneyAppModel = {
                communityId: activity.communityId,
                date: activity.date,
                creator: creator,
                creatorType: activity.creatorType,
                active: activity.active,
                repeatData: activity.repeatData,
                reoccurunces: activity.reoccurunces,
                creationDate: activity.creationDate,
                groupId: activity.groupId,
                origin: activity.origin,
                destination: activity.destination,
                title: activity.title,
                description: activity.description,
                journeyState: {
                  currentIndex: activity.journeyState!.currentIndex,
                  stages: Object.values(activity.journeyState!.stages),
                },
              };

              // New algorithm.

              let diff = 2;

              let hour_range = 24;

              if (
                activity.repeatData.repeatStartTime &&
                activity.repeatData.repeatEndTime
              ) {
                let val = getNextTimeSlotWithEndTime(
                  new Date(),
                  new Date(activity.repeatData.repeatStartTime),
                  new Date(activity.repeatData.repeatEndTime),

                  activity.repeatData.repeatValue
                );

                hour_range = moment(val?.slotEndTime).diff(
                  moment(val?.timeSlot),
                  'hours'
                );
              }

              let week_date = moment(endDate).add(-2, 'weeks');

              // For each week between search range...
              for (let j = 0; j < diff; j++) {
                //  We now know what time to begin our loops, on the days we would like them.

                // For each day we repeat
                for (
                  let k = 0;
                  k < activity.repeatData.repeatDays.length;
                  k++
                ) {
                  let dayNumber = activity.repeatData.repeatDays[k];

                  let hourStr = activity.repeatData.repeatAllDay
                    ? '00'
                    : activity.repeatData.repeatStartTime
                    ? moment(activity.repeatData.repeatStartTime)
                        .utc()
                        .format('HH')
                    : '00';
                  let minStr = activity.repeatData.repeatAllDay
                    ? '00'
                    : activity.repeatData.repeatStartTime
                    ? moment(activity.repeatData.repeatStartTime)
                        .utc()
                        .format('mm')
                    : '00';

                  week_date.utc().set('hour', Number.parseInt(hourStr));
                  week_date.utc().set('minute', Number.parseInt(minStr));

                  let instance_date = week_date;
                  instance_date.set('day', dayNumber);

                  // For each hour within day to repeat
                  for (let h = 0; h < hour_range; h++) {
                    let repeatId = instance_date
                      .utc()
                      .format('yyyy-MM-DD/HH-mm');

                    if (h === 0) {
                    }
                    let reoccurenceDb = await getReoccourence(
                      instance_date.utc().format('yyyy-MM-DD'),
                      instance_date.format('HH-mm'),
                      reoccurence
                    );

                    if (reoccurenceDb !== null) {
                      let userList: JourneyUserAppModel[] = [];
                      for (
                        let j = 0;
                        j < Object.values(reoccurenceDb.users).length;
                        j++
                      ) {
                        let curUser: any = Object.values(reoccurenceDb.users)[
                          j
                        ];
                        let user = db.ref(
                          '/new_data/user_data/users/' + curUser.userId
                        );
                        //Will listen to the users table and update state automatically
                        //GET ONLY ACTIVE JOURNEYS
                        let userData = (await user.once('value')).val();

                        let journeyUser: JourneyUserAppModel = {
                          user: userData,
                          id: curUser.userId,
                          leaveTime: curUser.leaveTime,
                        };
                        userList.push(journeyUser);
                      }

                      community_activites.push({
                        ...reoccurence,
                        date: instance_date.utc().toISOString(),
                        users: userList,
                        journeyState: {
                          currentIndex: activity.journeyState!.currentIndex,
                          stages: Object.values(activity.journeyState!.stages),
                        },

                        repeatId: repeatId,
                      });
                    } else {
                      community_activites.push({
                        ...reoccurence,
                        date: instance_date.utc().toISOString(),
                        repeatId: repeatId,
                        users: [],
                      });
                    }

                    instance_date.utc().add(1, 'hour');
                  }
                }
              }
            } else {
            }
            //
            //
          }
        }
      }
      return { endDate: endDate, activities: community_activites };
    } catch (err: any) {
      // Go to error page if error occurs
      // goToErrorPage(navigate);
    }
  }
);

export async function getCreatorFromJourney(activity: JourneyModel) {
  let creator: UserDataModel | CoalitionModel | CommunityModel | undefined =
    undefined;
  if (activity.creatorType === CreatorEnum.COALITION) {
    let ref = db.ref(
      '/new_data/coalition_data/coalitions/' + activity.creatorId
    );
    let data = (await ref.once('value')).val();

    if (data !== null) {
      creator = data;
    }
  } else if (activity.creatorType === CreatorEnum.COMMUNITY) {
    let ref = db.ref(
      '/new_data/community_data/communities/' + activity.creatorId
    );
    let data = (await ref.once('value')).val();

    if (data !== null) {
      creator = data;
    }
  } else if (activity.creatorType === CreatorEnum.USER) {
    let ref = db.ref('/new_data/user_data/users/' + activity.creatorId);
    let data = (await ref.once('value')).val();

    if (data !== null) {
      creator = data;
    }
  }
  return creator;
}
export const likePost = createAsyncThunk(
  'LikePost',
  async ({
    post,
    userId,
    userData,
    communityId,
  }: {
    post: PostAppModel;
    userId: string;
    userData: UserDataModel;
    communityId: string;
  }): Promise<PostAppModel | undefined> => {
    try {
      let creationDate = getCurrentDateTime();
      let newPost: PostAppModel = post;

      let likeObj: LikeModel = { creatorId: communityId, creationDate, id: '' };
      let pushedRef = await db
        .ref('/new_data/community_data/posts/' + post.id + '/likes/')
        .push(likeObj);
      if (pushedRef && pushedRef.key) {
        // Setup likewith it's ID
        likeObj = { ...likeObj, id: pushedRef.key! };
        await db
          .ref(
            '/new_data/community_data/posts/' +
              communityId +
              '/' +
              post.id +
              '/likes/' +
              pushedRef.key
          )
          .set(likeObj);

        let likeCreatorObj: CreatorModel = {
          id: userData.id,
          firstName: userData.firstName,
          selfieImageUrl: userData.selfieImageUrl
            ? userData.selfieImageUrl
            : '',
        };

        let likeAppObj: LikeAppModel = {
          id: '',
          creationDate: '',
          creator: likeCreatorObj,
        };

        if (newPost.likes) {
          newPost = { ...newPost, likes: [...newPost.likes, likeAppObj] };
        } else {
          newPost = { ...newPost, likes: [likeAppObj] };
        }
      }

      return newPost;
    } catch (err) {
      return;
    }

    return;
  }
);

export const unlikePost = createAsyncThunk(
  'UnlikePost',
  async ({
    post,
    like,
    communityId,
  }: {
    post: PostAppModel;
    like: LikeAppModel;
    userData: UserDataModel;
    communityId: string;
  }): Promise<PostAppModel | undefined> => {
    try {
      let newPost: PostAppModel = {
        ...post,
        likes: post.likes?.filter((val: LikeAppModel) => val.id !== like.id),
      };

      await db
        .ref(
          '/new_data/community_data/posts/' +
            communityId +
            '/' +
            post.id +
            '/likes/' +
            like.id
        )
        .remove();

      return newPost;
    } catch (err) {
      return;
    }
  }
);

export const commentPost = createAsyncThunk(
  'CommentPost',
  async ({
    post,
    userData,
    communityId,
    content,
  }: {
    post: PostAppModel;
    userData: UserDataModel;
    communityId: string;
    content: string;
  }): Promise<PostAppModel | undefined> => {
    try {
      let creationDate = getCurrentDateTime();
      let newPost: PostAppModel = post;

      let commentObj: CommentModel = {
        content: content,
        creatorId: communityId,
        creationDate,
        id: '',
        public: true,
      };
      let pushedRef = await db
        .ref('/new_data/community_data/posts/' + post.id + '/comments/')
        .push(commentObj);
      if (pushedRef && pushedRef.key) {
        // Setup likewith it's ID
        commentObj = { ...commentObj, id: pushedRef.key! };
        await db
          .ref(
            '/new_data/community_data/posts/' +
              communityId +
              '/' +
              post.id +
              '/comments/' +
              pushedRef.key
          )
          .set(commentObj);

        let commentCreatorObj: CreatorModel = {
          id: userData.id,
          firstName: userData.firstName,
          selfieImageUrl: userData.selfieImageUrl
            ? userData.selfieImageUrl
            : '',
        };

        let commentAppObj: CommentAppModel = {
          id: '',
          creationDate: creationDate,
          creator: commentCreatorObj,
          content,
        };

        if (newPost.comments) {
          newPost = {
            ...newPost,
            comments: [...newPost.comments, commentAppObj],
          };
        } else {
          newPost = { ...newPost, comments: [commentAppObj] };
        }
      }

      return newPost;
    } catch (err) {
      return;
    }

    return;
  }
);

export const updateComment = createAsyncThunk(
  'UpdateComment',
  async ({
    post,
    comment,
  }: {
    post: PostAppModel;
    comment: CommentAppModel;
  }): Promise<CommentAppModel | undefined> => {
    try {
      await db
        .ref(
          '/new_data/community_data/posts/' +
            post.id +
            '/comments/' +
            comment.id
        )
        .set(comment);

      return comment;
    } catch (err) {
      return;
    }

    return;
  }
);
