import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import { primaryColor } from '../../assets/styles/styles';
import { RoundedButton } from './RoundedButton';

const userTestimonials = [
  {
    avatar: <Avatar alt="Phil Cuff" src="/static/s.jpg" />,
    name: 'Phillip Cuff',
    occupation: 'General Manager, Revolucion de Cuba',
    testimonial:
      ' RouteBuddies means we can take care of our staff whilst showing our dedication as a business to them.',
  },

  {
    avatar: <Avatar alt="Tom" src="/static/images/avatar/david.jpg" />,
    name: 'Tom Corliandris',
    occupation: 'Co-Founder, Boho Club',
    testimonial: `It's simplicity - was the easiest thing about it. It's super easy to use & super user friendly - The less we have to do to do better, the easier things are for everyone!`,
  },
  {
    avatar: <Avatar alt="Shaye" src="/static/images/avatar/david.jpg" />,
    name: 'Shaye Svikeris',
    occupation: 'General Manager, Revolution Cardiff',
    testimonial: `It’s an amazing opportunity for us to safeguard our staff while building a strong community in the nightlife scene. We sometimes spend up to £50 monthly on taxis for our staff, so it’s a very welcomed initiative.`,
  },
  // {
  //   avatar: <Avatar alt="Phil" src="/static/images/avatar/emily.jpg" />,
  //   name: 'Phil',
  //   occupation: 'Assistant General Manager, Revolution',
  //   testimonial:
  //     "The safety net means our late-night staff can recieve critical help even when i'm not available to help.",
  // },
];

const whiteLogos = [
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
];

const darkLogos = [
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg',
];

const logoStyle = {
  width: '64px',
  opacity: 0.3,
};

export default function Testimonials() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'left' },
        }}
      >
        <Typography component="h2" variant="h4" color={primaryColor}>
          Our Testimonials
        </Typography>
        <Typography
          variant="body1"
          color={primaryColor}
          sx={{ mb: { xs: 2, sm: 4 } }}
        >
          Our customers believe in safety in numbers too - See what they love
          about RouteBuddies.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body2" color="black">
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
              </Box>
            </Card>
          </Grid>
        ))}
        <Grid
          item
          container
          justifyContent={'center'}
          sx={{ paddingTop: '20px' }}
        >
          <RoundedButton
            defaultColor={primaryColor}
            defaultBackgroundColor={'white'}
            hoverColor={'white'}
            hoverBackgroundColor={primaryColor}
            style={{ marginTop: 50 }}
            onClick={() => {
              const sectionElement = document.getElementById('signup');
              const offset = 128;
              if (sectionElement) {
                const targetScroll = sectionElement.offsetTop - offset;
                sectionElement.scrollIntoView({ behavior: 'smooth' });
                window.scrollTo({
                  top: targetScroll,
                  behavior: 'smooth',
                });
              }
            }}
            width={190}
          >
            Join Now
          </RoundedButton>
        </Grid>
      </Grid>
    </Container>
  );
}
