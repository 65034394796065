import {
  Avatar,
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment, { isDate } from 'moment';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Delete, Edit, Launch } from '@mui/icons-material';
import {
  getRemainingUserForGroup,
  useTools,
} from '../../../services/general_services';
import UserDataModel from '../../../models/UserDataModel';
import {
  setDeleteUser,
  setSelectedUser,
} from '../../../store/slices/enterprise.users.slice';
import { PostAppModel } from '../../../models/PostModel';
import { CreatorEnum, JourneyAppModel } from '../../../models/GroupModel';
import {
  setSelectedActivity,
  setSelectedCommunity,
} from '../../../store/slices/enterprise.slice';
import { CommunityModel } from '../../../models/CommunityModel';
import { CoalitionModel } from '../../../models/CoalitionModel';
import { setSelectedAdminCommunity } from '../../../store/slices/admin.slice';

export function UserGrid({ rows }: { rows: UserDataModel[] }) {
  const { dispatch, navigate } = useTools();

  const columns: GridColDef[] = [
    {
      field: 'selfieImageUrl',
      headerName: 'Member',
      flex: 2,
      renderCell: params => {
        return (
          <Tooltip title={params.row.firstName + ' ' + params.row.lastName}>
            <Chip
              label={params.row.firstName + ' ' + params.row.lastName}
              avatar={
                <Avatar
                  alt={params.row.firstName}
                  src={params.row.selfieImageUrl}
                />
              }
            />
          </Tooltip>
        );
      },
    },
    {
      field: 'joinDate',
      headerName: 'Date Joined',
      flex: 1,
    },
    {
      field: 'id',
      headerName: 'User ID',
      flex: 1,
    },
    {
      field: 'delete',
      headerName: '',
      flex: 0.5,
      renderCell: params => {
        return (
          <IconButton
            key={'id2'}
            onClick={() => {
              dispatch(setDeleteUser(params.row));
            }}
          >
            <Delete color="error" />
          </IconButton>
        );
      },
    },
    {
      field: 'action',
      headerName: '',
      flex: 0.5,
      renderCell: params => {
        return (
          <IconButton
            key={'id2'}
            onClick={() => {
              dispatch(setSelectedUser(params.row));
              window.scrollTo(0, 0);
              navigate('/enterprise/members/' + params.row.id);
            }}
          >
            <Launch />
          </IconButton>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={rows}
      slotProps={{
        panel: {
          sx: {
            [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
              display: 'none',
            },
          },
        },
      }}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
        filter: {
          filterModel: {
            items: [{ field: 'name', operator: '=', value: '' }],
          },
        },
      }}
      pageSizeOptions={[5]}
      disableRowSelectionOnClick
      style={{ minHeight: 250, width: '100%' }}
    />
  );
}
export function CommunityGrid({ rows }: { rows: CommunityModel[] }) {
  const { dispatch, navigate } = useTools();

  const columns: GridColDef[] = [
    {
      field: 'communityId',
      headerName: 'ID',
      flex: 1,
    },

    {
      field: 'name',
      headerName: 'Name',
      flex: 3,
      renderCell: params => {
        return (
          <Tooltip title={params.row.name ? params.row.name : ''}>
            <Chip
              label={params.row.name}
              avatar={
                <Avatar
                  alt={params.row.name}
                  src={
                    params.row.imageUrl
                      ? params.row.imageUrl
                      : '/static/avatar.svg'
                  }
                />
              }
            />
          </Tooltip>
        );
      },
    },
    {
      field: 'active',
      headerName: 'Active',
      flex: 1,
      renderCell: params => {
        return (
          <Chip
            label={params.row.active ? 'Active' : 'Inactive'}
            color={params.row.active ? 'success' : 'warning'}
          />
        );
      },
    },
    {
      field: 'creationDate',
      headerName: 'Created',
      flex: 2,
      renderCell: params => {
        return (
          <Chip
            label={moment(params.row.creationDate).format('DD/MM/yy HH:mma')}
          />
        );
      },
    },
    {
      field: 'action',
      headerName: '',
      flex: 0.5,
      renderCell: params => {
        return (
          <IconButton
            key={'id2'}
            onClick={() => {
              dispatch(setSelectedAdminCommunity(params.row));
              window.scrollTo(0, 0);
              navigate('/admin/communities/' + params.row.id);
            }}
          >
            <Launch />
          </IconButton>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={rows}
      slotProps={{
        panel: {
          sx: {
            [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
              display: 'none',
            },
          },
        },
      }}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      pageSizeOptions={[5]}
      disableRowSelectionOnClick
      style={{ minHeight: 250, width: '100%' }}
    />
  );
}

export function CoalitionGrid({ rows }: { rows: CoalitionModel[] }) {
  const { dispatch, navigate } = useTools();

  const columns: GridColDef[] = [
    {
      field: 'coalitionId',
      headerName: 'ID',
      flex: 1,
    },

    {
      field: 'name',
      headerName: 'Name',
      flex: 3,
      renderCell: params => {
        return (
          <Tooltip title={params.row.name ? params.row.name : ''}>
            <Chip
              label={params.row.name}
              avatar={
                <Avatar
                  alt={params.row.name}
                  src={
                    params.row.imageUrl
                      ? params.row.imageUrl
                      : '/static/avatar.svg'
                  }
                />
              }
            />
          </Tooltip>
        );
      },
    },

    {
      field: 'action',
      headerName: '',
      flex: 0.5,
      renderCell: params => {
        return (
          <IconButton
            key={'id2'}
            onClick={() => {
              dispatch(setSelectedCommunity(params.row));
              window.scrollTo(0, 0);
              navigate('/admin/coalitions/' + params.row.id);
            }}
          >
            <Launch />
          </IconButton>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={rows}
      slotProps={{
        panel: {
          sx: {
            [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
              display: 'none',
            },
          },
        },
      }}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      pageSizeOptions={[5]}
      disableRowSelectionOnClick
      style={{ minHeight: 250, width: '100%' }}
    />
  );
}

export function CommunityUsersGrid({ rows }: { rows: UserDataModel[] }) {
  const { dispatch, navigate } = useTools();

  const columns: GridColDef[] = [
    {
      field: 'selfieImageUrl',
      headerName: 'User',
      width: 120,
      renderCell: params => {
        return (
          <Tooltip title={params.row.firstName + ' ' + params.row.lastName}>
            <Chip
              label={params.row.firstName + ' ' + params.row.lastName}
              avatar={
                <Avatar
                  alt={params.row.firstName}
                  src={params.row.selfieImageUrl}
                />
              }
            />
          </Tooltip>
        );
      },
    },
    {
      field: 'comm',
      headerName: 'Community',
      flex: 1,
      renderCell: params => {
        return (
          <Tooltip
            title={params.row.community.name ? params.row.community.name : ''}
          >
            <Chip
              label={params.row.community.name}
              avatar={
                <Avatar
                  alt={params.row.community.name}
                  src={params.row.community.logoUrl}
                />
              }
            />
          </Tooltip>
        );
      },
    },
    {
      field: 'joinDate',
      headerName: 'Date Joined',
      flex: 1,
    },
    {
      field: 'id',
      headerName: 'User ID',
      flex: 1,
    },
    {
      field: 'action',
      headerName: '',
      flex: 0.5,
      renderCell: params => {
        return (
          <Button
            onClick={() => {
              dispatch(setSelectedUser(params.row));
              window.scrollTo(0, 0);
              navigate('/admin/members/' + params.row.id);
            }}
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={rows}
      slotProps={{
        panel: {
          sx: {
            [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
              display: 'none',
            },
          },
        },
      }}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      pageSizeOptions={[5]}
      disableRowSelectionOnClick
      style={{ minHeight: 250, width: '100%' }}
    />
  );
}

export function AdminCommunityUsersGrid({
  rows,
}: {
  rows: { id: string; user: UserDataModel[]; community: CommunityModel }[];
}) {
  const { dispatch, navigate } = useTools();

  const columns: GridColDef[] = [
    {
      field: 'selfieImageUrl',
      headerName: 'User',
      width: 120,
      renderCell: params => {
        return (
          <Tooltip
            title={params.row.user.firstName + ' ' + params.row.user.lastName}
          >
            <Chip
              label={params.row.user.firstName + ' ' + params.row.user.lastName}
              avatar={
                <Avatar
                  alt={params.row.user.firstName}
                  src={params.row.user.selfieImageUrl}
                />
              }
            />
          </Tooltip>
        );
      },
    },
    {
      field: 'comm',
      headerName: 'Community',
      flex: 1,
      renderCell: params => {
        return (
          <Tooltip
            title={params.row.community.name ? params.row.community.name : ''}
          >
            <Chip
              label={params.row.community.name}
              avatar={
                <Avatar
                  alt={params.row.community.name}
                  src={params.row.community.logoUrl}
                />
              }
            />
          </Tooltip>
        );
      },
    },
    {
      field: 'joinDate',
      headerName: 'Date Joined',
      flex: 1,
    },
    {
      field: 'id',
      headerName: 'User ID',
      flex: 1,
    },
    {
      field: 'action',
      headerName: '',
      flex: 0.5,
      renderCell: params => {
        return (
          <Button
            onClick={() => {
              dispatch(setSelectedUser(params.row));
              window.scrollTo(0, 0);
              navigate('/admin/members/' + params.row.id);
            }}
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={rows}
      slotProps={{
        panel: {
          sx: {
            [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
              display: 'none',
            },
          },
        },
      }}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      pageSizeOptions={[5]}
      disableRowSelectionOnClick
      style={{ minHeight: 250, width: '100%' }}
    />
  );
}

export function UserActivitiesGrid({
  rows,
  style,
  loadMore,
}: {
  rows: JourneyAppModel[];
  style?: any;
  loadMore?: any;
}) {
  const { dispatch, navigate } = useTools();

  useEffect(() => {
    console.log('rows were: ', rows);
  }, [rows]);

  const columns: GridColDef[] = [
    {
      field: 'organiser',
      headerName: 'Organiser',
      valueGetter: params => params.row?.creator?.firstName,
      flex: 2,
      renderCell: params => {
        return (
          <Tooltip
            title={
              params.row.creatorType !== CreatorEnum.USER
                ? params.row.creator.name
                : params.row.creator.firstName && params.row.creator.lastName
                ? params.row.creator.firstName +
                  ' ' +
                  params.row.creator.lastName
                : ''
            }
          >
            <Chip
              label={
                params.row.creatorType === CreatorEnum.USER
                  ? params.row.creator.firstName +
                    ' ' +
                    params.row.creator.lastName
                  : params.row.creator.name
              }
              avatar={
                <Avatar
                  alt={
                    params.row.creatorType === 'USER'
                      ? params.row.creator?.firstName
                      : params.row.creator.name
                  }
                  src={
                    params.row.creatorType === 'USER'
                      ? params.row.creator.selfieImageUrl
                      : params.row.creator.logoUrl
                  }
                />
              }
            />
          </Tooltip>
        );
      },
    },
    {
      field: 'title',
      headerName: 'Activity Name',
      flex: 1.5,
      valueGetter: params => params.row?.title,
      renderCell: params => {
        return <Tooltip title={'params.row.title'}>{params.row.title}</Tooltip>;
      },
    },
    {
      field: 'origin',
      headerName: 'Trip',
      flex: 3,
      valueGetter: params => params.row?.origin.address,
      renderCell: params => {
        return (
          <Tooltip
            title={
              params.row.origin.address.split(',')[0] +
              ' > ' +
              params.row.destination.address.split(',')[0]
            }
          >
            <>
              {params.row.origin.address.split(',')[0] +
                ' > ' +
                params.row.destination.address.split(',')[0]}
            </>
          </Tooltip>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      valueGetter: params => params.row.date,
      flex: 1.5,
      renderCell: params => {
        return (
          <Tooltip title={moment(new Date(params.row.date)).fromNow()}>
            <Chip label={moment(new Date(params.row.date)).fromNow()} />
          </Tooltip>
        );
      },
    },
    {
      field: 'users',
      headerName: 'Members',
      valueGetter: params => (params.row?.users ? params.row.users.length : 0),
      flex: 1,
      renderCell: params => {
        return (
          <Tooltip
            title={
              params.row?.users
                ? getRemainingUserForGroup(params.row).length
                : 0 + ' Joined'
            }
          >
            <>
              {params.row.users
                ? getRemainingUserForGroup(params.row).length
                : 0}
            </>
          </Tooltip>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      valueGetter: params => params.row.journeyState,
      renderCell: params => {
        let state = params.row.journeyState
          ? params.row.journeyState.stages[
              params.row.journeyState.stages.length - 1
            ].stage
          : 'WFU';

        if (moment(new Date(params.row.date)).isBefore(moment(new Date()))) {
          state = 'JF';
        }
        return (
          <Tooltip
            title={
              state === 'WFU'
                ? 'Upcoming'
                : state === 'JIP'
                ? 'In Progress'
                : 'Done'
            }
          >
            <Chip
              label={
                state === 'WFU'
                  ? 'Upcoming'
                  : state === 'JIP'
                  ? 'In Progress'
                  : 'Done'
              }
              style={{
                fontSize: 12,
                backgroundColor:
                  state === 'WFU'
                    ? 'rgba(255,0,0,0.2)'
                    : state === 'JIP'
                    ? 'rgba(255,100,0,0.2)'
                    : 'rgba(0,255,0,0.2)',
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: 'action',
      headerName: '',
      flex: 0.5,
      renderCell: params => {
        return (
          <Tooltip title="See Activity">
            <IconButton
              key={'id2'}
              onClick={() => {
                dispatch(setSelectedActivity(params.row));
                window.scrollTo(0, 0);
                navigate('/enterprise/activities/' + params.row.id);
              }}
            >
              <Launch />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        rows={rows}
        slotProps={{
          panel: {
            sx: {
              [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
                display: 'none',
              },
            },
          },
        }}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        style={{ minHeight: 250, ...style }}
      />
      {loadMore && loadMore}
    </>
  );
}

export function ActivitiesGrid({
  rows,
  style,
  loadMore,
  columns,
}: {
  rows: JourneyAppModel[];
  style?: any;
  loadMore?: any;
  columns: any;
}) {
  const { dispatch, navigate } = useTools();

  return (
    <>
      <DataGrid
        rows={rows}
        slotProps={{
          panel: {
            sx: {
              [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
                display: 'none',
              },
            },
          },
        }}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        style={{ minHeight: 250, ...style }}
      />
      {loadMore && loadMore}
    </>
  );
}
