import { Slide, Fade, Grid, useTheme, Button, Icon } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';

import NavBar from '../components/NavBar';
import { useLocation, useNavigate, useParams } from 'react-router';
import '../../index.css';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  executeScroll,
  getCurrentDateTime,
} from '../../services/home_services';
import useAnalyticsEventTracker from '../../services/analytics_services';
import { primaryColor } from '../../assets/styles/styles';
import { Helmet } from 'react-helmet';
import { PageType, setCurrentPage } from '../../store/slices/general.slice';
import { useDispatch } from 'react-redux';
import { RoundedButton } from '../components/RoundedButton';
import { getMobileOperatingSystem } from '../../services/general_services';
import Features from '../components/Features';
import { VSNIAnnouncement } from '../components/VSNIAnnouncement';
import { db } from '../../services/firebase_services';
import ReactPlayer from 'react-player';
import VideoPlayer from '../components/VideoPlayer';
import {
  AddReaction,
  DirectionsWalk,
  MapRounded,
  SosRounded,
} from '@mui/icons-material';
import { HomeFeature } from '../components/HomeFeature';

export default function Home() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const appTrigger = params.get('appTrigger');
  const link = params.get('link');
  const id = params.get('id');

  const sponsor = params.get('sponsorInfo');

  const localStyles = {
    centerVert: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      height: '200px',
    },
  };
  const navigate = useNavigate();
  const theme = useTheme();

  const [initialAnimationStart, setInitialAnimation] = useState(false);
  const [fadeAnimation, setFadeAnimation] = useState(false);
  const [fadeAnimation2, setFadeAnimation2] = useState(false);

  const { install } = useParams();
  const deepLink = 'routebuddies://';

  useEffect(() => {
    let fallbackTimeout: any;

    const redirectToDeepLink = () => {
      let location = deepLink;

      if (id && link) {
        location += `${link}/${id}`;
      } else if (link) {
        location += link;
      }

      console.log('Trying deep link:', location);

      // Create an iframe to attempt opening the deep link
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = location;
      document.body.appendChild(iframe);

      // Remove the iframe after attempting
      setTimeout(() => document.body.removeChild(iframe), 1000);
    };

    async function redirectToSponsor() {
      let dateTime = getCurrentDateTime();
      await db.ref('/new_data/sponsor_visits/impactive_it/').push({
        dateTime,
      });
      let location = 'https://impactiveit.com';

      window.location.href = location;
    }

    const redirectToFallback = () => {
      const platform = getMobileOperatingSystem();
      const fallbackLink =
        platform === 'Android'
          ? 'https://play.google.com/store/apps/details?id=com.routebuddies'
          : 'https://apps.apple.com/gb/app/routebuddies/id6443986928';

      console.log('Fallback?');
      window.location.href = fallbackLink;
    };

    const tryRedirect = () => {
      try {
        redirectToDeepLink();

        fallbackTimeout = setTimeout(() => {
          redirectToFallback();
        }, 200); // Adjust timeout as needed
      } catch (err) {
        console.log(err);
      }
    };

    const handleBlur = () => {
      // If the window loses focus before the timeout fires, clear the timeout
      clearTimeout(fallbackTimeout);
    };

    if (appTrigger) {
      gaEventTracker('appTrigger');

      tryRedirect();

      window.addEventListener('blur', handleBlur);

      // Cleanup function to remove the event listener
      return () => {
        window.removeEventListener('blur', handleBlur);
      };
    } else if (sponsor) {
      redirectToSponsor();
    }
  }, [appTrigger]);

  useEffect(() => {
    setInitialAnimation(true);
  }, []);

  const [loggedIn, setLoggedIn] = useState(false);
  const [appOpen, setAppOpen] = useState(false);
  // Pre-reg fields

  const gaEventTracker = useAnalyticsEventTracker('Home');

  const featureRef = useRef<HTMLDivElement | null>(null);
  const [featureAnimation, setFeatureAnimation] = useState(false);
  const howItWorksRef = useRef<HTMLDivElement | null>(null);
  const [howItWorksAnimation, setHowItWorksAnimation] = useState(false);
  const whyRef = useRef<HTMLDivElement | null>(null);
  const [whyRouteBuddiesAnimation, setWhyRouteBuddiesAnimation] =
    useState(false);
  const commRef = useRef<HTMLDivElement | null>(null);
  const [commAnimation, setCommAnimation] = useState(false);

  const ourStoryRef = useRef<HTMLDivElement | null>(null);
  const [ourStoryAnimation, setOurStory] = useState(false);

  const asSeenOnRef = useRef<HTMLDivElement | null>(null);
  const [asSeenOnAnimation, setAsSeenOnAnimation] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function isWithinView(ref: any, setShow: Function, range: number) {
    try {
      if (!ref && !ref.current) return;
      if (ref && ref !== null && ref.current && ref.current !== null) {
        const { top } = ref.current.getBoundingClientRect();

        if (top < range) setShow(true);
      } else {
        return;
      }
    } catch (error) {}
  }
  useEffect(() => {
    !featureAnimation &&
      isWithinView(featureRef, setFeatureAnimation, window.innerHeight * 0.7);
    !howItWorksAnimation &&
      isWithinView(
        howItWorksRef,
        setHowItWorksAnimation,
        window.innerHeight * 0.7
      );
    !whyRouteBuddiesAnimation &&
      isWithinView(
        whyRef,
        setWhyRouteBuddiesAnimation,
        window.innerHeight * 0.7
      );
    !commAnimation &&
      isWithinView(commRef, setCommAnimation, window.innerHeight * 0.7);
    !ourStoryAnimation &&
      isWithinView(ourStoryRef, setOurStory, window.innerHeight * 0.7);
  }, [scrollPosition]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentPage(PageType.HOME));
  }, []);

  function openAppStore() {
    gaEventTracker('go_to_reg_page');
    window.location.href = '/register';
  }

  const featureItems = [
    {
      title: 'Community Spaces',
      description:
        'Create or join private spaces with the people you trust most.',
      imgSrc: '/static/community_icon.png',
    },
    {
      title: 'Local Feeds',
      description:
        'Stay updated and proactive with real-time local safety news.',
      imgSrc: '/static/feed_icon.png',
    },
    {
      title: 'Group-Up',
      description: 'Make your events ',
      imgSrc: '/static/fist_icon.png',
    },
    {
      title: 'Emergency Ready',
      description: 'Automated emergency alerts to contacts and police.',
      imgSrc: '/static/danger_icon.png',
    },
  ];

  const section5Items = [
    {
      title: 'Communities',
      description: 'Join our free public communities, or join a private space.',
      imgSrc: '/static/verified_icon.png',
      imgStyle: { width: '100%', maxWidth: 100 },
    },
    {
      title: 'Find Buddies',
      description:
        "Join communities you for your day-to-day activities. Whether it's your daily dog walk, or a jog around the park!",
      imgSrc: '/static/group_icon.png',
      imgStyle: {
        width: '100%',
        maxWidth: 100,
        paddingTop: '13px',
        paddingBottom: '13px',
      },
    },
    {
      title: 'Make Changes',
      description:
        "Be a part of the larger change we're making in your local area, join our fight for street safety for all.",
      imgSrc: '/static/fist_icon.png',
      imgStyle: { width: '100%', maxWidth: 100 },
    },
  ];

  function Carousel() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div
        style={{
          margin: 3,
          marginBottom: '10px',
          padding: 40,
          width: '100%',
          alignItems: 'center',
          color: '#333',
        }}
      >
        <Slider {...settings}>
          <div style={localStyles.centerVert}>
            <a href="https://www.walesonline.co.uk/news/wales-news/first-kind-app-launches-cardiff-26209655">
              <img
                style={{ width: '90%', margin: '5%', marginTop: '20px' }}
                src={'/static/walesonline.png'}
              />
            </a>
          </div>
          <div style={localStyles.centerVert}>
            <a href="https://businessnewswales.com/a-cardiff-entrepreneur-launches-new-app-to-prevent-street-harassment/">
              <img
                style={{ width: '90%', margin: '5%' }}
                src={'/static/itvwales.png'}
              />
            </a>
          </div>
          <div style={localStyles.centerVert}>
            <a href="https://www.wales247.co.uk/new-app-to-prevent-street-harassment-of-women-in-cardiff">
              <img
                style={{ width: '90%', margin: '5%', marginTop: '25px' }}
                src={'/static/wales247.png'}
              />
            </a>
          </div>
          <div style={localStyles.centerVert}>
            <a href="https://businessnewswales.com/a-cardiff-entrepreneur-launches-new-app-to-prevent-street-harassment/">
              <img
                style={{ width: '90%', margin: '5%' }}
                src={'/static/businessnewswales.png'}
              />
            </a>
          </div>
        </Slider>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>

      <NavBar
        theme={theme}
        navigate={navigate}
        loggedIn={loggedIn}
        transparent={true}
      />

      <Grid
        container
        flexDirection={'column'}
        sx={{
          maxWidth: '100vw',
        }}
      >
        {/* Section 1 - Intro*/}
        <Grid
          container
          flexDirection={'row'}
          style={{
            backgroundColor: primaryColor,
            height: '100vh',
          }}
          justifyContent={'center'}
        >
          <VideoPlayer />
          <Grid
            item
            container
            sx={{
              height: { xs: `100vh` },
              position: 'absolute',
              zIndex: 2,
            }}
            xs={12}
            justifyContent={'center'}
          >
            <Grid
              item
              container
              md={9}
              sx={{
                height: {
                  xs: `100vh`,
                  md: `auto`,
                },
                minHeight: '80vh',

                marginBottom: {
                  xs: 5,
                  sm: 0,
                },
              }}
              style={{
                overflow: 'visible',
                // height: 'Calc(100vh - 160px)',
              }}
            >
              {/* Text */}
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                sx={{ padding: { xs: 10, sm: 10, md: '10px' } }}
                style={{
                  height: 'inherit',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Fade
                  in={fadeAnimation}
                  easing={{ enter: theme.transitions.easing.easeInOut }}
                  timeout={500}
                >
                  <div>
                    <Typography
                      component="h1"
                      variant="h3"
                      color="white"
                      style={{
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                        fontWeight: '700',
                        marginTop: 10,
                      }}
                    >
                      Buddies. Pals. Friends.
                    </Typography>{' '}
                    <Typography
                      variant={'body2'}
                      color={'white'}
                      style={{
                        fontFamily: 'Urbanist',
                        paddingTop: '10px',
                        fontSize: '19px',
                        paddingBottom: '20px',
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                        letterSpacing: 1.2,
                      }}
                    >
                      Explore the world freely with your RouteBuddies today!
                    </Typography>
                    <Grid container>
                      <Button
                        style={{
                          padding: 20,
                          borderRadius: '100px',
                          backgroundColor: 'white',
                          color: 'white',
                          paddingLeft: 40,
                          paddingRight: 40,
                          marginTop: 20,
                          marginRight: 15,
                        }}
                        onClick={() => {
                          executeScroll(featureRef);
                        }}
                      >
                        <Typography
                          style={{
                            color: 'black',
                            fontFamily: 'Urbanist',
                            fontWeight: '700',
                            textTransform: 'none',
                          }}
                        >
                          Learn More
                        </Typography>
                      </Button>
                      <Button
                        style={{
                          padding: 20,
                          borderRadius: '100px',
                          backgroundColor: primaryColor,
                          color: 'white',
                          paddingLeft: 40,
                          paddingRight: 40,
                          marginTop: 20,
                        }}
                        onClick={() => {
                          window.location.href =
                            'https://routebuddies.com/?appTrigger=true';
                        }}
                      >
                        <Typography
                          style={{
                            color: 'white',
                            fontFamily: 'Urbanist',
                            fontWeight: '700',
                            textTransform: 'none',
                          }}
                        >
                          Download the app
                        </Typography>
                      </Button>
                    </Grid>
                  </div>
                </Fade>
              </Grid>
            </Grid>

            <Grid
              style={{ position: 'absolute' }}
              sx={{
                right: {
                  xs: 60,
                  lg: 140,
                },
                bottom: {
                  xs: 20,
                  sm: 50,
                  md: 80,
                  lg: 60,
                  xl: 20,
                },
              }}
            >
              <Slide
                direction="left"
                in={initialAnimationStart}
                timeout={500}
                easing={{ enter: theme.transitions.easing.easeInOut }}
                onEntered={() => {
                  setFadeAnimation(true);
                }}
              >
                <Grid
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '210px',
                      md: '370px',
                      lg: '420px',
                      xl: '460px',
                    },
                    display: {
                      xs: 'none',
                      md: 'flex',
                    },
                  }}
                  className="floating"
                >
                  <img
                    src={'/static/app_phone.png'}
                    style={{
                      width: '100%',
                      zIndex: 100,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />
                </Grid>
              </Slide>
            </Grid>
          </Grid>

          <Grid xs={6} sm={12} md={7}></Grid>
          {/* image */}
          <Grid
            item
            xs={6}
            sm={12}
            md={5}
            justifyContent={'center'}
            sx={{
              zIndex: 20,
              height: { xs: 'auto', md: '200' },
              display: { xs: 'none', sm: 'none', md: 'block' },
            }}
          ></Grid>
        </Grid>

        {/* <VSNIAnnouncement /> */}

        {/* Section 2 - Features*/}

        <div ref={featureRef} style={{ height: '100%', width: '100%' }}>
          {/* Inner Container - Less page width */}

          {/* Section 4 -Why does RouteBuddies Exist */}
          <Grid
            container
            item
            xs={12}
            ref={whyRef}
            justifyContent={'center'}
            style={{ backgroundColor: primaryColor }}
          >
            <Fade
              in={whyRouteBuddiesAnimation}
              easing={{ enter: theme.transitions.easing.easeInOut }}
              timeout={500}
            >
              <Grid container item xs={12}>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={'center'}
                  alignItems={'center'}
                  style={{ backgroundColor: primaryColor }}
                  paddingTop={0}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{
                      display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'flex',
                      },
                      height: '100%',
                    }}
                    md={5}
                    style={{
                      backgroundImage: `url(/static/team.jpg)`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  ></Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    md={7}
                    sx={{
                      padding: { xs: 5, sm: 15 },
                      paddingTop: 15,
                      paddingBottom: 15,
                    }}
                  >
                    <Grid
                      container
                      item
                      sx={{
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="h3"
                        color="white"
                        style={{
                          fontWeight: '700',
                        }}
                        sx={{
                          fontSize: {
                            xs: '2rem',
                            sm: '3rem',
                          },
                        }}
                      >
                        RouteBuddies is more than a safety app.
                      </Typography>
                      <div
                        style={{
                          width: '100%',
                          height: '10px',
                          backgroundColor: 'white',
                          marginTop: 30,
                          marginBottom: 30,
                        }}
                      />
                      <Typography
                        variant={'body2'}
                        color={'#000'}
                        style={{
                          marginBottom: 10,
                          fontFamily: 'Urbanist',
                          fontSize: 19,
                          color: 'white',
                          letterSpacing: 1.3,
                          marginTop: 10,
                        }}
                      >
                        We're a collection of 'buddies' dedicated to adventuring
                        freely, and creating safe spaces across the globe 🌎
                      </Typography>
                      <Typography
                        variant={'body2'}
                        color={'#000'}
                        style={{
                          marginBottom: 10,
                          fontFamily: 'Urbanist',
                          fontSize: 19,
                          letterSpacing: 1.3,
                          color: 'white',
                          marginTop: 10,
                        }}
                      >
                        From street walks - to mountain hikes - There's always
                        buddies around to join you!
                      </Typography>

                      <Button
                        style={{
                          padding: 20,
                          borderRadius: '100px',
                          backgroundColor: 'white',
                          color: 'white',
                          paddingLeft: 40,
                          paddingRight: 40,
                          marginTop: 20,
                        }}
                        onClick={() => {
                          window.location.href =
                            'https://routebuddies.com/?appTrigger=true';
                        }}
                      >
                        <Typography
                          style={{
                            color: primaryColor,
                            fontFamily: 'Urbanist',
                            fontWeight: '700',
                            textTransform: 'none',
                          }}
                        >
                          Find a community today
                        </Typography>
                      </Button>

                      {/* <Button
                      onClick={() => {
                        window.location.href =
                          'https://routebuddies.com/?appTrigger=true';
                      }}
                    >
                      <Typography
                        style={{ fontFamily: 'Urbanist', fontWeight: '700' }}
                      >
                        Find your community today
                      </Typography>
                    </Button> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </div>
      </Grid>

      {/* Section 3 - How it works */}

      {/* Inner Container - Less page width */}

      {/* Section 4 -Why does RouteBuddies Exist */}

      <Grid
        container
        item
        xs={12}
        justifyContent={'center'}
        sx={{
          padding: { xs: 5, sm: 15 },
          paddingTop: 15,
          backgroundColor: 'white',
          paddingBottom: 10,
        }}
      >
        <Grid item container xs={12} justifyContent={'center'}>
          <Grid container flexDirection={'column'} alignContent={'center'}>
            <Typography
              component="h3"
              variant="h3"
              color={primaryColor}
              sx={{
                marginBottom: '10px',
                fontWeight: '700',
                fontFamily: 'Urbanist',
                maxWidth: 600,

                fontSize: {
                  xs: '2rem',
                  sm: '3rem',
                },
              }}
            >
              What does RouteBuddies offer?
            </Typography>
            <div
              style={{
                width: '100%',
                height: '10px',
                backgroundColor: primaryColor,
                marginTop: 30,
                marginBottom: 30,
                maxWidth: 600,
              }}
            />
            <Typography
              variant={'body2'}
              color={'white'}
              style={{
                marginBottom: 10,
                fontFamily: 'Urbanist',
                fontSize: 19,
                letterSpacing: 1.3,
                marginTop: 10,

                color: 'rgba(40,40,40,1)',
                maxWidth: 600,
              }}
            >
              RouteBuddies is transforming the way communities keep each other
              safe on-the-go:
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={12}
            md={10}
            lg={8}
            justifyContent={'center'}
          >
            <Grid container paddingTop={5} spacing={3}>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                container
                justifyContent={'center'}
              >
                <img
                  src="/static/feature_1.png"
                  style={{ width: '100%', maxWidth: 280 }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                container
                justifyContent={'center'}
              >
                <img
                  src="/static/feature_3.png"
                  style={{ width: '100%', maxWidth: 280 }}
                />
              </Grid>

              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                container
                justifyContent={'center'}
              >
                <img
                  src="/static/feature_5.png"
                  style={{ width: '100%', maxWidth: 280 }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                container
                justifyContent={'center'}
              >
                <img
                  src="/static/feature_2.png"
                  style={{ width: '100%', maxWidth: 280 }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                container
                justifyContent={'center'}
              >
                <img
                  src="/static/feature_4.png"
                  style={{ width: '100%', maxWidth: 280 }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                container
                justifyContent={'center'}
              >
                <img
                  src="/static/feature_6.png"
                  style={{ width: '100%', maxWidth: 280 }}
                />
              </Grid>
            </Grid>
            <Button
              style={{
                padding: 20,
                borderRadius: '100px',
                backgroundColor: primaryColor,
                color: 'white',
                paddingLeft: 40,
                paddingRight: 40,
                marginTop: '50px',
              }}
              onClick={() => {
                window.location.href =
                  'https://routebuddies.com/?appTrigger=true';
              }}
            >
              <Typography
                style={{
                  color: 'white',
                  fontFamily: 'Urbanist',
                  fontWeight: '700',
                  textTransform: 'none',
                }}
              >
                Download the app
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} ref={commRef} justifyContent={'center'}>
        <Fade
          in={commAnimation}
          easing={{ enter: theme.transitions.easing.easeInOut }}
          timeout={500}
        >
          <Grid container item xs={12}>
            <Grid
              container
              item
              xs={12}
              justifyContent={'center'}
              alignItems={'center'}
              style={{ backgroundColor: primaryColor }}
            >
              <Grid
                item
                container
                xs={12}
                md={7}
                sx={{
                  padding: { xs: 5, sm: 15 },
                  paddingTop: 15,
                  paddingBottom: 15,
                }}
              >
                <Grid
                  container
                  item
                  sx={{
                    justifyContent: 'flex-start',
                  }}
                >
                  <Typography
                    component="h1"
                    variant="h3"
                    color="white"
                    style={{
                      fontWeight: '700',
                      color: 'white',
                    }}
                    sx={{
                      fontSize: {
                        xs: '2rem',
                        sm: '3rem',
                      },
                    }}
                  >
                    How does it work?
                  </Typography>
                  <div
                    style={{
                      width: '100%',
                      height: '10px',
                      backgroundColor: 'white',
                      marginTop: 30,
                      marginBottom: 30,
                    }}
                  />
                  <Typography
                    variant={'body2'}
                    color={'#000'}
                    style={{
                      marginBottom: 10,
                      fontFamily: 'Urbanist',
                      fontSize: 19,
                      letterSpacing: 1.3,
                      marginTop: 10,
                      color: 'white',
                    }}
                  >
                    RouteBuddies makes it easier to find buddies for your
                    everyday activities:
                  </Typography>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ marginTop: 30 }}
                    spacing={2}
                  >
                    <Grid
                      item
                      flexDirection={'column'}
                      alignItems={'center'}
                      alignContent={'center'}
                      style={{ display: 'flex' }}
                    >
                      <div
                        style={{
                          justifyContent: 'center',
                          alignContent: 'center',
                          alignItems: 'center',
                          width: 60,
                          height: 60,
                          borderRadius: '60px',
                          backgroundColor: 'white',
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: 'Urbanist',
                            fontWeight: '700',
                            textAlign: 'center',
                            color: primaryColor,
                            fontSize: 20,
                            alignSelf: 'center',
                          }}
                        >
                          1
                        </Typography>
                      </div>
                      <div
                        style={{
                          width: 10,
                          height: 80,
                          marginTop: -10,
                          backgroundColor: 'white',
                        }}
                      />
                    </Grid>
                    <Grid item container flex={1} flexDirection={'column'}>
                      <Typography
                        style={{
                          fontFamily: 'Urbanist',
                          fontSize: 24,
                          letterSpacing: 1.3,
                          color: 'white',
                          textAlign: 'left',
                          width: '100%',
                          fontWeight: '700',
                          alignContent: 'center',
                          marginBottom: 5,
                        }}
                      >
                        Quick Verification
                      </Typography>
                      <Typography
                        variant={'body2'}
                        color={'#000'}
                        style={{
                          fontFamily: 'Urbanist',
                          fontSize: 16,
                          letterSpacing: 1.3,
                          color: 'white',
                        }}
                      >
                        With just a few quick snaps, we'll verify your identity.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={2}>
                    <Grid
                      item
                      flexDirection={'column'}
                      alignItems={'center'}
                      alignContent={'center'}
                      style={{ display: 'flex' }}
                    >
                      <div
                        style={{
                          justifyContent: 'center',
                          alignContent: 'center',
                          alignItems: 'center',
                          width: 60,
                          height: 60,
                          borderRadius: '60px',
                          backgroundColor: 'white',
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: 'Urbanist',
                            fontWeight: '700',
                            textAlign: 'center',
                            color: primaryColor,
                            fontSize: 20,
                            alignSelf: 'center',
                            marginTop: -1,
                          }}
                        >
                          2
                        </Typography>
                      </div>
                      <div
                        style={{
                          width: 10,
                          height: 80,
                          marginTop: -10,
                          backgroundColor: 'white',
                        }}
                      />
                    </Grid>
                    <Grid item container flex={1} flexDirection={'column'}>
                      <Typography
                        style={{
                          fontFamily: 'Urbanist',
                          fontSize: 24,
                          letterSpacing: 1.3,
                          color: 'white',
                          textAlign: 'left',
                          width: '100%',
                          fontWeight: '700',
                          alignContent: 'center',
                          marginBottom: 5,
                        }}
                      >
                        Find Buddies
                      </Typography>
                      <Typography
                        variant={'body2'}
                        color={'#000'}
                        style={{
                          fontFamily: 'Urbanist',
                          fontSize: 16,
                          letterSpacing: 1.3,
                          color: 'white',
                        }}
                      >
                        Find nearby buddy groups to vibe with!
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={2}>
                    <Grid
                      item
                      flexDirection={'column'}
                      alignItems={'center'}
                      alignContent={'center'}
                      style={{ display: 'flex' }}
                    >
                      <div
                        style={{
                          justifyContent: 'center',
                          alignContent: 'center',
                          alignItems: 'center',
                          width: 60,
                          height: 60,
                          borderRadius: '60px',
                          backgroundColor: 'white',
                          marginTop: -1,
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: 'Urbanist',
                            fontWeight: '700',
                            textAlign: 'center',
                            color: primaryColor,
                            fontSize: 20,

                            alignSelf: 'center',
                          }}
                        >
                          3
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item container flex={1} flexDirection={'column'}>
                      <Typography
                        style={{
                          fontFamily: 'Urbanist',
                          fontSize: 24,
                          letterSpacing: 1.3,
                          color: 'white',
                          textAlign: 'left',
                          width: '100%',
                          fontWeight: '700',
                          alignContent: 'center',
                          marginBottom: 5,
                        }}
                      >
                        Adventure Together!
                      </Typography>
                      <Typography
                        variant={'body2'}
                        color={'#000'}
                        style={{
                          fontFamily: 'Urbanist',
                          fontSize: 16,
                          letterSpacing: 1.3,
                          color: 'white',
                        }}
                      >
                        Explore the world freely with your new buddies.
                      </Typography>
                    </Grid>
                  </Grid>

                  <Button
                    style={{
                      padding: 20,
                      borderRadius: '100px',
                      backgroundColor: 'white',
                      color: primaryColor,
                      paddingLeft: 40,
                      paddingRight: 40,
                      marginTop: 40,
                    }}
                    onClick={() => {
                      window.location.href =
                        'https://routebuddies.com/?appTrigger=true';
                    }}
                  >
                    <Typography
                      style={{
                        color: primaryColor,
                        fontFamily: 'Urbanist',
                        fontWeight: '700',
                        textTransform: 'none',
                      }}
                    >
                      Let's get started!
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'flex',
                  },
                  height: '100%',
                }}
                md={5}
                style={{
                  backgroundImage: `url(/static/women.jpg)`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              ></Grid>{' '}
            </Grid>
          </Grid>
        </Fade>
      </Grid>
    </>
  );
}
