import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { getCurrentDateTime } from './home_services';
import { db } from './firebase_services';
import { RoleEnum } from '../models/CommunityRoleModel';
import {
  GroupModel,
  GroupUserModel,
  JourneyAppModel,
  JourneyUserAppModel,
} from '../models/GroupModel';
import moment from 'moment';
import { useTheme } from '@mui/material';
import { EventType } from '../models/EventEnum';
import {
  Comment,
  Delete,
  Event,
  EventAvailable,
  EventBusy,
  EventNote,
  Group,
  GroupAdd,
  GroupRemove,
  NordicWalking,
  PostAdd,
  Sos,
} from '@mui/icons-material';

export function useTools() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const theme = useTheme();

  return { navigate, dispatch, theme };
}

// Get remaining users for group will return users for group who have not left and are not kicked
//This is used to make sure the order, and current index are accurate to remaining users
export function getRemainingUserForGroup(group: JourneyAppModel) {
  return group && group.users
    ? group.users.filter(
        (val: JourneyUserAppModel) => !val.leaveTime && !val.kickTime
      )
    : [];
}

export function addToUserCommmunities(
  id: string,
  userId: string,
  setLoading: Function,
  setDone: Function
) {
  setLoading(true);
  let dateTime = getCurrentDateTime();
  db.ref('/new_data/user_data/user_communities/' + userId + '/' + id).set({
    active: true,
    communityId: id,
    role: RoleEnum.MEMBER,
  });

  db.ref('/new_data/community_data/community_users/' + id + '/' + userId).set({
    userId: userId,
    active: true,
    joinDate: dateTime,
  });
  setLoading(false);
  setDone(true);
}
export function isDivideBy(number: number, a: number) {
  return number % a === 0;
}

export function getLastObjectOfList(list: any[]) {
  try {
    return list[list.length - 1];
  } catch (err) {
    console.error('Error getting last object of list: ', list);
  }
}

export async function getReoccourence(
  dateStr: string,
  timeStr: string,
  activity: JourneyAppModel
) {
  let location =
    activity.creatorType === 'COMMUNITY' ? 'community_data' : 'coalition_data';

  let id =
    activity.creatorType === 'COMMUNITY'
      ? // @ts-ignore
        activity.creator.communityId
      : // @ts-ignore
        activity.creator.id;
  let reoccurenceRef = db.ref(
    `/new_data/${location}/repeat_activities/${id}/${activity.groupId}/occurences/${dateStr}/${timeStr}`
  );

  let reoccurenceDb = (await reoccurenceRef.once('value')).val();

  return reoccurenceDb;
}
export function getNextTimeSlotWithEndTime(
  currentTime: Date,
  startTime: Date,
  endTime: Date,
  repeatValue: number
) {
  const current = moment(currentTime);

  const isDST = current.isDST();

  if (isDST) {
    current.add(1, 'hour');
  }

  startTime.setDate(currentTime.getDate());
  startTime.setMonth(currentTime.getMonth());
  startTime.setFullYear(currentTime.getFullYear());

  endTime.setDate(currentTime.getDate());
  endTime.setMonth(currentTime.getMonth());
  let start = moment(startTime).utc();
  let end = moment(endTime).utc();

  // If endTime is earlier in the day than startTime, add a day to end
  if (end.format('HHmm') < start.format('HHmm')) {
    end.add(1, 'day');
  }

  while (start.isSameOrBefore(current)) {
    start.add(repeatValue, 'minutes');
  }

  // Ensure the nextTimeSlot is within the given range
  if (start.utc().isAfter(end.utc())) {
    return; // No available time slot within the range
  }

  return { timeSlot: start.toDate(), slotEndTime: end.toDate() };
}

export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS';
  }

  return 'unknown';
}

export const iconMapping = {
  [EventType.SOS_CREATED]: (
    <Sos style={{ color: 'white' }} fontSize={'medium'} />
  ),
  [EventType.SOS_JOINED]: (
    <Sos style={{ color: 'white' }} fontSize={'medium'} />
  ),
  [EventType.SOS_LEFT]: <Sos style={{ color: 'white' }} fontSize={'medium'} />,
  [EventType.SOS_CLOSED]: (
    <Sos style={{ color: 'white' }} fontSize={'medium'} />
  ),
  [EventType.ACTIVITY_CREATED]: (
    <Event style={{ color: 'white' }} fontSize={'medium'} />
  ),
  [EventType.ACTIVITY_JOINED]: (
    <EventNote style={{ color: 'white' }} fontSize={'medium'} />
  ),
  [EventType.ACTIVITY_LEFT]: (
    <EventBusy style={{ color: 'white' }} fontSize={'medium'} />
  ),
  [EventType.ACTIVITY_CLOSED]: (
    <NordicWalking style={{ color: 'white' }} fontSize={'medium'} />
  ),
  [EventType.ACTIVITY_FINISHED]: (
    <EventAvailable style={{ color: 'white' }} fontSize={'medium'} />
  ),
  [EventType.ACTIVITY_STARTED]: (
    <NordicWalking style={{ color: 'white' }} fontSize={'medium'} />
  ),
  [EventType.COMMUNITY_JOINED]: (
    <GroupAdd style={{ color: 'white' }} fontSize={'medium'} />
  ),
  [EventType.COMMUNITY_LEFT]: (
    <GroupRemove style={{ color: 'white' }} fontSize={'medium'} />
  ),
  [EventType.COMMUNITY_KICKED]: (
    <Group style={{ color: 'white' }} fontSize={'medium'} />
  ),
  [EventType.POST_CREATED]: (
    <PostAdd style={{ color: 'white' }} fontSize={'medium'} />
  ),
  [EventType.POST_DELETED]: (
    <Delete style={{ color: 'white' }} fontSize={'medium'} />
  ),
  [EventType.POST_COMMENT_CREATED]: (
    <Comment style={{ color: 'white' }} fontSize={'medium'} />
  ),
  [EventType.POST_COMMENT_DELETED]: (
    <Comment style={{ color: 'white' }} fontSize={'medium'} />
  ),
};

export const getReadableEventType = (eventType: EventType) => {
  const readableEventTypes = {
    [EventType.SOS_CREATED]: 'SOS Created',
    [EventType.SOS_JOINED]: 'SOS Joined',
    [EventType.SOS_LEFT]: 'SOS Left',
    [EventType.SOS_CLOSED]: 'SOS Closed',
    [EventType.ACTIVITY_CREATED]: 'Activity Created',
    [EventType.ACTIVITY_JOINED]: 'Activity Joined',
    [EventType.ACTIVITY_LEFT]: 'Activity Left',
    [EventType.ACTIVITY_CLOSED]: 'Activity Closed',
    [EventType.ACTIVITY_FINISHED]: 'Activity Finished',
    [EventType.ACTIVITY_STARTED]: 'Activity Started',
    [EventType.COMMUNITY_JOINED]: 'Community Joined',
    [EventType.COMMUNITY_LEFT]: 'Community Left',
    [EventType.COMMUNITY_KICKED]: 'Community Kicked',
    [EventType.POST_CREATED]: 'Post Created',
    [EventType.POST_DELETED]: 'Post Deleted',
    [EventType.POST_COMMENT_CREATED]: 'Post Comment Created',
    [EventType.POST_COMMENT_DELETED]: 'Post Comment Deleted',
  };

  return {
    readable: readableEventTypes[eventType] || 'Unknown Event Type',
    icon: iconMapping[eventType] || null,
  };
};
