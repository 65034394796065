import { Button, TextField, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';

// Imports; Material UI
import { CheckCircleRounded } from '@mui/icons-material';

import { Checkbox, Grid, useTheme } from '@mui/material';

import NavBar from '../components/NavBar';
import { useNavigate } from 'react-router';
import '../../index.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  registerInterest,
  sendContactMessage,
} from '../../services/home_services';
import useAnalyticsEventTracker from '../../services/analytics_services';
import { Helmet } from 'react-helmet';
import { PageType, setCurrentPage } from '../../store/slices/general.slice';
import { useAppDispatch } from '../../store/store';
import { useDispatch } from 'react-redux';
import { primaryColor } from '../../assets/styles/styles';
export default function Contact({}: any) {
  const navigate = useNavigate();
  const theme = useTheme();
  //settings for slider

  const [loggedIn, setLoggedIn] = useState(false);

  // Pre-reg fields
  const [fullName, setFullName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [message, setMessage] = useState('');
  const [recieveUpdates, setRecieveUpdates] = useState(false);
  const [fullNameError, setFullNameError] = useState('');
  const [emailAddressError, setEmailAddressError] = useState('');
  const [messageError, setMessageError] = useState('');

  //Has user signed up?
  const [done, setDone] = useState(false);

  const regRef: any = useRef(null);
  const infoRef: any = useRef(null);

  const gaEventTracker = useAnalyticsEventTracker('Home');

  function renderForm(type: string) {
    return (
      <>
        {!done ? (
          <Grid
            container
            item
            xs={type === 'large' ? 12 : 12}
            sm={type === 'large' ? 19 : 12}
            spacing={2}
            alignItems={'flex-start'}
            justifyContent={type === 'large' ? 'flex-start' : 'flex-start'}
            sx={{ marginTop: '20px' }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              style={type === 'large' ? { paddingBottom: 15 } : {}}
              justifyContent={'flex-start'}
            >
              <TextField
                value={fullName}
                placeholder={'Enter full name'}
                style={{ width: '100%' }}
                onChange={(e: any) => setFullName(e.target.value)}
              ></TextField>
              {fullNameError !== '' && (
                <Typography variant={'body2'} color={'primary'}>
                  {fullNameError}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={type === 'large' ? { paddingBottom: 15 } : {}}
              justifyContent={'center'}
            >
              <TextField
                value={emailAddress}
                placeholder={'Enter email address'}
                style={{ width: '100%' }}
                onChange={(e: any) => setEmailAddress(e.target.value)}
              ></TextField>
              {emailAddressError !== '' && (
                <Typography variant={'body2'} color={'primary'}>
                  {emailAddressError}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              style={type === 'large' ? { paddingBottom: 15 } : {}}
              justifyContent={'center'}
            >
              <TextField
                value={message}
                placeholder={'Enter message'}
                style={{ width: '100%' }}
                onChange={(e: any) => setMessage(e.target.value)}
              ></TextField>
              {messageError !== '' && (
                <Typography variant={'body2'} color={'primary'}>
                  {messageError}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                onClick={() => {
                  gaEventTracker('interest_registered');
                  sendContactMessage(
                    fullName,
                    setFullNameError,
                    emailAddress,
                    setEmailAddressError,
                    message,
                    setMessageError,
                    setDone
                  );
                }}
                variant={'contained'}
                style={
                  type === 'large'
                    ? {
                        marginTop: 15,
                        width: '100%',
                        height: 60,
                        marginBottom: 60,
                      }
                    : { width: '100%' }
                }
              >
                Send Message
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            item
            xs={12}
            flexDirection={'column'}
            justifyContent={'space-evenly'}
            alignItems={'center'}
          >
            <CheckCircleRounded
              style={{ color: 'green', fontSize: 50, marginBottom: 15 }}
            />
            <Typography
              variant={'body2'}
              textAlign={'center'}
              style={{
                fontFamily: 'Urbanist',
                marginBottom: 10,
                color: theme.palette.primary.dark,
                maxWidth: 550,
              }}
            >
              You've showed your interest, we'll let you know when we've
              launched! Thank you for your support.
            </Typography>
          </Grid>
        )}
      </>
    );
  }
  const dispatch = useDispatch();

  const [fadeAnimation, setFadeAnimation] = useState(false);
  const [slidenimation, setSlideAnimation] = useState(false);

  useEffect(() => {
    dispatch(setCurrentPage(PageType.CONTACT));
    setFadeAnimation(true);
    setSlideAnimation(true);
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>
      <NavBar theme={theme} navigate={navigate} loggedIn={loggedIn} />
      <Grid
        xs={12}
        style={{
          height: '80px',
          backgroundImage: `url(${'/static/login-bground.jpg'})`,
        }}
      />
      {/* Section 1 */}
      <Grid container xs={12} justifyContent={'center'}>
        <Grid
          item
          container
          xs={10}
          justifyContent={'center'}
          ref={regRef}
          style={{ marginTop: 0 }}
        >
          <Grid
            item
            container
            xs={12}
            justifyContent={'flex-start'}
            flexDirection={'row'}
            style={{ borderBottom: '0.5px solid grey', marginBottom: 15 }}
          >
            <Typography
              variant="h4"
              fontWeight={'bold'}
              fontFamily={'Urbanist'}
              style={{
                fontFamily: 'UrbanistBold',
                marginTop: 40,
                marginBottom: 25,
                color: primaryColor,
              }}
            >
              Get in Touch {'  👋'}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            alignItems={'flex-start'}
            style={{ padding: '15px' }}
          >
            <Typography
              variant={'body2'}
              textAlign={'left'}
              style={{
                fontFamily: 'Urbanist',
                marginBottom: 10,
                color: theme.palette.primary.dark,
                maxWidth: 550,
              }}
            >
              Get in touch with the RouteBuddies team directly, we're here to
              help!
            </Typography>
            {renderForm('large')}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
