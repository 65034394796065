//-----------------------------------------------------------------------------------------------
// Imports
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import GeneralSlice from './slices/general.slice';
import EnterpriseSlice from './slices/enterprise.slice';
import EnterpriseUserSlice from './slices/enterprise.users.slice';
import AdminSlice from './slices/admin.slice';

import EnterpriseRequestSlice from './slices/enterprise.requests.slice';

//-----------------------------------------------------------------------------------------------
// Set application reducers
const reducer = {
  GeneralSlice,
  EnterpriseSlice,
  EnterpriseUserSlice,
  EnterpriseRequestSlice,
  AdminSlice,
};

//-----------------------------------------------------------------------------------------------
// Set store
const store = configureStore({
  reducer,
  devTools: true,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Redux exports

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
