import { useEffect, useState } from 'react';
import { auth } from '../../services/firebase_services';
import Login from '../pages/Login';
import {
  watchAuthState,
  watchAuthStateUser,
} from '../../services/login_services';
import { LoginScreen } from '../pages/LoginScreen';

// Show login if not authneticated
export function UserContainer({ children }: any) {
  const [authenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    if (auth.currentUser) {
      setAuthenticated(true);
    } else {
      watchAuthStateUser(setAuthenticated);
    }
  }, [auth]);
  return <>{authenticated ? children : <LoginScreen loginPage={false} />}</>;
}
