import { SettingsInputHdmiSharp } from '@mui/icons-material';
import moment from 'moment';
import {
  VerificationListItemModel,
  VerificationModel,
} from '../models/VerificationModel';
import { auth, db } from './firebase_services';

export async function getAllNeededVerifications(
  setAllNeededVerifications: Function,
  setLoading: Function
) {
  const verifications = db
    .ref('/new_data/verification/')
    .orderByChild('responded')
    .equalTo(false)
    .on('value', async val => {
      if (val.val() !== null) {
        let list: VerificationModel[] = Object.values(val.val()).filter(
          // @ts-ignore
          (val: VerificationModel) => val.responded === false
        );
        let verificationList: VerificationListItemModel[] = [];

        console.log('List: ', list);
        for (let i = 0; i < list.length; i++) {
          let user = (
            await db
              .ref('/new_data/user_data/users/' + list[i].userId)
              .once('value')
          ).val();

          if (user !== null) {
            verificationList.push({
              ver: list[i],
              user: user,
            });
          }
        }

        setAllNeededVerifications(verificationList);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });

  setLoading(true);
}
export async function getOlderVerifications(
  setOlderVerifications: Function,
  setLoading: Function
) {
  //Get user details
  let users_needed = db.ref('/new_data/verifications_needed/');
  setLoading(true);
  //Will listen to the users table and update state automatically
  users_needed.on('value', async function (userFound: any) {
    if (userFound.val()) {
      let usersInner: any[] = Object.values(userFound.val());

      if (usersInner) {
        let verificationList: VerificationListItemModel[] = [];
        for (let i = 0; i < usersInner.length; i++) {
          let verificationObj = db.ref(
            '/new_data/verification/' + usersInner[i].userId
          );
          let userObj = db.ref(
            '/new_data/user_data/users/' + usersInner[i].userId
          );

          //Will listen to the users table and update state automatically
          let ver: any = await verificationObj.once('value');
          let user: any = await userObj.once('value');

          if (i === usersInner.length - 1) {
            if (ver.val().status !== 'RESPONSE_NEEDED') {
              setOlderVerifications([
                ...verificationList,
                { ver: ver.val(), user: user.val() },
              ]);
              setLoading(false);
            } else {
              setOlderVerifications(verificationList);
              setLoading(false);
            }
          } else if (ver.val().status !== 'RESPONSE_NEEDED') {
            verificationList.push({ ver: ver.val(), user: user.val() });
          }
        }
      } else {
        setOlderVerifications([]);
        setLoading(false);
      }
    } else {
      setOlderVerifications([]);
      setLoading(false);
    }
  });
}
export function getAllReports(
  setAllReports: Function,
  setAllReportsLoading: Function
) {
  //Get user details
  let reports = db.ref('/new_data/reports/');
  let reportsObj: any = {};

  let all_reports: any = [];
  //Will listen to the users table and update state automatically
  reports.on('value', function (reportFound: any) {
    if (reportFound !== null) {
      reportsObj = reportFound.val();
      reportsObj = Object.values(reportsObj);

      //Loop through users reports
      for (let i = 0; i < reportsObj.length; i++) {
        //Loop through individual reports
        for (let j = 0; j < reportsObj[i].length; j++) {
          let individual_report = reportsObj[i][j];

          //Add report if it is

          let user = db.ref(
            '/new_data/user_data/users/' + individual_report.reportedUserId
          );
          let userObj: any = {};

          //Will listen to the users table and update state automatically
          user.on('value', function (userFound: any) {
            if (userFound !== null) {
              userObj = userFound.val();

              let user2 = db.ref(
                '/new_data/user_data/users/' + individual_report.userId
              );
              let user2Obj: any = {};

              //Will listen to the users table and update state automatically
              user2.on('value', function (user2Found: any) {
                if (user2Found !== null) {
                  user2Obj = user2Found.val();

                  let new_obj = {
                    user: user2Obj,
                    reported_user: userObj,
                    report: individual_report,
                  };
                  all_reports = all_reports.filter(
                    (val: any) =>
                      val.report.reportId !== individual_report.reportId
                  );
                  all_reports = [...all_reports, new_obj];
                  setAllReports(all_reports);
                  setAllReportsLoading(false);
                }
              });
            }
          });
        }
      }
    }
  });
}

export function getJourney(setJourney: Function, journey_id: string) {
  //Get user details
  let journey = db.ref('/new_data/journeys/' + journey_id);
  let journeyObj: any = {};
  //Will listen to the users table and update state automatically
  journey.on('value', function (journeyFound: any) {
    if (journeyFound !== null) {
      journeyObj = journeyFound.val();

      setJourney(journeyObj);
    }
  });
}

export async function getUser(user_id: string) {
  //Get user details
  let user = db.ref('/new_data/user_data/users/' + user_id);
  let userObj: any = {};
  //Will listen to the users table and update state automatically
  user.on('value', function (userFound: any) {
    if (userFound !== null) {
      userObj = userFound.val();

      return userObj;
    }
  });
}

export async function getNumPreSignups(setNumber: Function) {
  let preSignups = db.ref('/new_data/user_interests/');
  let preSignupsObj: any = {};

  let unchecked_reports: any = [];
  //Will listen to the users table and update state automatically
  preSignups.on('value', function (preSignupsFound: any) {
    if (preSignupsFound !== null) {
      preSignupsObj = preSignupsFound.val();
      preSignupsObj = Object.values(preSignupsObj);

      setNumber(preSignupsObj.length);
    }
  });
}

export async function getUncheckedReports(
  recentReports: any[],
  setRecentReports: Function,
  setAllReports: Function,
  setTempAllReports: Function,
  setAllReportsLoading: Function,
  setRecentReportsLoading: Function
) {
  //Get user details
  let reports = db.ref('/new_data/reports/');
  let reportsObj: any = {};

  let unchecked_reports: any = [];
  //Will listen to the users table and update state automatically
  reports.on('value', function (reportFound: any) {
    if (reportFound !== null) {
      reportsObj = reportFound.val();
      reportsObj = Object.values(reportsObj);

      //Loop through users reports
      for (let i = 0; i < reportsObj.length; i++) {
        //Loop through individual reports
        let reports_for_user = Object.values(reportsObj[i]);
        for (let j = 0; j < reports_for_user.length; j++) {
          let individual_report: any = reports_for_user[j];

          if (individual_report.length) {
            for (let k = 0; k < individual_report.length; k++) {
              let user = db.ref(
                '/new_data/user_data/users/' +
                  individual_report[k].reportedUserId
              );
              let userObj: any = {};

              //Will listen to the users table and update state automatically
              user.on('value', function (userFound: any) {
                if (userFound !== null) {
                  userObj = userFound.val();

                  let user2 = db.ref(
                    '/new_data/user_data/users/' + individual_report[k].userId
                  );
                  let user2Obj: any = {};

                  //Will listen to the users table and update state automatically
                  user2.on('value', function (user2Found: any) {
                    if (user2Found !== null) {
                      user2Obj = user2Found.val();

                      let new_obj = {
                        user: user2Obj,
                        reported_user: userObj,
                        report: individual_report[k],
                      };
                      unchecked_reports = unchecked_reports.filter(
                        (val: any) =>
                          val.report.reportId !== individual_report[k].reportId
                      );
                      unchecked_reports = [...unchecked_reports, new_obj];
                      setAllReports(unchecked_reports);
                      setTempAllReports(unchecked_reports);
                      setRecentReports(
                        unchecked_reports.filter(
                          (val: any) => val.stage !== 2 && val.stage2 !== 2
                        )
                      );
                    }
                  });
                }
              });
            }
          } else {
            let user = db.ref(
              '/new_data/user_data/users/' + individual_report.reportedUserId
            );
            let userObj: any = {};

            //Will listen to the users table and update state automatically
            user.on('value', function (userFound: any) {
              if (userFound !== null) {
                userObj = userFound.val();

                let user2 = db.ref(
                  '/new_data/user_data/users/' + individual_report.userId
                );
                let user2Obj: any = {};

                //Will listen to the users table and update state automatically
                user2.on('value', function (user2Found: any) {
                  if (user2Found !== null) {
                    user2Obj = user2Found.val();

                    let new_obj = {
                      user: user2Obj,
                      reported_user: userObj,
                      report: individual_report,
                    };
                    unchecked_reports = unchecked_reports.filter(
                      (val: any) =>
                        val.report.reportId !== individual_report.reportId
                    );
                    unchecked_reports = [...unchecked_reports, new_obj];
                    setAllReports(unchecked_reports);
                    setTempAllReports(unchecked_reports);

                    setRecentReports(
                      unchecked_reports.filter(
                        (val: any) =>
                          val.report.stage !== 2 && val.report.stage2 !== 2
                      )
                    );
                  }
                });
              }
            });
          }
        }

        setRecentReportsLoading(false);
        setAllReportsLoading(false);
      }
    }
  });
  setRecentReportsLoading(false);
}

export async function getUserReports(
  userId: string,
  setAllReports: Function,
  setAllReportsLoading: Function
) {
  //Get user details
  let reports = db.ref('/new_data/reports/' + userId);
  let reportsObj: any = {};

  let unchecked_reports: any = [];
  //Will listen to the users table and update state automatically
  reports.on('value', function (reportFound: any) {
    if (reportFound.val() !== null) {
      reportsObj = reportFound.val();
      reportsObj = Object.values(reportsObj);

      //Loop through individual reports
      for (let j = 0; j < reportsObj.length; j++) {
        let individual_report: any = reportsObj[j];

        if (individual_report.length) {
          for (let k = 0; k < individual_report.length; k++) {
            let user = db.ref(
              '/new_data/user_data/users/' + individual_report[k].reportedUserId
            );
            let userObj: any = {};

            //Will listen to the users table and update state automatically
            user.on('value', function (userFound: any) {
              if (userFound !== null) {
                userObj = userFound.val();

                let user2 = db.ref(
                  '/new_data/user_data/users/' + individual_report[k].userId
                );
                let user2Obj: any = {};

                //Will listen to the users table and update state automatically
                user2.on('value', function (user2Found: any) {
                  if (user2Found !== null) {
                    user2Obj = user2Found.val();

                    let new_obj = {
                      user: user2Obj,
                      reported_user: userObj,
                      report: individual_report[k],
                    };
                    unchecked_reports = unchecked_reports.filter(
                      (val: any) =>
                        val.report.reportId !== individual_report[k].reportId
                    );
                    unchecked_reports = [...unchecked_reports, new_obj];
                    setAllReports(unchecked_reports);
                  }
                });
              }
            });
          }
        } else {
          let user = db.ref(
            '/new_data/user_data/users/' + individual_report.reportedUserId
          );
          let userObj: any = {};

          //Will listen to the users table and update state automatically
          user.on('value', function (userFound: any) {
            if (userFound !== null) {
              userObj = userFound.val();

              let user2 = db.ref(
                '/user_data/users/' + individual_report.userId
              );
              let user2Obj: any = {};

              //Will listen to the users table and update state automatically
              user2.on('value', function (user2Found: any) {
                if (user2Found !== null) {
                  user2Obj = user2Found.val();
                  let new_obj = {
                    user: user2Obj,
                    reported_user: userObj,
                    report: individual_report,
                  };
                  unchecked_reports = unchecked_reports.filter(
                    (val: any) =>
                      val.report.reportId !== individual_report.reportId
                  );
                  unchecked_reports = [...unchecked_reports, new_obj];

                  setAllReports(unchecked_reports);
                }
              });
            }
          });
        }
      }

      setAllReportsLoading(false);
    } else {
      setAllReportsLoading(false);
    }
  });
}

export async function getUncheckedUsers(
  setUncheckedUsers: Function,
  setUncheckedReportsLoading: Function
) {
  //Get user details
  let verifications = db.ref('/new_data/verification/');
  let verificationsObj: any = {};

  let unchecked_users: any = [];
  //Will listen to the users table and update state automatically
  verifications.on('value', function (verificationFound: any) {
    if (verificationFound !== null) {
      verificationsObj = Object.values(verificationFound.val());
      verificationsObj = verificationsObj.filter(
        (val: any) =>
          (!val.details_verification ||
            !val.passport_verification ||
            !val.picture_verification) &&
          val.userId
      );

      //Loop through users reports
      for (let i = 0; i < verificationsObj.length; i++) {
        let user = db.ref(
          '/new_data/user_data/users/' + verificationsObj[i].userId
        );
        verificationsObj = Object.values(verificationsObj);

        let userObj: any = {};
        //Will listen to the users table and update state automatically
        user.on('value', function (userFound: any) {
          if (userFound !== null) {
            userObj = userFound.val();

            let new_unchecked_user = userObj;
            unchecked_users = [...unchecked_users, new_unchecked_user];
            setUncheckedUsers(unchecked_users);
          }
        });
      }
    }
  });
  setUncheckedReportsLoading(false);
}

export async function getAllUsers(
  setAllUsers: Function,
  setTempAllUsers: Function,
  setAllUsersLoading: Function
) {
  //Get user details
  let users = db.ref('/new_data/user_data/users/');
  let usersObj: any = {};

  let unchecked_users: any = [];
  //Will listen to the users table and update state automatically
  users.on('value', function (userFound: any) {
    if (userFound !== null) {
      usersObj = Object.values(userFound.val());
      setAllUsers(usersObj);
      setTempAllUsers(usersObj);
      setAllUsersLoading(false);
      return;
    }
  });
}

export async function getUserListFromJourney(
  journey: any,
  setUserList: Function
) {
  if (journey) {
    let newList: any = [];
    //Loop through user waypoints
    for (let i = 0; i < journey.users.length; i++) {
      let user_obj = journey.users[i];

      let user = db.ref('/new_data/user_data/users/' + user_obj.userId);
      let user_db: any = {};
      //Will listen to the users table and update state automatically
      user.on('value', function (userFound: any) {
        if (userFound !== null) {
          user_db = userFound.val();
          newList.push(user_db);
        }
      });
    }

    setUserList(newList);
    return;
  }
}

export async function getEventListFromJourney(
  journey: any,
  setEventList: Function
) {
  if (journey) {
    let newEventList: any = [];

    //Group creation
    let dateGC: Date = new Date(journey.creation_date);
    let dateGCString = moment(dateGC).format('LLLL');
    newEventList.push({
      value: 'Group creation.',
      date: dateGC,
      dateString: dateGCString,
    });

    //Origin arrival times
    if (journey.origin.arrivals) {
      for (let i = 0; i < journey.origin.arrivals.length; i++) {
        let origin_obj = journey.origin.arrivals[i];

        let user = db.ref('/new_data/user_data/users/' + origin_obj.userId);
        let user_db: any = {};
        //Will listen to the users table and update state automatically
        user.on('value', function (userFound: any) {
          if (userFound !== null) {
            user_db = userFound.val();

            //User arrived
            let dateUA: Date = new Date(origin_obj.dateTime);
            let dateUAString = moment(dateUA).format('hh:mm A');
            newEventList.push({
              value: user_db.firstName + ' arrived at meetup point.',
              date: dateUA,
              dateString: dateUAString,
            });

            if (origin_obj.readyTime) {
              //User ready
              let dateUR: Date = new Date(origin_obj.readyTime.dateTime);
              let dateURString = moment(dateUR).format('hh:mm A');
              newEventList.push({
                value: user_db.firstName + ' is ready.',
                date: dateUR,
                dateString: dateURString,
              });
            }
          }
        });
      }
    }

    //Loop through user waypoints
    for (let i = 0; i < journey.users.length; i++) {
      let user_obj = journey.users[i];

      let user = db.ref('/new_data/user_data/users/' + user_obj.userId);
      let user_db: any = {};
      //Will listen to the users table and update state automatically
      user.on('value', function (userFound: any) {
        if (userFound !== null) {
          user_db = userFound.val();

          if (user_obj.joinTime) {
            let date: Date = new Date(user_obj.joinTime);
            let dateString = moment(date).format('hh:mm A');
            newEventList.push({
              value: user_db.firstName + ' joined.',
              date,
              dateString,
            });
          }
          if (user_obj.leaveTime) {
            let date: Date = new Date(user_obj.leaveTime);
            let dateString = moment(date).format('hh:mm A');
            newEventList.push({
              value: user_db.firstName + ' left.',
              date,
              dateString,
            });
          }
          if (user_obj.kickTime) {
            let date: Date = new Date(user_obj.kickTime);
            let dateString = moment(date).format('hh:mm A');
            newEventList.push({
              value: user_db.firstName + ' was kicked.',
              date,
              dateString,
            });
          }
          if (user_obj.finishTime) {
            let date: Date = new Date(user_obj.finishTime.dateTime);
            let dateString = moment(date).format('hh:mm A');
            newEventList.push({
              value: user_db.firstName + ' finished.',
              date,
              dateString,
            });
          }

          //Destination arrival times
          if (user_obj.arrivals) {
            for (let j = 0; j < user_obj.arrivals.length; j++) {
              let dest_obj = user_obj.arrivals[j];

              let inner_user = db.ref(
                '/new_data/user_data/users/' + dest_obj.userId
              );
              let inner_user_db: any = {};

              //Will listen to the users table and update state automatically
              inner_user.on('value', function (innerUserFound: any) {
                if (userFound !== null) {
                  inner_user_db = innerUserFound.val();

                  //User arrived
                  let dateUA: Date = new Date(dest_obj.dateTime);
                  let dateUAString = moment(dateUA).format('HH:mm A');
                  if (user_db.userId === inner_user_db.userId) {
                    newEventList.push({
                      value:
                        inner_user_db.firstName +
                        ' arrived at ' +
                        user_db.firstName +
                        "'s destination.",
                      date: dateUA,
                      dateString: dateUAString,
                    });
                  } else {
                    newEventList.push({
                      value:
                        inner_user_db.firstName +
                        ' arrived at their destination.',
                      date: dateUA,
                      dateString: dateUAString,
                    });
                  }

                  if (dest_obj.readyTime) {
                    //User ready
                    let dateUR: Date = new Date(dest_obj.readyTime.dateTime);
                    let dateURString = moment(dateUR).format('HH:mm A');
                    newEventList.push({
                      value: inner_user_db.firstName + ' is ready.',
                      date: dateUR,
                      dateString: dateURString,
                    });
                    return;
                  }
                }
              });
            }
          }
        }
        return;
      });
    }

    //Report made
    let dateRP: Date = new Date(journey.creation_date);
    let dateRPString = moment(dateRP).format('HH:mm A');
    newEventList.push({
      value: 'Report creation.',
      date: dateRP,
      dateString: dateRPString,
    });

    newEventList.sort((a: any, b: any) => new Date(a.date) < new Date(b.date));

    setEventList(newEventList);
    return;
  }
}

export function respondReport(
  report: any,
  responseStage: number,
  reportComment: string,
  setReportResponseState: Function
) {
  let newObject = report;
  let warningObject: any = {};
  let updates: any = {};

  //Set responder ID
  newObject.respondingUserId = auth.currentUser!.uid;

  //First ever response
  if (report.stage === 1) {
    //Set responseStage
    newObject.responseStage = responseStage;
    newObject.responseDate = new Date().toUTCString();

    // If accept/deny
    if (responseStage >= 0 && responseStage < 5) {
      newObject.stage = 2;

      if (responseStage === 0) {
        //Ban the user from the platform

        let user = db.ref('/new_data/user_data/users/' + report.reportedUserId);
        let user_db: any = {};

        user.on('value', function (userFound: any) {
          if (userFound) {
            user_db = userFound.val();

            user_db.banTime = { dateTime: new Date().toUTCString() };

            updates['/new_data/user_data/users/' + report.reportedUserId] =
              user_db;
          }
        });
      } else if (responseStage === 3 || responseStage === 1) {
        // accept w/warning || decline w/warning || request more info || request image
        // Send a warning -  accept/decline will be 'seen' upon clicking 'I understand'

        //Get ID to send warning to
        let id;
        if (responseStage === 1) {
          id = report.reportedUserId;
          warningObject.userId = report.userId;
          warningObject.warnedUserRole = 'reported';
        } else {
          id = report.userId;
          warningObject.userId = report.userId;
          warningObject.warnedUserRole = 'reporter';
        }

        //Get ref of location of new warning item
        var pushedRef = db.ref('/new_data/warnings/' + id).push(warningObject);

        //Set data of warning
        warningObject.warningId = pushedRef.key;
        newObject.responseComment = reportComment;
        warningObject.dateTime = new Date().toUTCString();
        warningObject.message = reportComment;
        warningObject.seen = false;
        warningObject.reportId = report.reportId;
        warningObject.type = 'general';

        //Get ref of location of new warning item
        db.ref('/new_data/warnings/' + id + '/' + pushedRef.key).set(
          warningObject
        );
      }
      //Email notification
    } else if (responseStage > 4 || responseStage < 7) {
      // accept w/warning || decline w/warning || request more info || request image
      // Send a warning -  accept/decline will be 'seen' upon clicking 'I understand'

      //Get ID to send warning to
      let id;
      if (responseStage === 6) {
        newObject.stage = 4;
        id = report.reportedUserId;
        warningObject.userId = report.userId;
        warningObject.warnedUserRole = 'reported';
        warningObject.type = 'request_pics';
      } else {
        newObject.stage = 3;
        id = report.userId;
        warningObject.userId = report.userId;
        warningObject.warnedUserRole = 'reporter';
        warningObject.type = 'request_info';
      }

      //Get ref of location of new warning item
      var pushedRef = db.ref('/new_data/warnings/' + id).push(warningObject);

      //Set data of warning
      warningObject.warningId = pushedRef.key;
      newObject.responseComment = reportComment;
      warningObject.dateTime = new Date().toUTCString();
      warningObject.message = reportComment;
      warningObject.seen = false;
      warningObject.reportId = report.reportId;

      //Get ref of location of new warning item
      db.ref('/new_data/warnings/' + id + '/' + pushedRef.key).set(
        warningObject
      );
    }
  } else if (report.stage === 3) {
    //Set responseStage
    newObject.response2Stage = responseStage;
    newObject.response2Date = new Date().toUTCString();

    // If accept/deny
    if (responseStage >= 0 && responseStage < 5) {
      newObject.stage2 = 2;

      if (responseStage === 0) {
        //Ban the user from the platform

        let user = db.ref('/new_data/user_data/users/' + report.reportedUserId);
        let user_db: any = {};

        user.on('value', function (userFound: any) {
          if (userFound) {
            user_db = userFound.val();

            user_db.banTime = { dateTime: new Date().toUTCString() };

            updates['/new_data/user_data/users/' + report.reportedUserId] =
              user_db;
          }
        });
      } else if (responseStage === 3 || responseStage === 1) {
        // accept w/warning || decline w/warning || request more info || request image
        // Send a warning -  accept/decline will be 'seen' upon clicking 'I understand'

        //Get ID to send warning to
        let id;
        if (responseStage === 1) {
          id = report.reportedUserId;
          warningObject.userId = report.userId;
          warningObject.warnedUserRole = 'reported';
        } else {
          id = report.userId;
          warningObject.userId = report.userId;
          warningObject.warnedUserRole = 'reporter';
        }

        //Get ref of location of new warning item
        var pushedRef = db.ref('/new_data/warnings/' + id).push(warningObject);

        //Set data of warning
        warningObject.warningId = pushedRef.key;
        newObject.response2Comment = reportComment;
        warningObject.dateTime = new Date().toUTCString();
        warningObject.message = reportComment;
        warningObject.seen = false;
        warningObject.reportId = report.reportId;
        warningObject.type = 'general';

        //Get ref of location of new warning item
        db.ref('/new_data/warnings/' + id + '/' + pushedRef.key).set(
          warningObject
        );
      }
      //Email notification
    } else if (responseStage > 4 || responseStage < 7) {
      // accept w/warning || decline w/warning || request more info || request image
      // Send a warning -  accept/decline will be 'seen' upon clicking 'I understand'

      //Get ID to send warning to
      let id;
      if (responseStage === 6) {
        newObject.stage2 = 4;
        id = report.reportedUserId;
        warningObject.userId = report.userId;
        warningObject.warnedUserRole = 'reported';
        warningObject.type = 'request_pics';
      } else {
        newObject.stage2 = 3;
        id = report.userId;
        warningObject.userId = report.userId;
        warningObject.warnedUserRole = 'reporter';
        warningObject.type = 'request_info';
      }

      //Get ref of location of new warning item
      var pushedRef = db.ref('/new_data/warnings/' + id).push(warningObject);

      //Set data of warning
      warningObject.warningId = pushedRef.key;
      newObject.responseComment = reportComment;
      warningObject.dateTime = new Date().toUTCString();
      warningObject.message = reportComment;
      warningObject.seen = false;
      warningObject.reportId = report.reportId;

      //Get ref of location of new warning item
      db.ref('/new_data/warnings/' + id + '/' + pushedRef.key).set(
        warningObject
      );
    }
  }
  let reports = db.ref('/new_data/reports/' + report.reportedUserId);
  let reports_db: any = {};

  reports.on('value', function (reportsFound: any) {
    if (reportsFound.val() !== null) {
      reports_db = reportsFound.val();

      reports_db = Object.values(reports_db).filter(
        (val: any) => val.reportId !== newObject.reportId
      );
      reports_db.push(newObject);
      updates['reports/' + report.reportedUserId] = reports_db;
    } else {
      updates['reports/' + report.reportedUserId] = [newObject];
    }
  });

  //Once done, set the current group to the updated group
  setReportResponseState(null);
  db.ref().update(updates);
  return;
}

export async function getReportWarning(
  reportId: string,
  userId: string,
  setWarnings: Function
) {
  let warnings = db.ref('/new_data/warnings/' + userId);
  let warnings_db: any = {};

  warnings.on('value', function (warningsFound: any) {
    if (warningsFound.val() !== null) {
      warnings_db = warningsFound.val();

      warnings_db = Object.values(warnings_db).filter(
        (val: any) => val.reportId === reportId
      );
      setWarnings(warnings_db);
    }
  });
  return;
}
