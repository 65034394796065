import { Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { watchAuthState } from '../../services/login_services';
import NavBar from '../components/NavBar';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store/store';
import { getAdminStatus } from '../../store/thunks/general.thunks';
import { auth } from '../../services/firebase_services';
import { Helmet } from 'react-helmet';

Chart.register(...registerables);
const labels = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
const data = {
  labels: labels,
  datasets: [
    {
      label: 'Weekly Active Users',
      data: [65, 59, 80, 81, 56, 55, 40],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 99, 132, 0.2)',
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 99, 132)',
        'rgb(255, 99, 132)',
        'rgb(255, 99, 132)',
        'rgb(255, 99, 132)',
        'rgb(255, 99, 132)',
        'rgb(255, 99, 132)',
      ],
      borderWidth: 1,
    },
  ],
};
export function AdminHome() {
  const [numWeeklyActiveUsers, setNumWeeklyActiveUsers] = useState(0);
  const [numWeeklyReturningUsers, setNumWeeklyReturningUsers] = useState(0);
  const [numJourneysComplete, setNumJourneysComplete] = useState(0);
  const [currentInfoSection, setCurrentInfoSection] = useState(1);
  const [loggedIn, setLoggedIn] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  useEffect(() => {
    watchAuthState(navigate, 'other', setLoggedIn);
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      if (currentInfoSection < 3) {
        let new_val = currentInfoSection + 1;
        setCurrentInfoSection(new_val);
      } else {
        setCurrentInfoSection(1);
      }
    }, 15000);
  }, []);

  return (
    <Grid container item xs={12}>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>
      <NavBar theme={theme} navigate={navigate} loggedIn={loggedIn} />

      <Grid
        container
        item
        xs={12}
        spacing={2}
        sx={{
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 20,
          paddingBottom: 50,
        }}
      >
        {/* Card showing weekly usage*/}
        <Grid item xs={12} sm={9} flexDirection={'column'}>
          <Bar data={data} />
        </Grid>
        {/* Card showing KPI's */}
        <Grid item xs={12} sm={3} alignItems={'center'} container>
          <Grid
            item
            style={{
              backgroundColor: '#fff',
              padding: '15px',
              paddingBottom: '5px',
              height: '200px',
              borderRadius: 20,
              maxWidth: '200px',
            }}
            sx={{ boxShadow: 1 }}
            container
            flexDirection={'row'}
          >
            <Grid
              item
              container
              xs={1}
              justifyContent={'center'}
              alignItems={'center'}
              onClick={() => {
                let new_val =
                  currentInfoSection === 1 ? 3 : currentInfoSection - 1;
                setCurrentInfoSection(new_val);
              }}
            >
              <ChevronLeft />
            </Grid>
            <Grid
              item
              container
              xs={10}
              justifyContent={'center'}
              sx={{ padding: '10px' }}
            >
              <Grid container item xs={12}>
                <Typography variant={'h2'} style={{ height: '50px' }}>
                  {currentInfoSection === 1
                    ? numWeeklyActiveUsers
                    : currentInfoSection === 2
                    ? numWeeklyReturningUsers
                    : currentInfoSection === 3 && numJourneysComplete}
                </Typography>
              </Grid>

              <Grid container item xs={12}>
                <Typography variant={'body2'} sx={{ marginBottom: '10px' }}>
                  {currentInfoSection === 1
                    ? 'Weekly active users'
                    : currentInfoSection === 2
                    ? 'Weekly returned users'
                    : currentInfoSection === 3 && 'Journeys Completed'}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent={'space-around'}
                spacing={1}
              >
                <span
                  onClick={() => {
                    setCurrentInfoSection(1);
                  }}
                  style={
                    currentInfoSection === 1
                      ? {
                          width: '10px',
                          height: '10px',
                          backgroundColor: 'grey',
                          borderRadius: '50%',
                        }
                      : {
                          width: '10px',
                          height: '10px',
                          border: '1px solid grey',
                          borderRadius: '50%',
                        }
                  }
                />
                <span
                  onClick={() => {
                    setCurrentInfoSection(2);
                  }}
                  style={
                    currentInfoSection === 2
                      ? {
                          width: '10px',
                          height: '10px',
                          backgroundColor: 'grey',
                          borderRadius: '50%',
                        }
                      : {
                          width: '10px',
                          height: '10px',
                          border: '1px solid grey',
                          borderRadius: '50%',
                        }
                  }
                />
                <span
                  onClick={() => {
                    setCurrentInfoSection(3);
                  }}
                  style={
                    currentInfoSection === 3
                      ? {
                          width: '10px',
                          height: '10px',
                          backgroundColor: 'grey',
                          borderRadius: '50%',
                        }
                      : {
                          width: '10px',
                          height: '10px',
                          border: '1px solid grey',
                          borderRadius: '50%',
                        }
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={1}
              justifyContent={'center'}
              alignItems={'center'}
              onClick={() => {
                let new_val =
                  currentInfoSection === 3 ? 1 : currentInfoSection + 1;
                setCurrentInfoSection(new_val);
              }}
            >
              <ChevronRight />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
