import {
  Autocomplete,
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import BadgeIcon from '@mui/icons-material/Badge';
import CasinoIcon from '@mui/icons-material/Casino';
import PeopleIcon from '@mui/icons-material/People';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { watchAuthState } from '../../../services/login_services';
import { useAppDispatch } from '../../../store/store';
import { Helmet } from 'react-helmet';
import { PageType, setCurrentPage } from '../../../store/slices/general.slice';
import { primaryColor } from '../../../assets/styles/styles';
import { useSelector } from 'react-redux';
import {
  getAllAdminUsers,
  getAllCoalitions,
} from '../../../store/thunks/admin.thunks';
import ReactImageUploading from 'react-images-uploading';
import { Close } from '@mui/icons-material';
import {
  CommunityLimits,
  CommunityModel,
} from '../../../models/CommunityModel';
import { CoalitionModel } from '../../../models/CoalitionModel';
import DescriptionIcon from '@mui/icons-material/Description';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import {
  checkValidEmail,
  getCurrentDateTime,
} from '../../../services/home_services';
import { toast } from 'react-toastify';
import { db, storage } from '../../../services/firebase_services';
import { addToCommunities } from '../../../store/slices/admin.slice';
export function RouteBuddiesAdminNewCommunity() {
  const [loggedIn, setLoggedIn] = useState(false);
  const { allCoalitions, allCoalitionsLoading } = useSelector(
    (state: any) => state.AdminSlice
  );
  const { admin } = useSelector((state: any) => state.GeneralSlice);

  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [pageNumber, setPageNumber] = useState(1);

  const [images, setImages] = useState<any[]>([]);
  const [imagesChanged, setImagedChanged] = useState(false);
  const [imagesError, setImagesError] = useState('');

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');

  const [limitType, setLimitType] = useState(CommunityLimits.NONE);

  const [coalitions, setCoalitions] = useState<string[]>([]);

  const [adminEmails, setAdminEmails] = useState<string[]>([]);
  const [adminEmailsError, setAdminEmailsError] = useState('');

  const [confirmCreation, setConfirmCreation] = useState(false);
  useEffect(() => {
    watchAuthState(navigate, 'other', setLoggedIn);
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage(PageType.ADMIN_COMMUNITIES));
  }, []);

  useEffect(() => {
    if (admin) {
      dispatch(getAllCoalitions());
      getAllAdminUsers();
    }
  }, [admin]);

  const onChange = (imageList: any[], addUpdateIndex: any) => {
    setImagedChanged(true);
    // data for submit
    setImages(imageList);
  };

  async function verifyPageOne() {
    let numErrors = 0;

    if (name.length < 5 || name.length > 20) {
      numErrors += 1;
      setNameError('Name must be between 5-20 characters.');
    } else {
      setNameError('');
    }

    if (description.length > 200) {
      numErrors += 1;
      setDescriptionError('Description must be under 200 characters.');
    } else {
      setDescriptionError('');
    }

    if (adminEmails.length === 0) {
      numErrors += 1;
      setAdminEmailsError(
        'You must enter at least one email. (Type & press "Enter")'
      );
    } else {
      for (let i = 0; i < adminEmails.length; i++) {
        let email = adminEmails[i];

        if (email === '') {
          setAdminEmailsError(`Emails must not be empty.`);
          numErrors += 1;
        } else if (email.length > 40) {
          setAdminEmailsError('Emails must be less than 40 characters.');
          numErrors += 1;
        } else if (!checkValidEmail(email)) {
          setAdminEmailsError('Email(s) provided are invalid.');
          numErrors += 1;
        } else {
          setAdminEmailsError('');
        }
      }
    }

    if (numErrors === 0) {
      setConfirmCreation(true);
    }
  }

  async function nextPage() {
    if (pageNumber === 1) {
      verifyPageOne();
    } else if (pageNumber === 2) {
    }
  }

  async function createCommunity() {
    setConfirmCreation(false);
    toast.info('Starting creation...');
    let community: CommunityModel = {
      communityId: '',
      active: true,
      limits: limitType,
      creatorId: 'ROUTEBUDDIES',
      creationDate: getCurrentDateTime(),
      name: name,
      desc: description,
      logoUrl: '',
      imageUrl: '',
    };

    let ref = await db
      .ref('/new_data/community_data/communities')
      .push(community);

    if (ref && ref.key !== null) {
      // Update the ID
      db.ref(
        '/new_data/community_data/communities/' + ref.key + '/communityId'
      ).set(ref.key);
      community = { ...community, communityId: ref.key };

      // Update the logo if changed
      if (imagesChanged) {
        // Upload image...]
        const response = await fetch(images[0].data_url);
        const selfieBlob = await response.blob();
        // Store the selfie in the firebase store
        let profilePictureUrl = await storage
          .ref('/images/communities/profile_pics/' + ref.key)
          .put(selfieBlob)
          .then((snapshot: any) => {
            return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
          });

        db.ref(
          '/new_data/community_data/communities/' + ref.key + '/imageUrl'
        ).set(profilePictureUrl);

        community = { ...community, imageUrl: profilePictureUrl };
      }

      // Go through coalitions
      for (let i = 0; i < coalitions.length; i++) {
        let id = coalitions[i];

        // Save for the new community!
        db.ref(
          '/new_data/community_data/community_coalitions/' + ref.key + '/' + id
        ).set({ active: true, coalitionId: id });
      }

      // Go through admins
      for (let i = 0; i < adminEmails.length; i++) {
        let email = adminEmails[i];
        toast.info(email + ' has been invited');

        // Send invite to email...
        db.ref('/new_data/email_send').push({
          type: 'COMMUNITY_ADMIN_INVITE',
          emailTo: email,
          emailFrom: 'noreply@routebuddies.com',
          data: {
            date: getCurrentDateTime(),
            communityId: ref.key,
            communityName: name,
          },
        });

        // Admin invite - either invited, accepted, denied
        db.ref(
          '/new_data/community_data/admin_invites/' +
            ref.key +
            '/' +
            email.replace(/\./g, ',')
        ).set({
          emailAddress: email.replace(/\./g, ','),
          status: 'INVITED',
          dateTime: getCurrentDateTime(),
        });
      }

      console.log('Adding to communities: ', community);
      dispatch(addToCommunities(community));

      toast.success('Community created!');

      navigate('/admin/communities');
    }
  }

  return (
    <Grid container item xs={12} sx={{ paddingTop: 0 }}>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>

      <Modal
        open={confirmCreation}
        onClose={() => {
          setConfirmCreation(false);
        }}
        aria-labelledby="Create Community?"
        aria-describedby="Learn more about this report."
      >
        <Grid
          container
          xs={12}
          sx={{ height: '100vh' }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid
            item
            container
            xs={10}
            sx={{ backgroundColor: 'white', margin: 'auto', padding: '35px' }}
          >
            <Grid container flexDirection={'column'}>
              {/* Title */}
              <Grid
                item
                container
                flexDirection={'column'}
                style={{
                  borderBottom: '0.5px solid rgba(200,200,200,0.4)',
                }}
              >
                <Typography
                  variant={'h4'}
                  fontWeight={'800'}
                  sx={{ marginBottom: 1 }}
                >
                  New Community
                </Typography>
                <Typography
                  variant={'body1'}
                  fontWeight={'400'}
                  style={{ marginBottom: '15px' }}
                >
                  Let's create a new community to help them stay safe together!
                </Typography>
              </Grid>

              {/* Details overview */}
              <Grid item container spacing={2}>
                <Grid item container xs={12} sm={6}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                      bgcolor: 'background.paper',
                    }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: primaryColor }}>
                          <BadgeIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={'Name'} secondary={name} />
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: primaryColor }}>
                          <DescriptionIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={'Description'}
                        secondary={
                          description.length > 0
                            ? description
                            : 'No description.'
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: primaryColor }}>
                          <CasinoIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={'Limits'} secondary={limitType} />
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: primaryColor }}>
                          <Diversity3Icon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={'Coalition(s)'}
                        secondary={
                          coalitions.length > 0
                            ? coalitions.map((val, index) => {
                                let str = val;
                                if (index !== coalitions.length - 1) {
                                  str += ', ';
                                }

                                return val;
                              })
                            : 'No Coalitions'
                        }
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: primaryColor }}>
                          <PeopleIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={'Admin(s)'}
                        secondary={
                          adminEmails.length > 0
                            ? adminEmails.map((val, index) => {
                                let str = val;
                                if (index !== coalitions.length - 1) {
                                  str += ', ';
                                }

                                return val;
                              })
                            : 'No Admins'
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid
                    item
                    container
                    style={{
                      backgroundImage: `url("${
                        images[0] ? images[0]['data_url'] : '/static/avatar.svg'
                      }")`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  ></Grid>
                </Grid>
              </Grid>

              {/* Buttons */}

              <Grid
                item
                container
                justifyContent={'flex-end'}
                sx={{ marginTop: 5 }}
              >
                <Button
                  onClick={() => {
                    setConfirmCreation(false);
                  }}
                  variant="contained"
                  sx={{
                    backgroundColor: 'rgba(0,0,0,0.6)',
                    color: 'white',
                    marginRight: 2,
                  }}
                  color="error"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    createCommunity();
                  }}
                  variant="contained"
                  color="success"
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      {allCoalitionsLoading ? (
        <Grid container item justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          spacing={2}
          sx={{
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 5,
          }}
          alignItems={'flex-start'}
        >
          {/* Title */}
          <Grid
            item
            container
            flexDirection={'column'}
            style={{
              borderBottom: '0.5px solid rgba(200,200,200,0.4)',
            }}
          >
            <Typography
              variant={'h4'}
              fontWeight={'800'}
              sx={{ marginBottom: 1 }}
            >
              New Community
            </Typography>
            <Typography
              variant={'body1'}
              fontWeight={'400'}
              style={{ marginBottom: '15px' }}
            >
              Let's create a new community to help them stay safe together!
            </Typography>
          </Grid>

          {/* Page 1  - Name description, image...*/}
          {pageNumber === 1 && (
            <Grid container spacing={2} style={{ paddingTop: '15px' }}>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={7}
                flexDirection={'column'}
              >
                <Typography
                  variant="h6"
                  fontWeight={'bold'}
                  style={{ fontSize: 12, color: 'rgba(0,0,0,0.7)' }}
                >
                  NAME*
                </Typography>
                <TextField
                  value={name}
                  variant="standard"
                  onChange={val => {
                    setName(val.target.value);
                  }}
                  placeholder="Enter Name (required)"
                  sx={{
                    width: '100%',
                    marginBottom: '10px',
                  }}
                />
                {nameError !== '' && (
                  <Typography variant={'body2'} color={'primary'}>
                    {nameError}
                  </Typography>
                )}

                <Typography
                  variant="h6"
                  fontWeight={'bold'}
                  style={{ fontSize: 12, color: 'rgba(0,0,0,0.7)' }}
                >
                  DESCRIPTION*
                </Typography>
                <TextField
                  value={description}
                  variant="standard"
                  multiline
                  onChange={val => {
                    setDescription(val.target.value);
                  }}
                  placeholder="Enter Description"
                  sx={{
                    marginBottom: '10px',
                    width: '100%',
                  }}
                  rows={3}
                />
                {descriptionError !== '' && (
                  <Typography
                    variant={'body2'}
                    color={'primary'}
                    style={{ marginBottom: '10px' }}
                  >
                    {descriptionError}
                  </Typography>
                )}

                <Typography
                  variant="h6"
                  fontWeight={'bold'}
                  style={{ fontSize: 12, color: 'rgba(0,0,0,0.7)' }}
                >
                  LIMITS*
                </Typography>

                <FormControl fullWidth style={{ marginBottom: '10px' }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    multiple={false}
                    options={['ID_VERIFIED', 'ADMIN_VERIFIED', 'NONE']}
                    value={limitType}
                    sx={{ flex: 1, border: 'none' }}
                    renderInput={params => (
                      <TextField {...params} variant="standard" />
                    )}
                    onChange={(event: any, newValue: string | null) => {
                      if (newValue !== null) {
                        setLimitType(newValue as CommunityLimits);
                      }
                    }}
                  />
                </FormControl>

                <Typography
                  variant="h6"
                  fontWeight={'bold'}
                  style={{ fontSize: 12, color: 'rgba(0,0,0,0.7)' }}
                >
                  COALITION(S)
                </Typography>

                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    multiple={true}
                    options={allCoalitions.map((val: CoalitionModel) => {
                      return val.coalitionId;
                    })}
                    value={coalitions}
                    sx={{ flex: 1, border: 'none', marginBottom: '10px' }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select Coalition(s)"
                      />
                    )}
                    onChange={(event: any, newValue: string[]) => {
                      if (newValue !== null) {
                        setCoalitions(newValue);
                      }
                    }}
                  />
                </FormControl>

                <Typography
                  variant="h6"
                  fontWeight={'bold'}
                  style={{ fontSize: 12, color: 'rgba(0,0,0,0.7)' }}
                >
                  ADMIN(S)*
                </Typography>

                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    multiple={true}
                    options={[]}
                    value={adminEmails}
                    sx={{ flex: 1, border: 'none' }}
                    freeSolo
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Enter Email(s)"
                      />
                    )}
                    onChange={(event: any, newValue: string[]) => {
                      if (newValue !== null) {
                        setAdminEmails(newValue);
                      }
                    }}
                  />
                </FormControl>

                {adminEmailsError !== '' && (
                  <Typography
                    variant={'body2'}
                    color={'primary'}
                    style={{ marginBottom: '10px' }}
                  >
                    {adminEmailsError}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={5}
                alignItems={'flex-start'}
                flexDirection={'column'}
              >
                <ReactImageUploading
                  value={images}
                  onChange={onChange}
                  maxNumber={1}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div
                      className="upload__image-wrapper"
                      style={{
                        width: '100%',
                        justifyContent: 'center',
                        display: 'flex',
                        backgroundColor: !isDragging
                          ? 'rgba(0,0,0,0.04)'
                          : 'rgba(95,102,234,0.5)',
                        padding: 10,
                        borderRadius: 5,
                        marginBottom: 5,
                        cursor: 'pointer',
                        alignItems: 'center',
                        height: '100%',
                      }}
                      {...dragProps}
                    >
                      {imageList.length === 0 && (
                        <Grid container alignItems={'center'}>
                          <Grid
                            item
                            container
                            justifyContent={'center'}
                            alignItems={'center'}
                            xs={12}
                          >
                            <img
                              src={'/static/avatar.svg'}
                              style={{
                                backgroundPosition: 'center',
                                width: '70%',
                                height: 'auto',
                                maxHeight: '120px',
                                maxWidth: '120px',
                                backgroundSize: 'cover',
                                borderRadius: 400,
                                background: 'none',
                              }}
                              onClick={onImageUpload}
                            />
                          </Grid>

                          <Grid container item sx={{ marginTop: 2 }}>
                            <Typography
                              variant="body1"
                              textAlign={'center'}
                              style={{ fontSize: 13 }}
                            >
                              Click{' '}
                              <span
                                style={{
                                  color: primaryColor,
                                  textDecoration: 'underline',
                                  fontWeight: 'bold',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  onImageUpload();
                                }}
                              >
                                here
                              </span>{' '}
                              to upload an image, or drag it over this area.
                            </Typography>
                          </Grid>
                        </Grid>
                      )}

                      {imageList.map((image, index) => {
                        let src = image['data_url'];
                        return (
                          <div key={index} className="image-item">
                            <div
                              style={{
                                width: 140,
                                height: 140,
                                borderRadius: 400,
                                overflow: 'hidden',
                                backgroundImage: `url("${src}")`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                              }}
                            ></div>
                            <IconButton
                              onClick={() => onImageRemove(index)}
                              style={{
                                backgroundColor: 'red',
                                padding: 10,
                                borderRadius: 50,
                                width: 40,
                                height: 40,
                                position: 'relative',
                                top: -130,
                                right: -110,
                              }}
                            >
                              <Close style={{ color: 'white' }} />
                            </IconButton>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </ReactImageUploading>
                {imagesError !== '' && (
                  <Grid
                    style={{
                      width: '100%',
                      marginBottom: 10,
                      marginTop: 5,
                      justifyContent: 'center',
                    }}
                  >
                    <Typography style={{ textAlign: 'left', color: 'red' }}>
                      {imagesError}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}

          {/* Page 2 - Free / Premium */}
          {/* Will get user to pay for premium if needed */}

          {/* Page 3 - Add to coalition (Optional) */}

          {/*  Buttons */}
          <Grid
            item
            container
            xs={12}
            justifyContent={pageNumber === 1 ? 'flex-end' : 'space-between'}
            sx={{ marginBottom: 5 }}
          >
            {pageNumber > 1 && (
              <Button
                onClick={() => {
                  setPageNumber(pageNumber - 1);
                }}
                color="inherit"
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(0,0,0,0.6)',
                  color: 'white',
                  marginRight: 2,
                }}
              >
                Back
              </Button>
            )}
            <Button
              onClick={() => {
                nextPage();
              }}
              variant="contained"
            >
              {pageNumber < 2 ? 'Next' : 'Create Activity'}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
