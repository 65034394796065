import { FormControl, Chip, Input, Typography, Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { checkValidEmail } from '../../services/home_services';

interface IProps {
  values: any[];
  setValues: Function;
}
export function MultiInput({ values, setValues }: IProps) {
  const [currValue, setCurrValue] = useState('');

  const handleKeyUp = (e: any) => {
    if (e.keyCode == 32 || e.keyCode == 13 || e.keyCode === 188) {
      if (checkValidEmail(e.target.value)) {
        setValues((oldState: any) => [...oldState, e.target.value]);
        setCurrValue('');
      } else {
        window.alert('Invalid email provided');
      }
    }
  };

  useEffect(() => {}, [values]);

  const handleChange = (e: any) => {
    setCurrValue(e.target.value);
  };

  const handleDelete = (item: any, index: number) => {
    let arr = [...values];
    arr.splice(index, 1);

    setValues(arr);
  };
  return (
    <FormControl
      style={{
        width: '100%',
        borderRadius: 2,
        padding: 2,
      }}
    >
      <div className={'container'}>
        {values.map((item, index) => (
          <Chip
            size="small"
            onDelete={() => handleDelete(item, index)}
            label={item}
          />
        ))}
      </div>
      <Input
        value={currValue}
        onChange={handleChange}
        onKeyDown={handleKeyUp}
        placeholder="Enter email addresses here"
      />

      <Typography style={{ fontSize: 12 }}>
        Press "enter", "comma", or "space" key to confirm email.
      </Typography>
    </FormControl>
  );
}
