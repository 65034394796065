import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserDataModel from '../../models/UserDataModel';
import { PostAppModel } from '../../models/PostModel';
import { getCommunityAdminStatus } from '../thunks/general.thunks';
import {
  getCommunityRequests,
  getCommunityUsers,
  getUserById,
  getUserJourneysForCommunity,
  isUserCommunityAdmin,
} from '../thunks/enterprise.users.thunks';
import { CommunityRequestAppModel } from '../../models/CommunityRequestModel';

/**
 * modalHeight - Height of the information modal being shown during a journey - Used to display properly.
 * reportUser - Data of a user from a group selected to be reported.
 * page - The current page a user is on - PageType.HOME - PageType.JOURNEYS etc.
 */
interface IProps {
  requestList: CommunityRequestAppModel[];
  requestListLoading: boolean;
  requestListError: boolean;
  selectedRequest: CommunityRequestAppModel | undefined;
}
const enterpriseInitialState: IProps = {
  requestList: [],
  requestListError: false,
  requestListLoading: false,
  selectedRequest: undefined,
};

const enterpriseSlice = createSlice({
  name: 'enterprise_request_data',
  initialState: enterpriseInitialState,
  reducers: {
    // Setters
    setRequestList: (
      state: IProps,
      { payload }: PayloadAction<CommunityRequestAppModel[]>
    ) => {
      state.requestList = payload;
    },
    setSelectedRequest: (
      state: IProps,
      { payload }: PayloadAction<CommunityRequestAppModel | undefined>
    ) => {
      state.selectedRequest = payload;
    },
  },

  extraReducers: (builder: any) => {
    builder.addCase(
      getCommunityRequests.pending,
      (state: IProps, { payload }: PayloadAction<boolean>) => {
        state.requestListLoading = true;
        state.requestListError = false;
      }
    );
    builder.addCase(
      getCommunityRequests.rejected,
      (state: IProps, { payload }: PayloadAction<boolean>) => {
        state.requestListError = true;
        state.requestListLoading = false;
      }
    );
    builder.addCase(
      getCommunityRequests.fulfilled,
      (
        state: IProps,
        { payload }: PayloadAction<CommunityRequestAppModel[] | undefined>
      ) => {
        if (payload && payload !== null) {
          state.requestList = payload;
        }

        state.requestListError = false;
        state.requestListLoading = false;
      }
    );
  },
});

export const { setRequestList, setSelectedRequest } = enterpriseSlice.actions;

export default enterpriseSlice.reducer;
