import { CircularProgress, Container, Modal, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

import { Grid } from "@mui/material";

import NavBar from "../components/NavBar";
import { useNavigate } from "react-router";
import { watchAuthState } from "../../services/login_services";
import {
  getNumPreSignups,
  getUncheckedReports,
} from "../../services/report_services";
import ReportCard from "../components/ReportCard";
import ReportInfo from "../components/ReportInfo";

export default function Reports({ theme, mapboxgl }: any) {
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(false);

  //States for data
  const [allReports, setAllReports] = useState<any>([]);
  const [tempAllReports, setTempAllReports] = useState<any>([]);
  const [allReportsLoading, setAllReportsLoading] = useState(true);
  const [recentReports, setRecentReports] = useState<any>([]);
  const [recentReportsLoading, setRecentReportsLoading] = useState(true);
  const [selectedReport, setSelectedReport] = useState<any>(null);

  const [numSignups, setNumSignups] = useState(0);
  //Check if authenticated
  useEffect(() => {
    watchAuthState(navigate, "other", setLoggedIn);
  }, []);

  useEffect(() => {
    if (loggedIn) {
      getUncheckedReports(
        recentReports,
        setRecentReports,
        setAllReports,
        setTempAllReports,
        setAllReportsLoading,
        setRecentReportsLoading
      );
      getNumPreSignups(setNumSignups);
    }

    return function cleanup() {
      setAllReports(null);
      setRecentReports(null);
    };
  }, [loggedIn]);

  return (
    <>
      <NavBar
        theme={theme}
        navigate={navigate}
        currentPage={"report"}
        loggedIn={loggedIn}
      />

      <Container
        style={{
          backgroundColor: theme.palette.secondary.main,
          height: "calc(100vh - 48px)",
          width: "100vw",
          marginTop: "48px",
          padding: 15,
        }}
      >
        <Grid container>
          <Typography variant={"h1"}>Num Pre-Signups</Typography>
          <Typography variant={"body2"}>{numSignups}</Typography>
        </Grid>
        <Grid
          container
          sx={{ marginTop: 5, color: "grey" }}
          flexDirection={"column"}
        >
          <Grid container item flexDirection={"column"} xs={12}>
            <Typography
              color={"inherit"}
              variant="h6"
              style={{ height: 30, marginBottom: 10 }}
            >
              Recent reports
            </Typography>

            <Grid container item xs={12} spacing={2}>
              {recentReportsLoading ? (
                <CircularProgress />
              ) : recentReports === null || recentReports.length === 0 ? (
                <Grid item xs={6}>
                  <img
                    src="/static/happy.svg"
                    style={{ width: "200px", margin: "15px" }}
                    alt={"No recent reports to show"}
                  />
                  <Typography>No recent reports to show.</Typography>
                </Grid>
              ) : (
                recentReports.map((report: any) => {
                  return (
                    <Grid item xs={4}>
                      {report !== null && (
                        <ReportCard
                          theme={theme}
                          report={report.report}
                          reported_user={report.reported_user}
                          user={report.user}
                          setSelectedReport={setSelectedReport}
                        />
                      )}
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Grid>
          <Grid container item flexDirection={"column"} xs={12} spacing={2}>
            <Grid container item xs={12} justifyContent={"space-between"}>
              <Typography
                color={"inherit"}
                variant="h6"
                style={{ height: 30, marginBottom: 10 }}
              >
                All Reports
              </Typography>
              <Grid item xs={6}>
                <TextField
                  variant={"outlined"}
                  placeholder={"Search reports, users, dates..."}
                  onChange={(val: any) => {
                    if (val.target.value.length === 0) {
                      setAllReports(tempAllReports);
                    } else {
                      let reducedReports = tempAllReports.filter(
                        (report: any) => {
                          return (
                            report.reported_user.firstName
                              .toLowerCase()
                              .includes(val.target.value.toLowerCase()) ||
                            report.reported_user.lastName
                              .toLowerCase()
                              .includes(val.target.value.toLowerCase()) ||
                            report.user.firstName
                              .toLowerCase()
                              .includes(val.target.value.toLowerCase()) ||
                            report.reported_user.lastName
                              .toLowerCase()
                              .includes(val.target.value.toLowerCase()) ||
                            report.report.description
                              .toLowerCase()
                              .includes(val.target.value.toLowerCase()) ||
                            report.report.date
                              .toLowerCase()
                              .includes(val.target.value.toLowerCase()) ||
                            report.reported_user.firstName
                              .toLowerCase()
                              .includes(val.target.value.toLowerCase())
                          );
                        }
                      );
                      setAllReports(reducedReports);
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              spacing={2}
              style={{ maxHeight: 250, overflow: "scroll" }}
            >
              {allReportsLoading ? (
                <CircularProgress color="primary" />
              ) : allReports === null ? (
                <Grid item xs={6}>
                  <img
                    src="/static/happy.svg"
                    style={{ width: "200px" }}
                    alt={"No reports to show"}
                  />
                  <Typography>No reports to show.</Typography>
                </Grid>
              ) : (
                allReports.map((report: any) => {
                  return (
                    <Grid item xs={4}>
                      {report !== null && (
                        <ReportCard
                          theme={theme}
                          report={report.report}
                          reported_user={report.reported_user}
                          user={report.user}
                          setSelectedReport={setSelectedReport}
                        />
                      )}
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Grid>
        </Grid>
        <Modal
          open={selectedReport !== null}
          onClose={() => {
            setSelectedReport(null);
          }}
          aria-labelledby="View Report"
          aria-describedby="Learn more about this report."
        >
          <Grid
            container
            xs={12}
            sx={{ height: "100vh" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid
              item
              container
              xs={10}
              sx={{ backgroundColor: "white", margin: "auto", padding: "35px" }}
            >
              <ReportInfo
                theme={theme}
                selectedReport={selectedReport}
                setSelectedReport={setSelectedReport}
                mapboxgl={mapboxgl}
              />
            </Grid>
          </Grid>
        </Modal>
      </Container>
    </>
  );
}
