import * as React from 'react';
import '../../index.css';
import {
  CheckRounded,
  ClearRounded,
  Close,
  CloseRounded,
  DoneRounded,
  InfoRounded,
  KeyboardArrowDownRounded,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import {
  getEventListFromJourney,
  getJourney,
  getReportWarning,
  getUserListFromJourney,
  respondReport,
} from '../../services/report_services';
import moment from 'moment';
import {
  db,
  sendNotification,
  sendNotificationToTopic,
} from '../../services/firebase_services';
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@mui/lab';
import * as turf from '@turf/turf';
import WarningCard from './WarningCard';
import { toast } from 'react-toastify';
import { getCurrentDateTime } from '../../services/home_services';
import { useTools } from '../../services/general_services';

export default function ImageVerificationInfo({
  theme,
  selectedVerification,
  setSelectedVerification,
  removeVer,
  mapboxgl,
}: any) {
  const [verifyDenial, setVerifyDenial] = useState(false);
  const [denialMessage, setDenialMessage] = useState('');
  const [denialMessageFinal, setDenialMessageFinal] = useState('');
  const [denialMessageError, setDenialMessageError] = useState('');

  const { dispatch } = useTools();
  const styles = {
    reportTypePicker: {
      height: 'auto',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    reportTypePickerSelected: {
      fontSize: 15,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 15,
      paddingTop: 3,
      paddingBottom: 3,
      paddingRight: 6,
      paddingLeft: 6,
      color: 'white',
      borderColor: theme.palette.primary.main,
      marginTop: 5,
      marginRight: 5,
    },
    reportTypePickerUnselected: {
      fontSize: 15,
      backgroundColor: 'white',
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      borderRadius: 15,
      paddingTop: 3,
      paddingBottom: 3,
      paddingRight: 6,
      paddingLeft: 6,
      marginTop: 5,
      marginRight: 5,
    },
    reportSelectedText: {
      color: 'white',
      fontSize: 12,
    },
    reportUnselectedText: {
      color: theme.palette.primary.main,
    },
  };

  async function acceptOrDeny(accept: boolean) {
    console.log('triggered');
    try {
      if (selectedVerification !== null) {
        setDenialMessageError('');

        console.log('Sending?');

        sendNotification(
          selectedVerification.user.id,
          accept ? 'Image Verified ✅' : 'Image Verification Required 🔒 ',
          accept
            ? "We've verified your new profile picture, check it out!"
            : denialMessage,
          {
            notificationType: accept
              ? 'IMAGE_VERIFICATION_SUCCESS'
              : 'IMAGE_VERIFICATION_FAILED',
          },
          'user',
          selectedVerification.photoUrl
        );

        if (accept) {
          // Update user profile picture!
          db.ref(
            'new_data/user_data/users/' +
              selectedVerification.user.id +
              '/profilePicUrl'
          ).set(selectedVerification.photoUrl);
        }
        console.log(selectedVerification.user);

        db.ref(
          `/new_data/photo_verifications/${selectedVerification.user.id}/responded`
        ).set(true);

        setDenialMessage('');
        setDenialMessageError('');
        setVerifyDenial(false);
        toast.success('Response made.');
        setSelectedVerification(null);
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      {selectedVerification.user ? (
        <Grid
          container
          spacing={2}
          style={{ height: '80vh', overflowY: 'scroll' }}
        >
          {/* Close button */}
          <Grid
            container
            item
            xs={12}
            justifyContent={'space-between'}
            style={{ height: '40px', marginBottom: 20 }}
          >
            <Typography variant="h3">
              {selectedVerification.user.firstName +
                ' ' +
                selectedVerification.user.lastName}
            </Typography>
            <IconButton
              aria-label="Close modal"
              component="span"
              onClick={() => {
                console.log('ok...');
                setSelectedVerification(null);
                setVerifyDenial(false);
                setDenialMessage('');
                setDenialMessageError('');
              }}
            >
              <Close />
            </IconButton>
          </Grid>
          <Grid container item xs={8}>
            {/* Left image panel */}
            <Grid
              container
              item
              xs={6}
              flexDirection={'column'}
              alignContent={'center'}
            >
              <Typography variant={'h6'}>Selfie</Typography>

              {selectedVerification.user.selfieImageUrl && (
                <img
                  src={selectedVerification.user.selfieImageUrl}
                  style={{ width: '100%', height: 'auto', zIndex: 3 }}
                  className="hover-zoom"
                />
              )}
            </Grid>
            <Grid
              container
              item
              xs={6}
              flexDirection={'column'}
              alignContent={'center'}
            >
              <Typography variant={'h6'}>ID</Typography>

              {selectedVerification.user.idImageUrl && (
                <img
                  src={selectedVerification.user.idImageUrl}
                  style={{ width: '100%', height: 'auto', zIndex: 3 }}
                  className="hover-zoom"
                />
              )}
            </Grid>
            <Grid
              container
              item
              xs={6}
              flexDirection={'column'}
              alignContent={'center'}
            >
              <Typography variant={'h6'}>Old Profile Pic</Typography>

              {selectedVerification.user.profilePicUrl && (
                <img
                  src={selectedVerification.user.profilePicUrl}
                  style={{ width: '100%', height: 'auto', zIndex: 3 }}
                  className="hover-zoom"
                />
              )}
            </Grid>
            <Grid
              container
              item
              xs={6}
              flexDirection={'column'}
              alignContent={'center'}
            >
              <Typography variant={'h6'}>New Profile Pic</Typography>

              {selectedVerification.photoUrl && (
                <img
                  src={selectedVerification.photoUrl}
                  style={{ width: '100%', height: 'auto', zIndex: 3 }}
                  className="hover-zoom"
                />
              )}
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={4}
            flexDirection={'column'}
            alignContent={'center'}
          >
            <Typography variant={'h6'}>Details Entered:</Typography>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow
                    key={0}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      User ID:
                    </TableCell>
                    <TableCell align="right">
                      {selectedVerification.user.id}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={0}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      First Name:
                    </TableCell>
                    <TableCell align="right">
                      {selectedVerification.user.firstName}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={1}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Last Name
                    </TableCell>
                    <TableCell align="right">
                      {selectedVerification.user.lastName}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={2}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Date of Birth
                    </TableCell>
                    <TableCell align="right">
                      {selectedVerification.user.dateOfBirth}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={3}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Sex
                    </TableCell>
                    <TableCell align="right">
                      {selectedVerification.user.sex}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {verifyDenial ? (
              <Grid container item sx={{ paddingTop: 1 }}>
                <Button
                  color={'primary'}
                  onClick={() => {
                    setVerifyDenial(false);
                    setDenialMessage('');
                    setDenialMessageError('');
                    setDenialMessageFinal('');
                  }}
                >
                  Cancel Denial
                </Button>
                {denialMessageFinal === '' && denialMessage !== 'Other.' ? (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    color="primary"
                    onChange={event => {
                      let msg = event.target.labels?.item(0).innerText;

                      if (msg) {
                        setDenialMessage(msg);

                        if (msg === 'Other.') {
                          setDenialMessageFinal('');
                        } else {
                          setDenialMessageFinal(msg);
                        }
                      }
                    }}
                  >
                    <FormControlLabel
                      value="id_mismatch"
                      control={<Radio />}
                      label="Identity document details do not match account details."
                      style={{ fontSize: '10px !important' }}
                      sx={{ fontSize: '1em' }}
                    />
                    <FormControlLabel
                      value="unclear_photo"
                      control={<Radio />}
                      style={{ fontSize: '10px' }}
                      label="Profile image and/or identity docuent image is unclear."
                    />
                    <FormControlLabel
                      value="id_invalid"
                      control={<Radio />}
                      style={{ fontSize: '10px' }}
                      label="Identity document not valid."
                    />
                    <FormControlLabel
                      value="suspicious"
                      style={{ fontSize: '10px' }}
                      control={<Radio />}
                      label="The photos provided are suspicious (Photo of a photo)."
                    />

                    <FormControlLabel
                      value="other"
                      style={{ fontSize: '10px' }}
                      control={<Radio />}
                      label="Other."
                    />
                  </RadioGroup>
                ) : (
                  <Grid item container xs={12}>
                    <Typography variant={'h6'}>
                      Double check message to the user:
                    </Typography>
                    <TextField
                      placeholder="Please enter a messsage to explain to the user why they've been denied."
                      value={denialMessageFinal}
                      disabled={true}
                      onChange={event => {
                        setDenialMessageFinal(event.target.value);
                      }}
                      style={{ width: '100%' }}
                      multiline
                    />
                    <Button
                      style={{
                        backgroundColor: 'green',
                        color: 'white',
                        marginTop: '10px',
                        width: '100%',
                        height: '50px',
                      }}
                      onClick={() => {
                        acceptOrDeny(false);
                      }}
                    >
                      Confirm
                    </Button>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid container item sx={{ paddingTop: 1 }}>
                <Grid container item xs={6} justifyContent={'center'}>
                  <IconButton
                    style={{
                      color: 'white',
                      backgroundColor: 'green',
                      borderRadius: 35,
                      zIndex: 2,
                    }}
                    onClick={() => {
                      //Accept
                      acceptOrDeny(true);
                    }}
                  >
                    <CheckRounded />
                  </IconButton>
                </Grid>
                <Grid container item xs={6} justifyContent={'center'}>
                  <IconButton
                    style={{
                      color: 'white',
                      backgroundColor: '#cd0438',
                      borderRadius: 35,
                      zIndex: 2,
                    }}
                    onClick={() => {
                      //Deny
                      setVerifyDenial(true);
                    }}
                  >
                    <ClearRounded />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <Typography>Something went wrong...</Typography>
      )}
    </>
  );
}
