export interface CommunityModel {
  communityId: string;
  active: boolean;
  limits: CommunityLimits;
  creatorId: string;
  creationDate: string;
  emailEnding?: string;
  name: string;
  desc: string;
  imageUrl?: string;
  logoUrl: string;
}

export enum CommunityLimits {
  ID_VERIFIED = 'ID_VERIFIED',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
  NONE = 'NONE',
}
