import {
  Card,
  CircularProgress,
  Container,
  Modal,
  TextField,
  Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import NavBar from '../components/NavBar';
import { useNavigate } from 'react-router';
import { watchAuthState } from '../../services/login_services';
import {
  getAllNeededVerifications,
  getOlderVerifications,
} from '../../services/report_services';
import { VerificationModel } from '../../models/VerificationModel';
import VerificationCard from '../components/VerificationCard';
import VerificationInfo from '../components/VerificationInfo';
import { Helmet } from 'react-helmet';
import { db } from '../../services/firebase_services';
import { getUserById } from '../../store/thunks/enterprise.users.thunks';
import { id } from 'date-fns/locale';
import UserDataModel from '../../models/UserDataModel';
import { primaryColor } from '../../assets/styles/styles';
import ImageVerificationInfo from '../components/ImageVerificationInfo';

export default function Verifications({ theme, mapboxgl }: any) {
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(false);

  //States for data
  const [allNeededVerifications, setAllNeededVerifications] = useState<
    VerificationModel[]
  >([]);

  const [imageVerifications, setImageVerifications] = useState<
    { photoUrl: string; user: UserDataModel; responded: boolean }[]
  >([]);
  const [allNeededVerificationLoading, setAllNeededVerificationsLoading] =
    useState<any>(false);

  const [olderVerifications, setOlderVerifications] = useState<
    VerificationModel[]
  >([]);
  const [olderVerificationLoading, setOlderVerificationsLoading] =
    useState<any>(false);

  const [selectedVerification, setSelectedVerification] = useState<any>(null);
  const [selectedImageVerification, setSelectedImageVerification] =
    useState<any>(null);

  const [numSignups, setNumSignups] = useState(0);
  //Check if authenticated
  useEffect(() => {
    watchAuthState(navigate, 'other', setLoggedIn);
  }, []);

  const imageRef = useRef<any>(false);
  useEffect(() => {
    if (loggedIn) {
      getAllNeededVerifications(
        setAllNeededVerifications,
        setAllNeededVerificationsLoading
      );
      getOlderVerifications(
        setOlderVerifications,
        setOlderVerificationsLoading
      );

      imageRef.current = db
        .ref('/new_data/photo_verifications')
        .orderByChild('responded')
        .equalTo(false);

      imageRef.current.on('value', async function (userFound: any) {
        if (userFound !== null) {
          let verList: any[] = Object.values(userFound.val());

          let allImageVerifications = [];
          for (let i = 0; i < verList.length; i++) {
            let user = (
              await db
                .ref('/new_data/user_data/users/' + verList[i].userId)
                .once('value')
            ).val();

            if (user !== null) {
              allImageVerifications.push({
                photoUrl: verList[i].photoUrl,
                user: user,
                responded: verList[i].responded,
              });
            }
          }
          setImageVerifications(allImageVerifications);
        }
      });
    }

    return function cleanup() {
      setAllNeededVerifications([]);
      setSelectedVerification(null);
      imageRef.current = false;
    };
  }, [loggedIn]);

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>
      <NavBar
        theme={theme}
        navigate={navigate}
        currentPage={'verification'}
        loggedIn={loggedIn}
      />

      <Container
        style={{
          backgroundColor: theme.palette.secondary.main,
          minHeight: 'calc(100vh - 48px)',
          width: '100vw',
          marginTop: '48px',
          padding: 15,
        }}
      >
        <Grid
          container
          sx={{ marginTop: 10, color: 'grey' }}
          flexDirection={'column'}
        >
          <Grid container item flexDirection={'column'} xs={12}>
            <Typography
              color={'inherit'}
              variant="h6"
              style={{ height: 30, marginBottom: 10 }}
            >
              Recent Verifications
            </Typography>

            <Grid container item xs={12} spacing={2}>
              {allNeededVerificationLoading ? (
                <CircularProgress />
              ) : allNeededVerifications === null ||
                allNeededVerifications.length === 0 ? (
                <Grid item xs={6}>
                  <img
                    src="/static/happy.svg"
                    style={{ width: '200px', margin: '15px' }}
                    alt={'No recent reports to show'}
                  />
                  <Typography>No recent reports to show.</Typography>
                </Grid>
              ) : (
                allNeededVerifications.map((ver: any) => {
                  return (
                    <Grid item xs={4}>
                      {ver !== null && (
                        <VerificationCard
                          theme={theme}
                          ver={ver.ver}
                          user={ver.user}
                          setSelectedVerification={setSelectedVerification}
                        />
                      )}
                    </Grid>
                  );
                })
              )}
            </Grid>

            <Typography
              color={'inherit'}
              variant="h6"
              style={{ height: 30, marginBottom: 10 }}
            >
              Image Verifications
            </Typography>

            <Grid container item xs={12} spacing={2}>
              {imageVerifications === null ||
              imageVerifications.length === 0 ? (
                <Grid item xs={6}>
                  <img
                    src="/static/happy.svg"
                    style={{ width: '200px', margin: '15px' }}
                    alt={'No active verifications to show'}
                  />
                  <Typography>No active verifications to show.</Typography>
                </Grid>
              ) : (
                imageVerifications.map((ver: any) => {
                  return (
                    <Grid item xs={4}>
                      {ver !== null && (
                        <Tooltip title="View Verification">
                          <Card
                            sx={{
                              height: '100%',
                              cursor: 'pointer',
                              backgroundColor: theme.palette.secondary.main,
                            }}
                            onClick={() => {
                              setSelectedImageVerification(ver);
                            }}
                          >
                            <Grid
                              container
                              alignContent={'center'}
                              alignItems={'center'}
                              boxShadow={2}
                              borderRadius={1}
                            >
                              <Grid
                                container
                                item
                                xs={12}
                                alignItems={'center'}
                                style={{
                                  backgroundColor: primaryColor,
                                  height: '100%',
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: theme.palette.primary.light,
                                    fontSize: 12,
                                    padding: '2px 5px',
                                    borderRadius: 10,
                                  }}
                                >
                                  Verification Needed
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                container
                                xs={12}
                                justifyContent={'center'}
                                padding={2}
                              >
                                <img
                                  src={ver.photoUrl}
                                  style={{
                                    width: '100px',
                                    height: '100px',
                                    borderRadius: '50px',
                                  }}
                                />
                              </Grid>
                              <Grid item container>
                                <Typography
                                  style={{
                                    fontFamily: 'Urbanist',
                                    fontWeight: '700',
                                    fontSize: 25,
                                    color: 'black',
                                    textAlign: 'center',
                                    width: '100%',
                                  }}
                                >
                                  {ver.user.firstName} {ver.user.lastName}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Card>
                        </Tooltip>
                      )}
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Grid>

          {/* <Grid container item flexDirection={'column'} xs={12}>
            <Typography
              color={'inherit'}
              variant="h6"
              style={{ height: 30, marginBottom: 10 }}
            >
              Older Verifications
            </Typography>

            <Grid container item xs={12} spacing={2}>
              {olderVerificationLoading ? (
                <CircularProgress />
              ) : olderVerifications === null ||
                olderVerifications.length === 0 ? (
                <Grid item xs={6}>
                  <img
                    src="/static/happy.svg"
                    style={{ width: '200px', margin: '15px' }}
                    alt={'No recent reports to show'}
                  />
                  <Typography>No recent reports to show.</Typography>
                </Grid>
              ) : (
                olderVerifications.map((ver: any) => {
                  return (
                    <Grid item xs={4}>
                      {ver !== null && (
                        <VerificationCard
                          theme={theme}
                          ver={ver.ver}
                          user={ver.user}
                          setSelectedVerification={setSelectedVerification}
                        />
                      )}
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Grid> */}
        </Grid>
        <Modal
          open={selectedVerification !== null}
          onClose={() => {
            setSelectedVerification(null);
          }}
          aria-labelledby="View Verification"
          aria-describedby="Learn more about this report."
        >
          <Grid
            container
            xs={12}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Grid
              item
              container
              xs={10}
              sx={{ backgroundColor: 'white', margin: 'auto', padding: '35px' }}
            >
              <VerificationInfo
                theme={theme}
                selectedVerification={selectedVerification}
                setSelectedVerification={setSelectedVerification}
                mapboxgl={mapboxgl}
              />
            </Grid>
          </Grid>
        </Modal>
        <Modal
          open={selectedImageVerification !== null}
          onClose={() => {
            setSelectedImageVerification(null);
          }}
          aria-labelledby="View Verification"
          aria-describedby="Learn more about this report."
        >
          <Grid
            container
            xs={12}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Grid
              item
              container
              xs={10}
              sx={{ backgroundColor: 'white', margin: 'auto', padding: '35px' }}
            >
              <ImageVerificationInfo
                theme={theme}
                removeVer={(ver: any) => {
                  let all_image_Ver = imageVerifications.filter(val2 => {
                    if (val2.user.id !== ver.user.id) {
                      return val2;
                    }
                  });

                  setImageVerifications(all_image_Ver);
                }}
                selectedVerification={selectedImageVerification}
                setSelectedVerification={(val: any) => {
                  if (val !== null) {
                    if (selectedImageVerification !== null) {
                      setImageVerifications(
                        [...imageVerifications].filter(val2 => {
                          console.log(val2, val);
                          if (val2.user.id !== val.user.id) {
                            return val2;
                          }
                        })
                      );
                    }
                  } else {
                    setImageVerifications(
                      [...imageVerifications].filter(val2 => {
                        console.log(val2, val);
                        if (
                          selectedImageVerification !== null &&
                          val2.user.id !== selectedImageVerification.user.id
                        ) {
                          return val2;
                        }
                      })
                    );
                  }

                  console.log('Setting to?');
                  setSelectedImageVerification(val);
                }}
                mapboxgl={mapboxgl}
              />
            </Grid>
          </Grid>
        </Modal>
      </Container>
    </>
  );
}
