import {
  Avatar,
  Button,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { auth, db } from '../../services/firebase_services';
import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { CoalitionModel } from '../../models/CoalitionModel';
import { CommunityModel } from '../../models/CommunityModel';
import * as turf from '@turf/turf';

import {
  CreatorEnum,
  JourneyAppModel,
  JourneyModel,
  JourneyUserAppModel,
} from '../../models/GroupModel';
import UserDataModel from '../../models/UserDataModel';
import { getCreatorFromJourney } from '../../store/thunks/community.thunks';
import {
  getLastObjectOfList,
  getNextTimeSlotWithEndTime,
  getRemainingUserForGroup,
  getReoccourence,
  useTools,
} from '../../services/general_services';
import {
  CalendarToday,
  People,
  Edit,
  Delete,
  LocationCityOutlined,
  ArrowForward,
  LocationSearching,
  LocationOn,
  Check,
  Launch,
  AccessTime,
} from '@mui/icons-material';
import { getCurrentDateTime } from '../../services/home_services';
import { ActivitiesGrid } from '../components/community_admin/DataGrid';
import { setSelectedActivity } from '../../store/slices/enterprise.slice';
import { primaryColor } from '../../assets/styles/styles';

export function ViewPublicTrips({ mapboxgl }: any) {
  const { communityId } = useParams();

  const { navigate } = useTools();
  const [currentActivity, setCurrentActivity] = useState<
    JourneyAppModel | undefined
  >(undefined);
  const [selectedCommunity, setSelectedCommunity] = useState<any>(undefined);
  const [activities, setActivities] = useState<JourneyAppModel[]>([]);
  async function getCommunityData() {
    let communityDb = db.ref(
      '/new_data/community_data/communities/' + communityId
    );

    let community: any = (await communityDb.once('value')).val();
    if (community) {
      setSelectedCommunity(community);
    }
  }

  useEffect(() => {
    if (map.current) return; // initialize map only once

    // if (mapboxgl && currentActivity) {
    //
    //   map.current = new mapboxgl.Map({
    //     container: mapContainer.current,
    //     style: 'mapbox://styles/mapbox/streets-v11',
    //     center: [lng, lat],
    //     zoom: zoom,
    //   });

    //   //Add markers to map
    //   const destPopup = new mapboxgl.Popup()
    //     .setHTML(
    //       `<strong>Destination:<br /></strong>${currentActivity.destination.address}`
    //     )
    //     .addTo(map.current);

    //   new mapboxgl.Marker({ color: '#cd0438' })
    //     .setLngLat([
    //       currentActivity.destination.lng,
    //       currentActivity.destination.lat,
    //     ])
    //     .addTo(map.current)
    //     .setPopup(destPopup);

    //   const popup = new mapboxgl.Popup()
    //     .setHTML(
    //       `<strong>Meetup Point:<br /></strong>${currentActivity.origin.address}`
    //     )
    //     .addTo(map.current);

    //   new mapboxgl.Marker({ color: '#cd0438' })
    //     .setLngLat([currentActivity.origin.lng, currentActivity.origin.lat])
    //     .addTo(map.current)
    //     .setPopup(popup);

    //   let origin: any = [
    //     currentActivity.origin.lng,
    //     currentActivity.origin.lat,
    //   ];
    //   let destination: any = [
    //     currentActivity.destination.lng,
    //     currentActivity.destination.lat,
    //   ];

    //   // A single point that animates along the route.
    //   // Coordinates are initially set to origin.
    //   const point: any = {
    //     type: 'FeatureCollection',
    //     features: [
    //       {
    //         type: 'Feature',
    //         properties: {},
    //         geometry: {
    //           type: 'Point',
    //           coordinates: origin,
    //         },
    //       },
    //     ],
    //   };

    //   // Create a LineString feature from the coordinates
    //   const lineString = turf.lineString([origin, destination]);

    //   // Calculate the bezier spline curve
    //   const bezierSpline = turf.bezierSpline(lineString);

    //   // Number of steps to use in the animation
    //   const steps = 500;

    //   // Calculate the length of the bezier spline
    //   const bezierSplineLength = turf.length(bezierSpline, {
    //     units: 'kilometers',
    //   });

    //   // Initialize the arc array with the starting point
    //   const arc = [origin];

    //   // Calculate points along the bezier spline
    //   for (let t = 1; t <= steps; t++) {
    //     const fraction = (t / steps) * 2;

    //     const distanceAlongCurve = fraction * bezierSplineLength;
    //     const pointOnCurve = turf.along(bezierSpline, distanceAlongCurve, {
    //       units: 'kilometers',
    //     });
    //     arc.push(pointOnCurve.geometry.coordinates);
    //   }

    //   // Update the route with the calculated arc coordinates
    //   const route = {
    //     type: 'FeatureCollection',
    //     features: [
    //       {
    //         type: 'Feature',
    //         geometry: {
    //           type: 'LineString',
    //           coordinates: arc,
    //         },
    //       },
    //     ],
    //   };

    //   // Used to increment the value of the point measurement against the route.
    //   let counter = 0;

    //   map.current.on('load', () => {
    //     // Add a source and layer displaying a point which will be animated in a circle.
    //     map.current.addSource('route', {
    //       type: 'geojson',
    //       data: route,
    //     });

    //     map.current.addSource('point', {
    //       type: 'geojson',
    //       data: point,
    //     });

    //     map.current.addLayer({
    //       id: 'route',
    //       source: 'route',
    //       type: 'line',
    //       paint: {
    //         'line-width': 2,
    //         'line-color': '#cd0438',
    //       },
    //     });

    //     map.current.addLayer({
    //       id: 'point',
    //       source: 'point',
    //       type: 'symbol',
    //       layout: {
    //         // This icon is a part of the Mapbox Streets style.
    //         // To view all images available in a Mapbox style, open
    //         // the style in Mapbox Studio and click the "Images" tab.
    //         // To add a new image to the style at runtime see
    //         // https://docs.mapbox.com/mapbox-gl-js/example/add-image/
    //         'icon-image': 'pitch-15',
    //         'icon-rotate': ['get', 'rotate'],
    //         'icon-rotation-alignment': 'map',
    //         'icon-allow-overlap': true,
    //         'icon-ignore-placement': true,
    //       },
    //     });

    //     function animate() {
    //       const start =
    //         route.features[0].geometry.coordinates[
    //           counter >= steps ? counter - 1 : counter
    //         ];
    //       const end =
    //         route.features[0].geometry.coordinates[
    //           counter >= steps ? counter : counter + 1
    //         ];
    //       if (!start || !end) return;

    //       // Update point geometry to a new position based on counter denoting
    //       // the index to access the arc
    //       point.features[0].geometry.coordinates =
    //         route.features[0].geometry.coordinates[counter];

    //       // Calculate the bearing to ensure the icon is rotated to match the route arc
    //       // The bearing is calculated between the current point and the next point, except
    //       // at the end of the arc, which uses the previous point and the current point
    //       point.features[0].properties.bearing = turf.bearing(
    //         turf.point(start),
    //         turf.point(end)
    //       );

    //       // Update the source with this new data
    //       map.current.getSource('point').setData(point);

    //       // Request the next frame of animation as long as the end has not been reached
    //       if (counter < steps) {
    //         requestAnimationFrame(animate);
    //         counter = counter + 1;
    //       } else {
    //         counter = 0;

    //         animate();
    //       }
    //     }

    //     // Set the coordinates of the original point back to origin
    //     point.features[0].geometry.coordinates = origin;

    //     // Update the source layer
    //     map.current.getSource('point').setData(point);

    //     // Start the animation
    //     animate();
    //   });
    // } else {
    //
    // }

    // if (currentActivity && map.current) {
    //   setLat(currentActivity.origin.lat);
    //   setLng(currentActivity.origin.lng);

    //   map.current.fitBounds(
    //     [
    //       {
    //         lat: currentActivity.origin.lat,
    //         lng: currentActivity.origin.lng,
    //       },
    //       {
    //         lat: currentActivity.destination.lat,
    //         lng: currentActivity.destination.lng,
    //       },
    //       {
    //         lat: currentActivity.destination.lat,
    //         lng: currentActivity.destination.lng - 0.5,
    //       },
    //     ],
    //     {
    //       speed: 4,
    //       padding: 100,
    //       offset: [-70, 0],
    //     }
    //   );
    // }
  }, [mapboxgl, currentActivity]);

  useEffect(() => {
    if (communityId) {
      getCommunityData();

      getAllUpcomingCommunityActivities({
        id: communityId,
        startDate: new Date(),
        endDate: moment(new Date()).add(7, 'd').toDate(),
        allActivities: [],
      });
    }
  }, [communityId]);

  const mapContainer = useRef<any>(null);
  const map = useRef<any>(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(15);

  async function getAllUpcomingCommunityActivities({
    id,
    startDate,
    endDate,
    allActivities,
  }: {
    id: string;
    startDate: Date;
    endDate: Date;
    allActivities: JourneyAppModel[];
  }) {
    try {
      let activitiesDb = db.ref('/new_data/community_data/activities/' + id);
      let repeatActivitiesDb = db.ref(
        '/new_data/community_data/repeat_activities/' + id
      );

      let date = new Date();
      date.setDate(date.getDate() + 14);

      let activities: any = (
        await activitiesDb
          .orderByChild('date')
          .startAt(startDate.toISOString())
          .endBefore(endDate.toISOString())
          .once('value')
      ).val();

      // Limit repeat activities to trips that are scheduled to start
      let repeatActivities = (
        await repeatActivitiesDb
          .orderByChild('repeatData/repeatEndTime')
          .endAt(endDate.toISOString())
          .once('value')
      ).val();

      let community_activites: JourneyAppModel[] = [];

      if (activities !== null) {
        let list: JourneyModel[] = Object.values(activities);

        for (let i = 0; i < list.length; i++) {
          let activity: JourneyModel = list[i];

          if (
            allActivities.length === 0 ||
            allActivities.find(val => val.groupId === activity.groupId) ===
              undefined
          ) {
            let creator:
              | UserDataModel
              | CoalitionModel
              | CommunityModel
              | undefined = await getCreatorFromJourney(activity);

            if (creator) {
              let userList = [];
              try {
                if (activity.users) {
                  for (
                    let j = 0;
                    j < Object.values(activity.users).length;
                    j++
                  ) {
                    let curUser: any = Object.values(activity.users)[j];
                    let user = db.ref(
                      '/new_data/user_data/users/' + curUser.userId
                    );
                    //Will listen to the users table and update state automatically
                    //GET ONLY ACTIVE JOURNEYS
                    let userData = (await user.once('value')).val();

                    let journeyUser: JourneyUserAppModel = {
                      user: userData,
                      id: curUser.userId,
                      leaveTime: curUser.leaveTime,
                    };
                    userList.push(journeyUser);
                  }
                }

                let activityToReturn: JourneyAppModel = {
                  communityId: activity.communityId,
                  active: activity.active,
                  creationDate: activity.creationDate,
                  groupId: activity.groupId,
                  creator: creator,
                  creatorType: activity.creatorType,
                  origin: activity.origin,
                  destination: activity.destination,
                  journeyState: activity.journeyState,
                  waypointOrder: activity.waypointOrder,
                  title: activity.title,
                  description: activity.description,
                  date: activity.date,
                  users: userList,
                };

                community_activites.push(activityToReturn);
              } catch (err) {}
            }
          }
        }
      }

      if (repeatActivities !== null) {
        // Repeat activities
        //
        // Starting at the initial 'date' value providied by the activity data,
        // the repeat activities will add up until a specific end date (In this case, 2 days initially)
        // within specific increments.
        //
        // Each repeat activity generated will be a placeholder, and will need to generate
        // a DATE identifier (YYYY-MM-DD) and a TIME identifier (HH:MM)
        //
        // These will indicate where in the database to look-up activity data
        // This data can be used instead of a placeholder, blank activity - Which will be used
        // when no such activity exists yet (A occurence)

        let list: JourneyModel[] = Object.values(repeatActivities);

        let currentDate = new Date();
        // Loop through currently available repeat trips.
        for (let i = 0; i < list.length; i++) {
          let activity = list[i];

          if (
            allActivities.length === 0 ||
            allActivities.find(val => val.groupId === activity.groupId) ===
              undefined
          ) {
            let creator:
              | UserDataModel
              | CoalitionModel
              | CommunityModel
              | undefined = await getCreatorFromJourney(activity);

            // Do not show repeat activities that are not scheduled yet.
            if (
              ((activity.repeatData &&
                activity.repeatData.repeatEndDate &&
                moment(activity.repeatData.repeatEndDate).isBefore(endDate)) ||
                activity.repeatData) &&
              creator
            ) {
              // Default data for the group...

              let reoccurence: JourneyAppModel = {
                communityId: activity.communityId,
                date: activity.date,
                creator: creator,
                creatorType: activity.creatorType,
                active: activity.active,
                repeatData: activity.repeatData,
                reoccurunces: activity.reoccurunces,
                creationDate: activity.creationDate,
                groupId: activity.groupId,
                origin: activity.origin,
                destination: activity.destination,
                title: activity.title,
                description: activity.description,
                journeyState: activity.journeyState,
              };

              // New algorithm.

              let diff = moment(endDate).diff(startDate, 'weeks');

              let hour_range = 24;

              // if (
              //   activity.repeatData.repeatStartTime &&
              //   activity.repeatData.repeatEndTime
              // ) {
              //   let val = getNextTimeSlotWithEndTime(
              //     new Date(),
              //     new Date(activity.repeatData.repeatStartTime),
              //     new Date(activity.repeatData.repeatEndTime),

              //     activity.repeatData.repeatValue
              //   );

              //
              //   hour_range = moment(val?.slotEndTime).diff(
              //     moment(val?.timeSlot),
              //     'hours'
              //   );
              //   hour_range += 1;
              // }

              // For each week between search range...
              // for (let j = 0; j < diff; j++) {
              //
              //   //  We now know what time to begin our loops, on the days we would like them.
              //   let week_date = moment(startDate).add(j, 'weeks');

              //   // For each day we repeat
              //   for (
              //     let k = 0;
              //     k < activity.repeatData.repeatDays.length;
              //     k++
              //   ) {
              //     let dayNumber = activity.repeatData.repeatDays[k];

              //     let hourStr = activity.repeatData.repeatAllDay
              //       ? '00'
              //       : activity.repeatData.repeatStartTime
              //       ? moment(activity.repeatData.repeatStartTime)
              //           .utc()
              //           .format('HH')
              //       : '00';
              //     let minStr = activity.repeatData.repeatAllDay
              //       ? '00'
              //       : activity.repeatData.repeatStartTime
              //       ? moment(activity.repeatData.repeatStartTime)
              //           .utc()
              //           .format('mm')
              //       : '00';

              //

              //     week_date.utc().set('hour', Number.parseInt(hourStr));
              //     week_date.utc().set('minute', Number.parseInt(minStr));

              //     let instance_date = week_date;
              //     instance_date.set('day', dayNumber);

              //     // For each hour within day to repeat
              //     for (let h = 0; h < hour_range; h++) {
              //       let repeatId = instance_date
              //         .utc()
              //         .format('yyyy-MM-DD/HH-mm');

              //       if (h === 0) {
              //
              //       }
              //       let reoccurenceDb = await getReoccourence(
              //         instance_date.utc().format('yyyy-MM-DD'),
              //         moment(new Date())
              //           .set('minutes', 0)
              //           .set('hours', 20)
              //           .format('hh-mm'),
              //         reoccurence
              //       );

              //

              //       if (reoccurenceDb !== null) {
              //
              //         if (reoccurenceDb.journeyState) {
              //           let stages: any[] = Object.values(
              //             reoccurenceDb.journeyState.stages
              //           );
              //           if (
              //             !reoccurence.journeyState ||
              //             stages[stages.length - 1].stage !== 'JF'
              //           ) {
              //             let userList: JourneyUserAppModel[] = [];
              //             for (
              //               let o = 0;
              //               o < Object.values(reoccurenceDb.users).length;
              //               o++
              //             ) {
              //               let curUser: any = Object.values(
              //                 reoccurenceDb.users
              //               )[o];

              //               if (
              //                 curUser.userId !== id &&
              //                 curUser.userId === id &&
              //                 !curUser.finishTime
              //               ) {
              //                 let user = db.ref(
              //                   '/new_data/user_data/users/' + curUser.userId
              //                 );
              //                 //Will listen to the users table and update state automatically
              //                 //GET ONLY ACTIVE JOURNEYS
              //                 let userData = (await user.once('value')).val();

              //                 let journeyUser: JourneyUserAppModel = {
              //                   user: userData,
              //                   id: curUser.userId,
              //                   leaveTime: curUser.leaveTime,
              //                   finishTime: curUser.finishTime,
              //                 };
              //                 userList.push(journeyUser);
              //               }

              //

              //               community_activites.push({
              //                 ...reoccurence,
              //                 date: instance_date.utc().toISOString(),
              //                 users: userList,
              //                 journeyState: reoccurenceDb.journeyState
              //                   ? reoccurenceDb.journeyState
              //                   : reoccurence.journeyState,
              //                 repeatId: repeatId,
              //               });
              //             }
              //           }
              //         }
              //       } else {
              //
              //         community_activites.push({
              //           ...reoccurence,
              //           date: moment(new Date())
              //             .set('minutes', 0)
              //             .set('hours', 20)
              //             .toS(),

              //           repeatId: repeatId,
              //           users: [],
              //         });
              //       }

              //       instance_date.utc().add(1, 'hour');
              //     }
              //   }
              // }

              community_activites.push({
                ...reoccurence,
                date: moment(new Date())
                  .set('minutes', 0)
                  .set('hours', 20)
                  .toString(),

                repeatId: '16-11-2023/21-00',
                users: [],
              });
            } else {
            }
          } else {
          }
          //
          //
        }
      }

      setActivities(community_activites);
    } catch (err: any) {
      // Go to error page if error occurs
      // goToErrorPage(navigate);
    }
  }
  function addJourneyForUser(
    groupId: string,
    userId: string,
    communityId: string,
    repeatActivity: boolean,
    repeatId: string,
    groupDate: string
  ) {
    if (repeatActivity) {
      db.ref(
        '/new_data/user_data/user_repeat_activities/' +
          userId +
          '/' +
          communityId +
          '/' +
          groupId +
          '/' +
          repeatId
      ).set({
        groupId: groupId,
        repeatActivity: repeatActivity,
        repeatId: repeatId,
        communityId: communityId,
        date: groupDate,
        active: true,
      });
    } else {
      db.ref(
        '/new_data/user_data/user_activities/' +
          userId +
          '/' +
          communityId +
          '/' +
          groupId
      ).set({
        active: true,
        groupId: groupId,
        repeatActivity: repeatActivity,
        communityId: communityId,
        date: groupDate,
      });
    }
  }

  const [joined, setJoined] = useState(false);

  async function joinActivity() {
    if (currentActivity) {
      let repeatActivity = currentActivity.repeatData !== undefined;
      let location =
        currentActivity.creatorType === 'COMMUNITY'
          ? 'community_data'
          : 'coalition_data';

      let id =
        currentActivity.creatorType === 'COMMUNITY'
          ? // @ts-ignore
            currentActivity.creator.communityId
          : // @ts-ignore
            currentActivity.creator.id;
      let date = getCurrentDateTime();

      if (
        repeatActivity &&
        currentActivity.repeatData &&
        currentActivity.repeatId
      ) {
        await db
          .ref(
            `/new_data/${location}/repeat_activities/${id}/${
              currentActivity.groupId
            }/occurences/${currentActivity.repeatId}/users/${
              auth.currentUser!.uid
            }`
          )
          .set({ joinTime: date, userId: auth.currentUser!.uid });
        await db
          .ref(
            `/new_data/${location}/repeat_activities/${id}/${
              currentActivity.groupId
            }/occurences/${currentActivity.repeatId}/users/${
              auth.currentUser!.uid
            }/leaveTime`
          )
          .remove();
        // Add the journey state if not existing...
        if (
          (
            await db
              .ref(
                `/new_data/${location}/repeat_activities/${id}/${currentActivity.groupId}/occurences/${currentActivity.repeatId}/journeyState`
              )
              .once('value')
          ).val() === null
        ) {
          await db
            .ref(
              `/new_data/${location}/repeat_activities/${id}/${currentActivity.groupId}/occurences/${currentActivity.repeatId}/journeyState`
            )
            .set({
              currentIndex: 0,
              stages: [{ stage: 'WFU', dateTime: getCurrentDateTime() }],
            });
        }

        addJourneyForUser(
          currentActivity.groupId,
          auth.currentUser!.uid,
          id,
          true,
          currentActivity.repeatId,
          currentActivity.date
        );
      } else {
        // Add user to group user list
        let date = getCurrentDateTime();
        await db
          .ref(
            `/new_data/${location}/activities/${id}/${
              currentActivity.groupId
            }/users/${auth.currentUser!.uid}/leaveTime`
          )
          .remove();

        await db
          .ref(
            `/new_data/${location}/activities/${id}/${
              currentActivity.groupId
            }/users/${auth.currentUser!.uid}`
          )
          .set({ joinTime: date, userId: auth.currentUser!.uid });
        addJourneyForUser(
          currentActivity.groupId,
          auth.currentUser!.uid,
          id,
          false,
          '',
          currentActivity.date
        );
      }
    }
  }
  async function leaveActivity() {
    if (currentActivity) {
      let repeatActivity = currentActivity.repeatData !== undefined;
      let location =
        currentActivity.creatorType === 'COMMUNITY'
          ? 'community_data'
          : 'coalition_data';

      let id =
        currentActivity.creatorType === 'COMMUNITY'
          ? // @ts-ignore
            currentActivity.creator.communityId
          : // @ts-ignore
            currentActivity.creator.id;
      let date = getCurrentDateTime();

      if (
        repeatActivity &&
        currentActivity.repeatData &&
        currentActivity.repeatId
      ) {
        await db
          .ref(
            `/new_data/${location}/repeat_activities/${id}/${
              currentActivity.groupId
            }/occurences/${currentActivity.repeatId}/users/${
              auth.currentUser!.uid
            }/leaveTime`
          )
          .set(date);
      } else {
        // Add user to group user list
        let date = getCurrentDateTime();

        await db
          .ref(
            `/new_data/${location}/activities/${id}/${
              currentActivity.groupId
            }/users/${auth.currentUser!.uid}/leaveTime`
          )
          .set(date);
      }
    }
  }
  return (
    <Grid
      container
      sx={{ paddingTop: 5, height: 'calc(100% - 80px)' }}
      justifyContent={'center'}
    >
      <Grid container justifyContent={'center'}>
        <Grid
          item
          container
          xs={12}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <div
            style={{
              width: 80,
              height: 80,
              borderRadius: 90,
              marginLeft: 10,
              marginRight: 10,
              backgroundSize: 'cover',
              backgroundImage: selectedCommunity
                ? `url(${selectedCommunity.logoUrl})`
                : '',
            }}
          />
          <Grid item> +</Grid>
          <div
            style={{
              width: 80,
              height: 80,
              marginLeft: 10,
              marginRight: 10,
              backgroundSize: 'cover',
              backgroundImage: `url(${'/static/red_logo.png'})`,
            }}
          />
        </Grid>
      </Grid>

      {currentActivity && (
        <Grid container xs={12} justifyContent={'center'}>
          <Grid item container xs={11} sm={10}>
            <Button
              onClick={() => {
                setJoined(false);
                setCurrentActivity(undefined);
              }}
            >
              Back
            </Button>
          </Grid>
        </Grid>
      )}
      {joined ? (
        <>
          <Grid
            item
            container
            xs={12}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid
              item
              container
              justifyContent={'center'}
              alignItems={'center'}
              style={{
                height: 70,
                width: 70,
                borderRadius: 80,
                backgroundColor: `green`,
              }}
            >
              <Check
                style={{
                  fontSize: 55,
                  fontWeight: 'bolder',
                  color: 'white',
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            justifyContent={'center'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <Typography
              variant="h5"
              fontWeight={'700'}
              style={{ textAlign: 'center', marginTop: 20 }}
            >
              You're in!
            </Typography>

            <Typography
              variant="body1"
              style={{
                textAlign: 'center',
                marginTop: 20,
                maxWidth: 350,
                paddingBottom: 100,
              }}
            >
              Be sure to meet your chaperones at{' '}
              {currentActivity?.origin.address.split(',')[0]} around{' '}
              {moment(currentActivity!.date).format('HH:mma')}
            </Typography>
          </Grid>
        </>
      ) : currentActivity ? (
        <Grid
          item
          container
          xs={12}
          sx={{ height: '100%' }}
          justifyContent={'center'}
        >
          <Grid
            item
            container
            xs={10}
            sx={{
              backgroundColor: 'white',
              borderRadius: 5,
              right: 50,
              bottom: 45,
            }}
            boxShadow={2}
            alignContent={'flex-start'}
            padding={2}
            paddingLeft={2}
            paddingRight={2}
          >
            <Grid
              item
              container
              xs={12}
              paddingLeft={1}
              paddingRight={1}
              justifyContent={'center'}
              flexDirection={'column'}
            >
              <Typography
                variant="h5"
                fontWeight={700}
                sx={{ marginBottom: 1 }}
              >
                {currentActivity.title
                  ? currentActivity.title
                  : 'Unnamed Activity'}
              </Typography>

              <Typography variant="body2" sx={{ marginBottom: 1 }}>
                {currentActivity.description &&
                  currentActivity.description.split(',')[0]}
              </Typography>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12}>
                <Grid
                  container
                  sx={{
                    padding: 2,
                  }}
                  justifyContent={'center'}
                  alignContent={'center'}
                  spacing={1}
                >
                  <Grid item container sx={{ textTransform: 'capitalize' }}>
                    <Grid
                      item
                      xs={2}
                      container
                      alignContent={'center'}
                      justifyContent={'center'}
                    >
                      <LocationOn style={{ fontSize: 18, marginRight: 2 }} />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography style={{ fontSize: 15 }}>
                        {currentActivity &&
                          currentActivity.origin.address.split(',')[0] +
                            ' > ' +
                            currentActivity.destination.address.split(',')[0]}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  sx={{
                    padding: 2,
                  }}
                  justifyContent={'center'}
                  alignContent={'center'}
                  spacing={1}
                >
                  <Grid item container sx={{ textTransform: 'capitalize' }}>
                    <Grid
                      item
                      xs={2}
                      container
                      alignContent={'center'}
                      justifyContent={'center'}
                    >
                      <AccessTime style={{ fontSize: 18, marginRight: 2 }} />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography style={{ fontSize: 15 }}>
                        {currentActivity &&
                          moment(currentActivity.date).format(
                            'Do MMM YYYY HH:mma'
                          )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  sx={{
                    padding: 2,
                  }}
                  justifyContent={'center'}
                  alignContent={'center'}
                  spacing={1}
                >
                  <Grid item container sx={{ textTransform: 'capitalize' }}>
                    <Grid
                      item
                      xs={2}
                      container
                      alignContent={'center'}
                      justifyContent={'center'}
                    >
                      <People style={{ fontSize: 18, marginRight: 2 }} />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography style={{ fontSize: 15 }}>
                        {currentActivity &&
                          getRemainingUserForGroup(currentActivity).length +
                            ' Joined.'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              padding={2}
              style={{
                marginBottom: 2,
              }}
              justifyContent={'center'}
            >
              <Grid item container sx={{ width: '45px', marginRight: 2 }}>
                {currentActivity.creatorType === CreatorEnum.USER ? (
                  <div
                    style={{
                      width: 45,
                      height: 45,
                      borderRadius: 25,
                      // @ts-ignore
                      backgroundImage: `url(${
                        // @ts-ignore
                        currentActivity.creator.selfieImageUrl!
                          ? // @ts-ignore
                            currentActivity.creator?.selfieImageUrl
                          : '/static/avatar.svg'
                      }`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: 45,
                      height: 45,
                      borderRadius: 25,
                      // @ts-ignore
                      backgroundImage: `url(${
                        // @ts-ignore
                        currentActivity.creator.logoUrl!
                          ? // @ts-ignore
                            currentActivity.creator?.logoUrl
                          : '/static/avatar.svg'
                      }`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  />
                )}
              </Grid>
              <Grid item container xs={9} flexDirection={'column'}>
                <Typography variant="h6" sx={{ fontSize: 12 }}>
                  Created By:
                </Typography>

                <Typography
                  variant="h6"
                  sx={{ fontSize: 15, fontWeight: '700' }}
                >
                  {currentActivity.creatorType === CreatorEnum.USER
                    ? //   @ts-ignore
                      currentActivity.creator.firstName +
                      ' ' +
                      //   @ts-ignore
                      currentActivity.creator.lastName
                    : //   @ts-ignore
                      currentActivity.creator.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent={'flex-end'}
              flexDirection={'row'}
            >
              <Button
                variant={'contained'}
                style={{
                  width: '100%',
                  height: 70,
                  color:
                    currentActivity.users &&
                    getRemainingUserForGroup(currentActivity).find(val => {
                      if (val.user.id === auth.currentUser!.uid) {
                        return val;
                      }
                    }) === undefined
                      ? primaryColor
                      : 'green',
                }}
                onClick={() => {
                  if (
                    currentActivity.users &&
                    getRemainingUserForGroup(currentActivity).find(
                      val => val.user && val.user.id === auth.currentUser!.uid
                    )
                  ) {
                    leaveActivity();
                    setCurrentActivity({
                      ...currentActivity,
                      users: currentActivity.users.map(val => {
                        if (val.user && val.user.id === auth.currentUser?.uid) {
                          return {
                            ...val,
                            leaveTime: new Date().toISOString(),
                          };
                        } else {
                          return val;
                        }
                      }),
                    });
                    setActivities(
                      activities.map(outerVal => {
                        if (
                          outerVal.repeatId &&
                          currentActivity.repeatId &&
                          outerVal.repeatId === currentActivity.repeatId
                        ) {
                          return {
                            ...currentActivity,
                            users: currentActivity.users!.map(val => {
                              if (
                                val.user &&
                                val.user.id === auth.currentUser?.uid
                              ) {
                                return {
                                  ...val,
                                  leaveTime: new Date().toISOString(),
                                };
                              } else {
                                return val;
                              }
                            }),
                          };
                        } else if (
                          outerVal.groupId === currentActivity.groupId
                        ) {
                          return {
                            ...currentActivity,
                            users: currentActivity.users!.map(val => {
                              if (
                                val.user &&
                                val.user.id === auth.currentUser?.uid
                              ) {
                                return {
                                  ...val,
                                  leaveTime: new Date().toISOString(),
                                };
                              } else {
                                return val;
                              }
                            }),
                          };
                        } else {
                          return outerVal;
                        }
                      })
                    );
                    setJoined(false);
                  } else {
                    joinActivity();
                    setJoined(true);
                  }
                }}
              >
                {currentActivity.users &&
                getRemainingUserForGroup(currentActivity).find(
                  val => val.user && val.user.id === auth.currentUser!.uid
                )
                  ? 'Leave Activity'
                  : 'Join Activity'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item container xs={10}>
          <Typography variant="h4" style={{ marginBottom: 5 }}>
            {selectedCommunity
              ? selectedCommunity.name + ' Trips'
              : 'Upcoming Trips'}
          </Typography>
          <Grid item xs={12}>
            <Typography variant={'body2'} style={{ marginBottom: 20 }}>
              Scroll to see activity information & to join on mobile devices.
            </Typography>
          </Grid>

          <div
            style={{
              padding: 2,
              border: '0.5px solid grey',
              minHeight: 200,
              overflowY: 'scroll',
              width: '100%',
            }}
          >
            <div style={{ minWidth: '500px' }}>
              <ActivitiesGrid
                rows={activities.map((val: JourneyAppModel) => {
                  return {
                    ...val,
                    id: val.repeatData
                      ? `${val.groupId}-${val.repeatId}`
                      : val.groupId,
                  };
                })}
                columns={[
                  {
                    field: 'title',
                    headerName: 'Activity Name',
                    flex: 3,
                    valueGetter: (params: any) => params.row?.title,
                    renderCell: (params: any) => {
                      return (
                        <Tooltip title={params.row.title}>
                          {params.row.title}
                        </Tooltip>
                      );
                    },
                  },
                  {
                    field: 'date',
                    headerName: 'Date',
                    valueGetter: (params: any) => params.row.date,
                    flex: 1.5,
                    renderCell: (params: any) => {
                      return (
                        <Tooltip
                          title={moment(new Date(params.row.date)).format(
                            'HH:mma'
                          )}
                        >
                          <Chip
                            label={moment(new Date(params.row.date)).format(
                              'HH:mma'
                            )}
                          />
                        </Tooltip>
                      );
                    },
                  },

                  {
                    field: 'action',
                    headerName: '',
                    flex: 1,
                    renderCell: (params: any) => {
                      return (
                        <Tooltip title="View">
                          <Button
                            key={'id2'}
                            onClick={() => {
                              setCurrentActivity(params.row);
                            }}
                            style={{
                              color:
                                params.row.users &&
                                getRemainingUserForGroup(params.row).find(
                                  val => val.user.id === auth.currentUser!.uid
                                )
                                  ? 'green'
                                  : primaryColor,
                            }}
                          >
                            {params.row.users &&
                            getRemainingUserForGroup(params.row).find(
                              val => val.user.id === auth.currentUser!.uid
                            )
                              ? 'JOINED'
                              : 'VIEW'}
                          </Button>
                        </Tooltip>
                      );
                    },
                  },
                ]}
              />
            </div>
          </div>
        </Grid>
      )}
    </Grid>
  );
}
