import { Grid } from '@mui/material';

export function BorderLine() {
  return (
    <Grid item container xs={12} justifyContent={'center'}>
      <Grid
        item
        container
        xs={10}
        style={{
          borderBottom: '0.5px solid ' + 'rgba(0,0,0,0.4)',
          marginTop: 30,
          marginBottom: 20,
        }}
      />
    </Grid>
  );
}
