import { CssVarsProvider } from '@mui/joy';
import { Box, CssBaseline, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Sidebar from './community_profile/DropZone';
import Header from './community_profile/Header';
import MyProfile from './community_profile/MyProfile';

export function RouteBuddiesAdminViewCommunity() {
  const { selectedAdminCommunity } = useSelector(
    (state: any) => state.AdminSlice
  );

  const { id } = useParams();

  useEffect(() => {
    if (!selectedAdminCommunity && id) {
    }
  }, []);
  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100dvh', width: '100%' }}>
        <Header />
        <Box
          component="main"
          className="MainContent"
          sx={{
            pt: { xs: 'calc(12px + var(--Header-height))', md: 3 },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
            overflow: 'auto',
          }}
        >
          <MyProfile />
        </Box>
      </Box>
    </CssVarsProvider>
  );
}
