import React, { memo } from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = memo(() => {
  return (
    <ReactPlayer
      url={
        'https://firebasestorage.googleapis.com/v0/b/routebuddies-app.appspot.com/o/files%2F0.054893986776178474?alt=media&token=3371a300-61ec-4ad3-8f5c-037acb0486fc'
      }
      width={'100%'}
      height={'100%'}
      playing={true}
      loop={true}
      volume={0}
      muted={true}
      controls={false}
      pip={true}
      wrapper={props => (
        <div
          style={{
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            top: 0,
            left: 0,
            zIndex: 1,
            overflow: 'hidden',
          }}
          className="player-wrapper"
        >
          {props.children}
        </div>
      )}
    />
  );
});

export default VideoPlayer;
