import { createAsyncThunk } from '@reduxjs/toolkit';
import UserDataModel from '../../models/UserDataModel';
import { db } from '../../services/firebase_services';
import { CoalitionModel } from '../../models/CoalitionModel';

// Get user data function lets us retreieve the user data (For logged in user)using a user ID.
export const getCommunityCoalitions = createAsyncThunk(
  'GetCommunityCoalitions',
  async ({ id }: any): Promise<CoalitionModel[] | undefined> => {
    try {
      let coalitions: CoalitionModel[] = [];
      let col_ref = db.ref(
        '/new_data/community_data/community_coalitions/' + id
      );

      let col_data: CoalitionModel[] | undefined = (
        await col_ref.once('value')
      ).val();

      if (col_data && col_data !== null && Object.values(col_data).length > 0) {
        let list = Object.values(col_data);

        for (let i = 0; i < list.length; i++) {
          let col_id = list[i].coalitionId;

          let final_col_ref = db.ref(
            '/new_data/coalition_data/coalitions/' + col_id
          );

          let final_col_data: CoalitionModel | undefined = (
            await final_col_ref.once('value')
          ).val();

          if (final_col_data && final_col_data !== null) {
            coalitions.push(final_col_data);
          }
        }
      }

      return coalitions;
    } catch (err: any) {
      // Go to error page if error occurs
      // goToErrorPage(navigate);
    }
  }
);
