import { Button } from '@mui/material';
import { useState } from 'react';

interface IProps {
  defaultColor: string;
  defaultBackgroundColor: string;
  hoverColor: string;
  hoverBackgroundColor: string;
  textColor?: string;
  onClick: Function;
  width: number | string;
  style?: any;
  sx?: any;
  children: any;
}
export function RoundedButton({
  defaultColor,
  defaultBackgroundColor,
  hoverColor,
  hoverBackgroundColor,
  textColor = defaultColor,
  onClick,
  width,
  style,
  sx,
  children,
}: IProps) {
  const [mouseOverDownload, setMouseOverDownload] = useState(false);

  return (
    <Button
      color={'primary'}
      sx={{ ...sx }}
      style={{
        padding: 10,
        backgroundColor: mouseOverDownload
          ? hoverBackgroundColor
          : defaultBackgroundColor,
        width: width,
        justifyContent: 'center',
        color: mouseOverDownload ? hoverColor : textColor,
        alignItems: 'center',
        borderRadius: 100,
        border: '5px solid ' + defaultColor,
        fontFamily: 'Urbanist',
        ...style,
      }}
      onClick={() => {
        onClick();
      }}
      onMouseOver={() => {
        setMouseOverDownload(true);
      }}
      onMouseOut={() => {
        setMouseOverDownload(false);
      }}
    >
      {children}
    </Button>
  );
}
