//File containing login methods
import LoginErrorModel from '../models/LoginErrorModel';
import { auth } from './firebase_services';
import AuthUserModel from '../models/AuthUserModel';

export async function watchAuthState(
  navigate: any,
  type: string,
  setLoggedIn: Function | undefined
): Promise<AuthUserModel | any> {
  await auth.onAuthStateChanged(async function (result: any) {
    if (result) {
      //Navigate to home page
      if (type === 'login') {
        window.scrollTo(0, 0);
        navigate('/admin');
      } else if (type === 'other' && setLoggedIn) {
        setLoggedIn(true);
      }
    } else {
      if (type !== 'login') {
        window.scrollTo(0, 0);
        navigate('/login');
      }
    }
    return;
  });
}

export async function watchCommunityAuthState(
  callback: Function
): Promise<boolean | any> {
  await auth.onAuthStateChanged(async function (result: any) {
    if (result) {
      callback();
      return true;
    } else {
      return false;
    }
  });
}

export async function watchAuthStateUser(
  setLoggedIn: Function
): Promise<AuthUserModel | any> {
  await auth.onAuthStateChanged(async function (result: any) {
    if (result) {
      setLoggedIn(true);
    }
    return;
  });
}

export function loginSubmit(
  email: string,
  password: string,
  setEmailError: Function,
  setPasswordError: Function
) {
  auth
    .signInWithEmailAndPassword(email, password)
    .then(function () {
      auth.currentUser?.reload();
      // navigation.window.scrollTo(0, 0)
      // navigate('Home', {userData: undefined});
    })
    .catch((error: LoginErrorModel) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode === 'auth/wrong-password') {
        setEmailError('');
        setPasswordError('Wrong password for this account.');
      } else {
        setPasswordError('');
        setEmailError(errorMessage);
      }
    });
  return;
}

export function logoutUser(navigate: any) {
  return auth.signOut().then(() => {
    window.scrollTo(0, 0);
    navigate('/');
  });
}
