import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { watchAuthState } from '../../../services/login_services';
import { Add, Error, People } from '@mui/icons-material';
import { useAppDispatch } from '../../../store/store';
import { Helmet } from 'react-helmet';
import { InfoPanel } from '../../components/InfoPanel';
import { PageType, setCurrentPage } from '../../../store/slices/general.slice';
import { primaryColor } from '../../../assets/styles/styles';
import { useSelector } from 'react-redux';
import { UserActivitiesGrid } from '../../components/community_admin/DataGrid';
import { JourneyModel } from '../../../models/GroupModel';
import {
  getAllAdminActivities,
  getAllAdminUsers,
  getUpcomingAdminActivities,
} from '../../../store/thunks/admin.thunks';
import { db } from '../../../services/firebase_services';

export function RouteBuddiesAdminHome() {
  const [currentInfoSection, setCurrentInfoSection] = useState(1);
  const [loggedIn, setLoggedIn] = useState(false);
  const {
    permitted,
    allCommunities,
    selectedCommunity,
    upcomingAdminActivities,
    upcomingAdminActivitiesLoading,
    upcomingAdminActivitiesError,
  } = useSelector((state: any) => state.AdminSlice);
  const { userList, userListLoading } = useSelector(
    (state: any) => state.EnterpriseUserSlice
  );
  const { admin, allUsers } = useSelector((state: any) => state.GeneralSlice);
  const { requestList } = useSelector(
    (state: any) => state.EnterpriseRequestSlice
  );
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [joke, setJoke] = useState('');

  const [numMembers, setNumMembers] = useState(0);

  useEffect(() => {
    watchAuthState(navigate, 'other', setLoggedIn);
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage(PageType.ADMIN_DASHBOARD));

    let responseClone: Response | undefined = undefined;
    async function getDadJoke() {
      fetch('https://icanhazdadjoke.com/', {
        headers: {
          Accept: 'text/plain',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then(function (response) {
          responseClone = response.clone(); // 2
          return response.text();
        })
        .then(
          function (data) {
            // Do something with data
            setJoke(data);
          },
          function (rejectionReason) {
            // 3

            responseClone &&
              responseClone
                .text() // 5
                .then(function (bodyText: any) {});
          }
        );
    }

    getDadJoke();
  }, []);

  async function getNumberMembers() {
    let users = (await db.ref('new_data/user_data/users').once('value')).val();

    setNumMembers(users === null ? 0 : Object.values(users).length);
  }

  useEffect(() => {
    if (admin) {
      dispatch(getUpcomingAdminActivities());
      dispatch(getAllAdminActivities());

      getNumberMembers();
    }
    return;
  }, [admin]);

  return (
    <Grid container item xs={12} sx={{ paddingTop: 0 }}>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>

      {upcomingAdminActivitiesLoading || userListLoading ? (
        <Grid container item justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          spacing={2}
          sx={{
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 0,
          }}
          alignItems={'flex-start'}
        >
          <Grid
            item
            container
            xs={12}
            flexDirection={'column'}
            alignContent={'center'}
            alignItems={'center'}
          >
            <img
              src={'/static/home.svg'}
              style={{ maxWidth: 180, marginBottom: 10 }}
            />
            <Typography
              variant="h2"
              fontSize={24}
              fontWeight={700}
              style={{ marginBottom: 10 }}
            >
              Welcome back my guy.
            </Typography>
            <Typography style={{ textAlign: 'center', marginBottom: 10 }}>
              This is the hub for you to track progress & and make progress. Go
              out and smash it!
            </Typography>
            <Alert severity="success">{joke}</Alert>
          </Grid>
          <Grid item container xs={12} alignItems={'flex-start'}>
            <Grid item container xs={6} sm={4} lg={3}>
              <InfoPanel
                backgroundColor={'rgba(60,200,255,0.2)'}
                icon={
                  <People fontSize={'large'} style={{ color: '#217dce' }} />
                }
                color={'#217dce'}
                title={'Members'}
                value={numMembers.toString()}
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate('/enterprise/members');
                }}
                xs={12}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Grid
              item
              container
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{
                paddingBottom: '5px',
                margin: 0,
              }}
            >
              <Typography
                variant="h6"
                fontWeight={'bold'}
                style={{
                  fontSize: 12,
                  color: 'rgba(0,0,0,0.7)',
                  paddingBottom: 2,
                }}
              >
                UPCOMING ACTIVITIES
              </Typography>
              <Button
                endIcon={<Add />}
                color={'primary'}
                sx={{ padding: 0.5 }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate('/admin/activities/new');
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight={'bold'}
                  style={{
                    fontSize: 12,
                    paddingRight: 3,
                  }}
                >
                  CREATE
                </Typography>
              </Button>
            </Grid>

            {upcomingAdminActivities && upcomingAdminActivities.length > 0 && (
              <Grid container>
                <UserActivitiesGrid
                  rows={upcomingAdminActivities.map((val: JourneyModel) => {
                    return { ...val, id: val.groupId };
                  })}
                />
              </Grid>
            )}

            {upcomingAdminActivities &&
              upcomingAdminActivities.length === 0 && (
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                  spacing={4}
                  sx={{ marginBottom: '25px' }}
                >
                  <Grid item>
                    <img src={'/static/go.svg'} style={{ maxWidth: 200 }} />
                  </Grid>
                  <Grid item>
                    <Typography>
                      No activities currently set up -{' '}
                      <a
                        style={{ cursor: 'pointer', color: primaryColor }}
                        onClick={() => {
                          window.scrollTo(0, 0);
                          navigate('/admin/activities/new');
                          dispatch(setCurrentPage(PageType.ADMIN_NEW_ACTIVITY));
                        }}
                      >
                        Create One
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
