import {
  Grid,
  Typography,
  TextField,
  Button,
  Icon,
  Alert,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useTools } from '../../services/general_services';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { loginSubmit, logoutUser } from '../../services/login_services';
import { primaryColor, styles } from '../../assets/styles/styles';
import { useSelector } from 'react-redux';
import { auth } from '../../services/firebase_services';
import { setLoginErrorMessage } from '../../store/slices/enterprise.slice';

interface IProps {
  loginPage: boolean;
}

export function LoginScreen({ loginPage = true }: IProps) {
  const [emailAddress, setEmailAddress] = useState('');
  const [emailAddressError, setEmailAddressError] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [hidePassword, setHidePassword] = useState(true);

  const { navigate, dispatch } = useTools();

  const passwordInputRef = useRef<any>();

  const { loginErrorMessage } = useSelector(
    (state: any) => state.EnterpriseSlice
  );

  return (
    <Grid container>
      <Grid
        container
        item
        xs={6}
        sx={{
          display: { xs: 'none', sm: 'flex' },
          backgroundImage: `url(${'/static/bground.jpg'})`,
          backgroundSize: 'cover',
          backgroundPosition: 'right',
        }}
      />

      <Grid
        container
        item
        sm={6}
        xs={12}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ height: '100vh', backgroundColor: '#fff', paddingTop: 2 }}
      >
        <Grid
          container
          item
          xs={9}
          sm={9}
          md={9}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
        >
          {loginErrorMessage !== '' && (
            <Alert severity="error" style={{ marginBottom: 10, width: '100%' }}>
              {loginErrorMessage}
              <a
                onClick={() => {
                  auth.signOut();
                  dispatch(setLoginErrorMessage(''));
                }}
                style={{
                  textDecoration: 'underline',
                  color: primaryColor,
                  cursor: 'pointer',
                  marginLeft: 5,
                }}
              >
                Logout
              </a>
            </Alert>
          )}
          <Grid item container xs={12} justifyContent={'center'}>
            <img
              src={'/static/red_logo.png'}
              style={{ width: '80%', maxWidth: '200px', marginBottom: 30 }}
            />
          </Grid>

          <Typography variant="h2" style={{ marginBottom: 10 }}>
            Welcome.
          </Typography>

          <TextField
            placeholder="Email Address"
            label="Email Address"
            value={emailAddress}
            onChange={e => {
              setEmailAddress(e.target.value);
            }}
            style={{
              width: '100%',
              borderRadius: 10,
              marginBottom: emailAddressError ? 5 : 10,
            }}
          />

          {emailAddressError !== '' && (
            <Grid
              style={{
                width: '100%',
                marginBottom: 10,
                marginTop: 5,
                justifyContent: 'center',
              }}
            >
              <Typography style={{ textAlign: 'left', color: primaryColor }}>
                {emailAddressError}
              </Typography>
            </Grid>
          )}
          <TextField
            inputRef={passwordInputRef}
            placeholder="Password"
            label="Password"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            onKeyDown={e => {
              if (e.code === 'Enter') {
                passwordInputRef.current?.blur();
                loginSubmit(
                  emailAddress,
                  password,
                  setEmailAddressError,
                  setPasswordError
                );
              }
            }}
            type="password"
            style={{
              width: '100%',
              borderRadius: 10,
              marginBottom: passwordError ? 5 : 10,
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 18,
                right: 20,
                backgroundColor: 'green',
              }}
              onClick={() => {
                setHidePassword(!hidePassword);
              }}
            >
              {hidePassword ? <Visibility /> : <VisibilityOff />}
            </div>
          </TextField>
          {passwordError !== '' && (
            <Grid
              style={{
                width: '100%',
                marginTop: 5,
                marginBottom: 10,
                justifyContent: 'center',
              }}
            >
              <Typography style={{ textAlign: 'left', color: primaryColor }}>
                {passwordError}
              </Typography>
            </Grid>
          )}

          <Button
            style={styles.largeRoundedBtn}
            variant="contained"
            onClick={() => {
              loginSubmit(
                emailAddress,
                password,
                setEmailAddressError,
                setPasswordError
              );
            }}
          >
            Login
          </Button>
          <Grid container justifyContent={'space-between'}>
            <Button
              style={{ marginTop: 5, textTransform: 'capitalize' }}
              variant="text"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate('/register');
              }}
            >
              Register here
            </Button>
            <Button
              style={{ marginTop: 5, textTransform: 'capitalize' }}
              variant="text"
              onClick={() => {
                loginSubmit(
                  emailAddress,
                  password,
                  setEmailAddressError,
                  setPasswordError
                );
              }}
            >
              Forgot your password?
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
