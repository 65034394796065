import { useEffect, useRef, useState } from 'react';
import { Button, Chip, Grid, IconButton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as turf from '@turf/turf';
import {
  CalendarToday,
  ChevronLeft,
  Delete,
  Edit,
  People,
} from '@mui/icons-material';
import moment from 'moment';
import { useTools } from '../../../services/general_services';
import { PageType, setCurrentPage } from '../../../store/slices/general.slice';
import { setSelectedActivity } from '../../../store/slices/enterprise.slice';
import { Comments } from './Comments';
import { getCurrentDateTime } from '../../../services/home_services';
import { CreatorEnum } from '../../../models/GroupModel';
import { getCommunityActivityById } from '../../../store/thunks/community.thunks';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';

export function ViewActivityScreen({ mapboxgl }: any) {
  const { dispatch, navigate } = useTools();

  const { selectedCommunity, selectedActivity } = useSelector(
    (state: any) => state.EnterpriseSlice
  );
  const { id } = useParams();

  const [initialized, setInitialized] = useState(false);

  const mapContainer = useRef<any>(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(15);

  const [eventList, setEventList] = useState<any[]>([]);

  useEffect(() => {
    if (eventList.length === 0 && selectedActivity) {
      let events = [];

      // Creation
      events.push({
        date: moment(selectedActivity.creationDate).format('HH:mma'),
        title: 'Activity Created',
      });
      // All users joining / leave
      if (selectedActivity.users) {
        for (let i = 0; i < selectedActivity.users.length; i++) {
          let user = selectedActivity.users[i];

          events.push({
            date: moment(user.joinTime).format('HH:mma'),
            title: `${user.user.firstName} joined`,
          });

          if (user.leaveTime) {
            events.push({
              date: moment(user.leaveTime).format('HH:mma'),
              title: `${user.user.firstName} left`,
            });
          }

          if (user.readyTime) {
            events.push({
              date: moment(user.readyTime).format('HH:mma'),
              title: `${user.user.firstName} clicked "I'm Ready'`,
            });
          }
          if (user.kickTime) {
            events.push({
              date: moment(user.kickTime).format('HH:mma'),
              title: `${user.user.firstName} was kicked.'`,
            });
          }
        }
      }

      //Journey started
      if (selectedActivity.journeyState.stages[1]) {
        events.push({
          date: moment(selectedActivity.journeyState.stages[1].dateTime).format(
            'HH:mma'
          ),
          title: `Activity started'`,
        });
      }

      // Journey finished.
      if (selectedActivity.journeyState.stages[2]) {
        events.push({
          date: moment(selectedActivity.journeyState.stages[2].dateTime).format(
            'HH:mma'
          ),
          title: `Activity finished'`,
        });
      }

      console.log('EVENTS???', events);
      setEventList(events);
    }
  }, [selectedActivity]);

  useEffect(() => {
    if (!selectedActivity && !initialized && id && selectedCommunity) {
      dispatch(
        getCommunityActivityById({
          communityId: selectedCommunity.communityId,
          id: id,
        })
      );
      setInitialized(true);
      dispatch(setCurrentPage(PageType.ENTERPRISE_ACTIVITY_VIEW));
    }
  }, [selectedCommunity]);

  useEffect(() => {
    if (mapboxgl && selectedActivity) {
      const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [lng, lat],
        zoom: zoom,
      });

      //Add markers to map
      const destPopup = new mapboxgl.Popup()
        .setHTML(
          `<strong>Destination:<br /></strong>${selectedActivity.destination.address}`
        )
        .addTo(map);

      new mapboxgl.Marker({ color: '#cd0438' })
        .setLngLat([
          selectedActivity.destination.lng,
          selectedActivity.destination.lat,
        ])
        .addTo(map)
        .setPopup(destPopup);

      const popup = new mapboxgl.Popup()
        .setHTML(
          `<strong>Meetup Point:<br /></strong>${selectedActivity.origin.address}`
        )
        .addTo(map);

      new mapboxgl.Marker({ color: '#cd0438' })
        .setLngLat([selectedActivity.origin.lng, selectedActivity.origin.lat])
        .addTo(map)
        .setPopup(popup);

      let origin: any = [
        selectedActivity.origin.lng,
        selectedActivity.origin.lat,
      ];
      let destination: any = [
        selectedActivity.destination.lng,
        selectedActivity.destination.lat,
      ];

      // A single point that animates along the route.
      // Coordinates are initially set to origin.
      const point: any = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates: origin,
            },
          },
        ],
      };

      // Create a LineString feature from the coordinates
      const lineString = turf.lineString([origin, destination]);

      // Calculate the bezier spline curve
      const bezierSpline = turf.bezierSpline(lineString);

      // Number of steps to use in the animation
      const steps = 500;

      // Calculate the length of the bezier spline
      const bezierSplineLength = turf.length(bezierSpline, {
        units: 'kilometers',
      });

      // Initialize the arc array with the starting point
      const arc = [origin];

      // Calculate points along the bezier spline
      for (let t = 1; t <= steps; t++) {
        const fraction = (t / steps) * 2;

        const distanceAlongCurve = fraction * bezierSplineLength;
        const pointOnCurve = turf.along(bezierSpline, distanceAlongCurve, {
          units: 'kilometers',
        });
        arc.push(pointOnCurve.geometry.coordinates);
      }

      // Update the route with the calculated arc coordinates
      const route = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: arc,
            },
          },
        ],
      };

      // Used to increment the value of the point measurement against the route.
      let counter = 0;

      map.on('load', () => {
        // Add a source and layer displaying a point which will be animated in a circle.
        map.addSource('route', {
          type: 'geojson',
          data: route,
        });

        map.addSource('point', {
          type: 'geojson',
          data: point,
        });

        map.addLayer({
          id: 'route',
          source: 'route',
          type: 'line',
          paint: {
            'line-width': 2,
            'line-color': '#cd0438',
          },
        });

        map.addLayer({
          id: 'point',
          source: 'point',
          type: 'symbol',
          layout: {
            // This icon is a part of the Mapbox Streets style.
            // To view all images available in a Mapbox style, open
            // the style in Mapbox Studio and click the "Images" tab.
            // To add a new image to the style at runtime see
            // https://docs.mapbox.com/mapbox-gl-js/example/add-image/
            'icon-image': 'pitch-15',
            'icon-rotate': ['get', 'rotate'],
            'icon-rotation-alignment': 'map',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
        });

        function animate() {
          const start =
            route.features[0].geometry.coordinates[
              counter >= steps ? counter - 1 : counter
            ];
          const end =
            route.features[0].geometry.coordinates[
              counter >= steps ? counter : counter + 1
            ];
          if (!start || !end) return;

          // Update point geometry to a new position based on counter denoting
          // the index to access the arc
          point.features[0].geometry.coordinates =
            route.features[0].geometry.coordinates[counter];

          // Calculate the bearing to ensure the icon is rotated to match the route arc
          // The bearing is calculated between the current point and the next point, except
          // at the end of the arc, which uses the previous point and the current point
          point.features[0].properties.bearing = turf.bearing(
            turf.point(start),
            turf.point(end)
          );

          // Update the source with this new data
          map.getSource('point').setData(point);

          // Request the next frame of animation as long as the end has not been reached
          if (counter < steps) {
            requestAnimationFrame(animate);
            counter = counter + 1;
          } else {
            counter = 0;

            animate();
          }
        }

        // Set the coordinates of the original point back to origin
        point.features[0].geometry.coordinates = origin;

        // Update the source layer
        map.getSource('point').setData(point);

        // Start the animation
        animate();
      });

      if (selectedActivity && map) {
        setLat(selectedActivity.origin.lat);
        setLng(selectedActivity.origin.lng);

        map.fitBounds(
          [
            {
              lat: selectedActivity.origin.lat,
              lng: selectedActivity.origin.lng,
            },
            {
              lat: selectedActivity.destination.lat,
              lng: selectedActivity.destination.lng,
            },
            {
              lat: selectedActivity.destination.lat,
              lng: selectedActivity.destination.lng - 0.5,
            },
          ],
          {
            speed: 4,
            padding: 100,
            offset: [-70, 0],
          }
        );
      }
    }
  }, [mapboxgl, selectedActivity]);

  return (
    <Grid container xs={12} item sx={{ height: '100%' }}>
      <Grid
        container
        item
        xs={12}
        sx={{ padding: 2, height: 'calc(100% - 80px)' }}
        justifyContent={'center'}
        alignItems={'flex-start'}
      >
        <Grid item container xs={12} padding={0}>
          <Button
            onClick={() => {
              window.scrollTo(0, 0);
              navigate('/enterprise/dashboard');
              dispatch(setSelectedActivity(undefined));
            }}
            variant={'text'}
            sx={{ position: 'relative', left: 0, marginBottom: 2 }}
          >
            <ChevronLeft />
            Back to dashboard
          </Button>
        </Grid>

        {selectedActivity ? (
          <Grid item container xs={12} sx={{ height: '100%' }}>
            <Grid
              item
              container
              xs={12}
              sm={8}
              sx={{ height: { xs: '350px', sm: '100%' } }}
            >
              {' '}
              <div
                style={{
                  width: '100%',
                  height: '100% !important',
                  borderRadius: 5,
                }}
                id="map"
                className="activity-map-container"
              />
            </Grid>
            <Grid
              item
              container
              sx={{
                backgroundColor: 'white',
                height: '100%',
              }}
              alignContent={'flex-start'}
              paddingLeft={4}
              xs={12}
              sm={4}
            >
              <Grid item container xs={12}>
                <Grid item container>
                  <Typography
                    variant="h4"
                    fontWeight={700}
                    sx={{ marginBottom: 1 }}
                  >
                    {selectedActivity.title
                      ? selectedActivity.title
                      : 'Unnamed Activity'}
                  </Typography>
                </Grid>

                <Grid item container>
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    {selectedActivity.description
                      ? selectedActivity.description
                      : 'No description.'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sx={{
                  paddingBottom: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ marginBottom: 1 }}
                  style={{ fontWeight: 'normal', fontFamily: 'sans-serif' }}
                >
                  {selectedActivity.content ? selectedActivity.content : ''}
                </Typography>
              </Grid>
              <Grid item container xs={12} spacing={1} marginBottom={2}>
                <Grid item>
                  <Chip
                    label={moment(selectedActivity.date)
                      .utc()

                      .format('DD/MM/YY HH:mma')}
                  />
                </Grid>
                <Grid item>
                  <Chip
                    label={
                      selectedActivity &&
                      selectedActivity.users &&
                      selectedActivity.users.length
                        ? selectedActivity.users.length
                        : 0 + ' Joined'
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sx={{
                  marginBottom: 2,
                  marginTop: 2,
                  paddingBottom: 2,
                  borderBottom: '0.5px solid rgba(0,0,0,0.2)',
                }}
              >
                <Grid item sx={{ width: '45px', marginRight: 2 }}>
                  {selectedActivity.creatorType === CreatorEnum.USER ? (
                    <div
                      style={{
                        width: 45,
                        height: 45,
                        borderRadius: 25,
                        // @ts-ignore
                        backgroundImage: `url(${
                          // @ts-ignore
                          selectedActivity.creator.selfieImageUrl!
                            ? // @ts-ignore
                              selectedActivity.creator?.selfieImageUrl
                            : '/static/avatar.svg'
                        }`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: 45,
                        height: 45,
                        borderRadius: 25,
                        // @ts-ignore
                        backgroundImage: `url(${
                          // @ts-ignore
                          selectedActivity.creator.logoUrl!
                            ? // @ts-ignore
                              selectedActivity.creator?.logoUrl
                            : '/static/avatar.svg'
                        }`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                      }}
                    />
                  )}
                </Grid>
                <Grid item container sx={{ flex: 1, flexDirection: 'column' }}>
                  <Typography variant="h6" sx={{ fontSize: 12 }}>
                    Created By:
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{ fontSize: 15, fontWeight: '700' }}
                  >
                    {selectedActivity.creatorType === CreatorEnum.USER
                      ? selectedActivity.creator.firstName +
                        ' ' +
                        selectedActivity.creator.lastName
                      : selectedActivity.creator.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container>
                <Timeline>
                  {eventList.map((val, index) => {
                    return (
                      <TimelineItem>
                        <TimelineOppositeContent color="text.secondary">
                          {val.date}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot />
                          {index !== eventList.length - 1 && (
                            <TimelineConnector />
                          )}
                        </TimelineSeparator>
                        <TimelineContent>{val.title}</TimelineContent>
                      </TimelineItem>
                    );
                  })}
                </Timeline>
              </Grid>
              <Grid
                item
                container
                justifyContent={'flex-end'}
                flexDirection={'row'}
              >
                {moment(selectedActivity.date).isAfter(moment(new Date())) && (
                  <>
                    <IconButton>
                      <Edit />
                    </IconButton>
                    <IconButton>
                      <Delete color="error" />
                    </IconButton>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Typography>Nothing here</Typography>
        )}
      </Grid>
    </Grid>
  );
}
