export enum EventType {
  SOS_CREATED = 'SOS_CREATED',
  SOS_JOINED = 'SOS_JOINED',
  SOS_LEFT = 'SOS_LEFT',
  SOS_CLOSED = 'SOS_CLOSED',
  ACTIVITY_CREATED = 'ACTIVITY_CREATED',
  ACTIVITY_JOINED = 'ACTIVITY_JOINED',
  ACTIVITY_STARTED = 'ACTIVITY_STARTED',
  ACTIVITY_LEFT = 'ACTIVITY_LEFT',
  ACTIVITY_CLOSED = 'ACTIVITY_CLOSED',
  ACTIVITY_FINISHED = 'ACTIVITY_FINISHED',
  COMMUNITY_JOINED = 'COMMUNITY_JOINED',
  COMMUNITY_LEFT = 'COMMUNITY_LEFT',
  COMMUNITY_KICKED = 'COMMUNITY_KICKED',
  POST_CREATED = 'POST_CREATED',
  POST_DELETED = 'POST_DELETED',
  POST_COMMENT_CREATED = 'POST_COMMENT_CREATED',
  POST_COMMENT_DELETED = 'POST_COMMENT_DELETED',
}
