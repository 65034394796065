import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { watchAuthState } from '../../../services/login_services';
import { Add, Error, People } from '@mui/icons-material';
import { useAppDispatch } from '../../../store/store';
import { Helmet } from 'react-helmet';
import { InfoPanel } from '../../components/InfoPanel';
import { PageType, setCurrentPage } from '../../../store/slices/general.slice';
import { primaryColor } from '../../../assets/styles/styles';
import { useSelector } from 'react-redux';
import {
  CoalitionGrid,
  CommunityGrid,
  UserActivitiesGrid,
} from '../../components/community_admin/DataGrid';
import { JourneyModel } from '../../../models/GroupModel';
import {
  getAllAdminActivities,
  getAllAdminUsers,
  getAllCoalitions,
  getAllCommunities,
  getUpcomingAdminActivities,
} from '../../../store/thunks/admin.thunks';
import { CommunityModel } from '../../../models/CommunityModel';
import { db } from '../../../services/firebase_services';
import { CoalitionModel } from '../../../models/CoalitionModel';

export function RouteBuddiesAdminCommunities() {
  const [currentInfoSection, setCurrentInfoSection] = useState(1);
  const [loggedIn, setLoggedIn] = useState(false);
  const {
    allCommunities,
    allCommunitiesLoading,
    allCoalitions,
    allCoalitionsLoading,
    upcomingAdminActivities,
  } = useSelector((state: any) => state.AdminSlice);

  const { admin, allUsers } = useSelector((state: any) => state.GeneralSlice);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    watchAuthState(navigate, 'other', setLoggedIn);
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage(PageType.ADMIN_COMMUNITIES));
  }, []);

  useEffect(() => {
    if (admin) {
      dispatch(getAllCoalitions());
      dispatch(getAllCommunities());
    }
  }, [admin]);

  return (
    <Grid container item xs={12} sx={{ paddingTop: 0 }}>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>

      {allCommunitiesLoading || allCoalitionsLoading ? (
        <Grid container item justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          spacing={2}
          sx={{
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 0,
          }}
          alignItems={'flex-start'}
        >
          <Grid
            item
            container
            xs={12}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Grid
              item
              container
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{
                paddingBottom: '5px',
                margin: 0,
              }}
            >
              <Typography
                variant="h6"
                fontWeight={'bold'}
                style={{
                  fontSize: 12,
                  color: 'rgba(0,0,0,0.7)',
                  paddingBottom: 2,
                }}
              >
                ALL COMMUNITIES
              </Typography>
              <Button
                endIcon={<Add />}
                color={'primary'}
                sx={{ padding: 0.5 }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate('/admin/communities/new');
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight={'bold'}
                  style={{
                    fontSize: 12,
                    paddingRight: 3,
                  }}
                >
                  CREATE
                </Typography>
              </Button>
            </Grid>

            <Grid container>
              <CommunityGrid
                rows={allCommunities.map((val: CommunityModel) => {
                  return { ...val, id: val.communityId };
                })}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Grid
              item
              container
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{
                paddingBottom: '5px',
                margin: 0,
              }}
            >
              <Typography
                variant="h6"
                fontWeight={'bold'}
                style={{
                  fontSize: 12,
                  color: 'rgba(0,0,0,0.7)',
                  paddingBottom: 2,
                }}
              >
                ALL COALITIONS
              </Typography>
              <Button
                endIcon={<Add />}
                color={'primary'}
                sx={{ padding: 0.5 }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate('/admin/coalitions/new');
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight={'bold'}
                  style={{
                    fontSize: 12,
                    paddingRight: 3,
                  }}
                >
                  CREATE
                </Typography>
              </Button>
            </Grid>

            <Grid container>
              <CoalitionGrid
                rows={allCoalitions.map((val: CoalitionModel) => {
                  return { ...val, id: val.coalitionId };
                })}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
