export interface CommunityRole {
  communityId: string;
  active: boolean;
  role: RoleEnum;
}

export enum RoleEnum {
  MEMBER = 'MEMBER',
  ADMIN = 'ADMIN',
}
