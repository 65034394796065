import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';

import enGB from 'date-fns/locale/en-GB';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import {
  Check,
  Close,
  ElectricalServicesSharp,
  Place,
} from '@mui/icons-material';
import { isDivideBy, useTools } from '../../../services/general_services';
import { PageType, setCurrentPage } from '../../../store/slices/general.slice';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getCurrentDateTime } from '../../../services/home_services';
import { toast } from 'react-toastify';
import {
  CreatorEnum,
  JourneyModel,
  RepeatDataModel,
} from '../../../models/GroupModel';
import { useSelector } from 'react-redux';
import { auth, db } from '../../../services/firebase_services';
import { RepeatType } from '../../../models/RecurrenceModel';
import {
  getAllCoalitions,
  getAllCommunities,
} from '../../../store/thunks/admin.thunks';
import { CoalitionModel } from '../../../models/CoalitionModel';
import { CommunityModel } from '../../../models/CommunityModel';
import { primaryColor } from '../../../assets/styles/styles';
import UserDataModel from '../../../models/UserDataModel';

export function RouteBuddiesNewCommunityAdmin() {
  const { dispatch, navigate } = useTools();
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [desc, setDesc] = useState('');
  const [descError, setDescError] = useState('');
  const [origin, setOrigin] = useState<any>(undefined);
  const [originError, setOriginError] = useState<any>('');
  const [originFocused, setOriginFocused] = useState(false);
  const [dest, setDest] = useState<any>(undefined);
  const [destError, setDestError] = useState<any>('');
  const [destFocused, setDestFocused] = useState(false);
  const [activityDate, setActivityDate] = useState<any>(new Date());
  const [activityDateError, setActivityDateError] = useState('');
  const [activityTime, setActivityTime] = useState<any>(new Date());

  const [startTime, setStartTime] = useState<any>(new Date());
  const [activityTimeError, setActivityTimeError] = useState('');

  const [originHovered, setOriginHovered] = useState(false);

  const [destHovered, setDestHovered] = useState(false);

  const [titleFocused, setTitleFocused] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const [repeatSpec, setRepeatSpec] = useState('0');
  const [repeatValue, setRepeatValue] = useState(15);
  const [repeatValueError, setRepeatValueError] = useState('');
  const [repeatType, setRepeatType] = useState(RepeatType.MINUTES);

  const [allDay, setAllDay] = useState(false);

  const [repeatStartTime, setRepeatStartTime] = useState<any>(new Date());
  const [repeatEndTime, setRepeatEndTime] = useState<any>(new Date());
  const [repeatDateError, setRepeatDateError] = useState('');

  const [repeatDays, setRepeatDays] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [repeatDaysError, setRepeatDaysError] = useState('');

  const [endValue, setEndValue] = useState('Never');
  const [dateToEnd, setDateToEnd] = useState<any>(new Date());
  const [dateToEndError, setDateToEndError] = useState<any>('');

  enum ActivityType {
    COALITION = 'COALITION',
    COMMUNITY = 'COMMUNITY',
  }

  const [chosenCommunity, setChosenCommunity] = useState<
    CommunityModel | undefined
  >(undefined);
  const [chosenCoalition, setChosenCoaltion] = useState<
    CoalitionModel | undefined
  >(undefined);

  const { admin } = useSelector((state: any) => state.GeneralSlice);

  const { allCoalitions, allCommunities } = useSelector(
    (state: any) => state.AdminSlice
  );

  const [usersInitialised, setUsersInitialised] = useState(false);
  const [userList, setUserList] = useState([]);

  const [selectedUserEmail, setSelectedUserEmail] = useState('');
  const [doubleCheck, setDoubleCheck] = useState(false);
  async function getUsers() {
    let users = (await db.ref('/new_data/user_data/users').once('value')).val();

    if (users !== null) {
      setUserList(Object.values(users));
    }
  }

  useEffect(() => {
    dispatch(setCurrentPage(PageType.ADMIN_NEW_ADMINS));

    if (admin) {
      dispatch(getAllCoalitions());
      dispatch(getAllCommunities());

      getUsers();
    }
  }, []);

  useEffect(() => {
    if (allCommunities && allCommunities.length > 0) {
      setChosenCommunity(allCommunities[0]);
    }
  }, [allCommunities]);
  useEffect(() => {
    if (allCoalitions && allCoalitions.length > 0) {
      setChosenCoaltion(allCoalitions[0]);
    }
  }, [allCoalitions]);
  return (
    <Grid container item xs={12}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <Helmet>
          <meta
            name="title"
            content="RouteBuddies | The Community Safety App | Safer Together"
          />
          <meta
            name="description"
            content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
          />
          <meta
            name="keywords"
            content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
          />
          <meta name="robots" content="index, follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
          <meta name="author" content="RouteBuddies Limited." />
        </Helmet>

        <Grid
          container
          item
          xs={12}
          spacing={2}
          sx={{
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 2,
          }}
          alignItems={'flex-start'}
        >
          <Grid
            item
            container
            xs={12}
            alignItems={'flex-start'}
            flexDirection={'column'}
          >
            <Grid item container flexDirection={'column'}>
              <Typography
                variant={'h4'}
                fontWeight={'800'}
                sx={{ marginBottom: 1 }}
              >
                New Community Admin
              </Typography>
              <Typography variant={'body1'} fontWeight={'400'}>
                Who shall we add today? Make sure to only ever add trusted
                individuals as community admins.
              </Typography>
            </Grid>
            <Grid item container xs={12} spacing={2} paddingTop={5}>
              <Grid item container xs={12} sm={6} sx={{ marginTop: 2 }}>
                <Grid item container sx={{ marginTop: 2 }}>
                  <Typography
                    variant="h6"
                    fontWeight={'bold'}
                    style={{ fontSize: 12, color: 'rgba(0,0,0,0.7)' }}
                  >
                    CHOOSE USER
                  </Typography>
                </Grid>
                {userList.length > 0 ? (
                  <FormControl fullWidth>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={
                        userList
                          ? userList
                              .filter(
                                (val: any) => val.email || val.emailAddress
                              )
                              .map((val: any) => {
                                return val.email ? val.email : val.emailAddress;
                              })
                          : []
                      }
                      sx={{ width: 300 }}
                      renderInput={params => (
                        <TextField {...params} label="Select User" />
                      )}
                      onChange={(event: any, newValue: string | null) => {
                        if (newValue !== null) {
                          setSelectedUserEmail(newValue);
                        }
                      }}
                    />
                  </FormControl>
                ) : (
                  <CircularProgress />
                )}
                <Grid item container sx={{ marginTop: 2 }}>
                  <Typography
                    variant="h6"
                    fontWeight={'bold'}
                    style={{ fontSize: 12, color: 'rgba(0,0,0,0.7)' }}
                  >
                    CHOOSE COMMUNITY
                  </Typography>
                </Grid>
                {allCommunities && chosenCommunity ? (
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={chosenCommunity.communityId}
                      onChange={(val: any) => {
                        let community = allCommunities.find(
                          (val2: any) => val2.id === val.target.value
                        );
                        if (community) {
                          setChosenCommunity(community);
                        }
                      }}
                    >
                      {allCommunities.map((val: CommunityModel) => {
                        return (
                          <MenuItem value={val.communityId} title={val.name}>
                            {val.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  <CircularProgress />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            justifyContent={'flex-end'}
            sx={{ marginBottom: 5 }}
          >
            {!doubleCheck ? (
              <Button
                onClick={() => {
                  setDoubleCheck(true);
                }}
                variant="contained"
              >
                Add User
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setDoubleCheck(false);
                  }}
                  style={{ backgroundColor: 'red', color: 'white' }}
                >
                  <Close style={{ color: 'white' }} />
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setDoubleCheck(false);

                    if (selectedUserEmail === '') {
                      toast.error('You must select a user to add.');
                    } else if (!chosenCommunity) {
                      toast.error('You must select a community.');
                    } else {
                      let user: any = userList.find((val: any) => {
                        if (val.email) {
                          if (val.email === selectedUserEmail) {
                            return val;
                          }
                        } else {
                          if (val.emailAddress === selectedUserEmail) {
                            return val;
                          }
                        }
                      });

                      if (!user) {
                        toast.error('Something went wrong...');
                      } else {
                        toast.info('Starting upload...');

                        // Add user to admins

                        db.ref(
                          '/new_data/community_data/admins/' +
                            chosenCommunity.communityId +
                            '/' +
                            user.id
                        ).set(true);
                        db.ref(
                          '/new_data/user_data/users/' +
                            user.id +
                            '/communityAdmin'
                        ).set(true);

                        db.ref(
                          '/new_data/user_data/user_communities/' +
                            user.id +
                            '/' +
                            chosenCommunity.communityId
                        ).set({
                          communityId: chosenCommunity.communityId,
                          active: true,
                          role: 'ADMIN',
                        });

                        toast.success('Finished upload...');
                        dispatch(setCurrentPage(PageType.ADMIN_USERS));
                        window.scrollTo(0, 0);
                        navigate('/admin/members');
                      }
                    }
                  }}
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                    marginLeft: 10,
                  }}
                >
                  <Check style={{ color: 'white' }} />
                  Confirm
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
}
