import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { watchAuthState } from '../../../services/login_services';
import { useAppDispatch } from '../../../store/store';
import { Helmet } from 'react-helmet';
import { PageType, setCurrentPage } from '../../../store/slices/general.slice';
import { useSelector } from 'react-redux';
import {
  UserActivitiesGrid,
  UserGrid,
} from '../../components/community_admin/DataGrid';
import { getCommunityUsers } from '../../../store/thunks/enterprise.users.thunks';
import { primaryColor } from '../../../assets/styles/styles';
import { toast } from 'react-toastify';
import {
  Add,
  CheckRounded,
  CloseRounded,
  ContentCopy,
} from '@mui/icons-material';
import '../../../index.css';
import { CommunityRequestAppModel } from '../../../models/CommunityRequestModel';
import moment from 'moment';
import { getCurrentDateTime } from '../../../services/home_services';
import { db } from '../../../services/firebase_services';
import { setRequestList } from '../../../store/slices/enterprise.requests.slice';
import { sendEmail } from '../../../services/email_services';
import { addToUserCommmunities } from '../../../services/general_services';
import { setUserList } from '../../../store/slices/enterprise.users.slice';
import { getAllCommunityActivities } from '../../../store/thunks/community.thunks';
import { JourneyAppModel } from '../../../models/GroupModel';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enGB from 'date-fns/locale/en-GB';

export function CommunityAdminActivites() {
  const [currentInfoSection, setCurrentInfoSection] = useState(1);
  const [loggedIn, setLoggedIn] = useState(false);
  const { currentPage } = useSelector((state: any) => state.GeneralSlice);
  const {
    selectedCommunity,
    allCommunityActivities,
    allCommunityActivitiesLoading,
    allCommunityActivitiesError,
    allActivitiesEndDate,
  } = useSelector((state: any) => state.EnterpriseSlice);
  const { userList, userListLoading, userListError } = useSelector(
    (state: any) => state.EnterpriseUserSlice
  );

  const { requestList, requestListLoading, requestListError } = useSelector(
    (state: any) => state.EnterpriseRequestSlice
  );

  const [searchValue, setSearchValue] = useState('');

  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  useEffect(() => {
    watchAuthState(navigate, 'other', setLoggedIn);
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage(PageType.ENTERPRISE_ACTIVITIES));
  }, []);

  useEffect(() => {
    if (selectedCommunity) {
      console.log('Searching here for it! ', selectedCommunity.communityId);
      dispatch(
        getAllCommunityActivities({
          creator: selectedCommunity,
          id: selectedCommunity.communityId,
          endDate: moment(allActivitiesEndDate).add(2, 'weeks'),
          allActivities: allCommunityActivities,
        })
      );
    } else {
    }
  }, [selectedCommunity, currentPage]);

  return (
    <Grid container item xs={12}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <Helmet>
          <meta
            name="title"
            content="RouteBuddies | The Community Safety App | Safer Together"
          />
          <meta
            name="description"
            content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
          />
          <meta
            name="keywords"
            content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
          />
          <meta name="robots" content="index, follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
          <meta name="author" content="RouteBuddies Limited." />
        </Helmet>

        <Grid
          container
          item
          xs={12}
          spacing={2}
          sx={{
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 2,
            flex: 1,
          }}
          alignItems={'center'}
        >
          <Grid
            item
            container
            xs={12}
            alignItems={'center'}
            justifyContent={'center'}
            flexDirection={'column'}
          >
            {!allCommunityActivities || allCommunityActivitiesLoading ? (
              <CircularProgress />
            ) : allCommunityActivities.length > 0 ? (
              <Grid item container>
                <Grid
                  item
                  container
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{
                    paddingBottom: '5px',
                    margin: 0,
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight={'bold'}
                    style={{
                      fontSize: 12,
                      color: 'rgba(0,0,0,0.7)',
                      paddingBottom: 2,
                    }}
                  >
                    ALL ACTIVITIES
                  </Typography>
                  <Button
                    endIcon={<Add />}
                    color={'primary'}
                    sx={{ padding: 0.5 }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate('/enterprise/activities/new');
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight={'bold'}
                      style={{
                        fontSize: 12,
                        paddingRight: 3,
                      }}
                    >
                      CREATE
                    </Typography>
                  </Button>
                </Grid>
                <UserActivitiesGrid
                  rows={allCommunityActivities.map((val: JourneyAppModel) => {
                    return {
                      ...val,
                      id: val.repeatData
                        ? `${val.groupId}-${val.repeatId}`
                        : val.groupId,
                    };
                  })}
                  loadMore={
                    <Grid
                      container
                      flexDirection={'column'}
                      spacing={2}
                      padding={2}
                      paddingTop={3}
                    >
                      <Grid
                        container
                        alignItems={'center'}
                        alignContent={'center'}
                      >
                        Showing trips up until{' '}
                        <DatePicker
                          value={new Date(allActivitiesEndDate)}
                          sx={{ width: '150px', marginLeft: '10px' }}
                          onChange={newValue => {
                            if (moment(newValue).isBefore(new Date())) {
                              toast.error('You must select a future date.');
                            } else {
                              dispatch(
                                getAllCommunityActivities({
                                  creator: selectedCommunity,
                                  id: selectedCommunity.communityId,
                                  endDate: moment(newValue),
                                  allActivities: allCommunityActivities,
                                })
                              );
                            }
                          }}
                        />
                      </Grid>

                      <Button
                        variant="contained"
                        style={{ marginTop: 5 }}
                        onClick={() => {
                          dispatch(
                            getAllCommunityActivities({
                              creator: selectedCommunity,
                              id: selectedCommunity.communityId,
                              endDate: moment(allActivitiesEndDate).add(
                                2,
                                'week'
                              ),
                              allActivities: allCommunityActivities,
                            })
                          );
                        }}
                      >
                        Load More
                      </Button>
                    </Grid>
                  }
                />
              </Grid>
            ) : (
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                spacing={2}
              >
                <Grid item>
                  <img src={'/static/walking.svg'} style={{ maxWidth: 200 }} />
                </Grid>
                <Grid item>
                  <Typography style={{ textAlign: 'center', maxWidth: 380 }}>
                    Safety in numbers is our jam. Create activities and they'll
                    appear in our app for your members!
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate('/enterprise/activities/new');
                      dispatch(
                        setCurrentPage(PageType.ENTERPRISE_NEW_ACTIVITY)
                      );
                    }}
                  >
                    Create Activity
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
}
