import { useNavigate, useParams } from 'react-router';
import { auth, db } from '../../../services/firebase_services';
import { CommunityModel } from '../../../models/CommunityModel';

import { Button, CircularProgress, Grid, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

import { primaryColor } from '../../../assets/styles/styles';
import { Check, LockOutlined } from '@mui/icons-material';
import { Footer } from '../../components/Footer';
import {
  addToUserCommmunities,
  useTools,
} from '../../../services/general_services';
import { getCurrentDateTime } from '../../../services/home_services';
import { PageType, setCurrentPage } from '../../../store/slices/general.slice';
import { toast } from 'react-toastify';
import { watchAuthState } from '../../../services/login_services';
import { createEvent } from '../../../services/event_services';
import { CreatorEnum } from '../../../models/GroupModel';
import { EventType } from '../../../models/EventEnum';

export function CommunityAdminInvite({}) {
  const { id } = useParams();

  //   Will be true when method is running - Use to disable button
  const [loading, setLoading] = useState(false);

  //   Will be set to true once the user request is accepted - Use to show success UI
  const [requested, setRequested] = useState(false);

  //
  const [done, setDone] = useState(false);

  const [initialised, setInitialised] = useState(false);

  const [community, setCommunity] = useState<CommunityModel | undefined>(
    undefined
  );

  const { navigate, dispatch } = useTools();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [mouseOverButton, setMouseOverButton] = useState(false);

  async function getCommunityData(id: string) {
    let communityDb = db.ref('/new_data/community_data/communities/' + id);

    let community: any = await communityDb.once('value');

    let communityVal = community.val();

    if (communityVal) {
      return communityVal;
    } else {
      window.location.href = '/error';
    }
  }

  async function checkCommunityPresence(id: string, userId: string) {
    let communityDb = db.ref(
      '/new_data/user_data/user_communities/' + userId + '/' + id
    );

    let community: any = await communityDb.once('value');

    let communityVal = community.val();

    if (communityVal && communityVal !== null) {
      return true;
    } else {
      return false;
    }
  }

  async function checkCommunityUserLength(id: string, userId: string) {
    let communityDb = db.ref('/new_data/community_data/community_users/' + id);

    let length: any = (await communityDb.once('value')).numChildren();

    if (length && length !== null) {
      return length;
    } else {
      return 0;
    }
  }

  async function triggerCommData() {
    if (id) {
      let communityData = await getCommunityData(id);

      let status = await checkInviteStatus();

      if (communityData && communityData !== null) {
        // If full...

        setCommunity(communityData);
        setDone(status === 'ACCEPTED' || status === 'DENIED');
        setInitialised(true);
      } else {
        window.location.href = '/404';
      }
    }
  }

  async function checkInviteStatus() {
    if (auth.currentUser && auth.currentUser.email) {
      let invite = (
        await db
          .ref(
            '/new_data/community_data/admin_invites/' +
              id +
              '/' +
              auth.currentUser?.email.replace(/\./g, ',')
          )
          .once('value')
      ).val();

      if (invite === null) {
        return 'NOT_INVITED';
      } else {
        return invite.status;
      }
    } else {
      return 'NOT_INVITED';
    }
  }
  useEffect(() => {
    console.log('Updated... ', auth.currentUser === null);
    if (
      id &&
      auth &&
      auth.currentUser &&
      auth.currentUser.uid &&
      !initialised
    ) {
      triggerCommData();
    }
  }, [id, auth.currentUser]);

  const innerStyles: any = {
    text: {
      fontFamily: 'Urbanist',
      textAlign: 'center',
      fontSize: { xs: '12px', md: '24px', lg: '16px' },
    },
    iconDiv: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    iconText: {
      marginLeft: 1,
    },
    btn: {
      padding: 10,
      backgroundColor: buttonClicked
        ? 'green'
        : mouseOverButton
        ? 'green'
        : 'white',
      width: 'Calc(100% - 40px)',
      justifyContent: 'center',
      color: buttonClicked ? 'white' : mouseOverButton ? 'white' : primaryColor,
      alignItems: 'center',
      // borderRadius: 43,
      // border: '5px solid',
      // borderColor: buttonClicked ? 'green' : '#fff',
      fontFamily: 'Urbanist',
      marginTop: 20,
      marginBottom: 20,
    },
    logo: {
      width: 80,
      height: 80,
      borderRadius: 90,
      marginLeft: 10,
      marginRight: 10,
      backgroundSize: 'cover',
    },
  };

  return (
    <Grid item container xs={12} justifyContent={'center'} sx={{ flex: 1 }}>
      {community ? (
        <Grid container flexDirection={'row'} justifyContent={'center'}>
          <Grid
            item
            sx={{
              minHeight: { xs: `100vh` },
              padding: { xs: '20px', md: '40px' },
              backgroundColor: 'white',
              display: 'flex',
            }}
            xs={12}
            justifyContent={'center'}
            alignContent={'center'}
          >
            <Grid
              item
              container
              flexDirection={'row'}
              xs={12}
              sm={12}
              md={7}
              lg={6}
              sx={{
                borderRadius: 10,
                backgroundColor: 'white',
                height: 'min-content',
              }}
              alignItems={'flex-start'}
            >
              {/* Initialising */}
              {!initialised && <CircularProgress color="primary" />}

              {/* No request recieved - Usual window */}
              {!done && !requested && initialised && (
                <Grid
                  item
                  container
                  xs={12}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Grid style={{ marginBottom: 40 }}>
                    <img
                      src={'/static/red_logo.png'}
                      style={{ height: '130px' }}
                    />
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <div
                      style={{
                        ...innerStyles.logo,
                        backgroundImage: community.imageUrl
                          ? `url(${community.imageUrl})`
                          : '',
                      }}
                    />
                    <Grid item> +</Grid>
                    <div
                      style={{
                        ...innerStyles.logo,
                        backgroundImage: `url(${'/static/avatar.svg'})`,
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{ marginTop: 2, marginBottom: '40px' }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight={'700'}
                      style={{ textAlign: 'center' }}
                    >
                      Become an admin of{' '}
                      <span style={{ textDecoration: 'underline' }}>
                        {community.name}?
                      </span>
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent={'center'}
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <Grid item xs={12} sm={9}>
                      <Typography variant="body1" fontSize={13}>
                        <LockOutlined
                          style={{
                            fontSize: 15,
                            paddingTop: 1,
                            marginRight: 2,
                          }}
                        />
                        <strong>As an admin you can:</strong>
                        <ul style={{ fontFamily: 'Arial', lineHeight: 2 }}>
                          <li>Manage community activities.</li>
                          <li>Monitor your community activity.</li>
                          <li>Manage your community members.</li>
                        </ul>
                        We're looking for ways to improve our system, and rely
                        on feedback from amazing community leaders like you!
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent={'center'}
                    spacing={2}
                    sx={{ height: 60, marginTop: '15px' }}
                  >
                    <Grid item container xs={6}>
                      <Button
                        onClick={() => {
                          if (
                            id &&
                            auth.currentUser !== null &&
                            auth.currentUser.email !== null &&
                            community
                          ) {
                            // Add to da community admins!
                            setDone(true);
                            db.ref(
                              '/new_data/community_data/admin_invites/' +
                                id +
                                '/' +
                                auth.currentUser?.email.replace(/\./g, ',') +
                                '/status'
                            ).set('DENIED');

                            db.ref(
                              '/new_data/community_data/admin_invites/' +
                                id +
                                '/' +
                                auth.currentUser?.email.replace(/\./g, ',') +
                                '/responseDate'
                            ).set(getCurrentDateTime());

                            setDone(true);
                          }
                        }}
                        color="error"
                        variant="contained"
                        disabled={!id || !auth.currentUser}
                        style={{ flex: 1 }}
                      >
                        Deny Invite
                      </Button>
                    </Grid>
                    <Grid item container xs={6}>
                      <Button
                        onClick={() => {
                          if (
                            id &&
                            auth.currentUser !== null &&
                            auth.currentUser.email !== null &&
                            community
                          ) {
                            let userId = auth.currentUser?.uid;
                            // Add to da community admins!
                            setDone(true);
                            db.ref(
                              '/new_data/community_data/admin_invites/' +
                                id +
                                '/' +
                                auth.currentUser?.email.replace(/\./g, ',') +
                                '/status'
                            ).set('ACCEPTED');

                            db.ref(
                              '/new_data/community_data/admin_invites/' +
                                id +
                                '/' +
                                auth.currentUser?.email.replace(/\./g, ',') +
                                '/responseDate'
                            ).set(getCurrentDateTime());

                            // Add to comm admins
                            db.ref(
                              '/new_data/community_data/admins/' +
                                id +
                                '/' +
                                auth.currentUser?.uid
                            ).set(true);

                            db.ref(
                              '/new_data/community_data/community_users/' +
                                id +
                                '/' +
                                auth.currentUser?.uid
                            ).set({
                              userId: userId,
                              active: true,
                              joinDate: getCurrentDateTime(),
                              role: 'ADMIN',
                            });

                            db.ref(
                              '/new_data/user_data/user_communities/' +
                                auth.currentUser?.uid +
                                '/' +
                                id
                            ).set({
                              communityId: community.communityId,
                              active: true,
                              role: 'ADMIN',
                            });

                            createEvent(
                              auth.currentUser?.uid,
                              community.communityId,
                              CreatorEnum.COMMUNITY,
                              EventType.COMMUNITY_JOINED,
                              community.communityId
                            );
                            toast.success('Welcome to RouteBuddies!');

                            navigate('/enterprise/dashboard');
                            dispatch(
                              setCurrentPage(PageType.ENTERPRISE_DASHBOARD)
                            );
                          }
                        }}
                        color="success"
                        variant="contained"
                        disabled={!id || !auth.currentUser}
                        style={{ flex: 1 }}
                      >
                        Accept Invite
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {/* Request recieved - In review */}
              {!done && requested && initialised && (
                <Grid item container xs={12}>
                  <Grid
                    item
                    container
                    xs={12}
                    flexDirection={'column'}
                    alignItems={'center'}
                    spacing={3}
                  >
                    <img
                      src={'/static/red_logo.png'}
                      style={{ height: '170px', marginBottom: 30 }}
                    />
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Grid
                      item
                      container
                      justifyContent={'center'}
                      alignItems={'center'}
                      style={{
                        ...innerStyles.logo,
                        backgroundColor: `green`,
                      }}
                    >
                      <Check
                        style={{
                          fontSize: 55,
                          fontWeight: 'bolder',
                          color: 'white',
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDirection={'column'}
                  >
                    <Typography
                      variant="h5"
                      fontWeight={'700'}
                      style={{ textAlign: 'center', marginTop: 20 }}
                    >
                      Request sent.
                    </Typography>

                    <Typography
                      variant="body1"
                      style={{
                        textAlign: 'center',
                        marginTop: 20,
                        maxWidth: 350,
                      }}
                    >
                      Once an administrator of the community has responded,
                      you'll get an email notification.
                    </Typography>
                  </Grid>

                  <Grid item container justifyContent={'center'}>
                    <Button
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate('/');
                      }}
                      variant="contained"
                      sx={{
                        marginRight: 1,
                        backgroundColor: 'rgba(0,0,0,0.6)',
                        marginTop: 5,
                      }}
                    >
                      Take Me Home
                    </Button>
                  </Grid>
                </Grid>
              )}

              {/* I have already joined... */}
              {done && initialised && (
                <Grid item container xs={12}>
                  <Grid item container xs={12}>
                    <Grid
                      item
                      container
                      xs={12}
                      flexDirection={'column'}
                      alignItems={'center'}
                      spacing={3}
                      padding={5}
                    >
                      <img
                        src={'/static/red_logo.png'}
                        style={{ height: '170px', marginBottom: 30 }}
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Grid
                        item
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                        style={{
                          ...innerStyles.logo,
                          backgroundColor: `green`,
                        }}
                      >
                        <Check
                          style={{
                            fontSize: 55,
                            fontWeight: 'bolder',
                            color: 'white',
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent={'center'}
                      flexDirection={'column'}
                      alignItems={'center'}
                    >
                      <Typography
                        variant="h5"
                        fontWeight={'700'}
                        style={{ textAlign: 'center', marginTop: 20 }}
                      >
                        All done.
                      </Typography>

                      <Typography
                        variant="body1"
                        style={{
                          textAlign: 'center',
                          marginTop: 20,
                          maxWidth: 350,
                        }}
                      >
                        We've processed your invitation reply, thank you for
                        using RouteBuddies.
                      </Typography>
                    </Grid>

                    <Grid item container justifyContent={'center'}>
                      <Button
                        onClick={() => {
                          window.scrollTo(0, 0);
                          navigate('/');
                        }}
                        variant="contained"
                        sx={{
                          marginRight: 1,
                          backgroundColor: 'rgba(0,0,0,0.6)',
                          marginTop: 5,
                        }}
                      >
                        Take Me Home
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item container justifyContent={'center'} alignItems="center">
          <CircularProgress />
        </Grid>
      )}
      <Footer />
    </Grid>
  );
}
