import {
  Button,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import {
  DateTimePicker,
  LocalizationProvider,
  StaticDateTimePicker,
} from '@mui/x-date-pickers';
import {
  JSXElementConstructor,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { Helmet } from 'react-helmet';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment';
import { primaryColor } from '../../../assets/styles/styles';
import { LocationCity, Place } from '@mui/icons-material';
import { useTools } from '../../../services/general_services';
import { PageType, setCurrentPage } from '../../../store/slices/general.slice';
import { MultiInput } from '../../components/MultiInput';

export function NewMembersScreen() {
  const { dispatch, navigate } = useTools();
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [desc, setDesc] = useState('');
  const [descError, setDescError] = useState('');
  const [origin, setOrigin] = useState<any>(undefined);
  const [originError, setOriginError] = useState<any>('');
  const [originFocused, setOriginFocused] = useState(false);
  const [dest, setDest] = useState<any>(undefined);
  const [destError, setDestError] = useState<any>('');
  const [destFocused, setDestFocused] = useState(false);
  const [activityDate, setActivityDate] = useState<any>(dayjs(new Date()));
  const [activityDateError, setActivityDateError] = useState('');

  const [values, setValues] = useState([]);

  const [titleFocused, setTitleFocused] = useState(false);
  function validateLocationData() {
    let numErrors = 0;

    if (!origin) {
      setOriginError('You must set a starting point for this activity.');
      numErrors += 1;
    } else {
      setOriginError('');
    }

    if (!dest) {
      setDestError('You must set a destination for this activity.');
      numErrors += 1;
    } else {
      setDestError('');
    }

    if (numErrors === 0) {
      //NEXT
    }
  }
  function validateDetailsData() {
    let numErrors = 0;

    if (title === '') {
      setTitleError('Title must not be empty');
      numErrors += 1;
    } else if (title.length > 20 || title.length < 2) {
      setTitleError('Title length must be between 2-20 characters.');
      numErrors += 1;
    } else if (!/^[A-Za-z]*/.test(title)) {
      setTitleError('Title must be text only.');
    } else {
      setTitleError('');
    }

    if (desc === '') {
      setDescError('Description must not be empty');
      numErrors += 1;
    } else if (desc.length > 20 || desc.length < 2) {
      setDescError('Description length must be between 2-20 characters.');
      numErrors += 1;
    } else if (!/^[A-Za-z]*/.test(desc)) {
      setDescError('Description must be text only.');
    } else {
      setDescError('');
    }

    if (moment(new Date()).isAfter(moment(activityDate))) {
      setActivityDateError('Your activity must take place in the future.');
      numErrors += 1;
    } else {
      setActivityDateError('');
    }
  }

  useEffect(() => {
    dispatch(setCurrentPage(PageType.ENTERPRISE_NEW_ACTIVITY));
  }, []);
  return (
    <Grid container item xs={12}>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>

      <Grid
        container
        item
        xs={12}
        spacing={2}
        sx={{
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 2,
        }}
        alignItems={'flex-start'}
      >
        <Grid
          item
          container
          xs={12}
          alignItems={'flex-start'}
          flexDirection={'column'}
        >
          <Grid item container>
            <Typography
              variant={'h4'}
              fontWeight={'800'}
              sx={{ marginBottom: 1 }}
            >
              New members
            </Typography>
            <Typography variant={'body1'} fontWeight={'400'}>
              Let's expand your community with new members. Remember - Only
              invite people that you trust into your community.
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={12}>
          <MultiInput values={values} setValues={setValues} />
        </Grid>
        <Grid item container xs={12} justifyContent={'flex-end'}>
          {' '}
          <Button
            onClick={() => {}}
            variant="contained"
            sx={{ marginRight: 1 }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {}}
            style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}
            variant="contained"
          >
            Invite member(s)
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
