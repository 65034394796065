import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { primaryColor } from '../../assets/styles/styles';
import { Grid } from '@mui/material';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Grid item container style={{ backgroundColor: primaryColor }}>
      <Container
        id="faq"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          color="white"
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          Frequently asked questions
        </Typography>
        <Box sx={{ width: '100%' }}>
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            style={{
              backgroundColor: 'transparent',
              border: '3px solid white',
              marginBottom: 10,
              borderRadius: 10,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography
                component="h3"
                variant="subtitle2"
                style={{
                  color: 'white',
                  fontFamily: 'Urbanist',
                  fontWeight: 'bolder',
                }}
              >
                How do I contact customer support if I have a question or issue?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' }, color: 'white' }}
              >
                You can reach our customer support team by emailing{' '}
                <Link style={{ color: 'white' }}>contact@routebuddies.com</Link>{' '}
                - We&apos;re here to assist you promptly.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
            style={{
              backgroundColor: 'transparent',
              border: '3px solid white',
              marginBottom: 10,
              borderRadius: 10,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography
                component="h3"
                variant="subtitle2"
                style={{
                  color: 'white',
                  fontFamily: 'Urbanist',
                  fontWeight: 'bold',
                }}
              >
                Is RouteBuddies available everywhere?{' '}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' }, color: 'white' }}
              >
                Yes, whilst we started as a UK-Only app, the RouteBuddies
                platform is now usable anywhere in the world.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
            style={{
              backgroundColor: 'transparent',
              border: '3px solid white',
              marginBottom: 10,
              borderRadius: 10,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography
                component="h3"
                variant="subtitle2"
                style={{
                  color: 'white',
                  fontFamily: 'Urbanist',
                  fontWeight: 'bold',
                }}
              >
                What makes your product stand out from others in the market?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' }, color: 'white' }}
              >
                RouteBuddies is more than just a safety app - It's a collection
                of individuals who are dedicated to keeping one-another safe.
                <br />
                <br />
                Where we see community - We see potential - It's why we're
                dedicated to building amazing tools to bring safety in numbers
                to the world. <br />
                <br />
                By joining RouteBuddies, you're not just using a tool - You're
                becoming a part of the solution.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}
            style={{
              backgroundColor: 'transparent',
              border: '3px solid white',
              marginBottom: 10,
              borderRadius: 10,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography
                component="h3"
                variant="subtitle2"
                style={{
                  color: 'white',
                  fontFamily: 'Urbanist',
                  fontWeight: 'bold',
                }}
              >
                Are members guaranteed support?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' }, color: 'white' }}
              >
                RouteBuddies supports users at all levels to ensure help is
                always available - From immediate SOS requests between community
                members, up to instant messaging to UK Police through the app
                for support in extreme situations.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </Grid>
  );
}
