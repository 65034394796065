import { ChevronRight, OpenInNew } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';

interface IProps {
  backgroundColor: string;
  icon: any;
  color: string;
  title: string;
  value: string;
  onClick: Function;
  xs: number;
}
export function InfoPanel({
  backgroundColor,
  icon,
  color,
  title,
  value,
  onClick,
  xs,
}: IProps) {
  return (
    <Grid
      item
      container
      xs={xs}
      sx={{
        backgroundColor: backgroundColor,
        height: 100,
        borderRadius: 5,
        padding: 2,
        cursor: 'pointer',
        marginRight: 2,
        marginBottom: { xs: 5, sm: 0 },
      }}
      onClick={() => onClick()}
    >
      <Grid item container xs={10}>
        <Grid item container xs={12}>
          <Typography
            variant="h6"
            fontSize={13}
            fontWeight={'800'}
            sx={{ color: color }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignContent={'center'}
          alignItems={'center'}
          spacing={2}
        >
          <Grid item>{icon}</Grid>
          <Grid item>
            <Typography
              variant={'h6'}
              style={{
                fontWeight: '400',
                fontFamily: 'Urbanist',
                color: color,
              }}
              fontSize={25 - value.length / 3}
            >
              {value}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={2} alignItems={'flex-end'}>
        <OpenInNew fontSize="small" style={{ color: color, fontSize: 15 }} />
      </Grid>
    </Grid>
  );
}
