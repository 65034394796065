import { CoalitionModel } from './CoalitionModel';
import { CommunityModel } from './CommunityModel';
import {
  LocationModel,
  JourneyUserModel,
  JourneyStateModel,
  JourneyUserAppModel,
} from './GroupModel';
import UserDataModel from './UserDataModel';

export interface RepeatConfig {
  repeatType: RepeatType;
  repeatValue: number;
  minsConfig?: MinsHoursConfig;
  hoursConfig?: MinsHoursConfig;
  daysConfig?: DaysConfig;

  monthlyConfig?: MonthlyConfig;
}

export interface MinsHoursConfig {
  startTime?: string;
  endTime?: string;
  startAfterDate?: string;
  endBeforeDate?: string;
  days: number[];
}
export interface DaysConfig {
  startAfterDate?: string;
  endBeforeDate?: string;
  days: number[];
}

export enum RepeatType {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
}

//   Monthly date index so we can know which week of the month for ON_DAY events E.g Second monday each month.
export enum MonthlyDateIndex {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD',
  FOURTH = 'FOURTH',
}

//   Types of monthly events - Either on a specific date, or on a day e.g Second monday each month.
export enum MonthlyType {
  ON_DATE = 'ON_DATE',
  ON_DAY = 'ON_DAY',
}

/**
 * Monthly config that will be used if they choose to do monthly events (On a specific day, or on a day)
 */
export interface MonthlyConfig {
  type: MonthlyType;
  date?: number;
  dayConfig: MonthlyDayConfig;
}

/**
 * Day index = 1st, 2nd, 3rd or 4th week of the month.
 * Day = 1-7 day numbers.
 */
export interface MonthlyDayConfig {
  dayIndex: MonthlyDateIndex;
  day: number;
}

export interface RepeatJourneyModel {
  active: boolean;
  creationDate: string;
  groupId: string;
  creatorId: string;
  creatorType: CreatorEnum;
  origin: LocationModel;
  destination: LocationModel;
  waypointOrder: any[];
  date: string;
  title: string;
  description: string;
  occurences: OccurenceModel[];
  repeatConfig: RepeatConfig;
}

export interface OccurenceModel {
  users: JourneyUserModel[];
  journeyState: JourneyStateModel;
  dateTime: string;
}

export enum CreatorEnum {
  COMMUNITY = 'COMMUNITY',
  COALITION = 'COALITION',
  USER = 'USER',
}

export interface JourneyAppModel {
  active: boolean;
  creationDate: string;
  groupId: string;
  creator: UserDataModel | CommunityModel | CoalitionModel;
  creatorType: CreatorEnum;
  origin: LocationModel;
  destination: LocationModel;
  users?: JourneyUserAppModel[];
  journeyState: JourneyStateModel;
  waypointOrder: any[];
  date: string;
  title: string;
  description: string;
}
