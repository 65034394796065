import { Facebook, Instagram } from '@mui/icons-material';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { primaryColor } from '../../assets/styles/styles';

export function Footer() {
  const navigate = useNavigate();
  function scrollTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  return (
    <Grid
      container
      item
      xs={12}
      style={{
        backgroundColor: 'black',
        padding: 50,
        color: 'white',
        textAlign: 'left',
      }}
    >
      <Grid
        container
        item
        xs={12}
        sm={4}
        alignItems={'center'}
        flexDirection={'column'}
        style={{ color: 'white !important' }}
        sx={{
          marginBottom: {
            xs: 3,
            sm: 0,
          },
        }}
      >
        <Grid
          item
          padding={1}
          style={{ backgroundColor: primaryColor, borderRadius: 5 }}
          justifyContent={'center'}
        >
          <Typography
            variant={'h6'}
            style={{
              fontFamily: 'Urbanist',
              marginBottom: 10,
              textAlign: 'center',
            }}
            fontWeight={'bold'}
          >
            Download the App:
          </Typography>
          <Button
            onClick={() => {
              window.location.href =
                'https://routebuddies.com/?appTrigger=true';
            }}
          >
            <img src="/static/app_store.png" style={{ width: 200 }} />
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={4}
        alignItems={'center'}
        flexDirection={'column'}
        style={{ color: 'white !important' }}
      >
        <Typography
          variant={'h6'}
          style={{ fontFamily: 'Urbanist', marginBottom: 15 }}
          fontWeight={'bold'}
        >
          Useful Links:
        </Typography>

        <Button
          variant={'text'}
          onClick={() => {
            window.scrollTo(0, 0);
            navigate('/privacypolicy');
            scrollTop();
          }}
          style={{ marginBottom: 10 }}
        >
          <Typography variant={'body1'}>Privacy Policy</Typography>
        </Button>
        <Button
          variant={'text'}
          onClick={() => {
            window.scrollTo(0, 0);
            navigate('/termsandconditions');
            scrollTop();
          }}
          style={{ marginBottom: 10 }}
        >
          <Typography variant={'body1'}>Terms and Conditions</Typography>
        </Button>
        <Button
          variant={'text'}
          onClick={() => {
            window.scrollTo(0, 0);
            navigate('/contact');
            scrollTop();
          }}
          style={{ marginBottom: 10 }}
        >
          <Typography variant={'body1'}>Contact Us</Typography>
        </Button>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={4}
        justifyContent={'center'}
        style={{ color: 'white !important' }}
        sx={{
          marginTop: {
            xs: 3,
            sm: 0,
          },
        }}
      >
        <Typography
          variant={'h6'}
          style={{ fontFamily: 'Urbanist' }}
          fontWeight={'bold'}
        >
          Follow Us:
        </Typography>

        <Grid container xs={12} justifyContent={'space-evenly'}>
          <Grid item>
            <IconButton
              onClick={() => {
                window.location.href =
                  'https://www.facebook.com/routebuddiesapp';
              }}
            >
              <Facebook color={'primary'} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                window.location.href =
                  'https://www.instagram.com/@routebuddiesapp';
              }}
            >
              <Instagram color={'primary'} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                window.location.href =
                  'https://www.tiktok.com/@routebuddiesapp';
              }}
            >
              <Typography
                style={{ fontFamily: 'Urbanist', fontSize: 13 }}
                variant="body1"
                color={'primary'}
              >
                TikTok
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
