import { Button, CircularProgress, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';

// Imports; Material UI
import {
  CheckCircleRounded,
  ChevronLeft,
  LocationCity,
  Search,
  Share,
} from '@mui/icons-material';

import { Grid, useTheme } from '@mui/material';

import NavBar from '../components/NavBar';
import { useNavigate } from 'react-router';
import '../../index.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useAnalyticsEventTracker from '../../services/analytics_services';
import { Helmet } from 'react-helmet';
import { PageType, setCurrentPage } from '../../store/slices/general.slice';
import { useDispatch } from 'react-redux';
import { primaryColor } from '../../assets/styles/styles';
import DarkTextField from '../components/TextFieldDarker';
import universityData from '../../assets/data/universityData';
import UniversityChoiceModel from '../../models/UniversityChoiceModel';
import { checkValidEmail } from '../../services/home_services';
import { toast } from 'react-toastify';
import { auth, db } from '../../services/firebase_services';
import LoginErrorModel from '../../models/LoginErrorModel';
export default function Register({}: any) {
  const navigate = useNavigate();
  const theme = useTheme();
  //settings for slider

  const [pageNumber, setPageNumber] = useState(3);

  const [emailEnding, setEmailEnding] = useState('');
  const [university, setUniversity] = useState('');

  const [searchVal, setSearchVal] = useState('');

  //Holds list of universities
  const [uniData, setUniData] = useState(universityData);

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [emailAddressError, setEmailAddressError] = useState('');
  const [passwordOne, setPasswordOne] = useState('');
  const [passwordOneError, setPasswordOneError] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');
  const [passwordTwoError, setPasswordTwoError] = useState('');

  //Has user signed up?
  const [done, setDone] = useState(false);

  const [loading, setLoading] = useState(false);
  const regRef: any = useRef(null);
  const infoRef: any = useRef(null);

  function validateForm() {
    let numErrors = 0;

    //Password validation
    if (passwordOne === '') {
      setPasswordOneError('Password must not be empty.');
      setPasswordTwoError('');
      numErrors += 1;
    } else if (
      !String(passwordOne).match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
      )
    ) {
      setPasswordTwoError('');
      setPasswordOneError(
        'Password must be minimum 8 characters, at least one number, one uppercase letter, one' +
          'lowercase letter and one special character.'
      );
      numErrors += 1;
    } else if (passwordOne !== passwordTwo) {
      setPasswordOneError('Passwords must be matching.');
      setPasswordTwoError('Passwords must be matching.');
      numErrors += 1;
    } else {
      setPasswordOneError('');
      setPasswordTwoError('');
    }

    //Email validation
    if (emailAddress === '') {
      setEmailAddressError('Email must not be empty');
      numErrors += 1;
    } else if (emailAddress.length > 40) {
      setEmailAddressError('Email must be less than 40 characters.');
      numErrors += 1;
    } else if (emailEnding === '' && !checkValidEmail(emailAddress)) {
      setEmailAddressError('Email provided is invalid.');
      numErrors += 1;
    } else if (
      emailEnding !== '' &&
      emailAddress.length > 3 &&
      emailAddress.length < 25 &&
      emailAddress.includes('@')
    ) {
      setEmailAddressError(
        'Email must be first part of university email address only between 3 & 25 characters length. (Text, numbers, hyphens or periods only, all lowercase)'
      );
      numErrors += 1;
    } else {
      setEmailAddressError('');
    }

    //Name validation
    if (firstName === '') {
      setFirstNameError('First name must not be empty');
      numErrors += 1;
    } else if (firstName.length > 20 || firstName.length < 2) {
      setFirstNameError('First name length must be between 2-20 characters.');
      numErrors += 1;
    } else if (!/^[A-Za-z]*/.test(firstName)) {
      setFirstNameError('First name must be text only.');
    } else {
      setFirstNameError('');
    }
    if (lastName === '') {
      setLastNameError('Surname must not be empty');
      numErrors += 1;
    } else if (lastName.length > 30 || lastName.length < 2) {
      setLastNameError('Surname length must be between 2-30 characters.');
      numErrors += 1;
    } else if (!/^[A-Za-z]*/.test(lastName)) {
      setLastNameError('Surname must be text only.');
    } else {
      setLastNameError('');
    }

    if (numErrors > 0) {
      setLoading(false);
      toast.error('You have errors in your details.');
    } else {
      registerUser();
    }
  }

  async function registerUser() {
    let emailToUse = emailEnding ? emailAddress + emailEnding : emailAddress;

    await auth
      .createUserWithEmailAndPassword(emailToUse, passwordOne)
      .then(async (authUser: { user: { uid: any } | null }) => {
        //If the creation was successful.
        if (authUser.user !== null) {
          const id = authUser.user.uid;

          db.ref(`/new_data/user_data/users/${id}`).set({
            emailAddress: emailToUse,
            firstName,
            lastName,
            id,
            university,
            rating: 5,
            trustLevel: 0,
            tutorialVisited: false,
          });
          toast.success('You have signed up for RouteBuddies.');
          setPageNumber(4);
          setLoading(false);
        }
      })
      .catch((error: LoginErrorModel) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === 'auth/wrong-password') {
          setEmailAddressError('');
          setPasswordOneError('Wrong password for this account.');
        } else if (errorCode === 'auth/missing-password') {
          setEmailAddressError('');
          setPasswordOneError('Wrong password for this account.');
        } else {
          setPasswordOneError('');
          setEmailAddressError('Error signing up.');
        }
      });
  }
  const gaEventTracker = useAnalyticsEventTracker('Home');

  useEffect(() => {
    if (searchVal === '') {
      setUniData(universityData);
    }
  }, [searchVal]);
  function renderForm(type: string) {
    return (
      <>
        {!done ? (
          <Grid container item xs={12} spacing={2} justifyContent={'center'}>
            {/* Student Choice */}
            {pageNumber === 1 && (
              <Grid
                item
                container
                xs={12}
                sm={11}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'row'}
                sx={{ marginTop: 10, marginBottom: 10 }}
              >
                <Grid
                  item
                  container
                  xs={10}
                  justifyContent={'space-around'}
                  spacing={2}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    sm={6}
                    justifyContent={{ xs: 'center', sm: 'flex-end' }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        paddingLeft: 8,
                        paddingRight: 8,
                        height: 100,
                        border: '8px solid #cd0438',
                        fontFamily: 'Urbanist',
                        borderRadius: 300,
                        width: { xs: '100%', sm: 'auto' },
                      }}
                      onClick={() => {
                        setPageNumber(2);
                      }}
                    >
                      <Typography
                        variant="h6"
                        fontSize={15}
                        style={{ fontFamily: 'Urbanist', color: primaryColor }}
                      >
                        I am a student
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={6}
                    justifyContent={{ xs: 'center', sm: 'flex-start' }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        paddingLeft: 8,
                        paddingRight: 8,
                        height: 100,
                        border: '8px solid #cd0438',
                        fontFamily: 'Urbanist',
                        borderRadius: 300,
                        width: { xs: '100%', sm: 'auto' },
                      }}
                      onClick={() => {
                        setPageNumber(3);
                      }}
                    >
                      <Typography
                        variant="h6"
                        fontSize={15}
                        style={{ fontFamily: 'Urbanist', color: '#fff' }}
                      >
                        I am not a student
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item container xs={2} sm={6}></Grid>
                </Grid>
              </Grid>
            )}
            {pageNumber === 2 && (
              <Grid
                item
                container
                xs={12}
                sm={12}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'row'}
                sx={{ marginTop: 3, marginBottom: 5 }}
              >
                <Grid
                  item
                  container
                  xs={10}
                  justifyContent={{ xs: 'flex-start' }}
                  sx={{
                    marginBottom: 4,
                  }}
                >
                  <Button
                    variant="text"
                    onClick={() => {
                      setPageNumber(1);
                    }}
                  >
                    <ChevronLeft />
                    Go Back
                  </Button>
                </Grid>
                <Grid
                  item
                  container
                  xs={10}
                  sm={12}
                  justifyContent={{ xs: 'center', sm: 'flex-end' }}
                  sx={{
                    marginBottom: 4,
                  }}
                >
                  <DarkTextField
                    placeholder="Search Universities..."
                    value={searchVal}
                    onChange={e => {
                      setSearchVal(e.target.value);

                      if (e.target.value !== '') {
                        setUniData(
                          universityData.filter((uniVal: any) =>
                            uniVal.name
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          )
                        );
                      } else {
                        setUniData([]);
                      }
                    }}
                    sx={{
                      width: { xs: '100%', sm: '200px' },
                      borderRadius: 30,
                      marginRight: 30,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent={'center'}
                  sx={{
                    overflow: 'hidden',
                  }}
                >
                  {uniData.map((val: UniversityChoiceModel, index: number) => {
                    return (
                      <Button
                        variant="text"
                        style={{ width: '100%' }}
                        onClick={() => {
                          setEmailEnding(val.email);
                          setUniversity(val.name);
                          setPageNumber(3);
                        }}
                      >
                        <Grid
                          item
                          container
                          xs={12}
                          sx={{
                            backgroundColor:
                              index % 2 ? 'rgba(0,0,0,0.03)' : '#fff',
                          }}
                          padding={2}
                        >
                          <Grid
                            item
                            xs={3}
                            container
                            justifyContent="center"
                            alignItems={'center'}
                          >
                            <LocationCity />
                          </Grid>

                          <Grid
                            item
                            container
                            xs={9}
                            flexDirection="column"
                            alignItems={'flex-start'}
                          >
                            <Typography
                              variant="h6"
                              fontSize={14}
                              sx={{ fontFamily: 'Urbanist' }}
                              textAlign={'left'}
                            >
                              {val.name}
                            </Typography>
                            <Typography
                              variant={'body1'}
                              fontSize={10}
                              color="rgba(0,0,0,0.7)"
                            >
                              {val.email}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Button>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            {pageNumber === 3 && (
              <Grid
                item
                container
                xs={10}
                alignItems={'flex-start'}
                justifyContent={'center'}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={type === 'large' ? { paddingBottom: 15 } : {}}
                  justifyContent={'flex-start'}
                >
                  <TextField
                    value={firstName}
                    placeholder={'Enter first name'}
                    style={{ width: '100%' }}
                    onChange={(e: any) => setFirstName(e.target.value)}
                  ></TextField>
                  {firstNameError !== '' && (
                    <Typography variant={'body2'} color={'primary'}>
                      {firstNameError}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={type === 'large' ? { paddingBottom: 15 } : {}}
                  justifyContent={'center'}
                >
                  {' '}
                  <TextField
                    value={lastName}
                    placeholder={'Enter last name'}
                    style={{ width: '100%' }}
                    onChange={(e: any) => setLastName(e.target.value)}
                  ></TextField>
                  {lastNameError !== '' && (
                    <Typography variant={'body2'} color={'primary'}>
                      {lastNameError}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={type === 'large' ? { paddingBottom: 15 } : {}}
                  justifyContent={'center'}
                >
                  <TextField
                    value={emailAddress}
                    placeholder={'Enter email address'}
                    style={{ width: '100%' }}
                    onChange={(e: any) => setEmailAddress(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <Typography variant="body1">{emailEnding}</Typography>
                      ),
                    }}
                  ></TextField>
                  {emailAddressError !== '' && (
                    <Typography variant={'body2'} color={'primary'}>
                      {emailAddressError}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={type === 'large' ? { paddingBottom: 15 } : {}}
                  justifyContent={'center'}
                >
                  <TextField
                    value={passwordOne}
                    placeholder={'Enter Password'}
                    type="password"
                    style={{ width: '100%' }}
                    onChange={(e: any) => setPasswordOne(e.target.value)}
                  ></TextField>
                  {passwordOneError !== '' && (
                    <Typography variant={'body2'} color={'primary'}>
                      {passwordOneError}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={type === 'large' ? { paddingBottom: 15 } : {}}
                  justifyContent={'center'}
                >
                  <TextField
                    value={passwordTwo}
                    placeholder={'Confirm Password'}
                    type="password"
                    style={{ width: '100%' }}
                    onChange={(e: any) => setPasswordTwo(e.target.value)}
                  ></TextField>
                  {passwordTwoError !== '' && (
                    <Typography variant={'body2'} color={'primary'}>
                      {passwordTwoError}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={() => {
                      gaEventTracker('registration');
                      setLoading(true);
                      validateForm();
                    }}
                    variant={'contained'}
                    disabled={loading}
                    style={
                      type === 'large'
                        ? {
                            width: '100%',
                            height: '56px',
                            marginBottom: 60,
                          }
                        : { width: '100%' }
                    }
                  >
                    {loading ? <CircularProgress /> : 'REGISTER'}
                  </Button>
                </Grid>
              </Grid>
            )}
            {pageNumber === 4 && (
              <Grid
                item
                container
                xs={10}
                justifyContent={'center'}
                alignItems="center"
                flexDirection={'column'}
              >
                <img
                  src="/static/success.svg"
                  width={'100%'}
                  style={{ marginBottom: 20, maxWidth: 250, marginTop: 20 }}
                />
                <Typography variant="body1" style={{ marginBottom: 20 }}>
                  Welcome aboard, get ready to find safety in numbers at your
                  next event.
                </Typography>

                <Typography variant="body1" style={{ marginBottom: 20 }}>
                  Download the app today on the App Store & Google Play!
                </Typography>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid
            container
            item
            xs={12}
            flexDirection={'column'}
            justifyContent={'space-evenly'}
            alignItems={'center'}
          >
            <CheckCircleRounded
              style={{ color: 'green', fontSize: 50, marginBottom: 15 }}
            />
            <Typography
              variant={'body2'}
              textAlign={'center'}
              style={{
                fontFamily: 'Urbanist',
                marginBottom: 10,
                color: theme.palette.primary.dark,
                maxWidth: 550,
              }}
            >
              You've showed your interest, we'll let you know when we've
              launched! Thank you for your support.
            </Typography>
          </Grid>
        )}
      </>
    );
  }
  const dispatch = useDispatch();

  const [fadeAnimation, setFadeAnimation] = useState(false);
  const [slidenimation, setSlideAnimation] = useState(false);

  useEffect(() => {
    dispatch(setCurrentPage(PageType.REGISTER));
    setFadeAnimation(true);
    setSlideAnimation(true);
  }, []);

  return (
    <>
      <Helmet>
        <meta name="title" content="RouteBuddies | Signup" />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>

      {/* Section 1 */}
      <Grid
        container
        item
        xs={12}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ minHeight: '100vh' }}
      >
        <Grid
          item
          container
          xs={12}
          sm={10}
          justifyContent={'center'}
          ref={regRef}
          style={{ marginTop: 0 }}
        >
          <Grid
            item
            container
            xs={10}
            justifyContent={'flex-start'}
            flexDirection={'column'}
            style={{
              borderBottom: '0.5px solid grey',
              paddingBottom: 0,
              marginBottom: 15,
            }}
          >
            <Typography
              variant="h4"
              fontWeight={'bold'}
              fontFamily={'Urbanist'}
              style={{
                fontFamily: 'UrbanistBold',
                marginTop: 40,
                marginBottom: pageNumber !== 4 ? 5 : 25,
                color: primaryColor,
              }}
            >
              {pageNumber !== 4 ? 'Signup' : "You're all signed up!"}
            </Typography>
            {pageNumber !== 4 && (
              <Typography style={{ marginBottom: 20 }}>
                Looking to get setup with an enterprise account?{' '}
                <a href="/enterprise">Get in touch here.</a>
              </Typography>
            )}
            {pageNumber !== 4 && (
              <Typography style={{ marginBottom: 20 }}>
                Login to the dashboard
                <a href="/enterprise/dashboard"> here.</a>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} container justifyContent={'center'}>
            {renderForm('large')}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
