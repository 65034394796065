import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserDataModel from '../../models/UserDataModel';
import { CommunityModel } from '../../models/CommunityModel';
import { PostAppModel } from '../../models/PostModel';
import { ReportAppModel } from '../../models/ReportModel';
import { getAdminStatus } from '../thunks/general.thunks';
import { JourneyAppModel } from '../../models/GroupModel';
import {
  getAllAdminActivities,
  getAllAdminUsers,
  getAllCoalitions,
  getAllCommunities,
  getUpcomingAdminActivities,
} from '../thunks/admin.thunks';
import { CoalitionModel } from '../../models/CoalitionModel';

/**
 * modalHeight - Height of the information modal being shown during a journey - Used to display properly.
 * reportUser - Data of a user from a group selected to be reported.
 * page - The current page a user is on - PageType.HOME - PageType.JOURNEYS etc.
 */
interface IProps {
  permitted: boolean;

  loginErrorMessage: string;
  upcomingAdminActivities: JourneyAppModel[];
  upcomingAdminActivitiesLoading: boolean;
  upcomingAdminActivitiesError: boolean;
  allAdminActivities: JourneyAppModel[];
  allAdminActivitiesLoading: boolean;
  allAdminActivitiesError: boolean;
  selectedActivity: JourneyAppModel | undefined;
  allUsers: {
    id: string;
    user: UserDataModel;
    community: CommunityModel;
    joinDate: string;
  }[];
  allUsersLoading: boolean;
  allUsersError: boolean;
  allCommunities: CommunityModel[];
  allCommunitiesLoading: boolean;
  allCommunitiesError: boolean;
  selectedAdminCommunity: CommunityModel | undefined;
  allCoalitions: CoalitionModel[];
  allCoalitionsError: boolean;
  allCoalitionsLoading: boolean;
}
const enterpriseInitialState: IProps = {
  permitted: false,
  loginErrorMessage: '',
  upcomingAdminActivities: [],
  upcomingAdminActivitiesLoading: false,
  upcomingAdminActivitiesError: false,
  allAdminActivities: [],
  allAdminActivitiesLoading: false,
  allAdminActivitiesError: false,
  selectedActivity: undefined,
  allUsers: [],
  allUsersLoading: false,
  allUsersError: false,
  allCommunities: [],
  allCommunitiesError: false,
  allCommunitiesLoading: false,
  selectedAdminCommunity: undefined,
  allCoalitions: [],
  allCoalitionsError: false,
  allCoalitionsLoading: false,
};

const enterpriseSlice = createSlice({
  name: 'enterprise_data',
  initialState: enterpriseInitialState,
  reducers: {
    // Setters

    setLoginErrorMessage: (
      state: IProps,
      { payload }: PayloadAction<string>
    ) => {
      state.loginErrorMessage = payload;
    },
    setUpcomingAdminActivities: (
      state: IProps,
      { payload }: PayloadAction<JourneyAppModel[]>
    ) => {
      state.upcomingAdminActivities = payload;
    },
    setSelectedActivity: (
      state: IProps,
      { payload }: PayloadAction<JourneyAppModel | undefined>
    ) => {
      state.selectedActivity = payload;
    },
    setSelectedAdminCommunity: (
      state: IProps,
      { payload }: PayloadAction<CommunityModel | undefined>
    ) => {
      state.selectedAdminCommunity = payload;
    },

    addToCommunities: (
      state: IProps,
      { payload }: PayloadAction<CommunityModel>
    ) => {
      state.allCommunities = [...state.allCommunities, payload].sort((a, b) =>
        new Date(a.creationDate) < new Date(b.creationDate) ? 1 : 0
      );
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(
      getAdminStatus.fulfilled,
      (state: IProps, { payload }: PayloadAction<boolean>) => {
        if (payload) {
          state.permitted = true;
        } else {
          state.permitted = false;
          state.loginErrorMessage =
            'You are not permitted to login to the enterprise edition of RouteBuddies, please contact sales to open an enterprise account.';
        }
      }
    );

    builder.addCase(getAllAdminActivities.rejected, (state: IProps) => {
      state.allAdminActivitiesLoading = false;
      state.allAdminActivitiesError = true;
    });
    builder.addCase(getAllAdminActivities.pending, (state: IProps) => {
      state.allAdminActivitiesLoading = true;
      state.allAdminActivitiesError = false;
    });
    builder.addCase(
      getAllAdminActivities.fulfilled,
      (
        state: IProps,
        { payload }: PayloadAction<JourneyAppModel[] | undefined>
      ) => {
        state.allAdminActivitiesError = false;
        state.allAdminActivitiesLoading = false;
        if (payload && payload.length > 0) {
          state.allAdminActivities = payload.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            // @ts-ignore
            return new Date(b.date) - new Date(a.date);
          });
        } else {
        }
      }
    );
    builder.addCase(getUpcomingAdminActivities.rejected, (state: IProps) => {
      state.upcomingAdminActivitiesError = true;
      state.upcomingAdminActivitiesLoading = false;
    });
    builder.addCase(getUpcomingAdminActivities.pending, (state: IProps) => {
      state.upcomingAdminActivitiesLoading = true;
      state.upcomingAdminActivitiesError = false;
    });
    builder.addCase(
      getUpcomingAdminActivities.fulfilled,
      (
        state: IProps,
        { payload }: PayloadAction<JourneyAppModel[] | undefined>
      ) => {
        state.upcomingAdminActivitiesError = false;
        state.upcomingAdminActivitiesLoading = false;
        if (payload && payload.length > 0) {
          state.upcomingAdminActivities = payload.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            // @ts-ignore
            return new Date(b.date) - new Date(a.date);
          });
        }
      }
    );
    builder.addCase(
      getAllAdminUsers.fulfilled,
      (
        state: IProps,
        {
          payload,
        }: PayloadAction<
          | {
              id: string;
              user: UserDataModel;
              community: CommunityModel;
              joinDate: string;
            }[]
          | undefined
        >
      ) => {
        if (payload && payload !== null) {
          state.allUsers = payload;
        }

        state.allUsersError = false;
        state.allUsersLoading = false;
      }
    );
    builder.addCase(getAllCoalitions.rejected, (state: IProps) => {
      state.allCoalitionsLoading = false;
      state.allCoalitionsError = true;
    });
    builder.addCase(getAllCoalitions.pending, (state: IProps) => {
      state.allCoalitionsLoading = true;
      state.allCoalitionsError = false;
    });
    builder.addCase(
      getAllCoalitions.fulfilled,
      (state: IProps, { payload }: PayloadAction<CoalitionModel[]>) => {
        state.allCoalitions = payload;
        state.allCoalitionsError = false;
        state.allCoalitionsLoading = false;
      }
    );
    builder.addCase(getAllCommunities.rejected, (state: IProps) => {
      state.allCommunitiesLoading = false;
      state.allCommunitiesError = true;
    });
    builder.addCase(getAllCommunities.pending, (state: IProps) => {
      state.allCommunitiesLoading = true;
      state.allCommunitiesError = false;
    });
    builder.addCase(
      getAllCommunities.fulfilled,
      (state: IProps, { payload }: PayloadAction<CommunityModel[]>) => {
        state.allCommunitiesLoading = false;
        state.allCommunities = payload.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return (
            // @ts-ignore
            new Date(b.creationDate) -
            // @ts-ignore
            new Date(a.creationDate)
          );
        });
      }
    );
  },
});

export const {
  setSelectedActivity,
  setLoginErrorMessage,
  setUpcomingAdminActivities,
  addToCommunities,
  setSelectedAdminCommunity,
} = enterpriseSlice.actions;

export default enterpriseSlice.reducer;
