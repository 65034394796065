import { createAsyncThunk } from '@reduxjs/toolkit';

import { db } from '../../services/firebase_services';
import UserDataModel from '../../models/UserDataModel';
import { CommunityRole } from '../../models/CommunityRoleModel';
import { CommunityModel } from '../../models/CommunityModel';

// Get user data function lets us retreieve the user data (For logged in user)using a user ID.
export const getAdminStatus = createAsyncThunk(
  'GetAdminStatus',
  async ({ id }: any): Promise<boolean> => {
    try {
      let user = db.ref('/new_data/user_data/users/' + id + '/admin');
      let userObj: any = {};

      let admin = false;

      let val = (await user.once('value')).val();

      if (val !== null) {
        admin = true;
      }

      return admin;
    } catch (err: any) {
      // Go to error page if error occurs

      return false;
      // goToErrorPage(navigate);
    }
  }
);

export const getCommunityAdminStatus = createAsyncThunk(
  'GetCommunityAdminStatus',
  async ({ id }: any): Promise<CommunityModel[] | undefined> => {
    try {
      let user = db.ref('/new_data/user_data/user_communities/' + id);
      let userObj: any = {};

      let communities: CommunityRole[] | null = (
        await user.once('value')
      ).val();

      if (communities !== null && Object.values(communities) !== null) {
        let list = Object.values(communities).filter(
          (val: CommunityRole) => val.active && val.role === 'ADMIN'
        );

        if (list !== null && list.length > 0) {
          let listToReturn = [];

          // Loop through where i am an admin and get data.
          for (let i = 0; i < list.length; i++) {
            let commRef = db.ref(
              '/new_data/community_data/communities/' + list[i].communityId
            );

            let commData: CommunityModel = (await commRef.once('value')).val();

            if (commData !== null) {
              listToReturn.push(commData);
            }

            if (i === list.length - 1) {
              return listToReturn;
            }
          }
        }
      } else {
        return;
      }
    } catch (err: any) {
      // Go to error page if error occurs
      // goToErrorPage(navigate);
    }
  }
);
