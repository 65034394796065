import {
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Slide,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { auth } from '../../../services/firebase_services';
import { useAppDispatch } from '../../../store/store';
import {
  getAdminStatus,
  getCommunityAdminStatus,
} from '../../../store/thunks/general.thunks';
import { LoginScreen } from '../../pages/LoginScreen';
import { ga } from 'react-ga';
import {
  logoutUser,
  watchCommunityAuthState,
} from '../../../services/login_services';
import { SideNavBar } from '../navbar/SideNavBar';
import { MenuOpen, Menu, Logout } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';
import theme from '../../../assets/styles/theme';
import { PageType, setCurrentPage } from '../../../store/slices/general.slice';
import {
  getCommunityRequests,
  getCommunityUsers,
  getUserData,
} from '../../../store/thunks/enterprise.users.thunks';
import { ToastContainer } from 'react-toastify';
import { setSelectedCommunity } from '../../../store/slices/enterprise.slice';
import { CommunityModel } from '../../../models/CommunityModel';
import moment from 'moment';
import { getUpcomingCommunityActivities } from '../../../store/thunks/community.thunks';
import { CreatorEnum } from '../../../models/GroupModel';
import { getCommunityCoalitions } from '../../../store/thunks/enterprise.coalition.thunks';
import { getCommunityEvents } from '../../../store/thunks/enterprise.events.thunks';

export function CommunityAdminContainer({ children }: any) {
  const scrollRef = useRef<any>();
  const {
    userData,
    userDataLoading,
    permitted,
    allCommunities,
    selectedCommunity,
    upcomingCommunityActivities,
    allCoalitions,
  } = useSelector((state: any) => state.EnterpriseSlice);

  const { currentPage } = useSelector((state: any) => state.GeneralSlice);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showNav, setShowNav] = useState(!isMobile);

  const [slideNav, setSlideNav] = useState(true);

  const [currentCommId, setCurrentCommId] = useState('');

  useEffect(() => {
    if (showNav) {
      setTimeout(() => {
        setSlideNav(true);
      }, 250);
    } else {
      setSlideNav(false);
    }
  }, [showNav]);

  useEffect(() => {
    if (auth && auth.currentUser) {
    }
  }, [auth]);

  useEffect(() => {
    watchCommunityAuthState(() => {
      if (auth.currentUser) {
        dispatch(getCommunityAdminStatus({ id: auth.currentUser.uid }));
        dispatch(getUserData({ id: auth.currentUser.uid }));
      }
    });
  }, []);

  // Scroll to top on new page
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [currentPage]);

  // When permitted, we should get the notifications for the community & data...
  useEffect(() => {
    if (permitted && selectedCommunity) {
      console.log("We're searching!");
      dispatch(getCommunityRequests({ community: selectedCommunity }));
      dispatch(
        getUpcomingCommunityActivities({
          id: selectedCommunity.communityId,
          startDate: new Date(),
          endDate: moment(new Date()).add(7, 'd').toDate(),
          allActivities: upcomingCommunityActivities,
          creatorType: CreatorEnum.COMMUNITY,
        })
      );
      dispatch(getCommunityUsers({ id: selectedCommunity.communityId }));
      dispatch(getCommunityCoalitions({ id: selectedCommunity.communityId }));
      dispatch(getCommunityEvents({ id: selectedCommunity.communityId }));
    }
  }, [permitted, selectedCommunity]);
  return (
    <>
      <ToastContainer />
      {userDataLoading ? (
        <CircularProgress />
      ) : permitted && userData ? (
        <Grid
          container
          justifyContent={'flex-end'}
          sx={{
            backgroundColor: '#cd0438',
            height: '100vh',
            overflow: 'hidden',
          }}
        >
          {slideNav && (
            <Grid
              item
              container
              xs={showNav ? 12 : 0}
              sm={showNav ? 4 : 0}
              md={showNav ? 3 : 0}
            >
              <Slide timeout={300} in={slideNav} direction="right">
                <div>
                  <SideNavBar setShowNav={setShowNav} />
                </div>
              </Slide>
            </Grid>
          )}

          <Grid
            container
            item
            xs={showNav ? 0 : 12}
            sm={showNav ? 8 : 12}
            md={showNav ? 9 : 12}
            sx={{
              backgroundColor: '#101010',
              paddingTop: 2,
              paddingBottom: 2,
              paddingRight: 1,
              minHeight: '100vh',
              overflowY: 'scroll',
              transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            }}
          >
            <Grid
              container
              item
              xs={12}
              component={'div'}
              ref={scrollRef}
              sx={{
                backgroundColor: '#fff',
                borderRadius: 5,
                overflow: 'scroll',
                padding: 2,
                maxHeight: '95vh',
              }}
              flexDirection={'row'}
            >
              <Grid item container xs={12}>
                <Grid item container xs={4} sm={4} alignItems={'center'}>
                  <IconButton
                    onClick={() => {
                      setShowNav(!showNav);
                    }}
                  >
                    {showNav ? <MenuOpen /> : <Menu />}
                  </IconButton>

                  <Typography
                    variant="h5"
                    fontWeight={'100'}
                    sx={{
                      paddingLeft: 2,
                      fontStyle: 'italic',
                      display: { xs: 'none', md: 'flex' },
                    }}
                  >
                    Hello, {userData.firstName} 👋
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={8}
                  sm={8}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                  spacing={1}
                >
                  <Grid
                    container
                    item
                    xs={10}
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                  >
                    {selectedCommunity ? (
                      <Grid item>
                        <Avatar
                          src={selectedCommunity.imageUrl}
                          alt={selectedCommunity.name}
                          style={{
                            marginRight: '15px',
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item>
                        <CircularProgress />
                      </Grid>
                    )}
                    <Grid item>
                      {allCommunities.length > 1 ? (
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedCommunity.communityId}
                            onChange={(val: any) => {
                              console.log(allCommunities, val.target.value);
                              dispatch(
                                setSelectedCommunity(
                                  allCommunities.find(
                                    (val2: CommunityModel) =>
                                      val2.communityId === val.target.value
                                  )
                                )
                              );
                            }}
                          >
                            {allCommunities &&
                              allCommunities.map((val: CommunityModel) => {
                                return (
                                  <MenuItem value={val.communityId}>
                                    {val.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      ) : (
                        <Typography variant="h6" fontSize={17}>
                          {selectedCommunity && selectedCommunity.name}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => {
                        logoutUser(navigate);
                      }}
                    >
                      <Logout />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sx={{
                  height: '100%',
                  paddingTop: 0,
                }}
                alignItems={'flex-start'}
              >
                {children}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <LoginScreen loginPage={false} />
      )}
    </>
  );
}
