import { CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { watchAuthState } from '../../../services/login_services';
import { useAppDispatch } from '../../../store/store';
import { Helmet } from 'react-helmet';
import { PageType, setCurrentPage } from '../../../store/slices/general.slice';
import { useSelector } from 'react-redux';
import {
  AdminCommunityUsersGrid,
  CommunityUsersGrid,
  UserGrid,
} from '../../components/community_admin/DataGrid';
import '../../../index.css';
import { getAllAdminUsers } from '../../../store/thunks/admin.thunks';

export function RouteBuddiesAdminUsers() {
  const [currentInfoSection, setCurrentInfoSection] = useState(1);
  const [loggedIn, setLoggedIn] = useState(false);
  const { currentPage } = useSelector((state: any) => state.GeneralSlice);
  const { selectedCommunity } = useSelector(
    (state: any) => state.EnterpriseSlice
  );

  const { requestList, requestListLoading, requestListError } = useSelector(
    (state: any) => state.EnterpriseRequestSlice
  );

  const { allUsers, allUsersLoading, allUsersError } = useSelector(
    (state: any) => state.AdminSlice
  );

  const [searchValue, setSearchValue] = useState('');

  const [filteredList, setFilteredList] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  useEffect(() => {
    watchAuthState(navigate, 'other', setLoggedIn);
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage(PageType.ADMIN_USERS));
  }, []);

  useEffect(() => {
    if (currentPage === PageType.ADMIN_USERS) {
      dispatch(getAllAdminUsers());
    }
  }, [currentPage]);

  return (
    <Grid container item xs={12}>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>

      <Grid
        container
        item
        xs={12}
        spacing={2}
        sx={{
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 2,
          flex: 1,
        }}
        alignItems={'center'}
      >
        <Grid
          item
          container
          xs={requestList && requestList.length > 0 ? 12 : 12}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
        >
          {!allUsers || allUsersLoading ? (
            <CircularProgress />
          ) : allUsers.length > 0 ? (
            <Grid item container>
              <Grid item container xs={12}>
                <Typography
                  variant="h6"
                  fontWeight={'bold'}
                  style={{
                    fontSize: 12,
                    color: 'rgba(0,0,0,0.7)',
                    paddingBottom: 2,
                  }}
                >
                  MEMBERS
                </Typography>
              </Grid>
              <AdminCommunityUsersGrid rows={allUsers} />
            </Grid>
          ) : (
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              spacing={2}
            >
              <Grid item>
                <img src={'/static/people.svg'} style={{ maxWidth: 200 }} />
              </Grid>
              <Grid item>
                <Typography style={{ textAlign: 'center', maxWidth: 380 }}>
                  Ready to build a safer community? Copy your invite link below
                  to send to members.
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
