import { createAsyncThunk, current } from '@reduxjs/toolkit';
import { CoalitionModel } from '../../models/CoalitionModel';
import { CommunityModel } from '../../models/CommunityModel';
import { JourneyAppModel, JourneyModel } from '../../models/GroupModel';
import UserDataModel from '../../models/UserDataModel';
import { db } from '../../services/firebase_services';
import { getCreatorFromJourney } from './community.thunks';

export const getAllCommunities = createAsyncThunk(
  'GetAllCommunities',
  async (): Promise<CommunityModel[]> => {
    let communities: CommunityModel[] = [];

    try {
      let activitiesDb = db.ref('/new_data/community_data/communities/');

      let activities: any = (await activitiesDb.once('value')).val();

      if (activities !== null) {
        communities = Object.values(activities);
      }
    } catch (error) {
      console.log(error);
    }
    return communities;
  }
);

export const getCommunityData = createAsyncThunk(
  'GetCommunityData',
  async ({ id }: { id: string }): Promise<CommunityModel | undefined> => {
    let comm = (
      await db.ref('/new_data/community_data/communities/').once('value')
    ).val();

    if (comm === null) {
      return;
    }
  }
);
export const getAllCoalitions = createAsyncThunk(
  'GetAllCoalitions',
  async (): Promise<CoalitionModel[]> => {
    let coalitions: CoalitionModel[] = [];

    try {
      let activitiesDb = db.ref('/new_data/coalition_data/coalitions/');

      let activities: any = (await activitiesDb.once('value')).val();

      if (activities !== null) {
        coalitions = Object.values(activities);
      }
    } catch (error) {
      console.log(error);
    }
    console.log('Returning coalitions: ', coalitions);

    return coalitions;
  }
);
export const getUpcomingAdminActivities = createAsyncThunk(
  'GetUpcomingAdminActivities',
  async (): Promise<JourneyAppModel[] | undefined> => {
    try {
      let activitiesDb = db.ref('/new_data/community_data/activities/');

      let activities: any = (await activitiesDb.once('value')).val();

      let community_activites: JourneyAppModel[] = [];

      if (activities !== null) {
        let list: JourneyModel[] = Object.values(activities);

        let creator_list: any[] = [];

        for (let i = 0; i < list.length; i++) {
          let inner_list = Object.values(list[i]);

          console.log('List inside length: ', inner_list.length);
          for (let j = 0; j < inner_list.length; j++) {
            let activity: JourneyModel = inner_list[j];

            let creator:
              | UserDataModel
              | CoalitionModel
              | CommunityModel
              | undefined = creator_list.find(
              val => val.id === activity.creatorId
            );

            if (!creator) {
              creator = await getCreatorFromJourney(activity);
              creator_list.push(creator);
            }

            console.log(creator);

            if (creator) {
              let activityToReturn: JourneyAppModel = {
                active: activity.active,
                communityId: activity.communityId,
                creationDate: activity.creationDate,
                groupId: activity.groupId,
                creator: creator,
                creatorType: activity.creatorType,
                origin: activity.origin,
                destination: activity.destination,
                journeyState: activity.journeyState,
                waypointOrder: activity.waypointOrder,
                title: activity.title,
                description: activity.description,
                date: activity.date,
              };
              if (i === list.length - 1 && j === inner_list.length - 1) {
                return [...community_activites, activityToReturn];
              } else {
                community_activites.push(activityToReturn);
              }
            }
          }
        }
      }
      return;
    } catch (err: any) {
      // Go to error page if error occurs
      // goToErrorPage(navigate);
    }
  }
);
export const getAllAdminActivities = createAsyncThunk(
  'GetAllAdminActivities',
  async (): Promise<JourneyAppModel[] | undefined> => {
    try {
      let activitiesDb = db.ref('/new_data/community_data/activities/');

      let activities: any = (await activitiesDb.once('value')).val();

      let community_activites: JourneyAppModel[] = [];

      if (activities !== null) {
        let list: JourneyModel[] = Object.values(activities);

        for (let i = 0; i < list.length; i++) {
          let inner_list = Object.values(list[i]);

          for (let j = 0; j < inner_list.length; j++) {
            let activity: JourneyModel = inner_list[j];

            let creator:
              | UserDataModel
              | CoalitionModel
              | CommunityModel
              | undefined = await getCreatorFromJourney(activity);

            if (creator) {
              let activityToReturn: JourneyAppModel = {
                communityId: activity.communityId,
                active: activity.active,
                creationDate: activity.creationDate,
                groupId: activity.groupId,
                creator: creator,
                creatorType: activity.creatorType,
                origin: activity.origin,
                destination: activity.destination,
                journeyState: {
                  ...activity.journeyState,
                  stages: activity.journeyState
                    ? Object.values(activity.journeyState?.stages)
                    : [],
                  currentIndex: activity.journeyState
                    ? activity.journeyState.currentIndex
                    : 0,
                },
                waypointOrder: activity.waypointOrder,
                title: activity.title,
                description: activity.description,
                date: activity.date,
              };

              if (i === list.length - 1 && j === inner_list.length - 1) {
                return [...community_activites, activityToReturn];
              } else {
                community_activites.push(activityToReturn);
              }
            }
          }
        }
      }
    } catch (err: any) {
      // Go to error page if error occurs
      // goToErrorPage(navigate);
    }
  }
);

export const getAllAdminUsers = createAsyncThunk(
  'GetAllAdminyUsers',
  async (): Promise<
    | {
        id: string;
        user: UserDataModel;
        community: CommunityModel;
        joinDate: string;
      }[]
    | undefined
  > => {
    try {
      let user_communities = db.ref(
        '/new_data/community_data/community_users/'
      );

      let users: any = await user_communities.once('value');

      let user_list: {
        id: string;
        user: UserDataModel;
        community: CommunityModel;
        joinDate: string;
      }[] = [];

      if (users !== null && users.val() !== null) {
        let data: any[] = Object.values(users.val());

        if (data.length) {
          // Loop through and collate data of
          for (let i = 0; i < data.length; i++) {
            let inner_list: any = Object.values(data[i]);
            let currentCommunityId = Object.keys(users.val())[i];

            let communityRef = db.ref(
              '/new_data/community_data/communities/' + currentCommunityId
            );

            let communityData = await (await communityRef.once('value')).val();

            if (communityData !== null) {
              for (let j = 0; j < inner_list.length; j++) {
                let obj: any = inner_list[j];

                if (obj.userId) {
                  let user = db.ref('/new_data/user_data/users/' + obj.userId);

                  let userObj = await user.once('value');

                  if (userObj && userObj.val() !== null) {
                    let objToAdd: any = {
                      id: userObj.val().userId,
                      ...userObj.val(),
                      joinDate: obj.joinDate,
                    };

                    if (j === inner_list.length - 1) {
                      return [
                        ...user_list,
                        {
                          id: objToAdd.id + '-' + communityData.communityId,
                          user: objToAdd,
                          community: communityData,
                          joinDate: obj.joinDate,
                        },
                      ];
                    } else {
                      user_list.push({
                        id: objToAdd.id + '-' + communityData.communityId,
                        user: objToAdd,
                        community: communityData,
                        joinDate: obj.joinDate,
                      });
                    }
                  }
                }
              }
            }
          }
        } else {
        }
      }

      return user_list;
    } catch (err: any) {
      // Go to error page if error occurs
      // goToErrorPage(navigate);
    }
  }
);
