import * as React from 'react';
import {
  Close,
  CloseRounded,
  DoneRounded,
  InfoRounded,
  KeyboardArrowDownRounded,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import {
  getEventListFromJourney,
  getJourney,
  getReportWarning,
  getUserListFromJourney,
  respondReport,
} from '../../services/report_services';
import moment from 'moment';
import { db } from '../../services/firebase_services';
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@mui/lab';
import * as turf from '@turf/turf';
import WarningCard from './WarningCard';
export default function ReportInfo({
  theme,
  selectedReport,
  setSelectedReport,
  mapboxgl,
}: any) {
  const styles = {
    reportTypePicker: {
      height: 'auto',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    reportTypePickerSelected: {
      fontSize: 15,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 15,
      paddingTop: 3,
      paddingBottom: 3,
      paddingRight: 6,
      paddingLeft: 6,
      color: 'white',
      borderColor: theme.palette.primary.main,
      marginTop: 5,
      marginRight: 5,
    },
    reportTypePickerUnselected: {
      fontSize: 15,
      backgroundColor: 'white',
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      borderRadius: 15,
      paddingTop: 3,
      paddingBottom: 3,
      paddingRight: 6,
      paddingLeft: 6,
      marginTop: 5,
      marginRight: 5,
    },
    reportSelectedText: {
      color: 'white',
      fontSize: 12,
    },
    reportUnselectedText: {
      color: theme.palette.primary.main,
    },
  };
  const [journey, setJourney] = useState<any>(null);
  const [eventList, setEventList] = useState<any>(null);
  const [userList, setUserList] = useState<any>([]);

  //Report response state
  const [reportResponseState, setReportResponseState] = useState<null | number>(
    null
  );
  const [reportResponseComment, setReportResponseComment] = useState('');
  //Map states
  const mapContainer = useRef<any>(null);
  const map = useRef<any>(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(15);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [warnings, setWarnings] = useState<any>(undefined);

  const mainPanelRef: any = useRef();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Get the warning for this report!
  useEffect(() => {
    let userId: any;
    if (selectedReport) {
      if (
        selectedReport.report.responseStage === 1 ||
        selectedReport.report.responseStage > 4
      ) {
        userId = selectedReport.report.userId;
      } else if (selectedReport.report.responseStage === 3) {
        userId = selectedReport.report.reportedUserId;
      }
      getReportWarning(selectedReport.report.reportId, userId, setWarnings);
    }
  }, [selectedReport]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    if (mapboxgl && journey) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [lng, lat],
        zoom: zoom,
      });

      //Add markers to map
      const popup = new mapboxgl.Popup()
        .setText('Meetup point')
        .addTo(map.current);

      new mapboxgl.Marker({ color: '#cd0438' })
        .setLngLat([journey.origin.lng, journey.origin.lat])
        .addTo(map.current)
        .setPopup(popup);

      let origin: any = null;
      let destination: any = null;
      for (let i = 0; i < journey.users.length; i++) {
        let journey_user = journey.users[i];

        //Set origin and destination
        if (i === 0) {
          origin = [journey.origin.lng, journey.origin.lat];
          destination = [
            journey_user.destination.lng,
            journey_user.destination.lat,
          ];
        } else {
          origin = destination;
          destination = [
            journey_user.destination.lng,
            journey_user.destination.lat,
          ];
        }

        //Journey index is normal counter
        let user = db.ref('/new_data/user_data/users/' + journey_user.userId);
        let user_db: any = {};
        //Will listen to the users table and update state automatically
        user.on('value', async function (userFound: any) {
          if (userFound !== null) {
            user_db = userFound.val();

            //Add markers to map
            const dest_popup = new mapboxgl.Popup()
              .setText(user_db.firstName + "'s destination")
              .addTo(map.current);

            new mapboxgl.Marker({ color: '#cd0438' })
              .setLngLat([
                journey_user.destination.lng,
                journey_user.destination.lat,
              ])
              .addTo(map.current)
              .setPopup(dest_popup);

            // A simple line from origin to destination.
            const route: any = {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'LineString',
                    coordinates: [origin, destination],
                  },
                },
              ],
            };

            // A single point that animates along the route.
            // Coordinates are initially set to origin.
            const point: any = {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: {},
                  geometry: {
                    type: 'Point',
                    coordinates: origin,
                  },
                },
              ],
            };

            // Calculate the distance in kilometers between route start/end point.

            const lineDistance = turf.distance(
              route.features[0].geometry.coordinates[0],
              route.features[0].geometry.coordinates[1],
              { units: 'kilometers' }
            );

            const arc = [];

            // Number of steps to use in the arc and animation, more steps means
            // a smoother arc and animation, but too many steps will result in a
            // low frame rate
            const steps = 500;

            // Draw an arc between the `origin` & `destination` of the two points
            for (let j = 0; j < lineDistance; j += lineDistance / steps) {
              const segment = turf.along(route.features[0], j);
              arc.push(segment.geometry.coordinates);
            }

            // Update the route with calculated arc coordinates
            route.features[0].geometry.coordinates = arc;

            // Used to increment the value of the point measurement against the route.
            let counter = 0;

            map.current.on('load', () => {
              // Add a source and layer displaying a point which will be animated in a circle.
              map.current.addSource('route' + i, {
                type: 'geojson',
                data: route,
              });

              map.current.addSource('point' + i, {
                type: 'geojson',
                data: point,
              });

              map.current.addLayer({
                id: 'route' + i,
                source: 'route' + i,
                type: 'line',
                paint: {
                  'line-width': 2,
                  'line-color': '#cd0438',
                },
              });

              map.current.addLayer({
                id: 'point' + i,
                source: 'point' + i,
                type: 'symbol',
                layout: {
                  // This icon is a part of the Mapbox Streets style.
                  // To view all images available in a Mapbox style, open
                  // the style in Mapbox Studio and click the "Images" tab.
                  // To add a new image to the style at runtime see
                  // https://docs.mapbox.com/mapbox-gl-js/example/add-image/
                  'icon-image': 'pitch-15',
                  'icon-rotate': ['get', 'rotate'],
                  'icon-rotation-alignment': 'map',
                  'icon-allow-overlap': true,
                  'icon-ignore-placement': true,
                },
              });

              let stops = journey.users.length + 1;

              function animate() {
                const start =
                  route.features[0].geometry.coordinates[
                    counter >= steps ? counter - 1 : counter
                  ];
                const end =
                  route.features[0].geometry.coordinates[
                    counter >= steps ? counter : counter + 1
                  ];
                if (!start || !end) return;

                // Update point geometry to a new position based on counter denoting
                // the index to access the arc
                point.features[0].geometry.coordinates =
                  route.features[0].geometry.coordinates[counter];

                // Calculate the bearing to ensure the icon is rotated to match the route arc
                // The bearing is calculated between the current point and the next point, except
                // at the end of the arc, which uses the previous point and the current point
                point.features[0].properties.bearing = turf.bearing(
                  turf.point(start),
                  turf.point(end)
                );

                // Update the source with this new data
                map.current.getSource('point' + i).setData(point);

                // Request the next frame of animation as long as the end has not been reached
                if (counter < steps) {
                  requestAnimationFrame(animate);
                  counter = counter + 1;
                } else {
                  counter = 0;

                  animate();
                }
              }

              // Set the coordinates of the original point back to origin
              point.features[0].geometry.coordinates = origin;

              // Update the source layer
              map.current.getSource('point' + i).setData(point);

              // Start the animation
              animate();
            });
          }
        });
      }
    }
  }, [mapboxgl, journey]);

  //Get current journey
  useEffect(() => {
    if (selectedReport.report.journeyId) {
      getJourney(setJourney, selectedReport.report.journeyId);
    }

    return function cleanup() {
      setJourney(null);
      setEventList(null);
      setUserList([]);
    };
  }, [selectedReport]);

  //Populate event list from journey
  useEffect(() => {
    if (journey) {
      getUserListFromJourney(journey, setUserList);
      getEventListFromJourney(journey, setEventList);
    }
  }, [journey]);

  //Populate event list from journey
  useEffect(() => {
    if (journey && map.current) {
      setLat(journey.origin.lat);
      setLng(journey.origin.lng);

      map.current.setCenter({
        lat: journey.origin.lat,
        lng: journey.origin.lng,
      });
    }
  }, [journey]);

  //On move
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ height: '80vh', overflowY: 'scroll' }}
      >
        {/* Close button */}
        <Grid
          container
          item
          xs={12}
          justifyContent={'flex-end'}
          style={{ height: '40px' }}
        >
          <IconButton
            aria-label="Close modal"
            component="span"
            onClick={() => setSelectedReport(null)}
          >
            <Close />
          </IconButton>
        </Grid>
        {/* Left image panel */}
        <Grid
          container
          item
          xs={12}
          md={6}
          flexDirection={'column'}
          alignContent={'center'}
        >
          <Typography variant={'h6'}>Timeline of events</Typography>
          {eventList === null ? (
            <CircularProgress color={'primary'} />
          ) : (
            <Timeline>
              {eventList.map((val: any, index: number) => {
                return (
                  <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                      {val.dateString}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      {index !== eventList.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>{val.value}</TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          )}
        </Grid>

        {/* If user has not clicked 'Buy ticket' show information */}
        <Grid item container xs={12} md={6} justifyContent={'center'}>
          <Box>
            <Grid container item xs={12} spacing={1}>
              {/* Right info panel */}
              <Grid item container xs={12}>
                <Typography variant={'h6'} fontWeight={'bolder'}>
                  Report against {selectedReport.reported_user.firstName}{' '}
                  {selectedReport.reported_user.lastName}
                </Typography>
              </Grid>

              <Grid container item xs={12} sm={4}>
                {/* 1 = no response */}
                {selectedReport.report.stage === 1 && (
                  <Typography
                    variant="body1"
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.light,
                      fontSize: 12,
                      padding: '2px 5px',
                      borderRadius: 10,
                    }}
                  >
                    Response Required
                  </Typography>
                )}
                {/* 2 = denied */}
                {selectedReport.report.stage === 2 && (
                  <Typography
                    variant="body1"
                    sx={
                      selectedReport.report.responseStage < 3
                        ? {
                            backgroundColor: '#00a80b',
                            color: theme.palette.primary.light,
                            fontSize: 12,
                            padding: '2px 5px',
                            borderRadius: 10,
                          }
                        : {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.light,
                            fontSize: 12,
                            padding: '2px 5px',
                            borderRadius: 10,
                          }
                    }
                  >
                    {selectedReport.report.responseStage < 3
                      ? 'Accepted'
                      : 'Declined'}
                  </Typography>
                )}
                {/* 3 = info */}
                {selectedReport.report.stage === 3 && (
                  <Typography
                    variant="body1"
                    sx={{
                      backgroundColor: '#0097a8',
                      color: theme.palette.primary.light,
                      fontSize: 12,
                      padding: '2px 5px',
                      borderRadius: 10,
                    }}
                  >
                    {selectedReport.report.responseStage === 5
                      ? 'Info Requested'
                      : 'Pics requested'}
                  </Typography>
                )}
              </Grid>

              <Grid
                container
                item
                xs={12}
                sm={8}
                spacing={2}
                alignItems={'center'}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.primary.dark,
                    fontSize: 12,
                  }}
                >
                  {moment(new Date(selectedReport.report.date)).format('LLLL')}
                </Typography>
              </Grid>

              <Grid container item xs={12} spacing={2}>
                <Grid container item sm={12} md={7} style={{ margin: 0 }}>
                  <Paper sx={{ width: '100%', padding: '15px' }}>
                    <Typography variant={'h6'} fontWeight={'bolder'}>
                      Report Information
                    </Typography>
                    <Typography variant={'body2'}>
                      "{selectedReport.report.description}"
                    </Typography>

                    <Box
                      style={{
                        height: 'auto',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                    >
                      {selectedReport.report.report_types.map((val: any) => {
                        return (
                          <Box style={styles.reportTypePickerSelected}>
                            <Typography
                              variant={'body2'}
                              style={styles.reportSelectedText}
                            >
                              {val === 1 && 'Sexual/Innappropriate Comments'}
                              {val === 2 && 'Violent'}
                              {val === 3 && 'Creepy'}
                              {val === 4 && 'Aggressive'}
                              {val === 5 && "Picture doesn't match"}
                              {val === 6 && 'Too drunk'}
                              {val === 7 && 'Fake/wrong address'}
                              {val === 8 && 'Other'}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Paper>
                </Grid>

                <Grid item sm={12} md={5} style={{ margin: 0 }}>
                  <Paper>
                    <List
                      sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        padding: '5px',
                      }}
                      component="div"
                      aria-labelledby="users"
                    >
                      {userList.map((val: any, index: number) => {
                        return (
                          <>
                            <ListItem
                              style={{ width: '100%', padding: '5px 10px' }}
                            >
                              <Avatar src={val.selfieImageUrl} />
                              <ListItemText
                                style={{ paddingLeft: 10 }}
                                primaryTypographyProps={{
                                  sx: { fontSize: 13 },
                                }}
                                secondaryTypographyProps={{
                                  sx: { fontSize: 11 },
                                }}
                                primary={`${val.firstName} ${val.lastName}`}
                                secondary={val.gender}
                              />
                            </ListItem>
                            {index !== userList.length - 1 && (
                              <Divider component="li" />
                            )}
                          </>
                        );
                      })}
                    </List>
                  </Paper>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <div ref={mapContainer} className="map-container" />
              </Grid>

              {selectedReport &&
                (selectedReport.report.stage === 1 ||
                  selectedReport.report.stage > 2) && (
                  <>
                    {reportResponseState === null &&
                      selectedReport.report.stage === 1 && (
                        <Grid
                          container
                          item
                          xs={12}
                          justifyContent="space-between"
                        >
                          <Typography>No response yet.</Typography>
                          <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            variant="outlined"
                          >
                            Respond
                            <KeyboardArrowDownRounded />
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'Respond to report',
                            }}
                          >
                            <MenuList
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: 5,
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  setReportResponseState(0);
                                }}
                              >
                                <ListItemIcon>
                                  <DoneRounded fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  Accept report & ban reported user.
                                </ListItemText>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setReportResponseState(1);
                                }}
                              >
                                <ListItemIcon>
                                  <DoneRounded fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  Accept report & send reported user a warning.
                                </ListItemText>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setReportResponseState(2);
                                }}
                              >
                                <ListItemIcon>
                                  <DoneRounded fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  Accept report & do nothing.
                                </ListItemText>
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                onClick={() => {
                                  setReportResponseState(3);
                                }}
                              >
                                <ListItemIcon>
                                  <CloseRounded fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  Decline report & send reporter a warning.
                                </ListItemText>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setReportResponseState(4);
                                }}
                              >
                                <ListItemIcon>
                                  <CloseRounded fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  Decline report & do nothing.
                                </ListItemText>
                              </MenuItem>
                              <>
                                <Divider />
                                <MenuItem
                                  onClick={() => {
                                    setReportResponseState(5);
                                  }}
                                >
                                  <ListItemIcon>
                                    <InfoRounded fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText>
                                    Request further info from reporter.
                                  </ListItemText>
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    setReportResponseState(6);
                                  }}
                                >
                                  <ListItemIcon>
                                    <InfoRounded fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText>
                                    Request a selfie + ID picture from reportee.
                                  </ListItemText>
                                </MenuItem>
                              </>
                            </MenuList>
                          </Menu>
                        </Grid>
                      )}

                    {reportResponseState !== null && (
                      <>
                        <Grid
                          container
                          item
                          xs={12}
                          justifyContent="space-between"
                        >
                          <Grid item xs={12} md={6}>
                            <Typography>
                              {reportResponseState === 0
                                ? 'Accept report & ban reported user?'
                                : reportResponseState === 1
                                ? 'Accept report & warn reported user?'
                                : reportResponseState === 2
                                ? 'Accept report & do nothing?'
                                : reportResponseState === 3
                                ? 'Decline report & warn reporting user?'
                                : reportResponseState === 4
                                ? 'Decline report & do nothing?'
                                : reportResponseState === 5
                                ? 'Request further information from reporting user?'
                                : reportResponseState === 6 &&
                                  'Request selfie and picture of ID from reported user?'}
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Button
                              onClick={() => {
                                setReportResponseState(null);
                              }}
                              variant="outlined"
                              style={{ fontSize: '12px', marginRight: 5 }}
                            >
                              Cancel
                              <CloseRounded style={{ marginLeft: 5 }} />
                            </Button>
                            <Button
                              onClick={() => {
                                respondReport(
                                  selectedReport.report,
                                  reportResponseState,
                                  reportResponseComment,
                                  setReportResponseState
                                );
                              }}
                              style={{
                                backgroundColor: 'green',
                                color: 'white',
                                fontSize: '12px',
                              }}
                            >
                              Confirm
                              <DoneRounded style={{ marginLeft: 5 }} />
                            </Button>
                          </Grid>
                          {(reportResponseState === 1 ||
                            reportResponseState === 3 ||
                            reportResponseState === 5 ||
                            reportResponseState === 6) && (
                            <Grid item xs={12}>
                              <TextField
                                color={'warning'}
                                multiline
                                label={'Warning Message'}
                                style={{
                                  width: '100%',
                                  height: '250px !important',
                                  marginTop: '10px',
                                }}
                                onChange={(val: any) => {
                                  setReportResponseComment(val.target.value);
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </>
                    )}
                  </>
                )}
              {selectedReport && selectedReport.report.stage === 2 && (
                <Grid container item xs={12} justifyContent="space-between">
                  <Typography>Report handled.</Typography>
                </Grid>
              )}
            </Grid>
          </Box>

          {warnings &&
            warnings.map((warning: any) => {
              return (
                <WarningCard
                  warning={warning}
                  theme={theme}
                  setReportResponseState={setReportResponseState}
                  mainPanelRef={mainPanelRef}
                  responseAvailable={selectedReport.report.response2Date}
                />
              );
            })}

          {selectedReport.report.response2Date && (
            <Grid
              container
              item
              xs={12}
              boxShadow={3}
              style={{
                borderRadius: 10,
                padding: '5px 10px',
                marginBottom: 10,
              }}
            >
              <Grid item xs={11}>
                <Typography variant={'h6'} fontStyle={{ fontSize: '14px' }}>
                  Final response:
                </Typography>
              </Grid>
              {selectedReport.report.response2Date && (
                <Grid container item xs={12} sm={8} alignItems={'center'}>
                  <Tooltip title={'Warning creation date'}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme.palette.primary.dark,
                        fontSize: 12,
                      }}
                    >
                      {moment(
                        new Date(selectedReport.report.response2Date)
                      ).format('LLLL')}
                    </Typography>
                  </Tooltip>
                </Grid>
              )}

              {selectedReport.report.response2Comment && (
                <Grid item xs={12}>
                  <Tooltip title={'Response message sent to user'}>
                    <Typography
                      variant={'body1'}
                      sx={{
                        fontSize: 11,
                        fontStyle: 'italic',
                      }}
                    >
                      "{selectedReport.report.response2Comment}"
                    </Typography>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
