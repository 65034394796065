import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { PageType } from '../../../store/slices/general.slice';
import { primaryColor } from '../../../assets/styles/styles';

interface NavButtonProps {
  buttonPage: PageType;
  page: PageType;
  onClick: Function;
  icon: any;
  hoveredIcon: any;
  text: string;
  notificationContent?: any;
}

export function NavigationButton({
  buttonPage,
  page,
  onClick,
  icon,
  hoveredIcon,
  text,
  notificationContent,
}: NavButtonProps) {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    setHovered(false);
  }, [page]);
  // /static/clients_white.png
  return (
    <Grid
      item
      container
      xs={12}
      justifyContent={'flex-end'}
      style={{ position: 'relative' }}
    >
      <Grid
        item
        container
        xs={11}
        style={{
          paddingLeft: 10,
          cursor: 'pointer',
          backgroundColor: buttonPage === page ? 'white' : 'transparent',
          borderTopLeftRadius: 30,
          borderBottomLeftRadius: 30,
          padding: 10,
          transition: '10',
        }}
        alignItems={'center'}
        onClick={() => {
          setHovered(false);
          onClick();
        }}
        onMouseOver={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          if (buttonPage !== page) {
            setHovered(false);
          }
        }}
      >
        <Grid item container xs={3} sm={2} justifyContent={'center'}>
          {hovered || buttonPage === page ? hoveredIcon : icon}
        </Grid>
        <Grid
          item
          container
          xs={notificationContent ? 6 : 9}
          sm={notificationContent ? 7 : 10}
          justifyContent={'flex-start'}
          style={{ paddingLeft: 10 }}
        >
          <Typography
            variant="h6"
            fontSize={18}
            style={{
              color: hovered || buttonPage === page ? primaryColor : 'white',
            }}
          >
            {text}
          </Typography>
        </Grid>

        {notificationContent && (
          <Grid
            item
            style={{
              padding: 1,
              height: '25px',
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 25,
              backgroundColor: primaryColor,
              position: 'absolute',
              alignItems: 'center',
              right: 40,
              display: 'flex',
            }}
          >
            <Typography
              variant="body1"
              style={{ color: 'white', fontWeight: '800', fontSize: 14 }}
            >
              {notificationContent}
            </Typography>
          </Grid>
        )}
      </Grid>
      {buttonPage === page && (
        <>
          <img
            src={'/static/nav_selected_top.png'}
            style={{
              width: 30,
              height: 30,
              position: 'absolute',
              right: 0,
              top: -30,
            }}
          />
          <img
            src={'/static/nav_selected_bottom.png'}
            style={{
              width: 30,
              height: 30,
              position: 'absolute',
              right: 0,
              bottom: -30,
            }}
          />
        </>
      )}
    </Grid>
  );
}
