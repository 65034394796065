import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

export const primaryColor = '#cd0438';
export const darkColor = '#575656';
export const blueColor = '#1573FE';

export const shadow = {
  shadowColor: 'rgba(0,0,0,0.5)',
  shadowOpacity: 1.0,
  elevation: 7,
  shadowRadius: 5,
};
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reportStatus: {
      backgroundColor: 'blue',
      color: theme.palette.primary.light,
      fontSize: 12,
      padding: '2px 5px',
    },
  })
);

export const styles = {
  largeRoundedBtn: {
    paddingTop: 25,
    paddingBottom: 25,
    borderRadius: 45,
    backgroundColor: primaryColor,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  profilePictureCreator: {
    height: 40,
    width: 40,
    borderRadius: 45,
    marginRight: 10,
  },
  profilePictureComments: {
    height: 30,
    width: 30,
    borderRadius: 45,
    marginRight: 10,
  },
  profilePicture: {
    width: 100,
    height: 100,
    borderRadius: 50,
    marginTop: 50,
    alignSelf: 'center',
  },
  navbar: {
    paddingTop: 30,
    height: '100vh',
    backgroundColor: '#101010',
  },
  sidePadding: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  modalBackdrop: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(0,0,0,0.6)',
    top: 0,
    left: 0,
    zIndex: 9,
  },
  modal: {
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 2,
    maxHeight: '95vh',
    overflowY: 'scroll',
    position: 'relative',
    zIndex: 10,
  },
  regPlate: {
    backgroundColor: '#FFDB1F',
    borderRadius: 5,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
};
