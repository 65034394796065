import { Grid } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import NavBar from '../components/NavBar';

export function AppTermsAndConditions({ theme }: any) {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  return (
    <Grid container item xs={12} justifyContent={'center'}>
      <Grid
        item
        xs={10}
        style={{
          marginTop: '10px',
        }}
      >
        <h2>1. Introduction</h2>
        <p>
          Welcome to RouteBuddies! Please carefully read these Terms and
          Conditions and End-User Licence Agreement (collectively referred to as
          "Agreement") before using our safety app.
        </p>

        <h2>2. Acceptance of Terms</h2>
        <p>
          By using the RouteBuddies app, you agree to comply with and be bound
          by the terms of this Agreement. If you do not agree, please do not use
          the app.
        </p>

        <h2>3. User Registration and Verification</h2>
        <p>
          a. To access certain features, you must register an account. You agree
          to provide accurate and complete information during the registration
          process.
        </p>
        <p>
          b. Identity Verification: Users can verify their identity by
          submitting their ID document and a selfie. RouteBuddies will perform a
          manual review, and images of ID documents are removed within 30 days.
        </p>

        <h2>4. User Behaviour and Responsibilities</h2>
        <p>
          a. User Conduct Guidelines: Users are expected to engage with others
          respectfully and courteously. Discrimination, harassment, bullying, or
          any form of harmful behaviour is strictly prohibited. There is no
          tolerance for objectionable content or abusive users. Violation of
          these guidelines may result in permanent account suspension.
        </p>
        <p>
          b. Community Moderation: RouteBuddies reserves the right to moderate
          and remove content that violates community guidelines. Users can
          report inappropriate content through the reporting mechanism within
          the app.
        </p>
        <p>
          c. Prohibited Content: Users are strictly prohibited from posting,
          sharing, or engaging in any activity that involves: Hate Speech:
          Content that promotes discrimination, hostility, or violence.
          Harassment and Bullying: Persistent, unwanted communication or
          actions. Explicit or Adult Content: Pornographic material or sexually
          explicit content. Illegal Activities: Promotion or glorification of
          illegal activities. Misinformation: Intentional dissemination of false
          or misleading information. Graphic Violence or Self-Harm: Content that
          depicts or encourages violence.
        </p>
        <p>
          d. Consequences of Violation: Violation of these guidelines may result
          in immediate corrective action, including permanent account
          termination.
        </p>

        <h2>5. Intellectual Property Rights</h2>
        <p>
          a. Ownership: All content, trademarks, logos, and intellectual
          property within the RouteBuddies app are the exclusive property of
          RouteBuddies. Any unauthorised use, reproduction, or distribution is
          strictly prohibited and may result in legal action.
        </p>
        <p>
          b. User-Generated Content Licence: By posting or submitting content on
          the app, users grant RouteBuddies a non-exclusive, royalty-free,
          worldwide licence to use, reproduce, modify, adapt, publish,
          translate, distribute, and display such content.
        </p>

        <h2>6. Payment Terms</h2>
        <p>a. RouteBuddies does not currently accept in-app payments.</p>

        <h2>7. Termination Conditions</h2>
        <p>a. Account Deletion: Users may delete their account at any time.</p>
        <p>
          b. Data Retention: RouteBuddies reserves the right to retain some data
          for security purposes even after account deletion. Users can request
          complete data deletion by contacting RouteBuddies support.
        </p>
        <p>
          c. Misuse Policy: RouteBuddies may terminate or suspend accounts for
          violations of this Agreement, including misuse of the app.
        </p>

        <h2>8. User Data and Privacy</h2>
        <p>
          a. Security Measures: RouteBuddies implements robust security
          measures, including encryption and access controls, to protect user
          data.
        </p>
        <p>
          b. Data Practices: Users' first name, last name, email address, and
          password are securely stored. Refer to the Privacy Policy for detailed
          information on data collection, processing, and storage.
        </p>

        <h2>9. Security Protocols</h2>
        <p>
          a. Firebase Security: The app employs Firebase security rules and
          Firebase Authentication for data security. Users are responsible for
          maintaining the confidentiality of their login credentials.
        </p>

        <h2>10. Physical Location QR Codes</h2>
        <p>
          a. Users can scan QR codes at physical locations where the app is in
          use. Such interactions are subject to the terms of this Agreement.
        </p>

        <h2>11. Misuse and Abuse</h2>
        <p>
          a. Prohibited Activities: Users are strictly prohibited from engaging
          in any activities that may constitute misuse or abuse of the
          RouteBuddies app. Prohibited activities include, but are not limited
          to: Attempts to gain unauthorised access to accounts, systems, or
          data. Distribution of malware, viruses, or any harmful software.
          Unauthorized attempts to disrupt or interfere with the app's
          functionality. Fraudulent activities, including identity theft or
          financial scams. Any use of the app for illegal or malicious purposes.
        </p>
        <p>
          b. Consequences of Misuse: RouteBuddies may take immediate corrective
          action, including but not limited to: Removal of offending content.
          Warnings issued to the user. Temporary suspension of account
          privileges. Permanent account termination.
        </p>
        <p>
          c. Indemnification: Users agree to indemnify and hold RouteBuddies
          harmless from any claims, damages, or liabilities arising out of their
          use of the app, participation in community activities, or interactions
          with other community members.
        </p>

        <h2>12. Limitation of Liability</h2>
        <p>
          a. Assumption of Risks: By using the RouteBuddies app and
          participating in community activities, you acknowledge and accept that
          community activities involve inherent risks and uncertainties.
        </p>
        <p>
          b. Personal Safety Measures: Users are responsible for their own
          safety during community activities. It is imperative to take necessary
          precautions to ensure safe navigation and adherence to local laws and
          regulations.
        </p>
        <p>
          c. Personal Information Sharing: RouteBuddies strongly advises against
          sharing personal information, such as home address or contact details,
          with unknown individuals within the app. Users should exercise caution
          and be mindful of the information they choose to disclose.
        </p>
        <p>
          d. Trip Planning Awareness: Before joining a trip with unfamiliar
          individuals, users should inform their loved ones of their plans.
          Additionally, users are encouraged to follow safety tips provided
          within the app, specifically designed to enhance personal safety
          during community activities.
        </p>
        <p>
          e. Community Interaction Risks: Users acknowledge that interactions
          with other community members, both online and offline, carry inherent
          risks. RouteBuddies does not conduct background checks on community
          members and cannot guarantee the intentions or actions of individuals.
        </p>
        <p>
          f. Precautionary Measures: Users are responsible for exercising due
          diligence when interacting with other community members. This includes
          but is not limited to verifying identities, using caution in sharing
          personal details, and reporting any suspicious behaviour to
          RouteBuddies.
        </p>
        <p>
          g. No Endorsement or Guarantee: RouteBuddies does not endorse or
          guarantee the safety, reliability, or accuracy of community
          activities, user-created content, or interactions between community
          members.
        </p>
        <p>
          h. Indemnification: Users agree to indemnify and hold RouteBuddies
          harmless from any claims, damages, or liabilities arising out of their
          use of the app, participation in community activities, or interactions
          with other community members.
        </p>
        <p>
          i. Acknowledgment of Terms: By continuing to use the RouteBuddies app,
          you acknowledge that you have read, understood, and agreed to the
          terms outlined in this Limitation of Liability section. If you do not
          agree with these terms, please discontinue the use of the app.
        </p>

        <h2>13. Dispute Resolution</h2>
        <p>
          a. Informal Resolution: In the event of any dispute, controversy, or
          claim arising out of or relating to this Agreement or the use of the
          RouteBuddies app, the parties agree to make a good faith effort to
          resolve the matter informally.
        </p>
        <p>
          b. Contacting Support: Users experiencing issues or disputes are
          encouraged to first contact RouteBuddies support at
          contact@routebuddies.com. RouteBuddies will make reasonable efforts to
          address and resolve the concerns promptly.
        </p>
        <p>
          c. Mediation: If an informal resolution is not successful, the parties
          agree to pursue mediation before resorting to formal legal
          proceedings.
        </p>
        <p>
          d. Selection of Mediator: The parties will mutually agree on a
          qualified and neutral third-party mediator. If an agreement cannot be
          reached on the choice of a mediator within 14 days, either party may
          request the appointment of a mediator by a competent authority.
        </p>
        <p>
          e. Mediation Process: Mediation will be conducted in accordance with
          the rules and procedures of a recognised mediation service. The costs
          of mediation, including the mediator's fees, will be shared equally by
          the parties unless otherwise agreed.
        </p>
        <p>
          f. Binding Nature: The parties agree that any resolution reached
          through mediation will be binding only if both parties agree to the
          terms. If no agreement is reached, either party may pursue legal
          action.
        </p>

        <h2>14. Governing Law and Jurisdiction</h2>
        <p>
          a. Choice of Law: This Agreement is governed by and construed in
          accordance with the laws of the United Kingdom.
        </p>
        <p>
          b. Exclusive Jurisdiction: Any legal actions or proceedings arising
          out of or related to this Agreement shall be brought exclusively in
          the competent courts of the United Kingdom.
        </p>

        <h2>15. Class Action Waiver</h2>
        <p>
          a. No Class Actions: Users agree that any disputes shall be resolved
          on an individual basis and not on a class, collective, or
          representative action basis. No arbitration or proceeding will be
          combined with another without the prior written consent of all parties
          to all affected arbitrations or proceedings.
        </p>

        <h2>16. User Conduct Guidelines</h2>
        <p>
          a. Unacceptable Behaviour: Users are expected to engage with others
          respectfully. Unacceptable behaviour includes but is not limited to
          discrimination, harassment, or any form of harmful conduct. Violation
          of these guidelines may result in permanent account suspension.
        </p>

        <h2>17. User Age Restrictions</h2>
        <p>
          a. Age Requirement: The RouteBuddies app is intended for users aged 18
          and above. Use by individuals under 18 is only permitted if provided
          by an educational establishment.
        </p>

        <h2>18. Modification of Terms</h2>
        <p>
          a. Modification Rights: RouteBuddies reserves the right to modify or
          update the terms of this Agreement. Users will be notified of changes,
          and continued use of the app constitutes acceptance of the modified
          terms.
        </p>

        <h2>19. Disclaimer of Warranties</h2>
        <p>
          a. As-Is Basis: The RouteBuddies app is provided "as is" without any
          warranties, express or implied. Users use the app at their own risk.
        </p>

        <h2>20. Complete Agreement Clause</h2>
        <p>
          a. Entire Understanding: This Agreement constitutes the entire
          understanding between the parties, superseding any prior agreements.
          No modification of this Agreement is valid unless in writing and
          signed by both parties.
        </p>

        <h2>21. Third-Party Services</h2>
        <p>
          a. Service Providers: RouteBuddies utilises third-party services from
          Google, Mapbox, and other companies to ensure the operational
          functionality of the app. Users are subject to the terms of these
          service providers when using their services within the app.
        </p>
      </Grid>
    </Grid>
  );
}
