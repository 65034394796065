import { CoalitionModel } from './CoalitionModel';
import { CommunityModel } from './CommunityModel';
import { CreatorType } from './PostModel';
import UserDataModel, { UserDataRestrictedModel } from './UserDataModel';

// Group model - For the group-up function
// DEPRACATED
export interface GroupModel {
  active: boolean;
  visible: boolean;
  creationDate: string;
  groupId: string;
  host: string;
  origin: LocationModel;
  destination: LocationModel;
  users?: GroupUserModel[];
  journeyState: JourneyStateModel;
  waypointOrder: any[];
  requests?: RequestModel[];
  date?: string;
}

export interface JourneyModel {
  active: boolean;
  creationDate: string;
  groupId: string;
  creatorId: string;
  creatorType: CreatorEnum;
  origin: LocationModel;
  destination: LocationModel;
  date: string;
  title: string;
  users?: JourneyUserModel[] | any;
  journeyState?: JourneyStateModel;
  waypointOrder?: any[];
  description: string;
  reoccurunces?: ReoccurenceModel[][];
  repeatData?: RepeatDataModel;
  repeatId?: string;
  communityId: string;
}

// ID = YYYY-MM-DD/HH-MM FORMAT - Can be split up into both.
export interface ReoccurenceModel {
  id: string;
  users?: JourneyUserModel[];
  journeyState: JourneyStateModel;
}
export interface ReoccurenceAppModel {
  id: string;
  users?: JourneyUserModel[];
  journeyState?: JourneyStateModel;
  waypointOrder?: any[];
}
export interface RepeatDataModel {
  repeatValue: number;
  repeatAllDay: boolean;
  repeatDays: number[];
  repeatEndDate?: string;
  repeatStartTime?: string;
  repeatEndTime?: string;
}

export enum CreatorEnum {
  COMMUNITY = 'COMMUNITY',
  COALITION = 'COALITION',
  USER = 'USER',
}

export interface JourneyAppModel {
  active: boolean;
  creationDate: string;
  groupId: string;
  creator: UserDataModel | CommunityModel | CoalitionModel;
  creatorType: CreatorEnum;
  origin: LocationModel;
  destination: LocationModel;
  users?: JourneyUserAppModel[];
  journeyState?: JourneyStateModel;
  waypointOrder?: any[];
  reoccurunces?: ReoccurenceModel[][];
  repeatData?: RepeatDataModel;
  date: string;
  title: string;
  description: string;
  repeatId?: string;
  communityId: string;
}

export interface JourneyUserModel {
  userId: string;
  leaveTime?: string;
  finishTime?: string;
  joinTime?: string;
  kickTime?: string;
  arrivals?: ArrivalModel[] | undefined;
  destination?: { lat: number; lng: number };
  address?: string;
}

export interface JourneyUserAppModel {
  user: UserDataModel;
  id: string;
  leaveTime?: string;
  finishTime?: string;
  joinTime?: string;
  readyTime?: string;
  kickTime?: string;
  arrivals?: ArrivalModel[] | undefined;
  destination?: { lat: number; lng: number };
  address?: string;
}

// Previous group model - Used on Journeys to display history of a group
export interface PreviousGroupModel {
  active: boolean;
  visible: boolean;
  creationDate: string;
  groupId: string;
  host: string;
  origin: LocationModel;
  destination: LocationModel;
  users: GroupUserModel[];
  journeyState: JourneyStateModel;
  waypointOrder: any[];
  requests?: RequestModel[];
  images?: string[];
  duration?: string;
  distance?: string;
  name?: string;
}

// Request model - Used when a user tries to request entry to a group.
export interface RequestModel {
  dateTime: string;
  userId: string;
  status: StatusType;
  address?: string;
  responseDateTime?: string;
  destination: { lat: number; lng: number };
}

// Status type - Used for group request status.
export enum StatusType {
  ACCEPTED = 'ACCEPTED',
  DENIED = 'DENIED',
  NEEDS_RESPONSE = 'NEEDS_RESPONSE',
  CANCELLED = 'CANCELLED',
}

// Journey state - Used in GroupModel
export interface JourneyStateModel {
  currentIndex: number;
  stages: StageModel[];
}

// Stage model - Used in JourneyStateModel
export interface StageModel {
  dateTime: string;
  stage: string;
}

// Group user model - Used within GroupModel, holding user information.
export interface GroupUserModel {
  destination: { lat: number; lng: number };
  leaveTime?: string;
  finishTime?: string;
  joinTime: string;
  kick?: { kickTime: string; kickSeen: boolean };
  arrivals?: ArrivalModel[] | undefined;
  departureTime?: string;
  user?: UserDataRestrictedModel;
  userId: string;
  address?: string;
}

// Origin model - Used to hold origin information
export interface LocationModel {
  address: string;
  arrivals?: ArrivalModel[];
  lat: number;
  lng: number;
}

// Arrival model - Used to store information about an arrival of a member on the origin or a user's destination
export interface ArrivalModel {
  dateTime: string;
  readyTime?: { dateTime: string };
  userId: string;
}

export interface UserActivityReferenceModel {
  activityId: string;
  creatorId: string;
  creatorType: CreatorEnum;
}
