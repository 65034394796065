import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserDataModel from '../../models/UserDataModel';
import { PostAppModel } from '../../models/PostModel';
import { getCommunityAdminStatus } from '../thunks/general.thunks';
import {
  getCommunityUsers,
  getUserById,
  getUserJourneysForCommunity,
  isUserCommunityAdmin,
} from '../thunks/enterprise.users.thunks';

/**
 * modalHeight - Height of the information modal being shown during a journey - Used to display properly.
 * reportUser - Data of a user from a group selected to be reported.
 * page - The current page a user is on - PageType.HOME - PageType.JOURNEYS etc.
 */
interface IProps {
  userList: UserDataModel[];
  userListLoading: boolean;
  userListError: boolean;
  selectedUser: UserDataModel | undefined;
  selectedUserAdmin: boolean;
  selectedUserJourneys: any[];
  deleteUser: undefined | UserDataModel;
}
const enterpriseInitialState: IProps = {
  userList: [],
  userListError: false,
  userListLoading: false,
  selectedUser: undefined,
  selectedUserAdmin: false,
  selectedUserJourneys: [],
  deleteUser: undefined,
};

const enterpriseSlice = createSlice({
  name: 'enterprise_user_data',
  initialState: enterpriseInitialState,
  reducers: {
    // Setters
    setUserList: (
      state: IProps,
      { payload }: PayloadAction<UserDataModel[]>
    ) => {
      state.userList = payload;
    },
    setSelectedUser: (
      state: IProps,
      { payload }: PayloadAction<UserDataModel | undefined>
    ) => {
      state.selectedUser = payload;
    },
    setSelectedUserAdmin: (
      state: IProps,
      { payload }: PayloadAction<boolean>
    ) => {
      state.selectedUserAdmin = payload;
    },
    setDeleteUser: (
      state: IProps,
      { payload }: PayloadAction<undefined | UserDataModel>
    ) => {
      state.deleteUser = payload;
    },
  },

  extraReducers: (builder: any) => {
    builder.addCase(
      getCommunityAdminStatus.pending,
      (state: IProps, { payload }: PayloadAction<boolean>) => {
        state.userListLoading = true;
        state.userListError = false;
      }
    );
    builder.addCase(
      getCommunityAdminStatus.rejected,
      (state: IProps, { payload }: PayloadAction<boolean>) => {
        state.userListError = true;
        state.userListLoading = false;
      }
    );
    builder.addCase(
      getCommunityUsers.fulfilled,
      (
        state: IProps,
        { payload }: PayloadAction<UserDataModel[] | undefined>
      ) => {
        if (payload && payload !== null) {
          state.userList = payload;
        }

        state.userListError = false;
        state.userListLoading = false;
      }
    );
    builder.addCase(
      getUserById.fulfilled,
      (
        state: IProps,
        { payload }: PayloadAction<UserDataModel | undefined>
      ) => {
        if (payload && payload !== null) {
          state.selectedUser = payload;
        }
      }
    );

    builder.addCase(
      isUserCommunityAdmin.fulfilled,
      (state: IProps, { payload }: PayloadAction<boolean>) => {
        if (payload && payload !== null) {
          state.selectedUserAdmin = payload;
        }
      }
    );
    builder.addCase(
      getUserJourneysForCommunity.fulfilled,
      (
        state: IProps,
        { payload }: PayloadAction<PostAppModel[] | undefined>
      ) => {
        if (payload && payload !== null) {
          state.selectedUserJourneys = payload;
        }
      }
    );
  },
});

export const {
  setUserList,
  setSelectedUser,
  setSelectedUserAdmin,
  setDeleteUser,
} = enterpriseSlice.actions;

export default enterpriseSlice.reducer;
