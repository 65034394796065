import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { primaryColor } from '../../assets/styles/styles';
import { Grid } from '@mui/material';
import { RoundedButton } from './RoundedButton';

export default function FAQVSNI() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Grid item container style={{ backgroundColor: primaryColor }}>
      <Container
        id="faq"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 8 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          color="white"
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          Frequently asked questions
        </Typography>
        <Box sx={{ width: '100%' }}>
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            style={{
              backgroundColor: 'transparent',
              border: '3px solid white',
              marginBottom: 6,
              borderRadius: 10,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography
                component="h3"
                variant="subtitle2"
                style={{
                  color: 'white',
                  fontFamily: 'Urbanist',
                  fontWeight: 'bolder',
                }}
              >
                How do I contact customer support if I have a question or issue?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' }, color: 'white' }}
              >
                You can reach our customer support team by emailing{' '}
                <Link style={{ color: 'white' }}>contact@routebuddies.com</Link>{' '}
                - We&apos;re here to assist you promptly.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
            style={{
              backgroundColor: 'transparent',
              border: '3px solid white',
              marginBottom: 10,
              borderRadius: 10,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography
                component="h3"
                variant="subtitle2"
                style={{
                  color: 'white',
                  fontFamily: 'Urbanist',
                  fontWeight: 'bold',
                }}
              >
                Is the VSNI available everywhere?{' '}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' }, color: 'white' }}
              >
                Currently, the VSNI operates in Cardiff as it's starting ground.
                But we look forward to bringing communities to every corner of
                the world! If you're not in Cardiff, you can still let us know
                you're interested in being a pioneer of your city.
              </Typography>{' '}
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' }, color: 'white' }}
              >
                We're always on the look out for passionate individuals in the
                Night Time Economy to help fuel our mission of a safer
                environment for staff!
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
            style={{
              backgroundColor: 'transparent',
              border: '3px solid white',
              marginBottom: 10,
              borderRadius: 10,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography
                component="h3"
                variant="subtitle2"
                style={{
                  color: 'white',
                  fontFamily: 'Urbanist',
                  fontWeight: 'bold',
                }}
              >
                What makes your product stand out from others in the market?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' }, color: 'white' }}
              >
                RouteBuddies is more than just a safety app - It's a collection
                of individuals who are dedicated to keeping one-another safe.
                <br />
                <br />
                Where we see community - We see potential - It's why we're
                dedicated to building amazing tools to bring safety in numbers
                to the world. <br />
                <br />
                By joining RouteBuddies, you're not just using a tool - You're
                becoming a part of the solution.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}
            style={{
              backgroundColor: 'transparent',
              border: '3px solid white',
              marginBottom: 10,
              borderRadius: 10,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography
                component="h3"
                variant="subtitle2"
                style={{
                  color: 'white',
                  fontFamily: 'Urbanist',
                  fontWeight: 'bold',
                }}
              >
                Are members guaranteed support?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' }, color: 'white' }}
              >
                RouteBuddies supports users at all levels to ensure help is
                always available - From immediate SOS requests between community
                members, up to instant messaging to UK Police through the app
                for support in extreme situations.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel5'}
            onChange={handleChange('panel5')}
            style={{
              backgroundColor: 'transparent',
              border: '3px solid white',
              borderRadius: 10,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
              aria-controls="panel4d-content"
              id="panel5d-header"
            >
              <Typography
                component="h3"
                variant="subtitle2"
                style={{
                  color: 'white',
                  fontFamily: 'Urbanist',
                  fontWeight: 'bold',
                }}
              >
                How is it kept secure?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' }, color: 'white' }}
              >
                We keep our system secure by manually verifying each & every
                business thoroughly before joining the safety net. Our strict
                usage policies, and mandatory education sessions are in place to
                ensure only vetted and trusted staff members are added to the
                community.
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' }, color: 'white' }}
              >
                It's a one strike policy at RouteBuddies. We take the security
                of our platform immensely seriously, and trust in verified
                business owners to manage their community members appropriately
                in the face of any event: e.g. Bad leavers, interpersonal work
                drama.
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' }, color: 'white' }}
              >
                If any members break any of our Terms & Conditions / Privacy
                Policy / EULA - The organisation whom originally had allowed the
                member access to the community shall be liable.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Grid item container justifyContent={'center'}>
          <RoundedButton
            defaultColor={primaryColor}
            defaultBackgroundColor={'white'}
            hoverColor={'white'}
            hoverBackgroundColor={primaryColor}
            onClick={() => {
              const sectionElement = document.getElementById('signup');
              const offset = 128;
              if (sectionElement) {
                const targetScroll = sectionElement.offsetTop - offset;
                sectionElement.scrollIntoView({ behavior: 'smooth' });
                window.scrollTo({
                  top: targetScroll,
                  behavior: 'smooth',
                });
              }
            }}
            style={{ marginTop: 10 }}
            width={190}
          >
            Ready to join?
          </RoundedButton>
          {/* Just for the update of site */}
        </Grid>
      </Container>
    </Grid>
  );
}
