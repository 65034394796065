import { createAsyncThunk } from '@reduxjs/toolkit';
import UserDataModel from '../../models/UserDataModel';
import { db } from '../../services/firebase_services';
import { EventAppModel, EventModel } from '../../models/EventModel';

// Get user data function lets us retreieve the user data (For logged in user)using a user ID.
export const getCommunityEvents = createAsyncThunk(
  'GetCommunityEvents',
  async ({ id }: any): Promise<EventAppModel[] | undefined> => {
    try {
      let user_events = db.ref('/new_data/community_data/events/' + id);

      let events: any = (await user_events.once('value')).val();

      let event_list: EventAppModel[] = [];

      if (events !== null) {
        console.log('Got events...');
        let data: any[] = Object.values(events);

        if (data.length) {
          // Loop through and collate data of

          for (let i = 0; i < data.length; i++) {
            let obj: EventModel = data[i];

            console.log('Obj? ', obj);
            if (obj.userId) {
              let user = db.ref('/new_data/user_data/users/' + obj.userId);

              let userObj = await user.once('value');

              if (userObj && userObj.val() !== null) {
                let event: EventAppModel = { ...obj, user: userObj.val() };

                console.log('Got event... Pushing!');
                event_list.push(event);
              }
            }
          }
        } else {
        }
      }

      console.log('Returning now: ', event_list);
      return event_list;
    } catch (err: any) {
      // Go to error page if error occurs
      // goToErrorPage(navigate);
    }
  }
);
