import { Grid } from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import NavBar from '../components/NavBar';

export function TermsAndConditions({ theme }: any) {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  return (
    <Grid container item xs={12} justifyContent={'center'}>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>
      <NavBar theme={theme} navigate={navigate} loggedIn={loggedIn} />

      <Grid
        item
        xs={10}
        style={{
          marginTop: '118px',
        }}
      >
        <div>
          <header>
            <h1>Terms and Conditions</h1>
          </header>

          <div>
            <span>Effective from 30 September 2022</span>
            <br />
            <br />
            <h3>CUSTOMER TERMS</h3>
            <br />
            <span>By clicking on the “</span>
            <b>Accept and Proceed</b>
            <span>
              ” button, you acknowledge that you have read these customer terms
              (“
            </span>
            <b>Customer Terms</b>
            <span>”) and agree to be bound by them. </span>
            <br />
            <br />
            <span>
              Please ensure that you have read all of these Customer Terms
              before you start using the Application, as you will be bound by
              these Customer Terms when you click “
            </span>
            <b>Accept and Proceed</b>
            <span>”. </span> <br />
            <br />
            <span>
              If you do not agree with any of these Customer Terms, you must
              immediately cease accessing and using the application and the
              services being provided under these Customer Terms. Your
              acceptance of these Customer Terms will operate as a binding
              agreement between you and RouteBuddies in respect of your use of
              the Application, the Booking Services and Services (each as
              defined below) and you continue to remain bound by these Customer
              Terms as long as you continue to use the Application, the Booking
              Services and/or the Services.
            </span>
            <br />
            <br />
            <h3>PARTIES</h3>
            <br />
            <span>
              These Customer Terms are between RouteBuddies UK Private Limited
              and You (each a “
            </span>
            <b>Party</b>
            <span>” and collectively the “</span>
            <b>Parties</b>
            <span>”). </span> <br />
            <br />
            <h3>BACKGROUND</h3>
            <br />
            <span>
              The Application contains booking functionality which enables You
              to book Journeys and other Transport Services from RouteBuddies
              UK.
            </span>
            <br />
            <br />
            <span>
              RouteBuddies UK provides: (i) Booking Services to enable You to
              book Journeys on the Application and (ii) Transport Services.
            </span>
            <br />
            <br />
            <span>
              These Customer Terms apply to Your access to, and use of, the
              Application (whether through a mobile phone or other electronic
              device), the Website, the Services and all information,
              recommendations and other products and services provided to You on
              or through the Application.
            </span>
            <br />
            <br />
            <h3>THE PARTIES AGREE AS FOLLOWS</h3>
            <br />
            <ol>
              <li>
                <h3>DEFINITIONS AND INTERPRETATION</h3>
              </li>
            </ol>
            <span>
              Unless the context requires otherwise, capitalised terms in these
              Customer Terms have the following meaning:
            </span>
            <br />
            <span>“</span>
            <b>Account</b>
            <span>” means the account created by You on the Application. </span>
            <br />
            <span>“</span>
            <b>Additional Costs</b>
            <span>” has the meaning given to that term in clause 6.2. </span>
            <br />
            <span>“</span>
            <b>Additional Fee</b>
            <span>
              ” means any toll, duty, taxes, levies or similar fees or charges
              that are not included in the Fare but are (a) incurred during a
              Journey to any third party (including government agencies and
              regulatory authorities) under any Applicable Laws in connection
              with the Journey; and (b) are of a type approved by RouteBuddies.
            </span>
            &ZeroWidthSpace;&ZeroWidthSpace;
            <br />
            <span>“</span>
            <b>Affiliate</b>
            <span>
              ” means, in relation to any entity, another company or entity that
              either directly or indirectly, through one or more intermediaries,
              Controls, is Controlled by, or is under common Control with that
              entity. For purposes of this definition, “Control” means, with
              regard to any company or entity, (i) the legal or beneficial
              ownership, directly or indirectly, of 50% or more of the shares
              (or other ownership interests if not a corporation) of the entity
              or company through voting rights or through the exercise of rights
              pursuant to an agreement; or (ii) the possession, directly or
              indirectly, of the power to direct or cause the direction of the
              management or policies of the entity or company.
            </span>
            <br />
            <span>“</span>
            <b>Agent</b>
            <span>
              ” means any independent third party who is authorised to carry out
              the Services on behalf of RouteBuddies.
            </span>
            <br />
            <b>“Applicable Laws” </b>
            <span>
              means (in any relevant jurisdiction) all laws, including rules of
              common law, principles of equity, statutes, regulations,
              proclamations, ordinances, by-laws, rules, statutory rules of an
              industry body, statutory and mandatory codes or guidelines or the
              like, writs, orders, injunctions, judgments applicable from time
              to time.
            </span>
            <br />
            <b>“Application” </b>
            <span>means the </span>
            <b>“RouteBuddies” </b>
            <span>
              software application and programmes provided by RouteBuddies to
              You subject to these Customer Terms.
            </span>
            <br />
            <b>“Booking Confirmation” </b>
            <span>
              means the booking confirmation provided by RouteBuddies UK to You
              when a Group has been found for Your Booking Services Request in
              accordance with clause 4.5(ii) and as further specified in clause
              5.
            </span>
            <br />
            <b>“Booking Services” </b>
            <span>
              shall mean such services provided by RouteBuddies UK which allows
              You to select and book Journeys for Yourself on the Application
              and which are more fully described under clause 4 of these
              Customer Terms.{' '}
            </span>
            <br />
            <b>“Booking Services Request” </b>
            <span>has the meaning set out in clause 4.1. </span>
            <br />
            <span>“</span>
            <b>Business Day</b>
            <span>
              ” means any day excluding a Saturday, Sunday or public holiday in
              the jurisdiction where You receive Transport Services from
              RouteBuddies.
            </span>
            <br />
            <b>“Cancellation Fee” </b>
            <span>
              means the amount payable by You as a result of You cancelling a
              Journey in accordance with clause 7, notified to You on the
              Application, or as updated here from time to time.
            </span>
            <br />
            <b>“Card Details” </b>
            <span>has the meaning set out in clause 6.4. </span>
            <br />
            <b>“Device” </b>
            <span>has the meaning set out in clause 3.1(iii). </span>
            <br />
            <b>“Group” </b>
            <span>
              means the individual licensed Group (who may be an Agent) that
              provides taxis, private hire Groups, rideshare Groups and the
              like, and that operates the Group for the performance of Journeys.
            </span>
            <br />
            <b>“Fare” </b>
            <span>
              means the amount specified on the Application payable for the
              specific Journey provided to You.{' '}
            </span>
            <b>“Force Majeure” </b>
            <span>has the meaning set out in clause 15.1. </span>
            <br />
            <b>“Indemnified Parties” </b>
            <span>has the meaning set out in clause 13.1. </span>
            <br />
            <b>“RouteBuddies IP” </b>
            <span>has the meaning set out in clause 12.1.3. </span>
            <br />
            <b>“RouteBuddies” </b>
            <span>has the meaning given in the </span>
            <b>“Parties” </b>
            <span>section on the front page of these Customer Terms. </span>
            <br />
            <b>“RouteBuddies UK” </b>
            <span>has the meaning given in the </span>
            <b>“Parties” </b>
            <span>section on the front page of these Customer Terms. </span>
            <br />
            <b>“Payment Processor” </b>
            <span>has the meaning set out in clause 6.3. </span>
            <br />
            <b>“Permitted Information” </b>
            <span>has the meaning set out in clause 12.6. </span>
            <br />
            <b>“Privacy Policy” </b>
            <span>
              means the privacy policy available on the Application or on{' '}
            </span>
            <span>the Privacy Policy</span>
            <span>
              , as amended by RouteBuddies from time to time.
              <br />
              <b>“Promo Code” </b>
              <span>has the meaning set out in clause 4.9. </span>
              <br />
              <b>“Registration Data” </b>
              <span>
                means Your name, email address, telephone number and other
                information (including personal data) and any other eligibility
                data communicated from time to time, that You provide to
                RouteBuddies for registering on the Application and for availing
                of the Services.{' '}
              </span>
              <br />
              <b>“Journey” </b>
              <span>
                means the travel in the Group arranged for You using the Booking
                Services.{' '}
              </span>
              <b>“Services” </b>
              <span>means: </span>
              <br />
              <ol>
                <span>(i) the Booking Services; </span>
                <br />
                <span>
                  (ii) the provision of the Application, which facilitates the
                  Booking Services and other products and services accessed
                  through the Application or via an offline mode including SMS,
                  in the jurisdiction where You receive the Transport Services;
                  and
                </span>
                <br />
                <span>(iii) the Transport Services. </span>
              </ol>
              <br />
              <b>“Total Service Fee” </b>
              <span>
                means the Fare, any Cancellation Fee and/or any Additional Fee
                (including peak prices and/or tolls payable to third parties (if
                applicable)) specified on the Application.
              </span>
              <br />
              <b>“Transport Services” </b>
              <span>
                means the transportation services provided to You by
                RouteBuddies UK via Journeys.{' '}
              </span>
              <b>“VAT Law” </b>
              <span>has the meaning given in clause 8.1. </span>
              <br />
              <b>“Group” </b>
              <span>
                means a motor Group or a private hire Group as recognised under
                the Private Hire Groups (London) Act 1998 operated by the Group,
                for the carriage of passengers.
              </span>
              <br />
              <b>“Website” </b>
              <span>means the webpage accessible at </span>
              <span>www.RouteBuddies.co.uk</span>
              <span>. </span>
              <br />
              <b>“You / Your” </b>
              <span>
                means you, the user of the Application, the Services and the
                Booking Services.{' '}
              </span>
              <b>“Your Content” </b>
              <span>has the meaning given in clause 12.4. </span>
              <br />
              <br />
              <ol>
                <li>
                  <h3>INTERPRETATION</h3>
                </li>
              </ol>
              <span>
                Unless the context requires otherwise, in these Customer Terms:
              </span>
              <br />
              <ol>
                <span>
                  (i) the singular includes the plural and vice versa;{' '}
                </span>
                <br />
                <span>
                  (ii) the words ‘such as’, ‘for example’, ‘including’,
                  ‘particularly’ and similar expressions are not used as, nor
                  are intended to be, interpreted as words of limitation;
                </span>
                <br />
                <span>(iii) a reference to: </span>
                <br />
                <ol>
                  <span>
                    (a) a person includes an individual, partnership, joint
                    venture, trust, corporation or any other entity or
                    association whether or not it is incorporated or has a
                    separate legal identity;{' '}
                  </span>
                  <br />

                  <span>
                    (b) a Party includes that Party’s executors, administrators,
                    successors, and permitted substitutes and assigns, including
                    any person taking by way of novation;
                  </span>
                  <br />
                  <span>(c) </span>
                  <b>“pounds” </b>
                  <span>or </span>
                  <b>“£” </b>
                  <span>is to the lawful currency of the United Kingdom; </span>
                  <br />
                  <span>
                    (d) anything (including a right, obligation or concept)
                    includes each part of it;
                  </span>
                  <br />
                </ol>
                <span>
                  (iv) in determining the time of day, the relevant time of day
                  is the time in the jurisdiction where You obtain a Journey
                  from a Group;
                </span>
                <br />
                <span>
                  (v) if a period of time is calculated from a particular day,
                  act or event (such as the giving of a notice), it is to be
                  calculated exclusive of that day, or the day of that act or
                  event; and
                  <br />
                  <span>
                    (vi) no rule of construction applies to the disadvantage of
                    a Party because that Party was responsible for the
                    preparation of these Customer Terms or any part of them.
                  </span>
                </span>
              </ol>
              <br />
              <br />
              <ol>
                <li>
                  <h3>REGISTRATION AND USAGE</h3>
                </li>
              </ol>
              <span>
                3.1. In order to receive the Transport Services, and use the
                Booking Services and the Application, You must:
              </span>
              <br />
              <ol>
                <span>
                  (i) be competent to enter into a contract under Applicable
                  Laws, and You must provide RouteBuddies with accurate,
                  complete, current, valid and true Registration Data;
                </span>
                <br />
                <span>
                  (ii) only open one Account using Your Registration Data, and
                  not use the account of any other person;
                </span>
                <br />
                <span>
                  (iii) download the Application onto Your own electronic
                  device, which must have a functioning mobile number, data
                  network and the ability to read text messages (SMS) and push
                  notifications from the Application, and meet the minimum
                  device requirements for optimum functionality of the
                  Application (“
                </span>
                <br />
                <span>
                  (iv) You must provide RouteBuddies with an image of a valid
                  identification document belonging to You, a selfie belonging
                  to you, and valid and true Registration Data. RouteBuddies
                  must verify the legitimacy of identification so security may
                  be maintained for every user.
                </span>
                <b>Device</b>
                <span>
                  ”). It is Your responsibility to check to ensure that You
                  download the correct version of the Application for Your
                  Device;
                </span>
                <br />
                <span>
                  (iv) only use the Application solely in accordance with these
                  Customer Terms and all Applicable Laws, and not use the
                  Services for any illegal or unlawful purposes;
                </span>
                <br />
                <span>
                  (v) only use the Services for Your sole, personal use, and not
                  transfer, sell, sub-license or assign them to a third party.
                  In the event that You request the Transport Services or use
                  the Booking Services for another individual, You shall be
                  solely liable for any breach of these Customer Terms by such
                  other individual;{' '}
                </span>
                <br />

                <span>
                  (vi) not allow persons under the age of legal majority to
                  receive the Transport Services;{' '}
                </span>
                <br />

                <span>
                  (vii) not do or try to do anything to interfere with or harm
                  the Application, the Booking Services, the Transport Services
                  or the network of RouteBuddies or any of its Affiliates in any
                  way whatsoever;{' '}
                </span>
                <br />

                <span>
                  (viii) provide such information and documents which
                  RouteBuddies and/or its Affiliates may reasonably request from
                  time to time, and promptly notify RouteBuddies UK and/or its
                  Affiliates (as applicable) of any change to any of Your
                  Registration Data or other information provided; and{' '}
                </span>
                <br />

                <span>
                  (ix) only use an authorised telephone or internet network to
                  access and use the Application.{' '}
                </span>
                <br />
              </ol>
              <span>
                3.2. When using the Services, standard messaging charges, data
                charges and/or voice charges (as applicable) may be imposed by
                Your Device provider. These charges are Your responsibility.{' '}
              </span>
              <br />
              <span>
                3.3. You are solely responsible for maintaining the
                confidentiality of Your Registration Data and Application login
                credentials. Because Your Registration Data and Application
                login credentials are how RouteBuddies verify that You are using
                the Application, You will be liable for all actions, inactions,
                activities and transactions, and any other use or misuse of the
                Application, that occurs through Your Account (whether initiated
                by You or any third party).
              </span>
              <br />
              <span>
                3.4. If the Device is lost or stolen, You must notify
                RouteBuddies immediately.
              </span>
              <br />
              <span>
                3.5. You must also notify RouteBuddies immediately if You cannot
                access Your Account, You know of or suspect any unauthorised
                access or use of Your Registration Data, login credentials or
                Account, or the security of Your Account has been compromised in
                any way.
              </span>
              <br />
              <span>
                3.6. RouteBuddies may immediately suspend the Services and/or
                Your use of the Application (including Your Account) if it has a
                reason to believe that: (i) the Registration Data or any other
                information provided by You is false or outdated; (ii) the
                security of Your Account has been compromised in any way; or
                (iii) You have not complied with any of the requirements in this
                clause 3.
              </span>
              <span>
                3.7. RouteBuddies may block, suspend, alter or update the
                Application, the Booking Services and/or the Transport Services
                at any time via the Application: (i) to make improvements to the
                Application and/or the Services (including the security of the
                Application and/or the Services); (ii) as required by Applicable
                Law; or (iii) to protect a legitimate business interest. If this
                is not acceptable to You, You may always terminate these
                Customer Terms in accordance with clause 16.2.{' '}
              </span>
              <br />
              <br />
              <ol>
                <li>
                  <h3>SERVICES</h3>
                </li>
              </ol>
              <br />
              <b>
                <i>Booking Services </i>
              </b>
              <br />
              <span>
                4.1. The Application allows You to send a request to book a
                Group for a Journey (a “
              </span>
              <b>Booking Services Request</b>
              <span>
                ”). You may book a Group using the Application if one is
                available near You / Your location. Once You have made a Booking
                Services Request:{' '}
              </span>
              <br />
              <ol>
                <span>
                  (i) You must ensure that Your Device is turned on and
                  configured appropriately (e.g. GPS is active), and the
                  Application is active;
                </span>
                <br />
                <span>
                  (ii) the Application will send Your Booking Services Request
                  to RouteBuddies UK;
                </span>
                <br />
                <span>
                  (iii) RouteBuddies UK will notify You that Your Booking
                  Services Request is being processed; and
                </span>
                <br />
                <span>
                  (iv) RouteBuddies UK will use its reasonable endeavours to
                  find a Group for Your Booking Services Request and where
                  RouteBuddies UK successfully finds a Group to perform Your
                  Journey, RouteBuddies UK will notify You that Your Booking
                  Services Request has been accepted.
                </span>
                <br />
              </ol>
              <span>
                4.2. The Booking Services provided through the Application shall
                include:
              </span>
              <br />
              <ol>
                <span>
                  (i) Accepting or rejecting Booking Services Requests;{' '}
                </span>
                <br />
                <span>(ii) Identifying Groups to provide Journeys; </span>
                <br />
                <span>
                  (iii) Keeping records of the Booking Services Requests;{' '}
                </span>
                <br />
                <span>
                  (iv) Remotely monitoring trips booked using the Application;
                </span>
                <br />
                <span>
                  (v) Receiving and dealing with feedback, questions and
                  complaints relating to bookings accepted by RouteBuddies UK;
                  and
                </span>
                <br />
                <span>
                  (vi) Providing customer support for grievances; in each case
                  in accordance with any relevant provision of Applicable Laws.
                  Pursuant to, and for the purposes of Applicable Laws,
                  RouteBuddies UK accepts Booking Services Requests at its
                  registered address and/or operating centres and is subject to
                  all statutory and regulatory obligations and liabilities with
                  respect to that activity.
                </span>
              </ol>
              <br />
              <span>
                4.3. RouteBuddies UK will only accept Your Booking Services
                Request once a Group has been found to provide Your Journey. The
                acceptance of Your Booking Services Request is subject to the
                availability of a Group around Your location at the time of Your
                request, inaccessibility of Your location, or a traffic accident
                or breakdown of the Group for some other reason (and
                RouteBuddies UK may accept or reject the request for a Journey
                contained in Your Booking Services Request in its sole and
                absolute discretion).
              </span>
              <br />
              <b>
                <i>Transport Services</i>
              </b>
              <br />
              <span>
                4.4. The Transport Services do not come into effect unless and
                until RouteBuddies UK has successfully found a Group to perform
                the relevant Journey, subsequently accepted Your Booking
                Services Request, provided a Booking Confirmation to You in
                accordance with clause 4.5 below and otherwise met the other
                requirements of clause 4.5.
              </span>
              <br />
              <span>
                4.5. If a Group validly informs RouteBuddies UK that they accept
                the request for the Journey contained in Your Booking Services
                Request, RouteBuddies UK will:
              </span>
              <br />
              <ol>
                <span>
                  (i) inform You via an SMS, push notification or confirmation
                  screen in the Application that a Group has been found to
                  provide the Journey;
                </span>
                <br />
                <span>
                  (ii) send You a Booking Confirmation through the Application;
                </span>
                <br />
                <span>
                  (iii) provide to You information regarding the Group
                  (including the Group members names and images, Group details,
                  and any other details RouteBuddies UK considers appropriate);
                  and
                </span>
                <br />
                <span>
                  (iv) provide the Group with Your details, such as name and
                  meetup point, necessary to enable the Group to provide the
                  Journey.
                </span>
                <br />
              </ol>
              <span>
                4.6 If no Group validly informs RouteBuddies UK that they accept
                the request for the Journey contained in Your Booking Services
                Request, RouteBuddies UK will notify You via an SMS, push
                notification or confirmation screen in the Application and
                RouteBuddies UK will not provide Transport Services to You in
                respect of that Booking Services Request.
              </span>
              <br />
              <span>
                4.7 Where a Booking Confirmation is issued by RouteBuddies UK to
                You in accordance with clause 4.5 and the other conditions set
                out in clauses 4.4 and 4.5 are met, (and subject to RouteBuddies
                UK’s right to cancel Journeys set out in clause 7.2),
                RouteBuddies UK will use reasonable endeavours to ensure that
                the Group meets You at Your meetup point by Your requested pick
                up time and transports You to closer to the destination set out
                in Your Booking Confirmation via the Journey. You accept and
                agree that weather, traffic, roadworks, act of police or other
                government authorities and other unexpected delays such as
                traffic accidents or health incidents affecting Groups are all
                outside RouteBuddies UK’s control and as a result, RouteBuddies
                UK does not commit to collect You at the requested pick up time
                or deliver You to Your requested destination or any way points
                by an estimated or any other time.{' '}
              </span>
              <br />
              <span>
                4.8. While RouteBuddies UK requests each Group to use all
                reasonable efforts to ensure their Group arrives prior to Your
                requested pick-up time, You must book Your Journey after
                considering any adverse conditions such as the weather, traffic,
                roadworks, and other unexpected delays such as traffic
                accidents. If there is any delay by the Group in reaching Your
                pick-up location, You should contact the Group assigned for Your
                Journey through the Application or by contacting RouteBuddies UK
                via in-App support or at its call centre.
              </span>
              <br />
              <span>
                4.9. Except as expressly stated in these Customer Terms, the
                obligations of RouteBuddies are limited to (a) payment
                collection on behalf of RouteBuddies UK; and (b) disbursement of
                funds based on the directions from RouteBuddies UK; and the
                obligations of RouteBuddies UK are limited to: (a) managing and
                operating the booking functionality within the Application; (b)
                providing Booking Services to You; and (c) providing Transport
                Services to You.
              </span>
              <br />
              <span>
                4.10. RouteBuddies UK may, in its sole discretion, create and
                make available and/or offer promotional offers or benefits,
                redeemable through the Application (
              </span>
              <b>“Promo Codes”</b>
              <span>
                ) in relation to the Services or any third party benefits or
                services subject to any additional terms set out by RouteBuddies
                UK from time to time. You agree that the Promo Codes (i) shall
                be used for the intended and lawful purpose and as permitted by
                RouteBuddies UK; (ii) may expire prior to use or be disabled at
                RouteBuddies UK’s discretion. RouteBuddies UK reserves the right
                to withhold benefits and credits in case it believes that the
                use of the Promo Code was fraudulent, illegal or in violation of
                the applicable terms and conditions of the Promo Code.
                RouteBuddies also reserves the right to withdraw, extend or
                amend promotional offers at any time.{' '}
              </span>
              <br />
              <br />
              <ol>
                <li>
                  <h3>BOOKING CONFIRMATION</h3>
                </li>
              </ol>
              <span>
                5.1. Where Your Booking Services Request has been accepted by
                RouteBuddies UK and RouteBuddies UK has sent You a Booking
                Confirmation, You must check the booking details on the Booking
                Confirmation, including the pick-up time and location. If the
                pick-up time or location is wrong, You will have to cancel Your
                booking in accordance with clause 7.1 and submit another Booking
                Services Request with the correct time and location details.
              </span>
              <br />
              <span>
                5.2. You are responsible for any delay that may be caused due to
                Your failure to check the Booking Confirmation or failure to
                contact RouteBuddies UK immediately to correct the booking
                details.
              </span>
              <span>
                5.3. You must enter all requested waypoints and Your destination
                within the Application, including any changes that You want to
                make to these whilst in trip. The Group will not take You to any
                destination or waypoint that You have not entered within the
                Application. In case You change your destination during an
                ongoing trip, the Group may at his discretion refuse to continue
                the trip to the new destination.{' '}
              </span>
              <br />
              <br />
              <ol>
                <li>
                  <h3>PAYMENT</h3>
                </li>
              </ol>
              <br />
              <b>
                <i>Total Service Fee </i>
              </b>
              <br />
              <span>
                6.1. You understand that You will be charged the Total Journey
                Fee and You must pay the Total Service Fee and any VAT that may
                be applicable. RouteBuddies collects the Total Service Fee on
                behalf and as agent of RouteBuddies UK.
              </span>
              <br />
              <b>
                <i>Additional Costs </i>
              </b>
              <br />
              <span>
                6.2. Subject to clause 10, RouteBuddies UK reserves the right to
                charge You any reasonable costs required to clean or repair the
                Group that are attributable to You during a Journey (“
              </span>
              <b>Additional Costs</b>
              <span>
                ”) and You shall be responsible for the cost of such repair or
                cleaning. RouteBuddies will collect payment for such costs on
                behalf and as agent of RouteBuddies UK. RouteBuddies UK shall
                only charge You the Additional Costs in the event that a Group
                reports the need for repair or cleaning, and such repair or
                cleaning request has been verified by RouteBuddies UK in
                RouteBuddies UK’s reasonable discretion. RouteBuddies reserves
                the right to facilitate payment for any such verified Additional
                Costs using Your payment method designated in Your Account on
                behalf of and as agent for RouteBuddies UK.
              </span>
              <br />
              <span>
                6.3. Payment of the Total Service Fee and any Additional Costs
                shall be considered the same as payment made directly by You to
                RouteBuddies UK for the provision of the Services and such
                payments will be facilitated by a payment gateway and/or payment
                processing services provider appointed by RouteBuddies (the “
              </span>
              <b>Payment Processor</b>
              <span>
                ”). The Payment Processor may be RouteBuddies, one of its
                related bodies corporate, an unrelated third party or any party
                sub-contracted by RouteBuddies for this purpose.
              </span>
              <br />
              <span>
                6.4. You will be required to provide relevant payment details
                including credit/debit card details (“
              </span>
              <b>Card Details</b>
              <span>
                ”) to the Payment Processor in order for RouteBuddies to process
                payment of the Total Service Fee and any Additional Costs, and
                You hereby authorise the Payment Processor to do so. Your
                authorisation:
              </span>
              <br />
              <ol>
                <span>
                  (i) permits the Payment Processor to debit or credit the bank
                  account or debit/credit card account associated with Your
                  payment details;
                </span>
                <br />
                <span>
                  (ii) permits the Payment Processor to use Your Card Details
                  for the processing of transactions initiated by You or via
                  Your Account;{' '}
                </span>
                <br />

                <span>
                  (iii) will remain in effect as long as You maintain an Account
                  (and if You delete Your Card Details or Account, RouteBuddies
                  and the Payment Processor will not be able to process any
                  further transactions initiated by You); and
                </span>
                <br />
                <span>
                  (iv) is subject to any other terms and conditions of the
                  Payment Processor specified through the Application, SMS or
                  other methods from time to time.
                </span>
              </ol>
              <br />
              <span>
                6.5. RouteBuddies UK shall issue invoices to You for the
                Transport Services provided to You and any Additional Costs
                payable pursuant to clause 6.2.
              </span>
              <br />
              <span>
                6.6. Subject to clause 7.2, the Total Service Fee paid by You is
                final and non-refundable, unless otherwise determined by
                RouteBuddies UK. If any amount paid by You is fully or partially
                refundable for any reason, such amounts will be refunded to You
                by RouteBuddies on behalf of RouteBuddies UK in accordance with
                and subject to Applicable Law. You may contact RouteBuddies
                and/or its Affiliates for any issues arising therefrom.
              </span>
              <br />
              <span>
                6.8. Any payment processing-related issue not caused by an error
                or fault with the Application must be resolved by You and the
                relevant Payment Processor.
              </span>
              <br />
              <span>
                6.9. You agree that use of certain Promo Codes may result in
                different charges for the same services and shall not have a
                bearing on charges applied to You unless the Promo Codes have
                been specifically made available to You.
              </span>
              <br />
              <span>
                6.10. In the event of a default or failure to pay the Total
                Journey Fee for any reason by You: (i) RouteBuddies UK may
                restrict You from making a Booking Services Request through the
                Application until the outstanding Total Service Fee in respect
                of the previous Journey(s) has been paid by You; (ii) You agree
                and acknowledge that RouteBuddies UK may, or RouteBuddies (or
                third party service providers on its behalf) may, as agent of
                RouteBuddies UK, recover any outstanding monies payable by You
                for Journey(s) in respect of which the relevant Total Journey
                Fee has not been paid; and (iii) You will be responsible, and
                must pay, for all costs incurred by RouteBuddies UK and
                RouteBuddies (including costs for which RouteBuddies may be
                contingently liable) in any attempt to collect any monies owed
                by You to RouteBuddies UK under these Customer Terms including
                debt collection agent costs, repossession costs, location search
                costs, process server costs and solicitor costs on a
                solicitor/client basis.{' '}
              </span>
              <br />
              <br />
              <ol>
                <li>
                  <h3>CANCELLATION AND EARLY TERMINATION OF A JOURNEY</h3>
                </li>
              </ol>
              <br />
              <span>
                7.1. You may cancel Your request for a Journey from a Group at
                any time, however, subject to clause 7.3, You may be charged a
                Cancellation Fee according to our cancellation policy which can
                be found on our Website.
              </span>
              <br />
              <span>
                7.2. RouteBuddies UK may cancel a Journey after a Booking
                Confirmation has been provided to You but before a Journey has
                commenced. This will only happen if a Group requests the
                cancellation of that Journey before collecting You at the
                requested pick-up location due to adverse conditions such as the
                weather, traffic, roadworks, and other unexpected delays such as
                traffic accidents which are outside of the Group’s reasonable
                control (both when such conditions prohibit a Group from
                performing a Journey, and/or impact the Group’s ability to
                perform the Journey within a reasonable time). RouteBuddies UK
                shall promptly inform You of such cancellation using the
                Application and use its reasonable endeavours to find an
                alternative Group for Your Booking Services Request. You are
                able to cancel Your request for a Journey at any time in
                accordance with clause 7.1. You will not be charged a
                Cancellation Fee where Your Journey has been cancelled by
                RouteBuddies UK in accordance with this clause 7.2.
              </span>
              <br />
              <span>
                7.3. You will be notified of Your liability for any Cancellation
                Fee through the Application at the time You attempt to cancel
                Your Booking Services Request (If cancellation fee is
                applicable), and any Cancellation Fee payable by You will be
                processed by RouteBuddies, in accordance with clause 6.
              </span>
              <br />
              <span>
                7.4 RouteBuddies UK may only terminate a Journey once it has
                commenced if a safety concern takes place during a Journey.
                RouteBuddies UK may inform you of this cancellation via the
                Application or via its staff or agents. In the event of such
                termination, RouteBuddies UK will use its reasonable endeavours
                to find an alternative Group for Your Booking Services Request.{' '}
              </span>
              <br />
              <br />
              <ol>
                <li>
                  <h3>VAT</h3>
                </li>
              </ol>
              <br />
              <span>8.1. VAT law includes: </span>
              <br />
              <ol>
                <span>(i) Value Added Tax Act 1994, UK; </span>
                <br />
                <span>(ii) VAT Law of 1968, Netherlands; and </span>
                &ZeroWidthSpace;&ZeroWidthSpace;
                <span>
                  (iii) Council Directive 2006/112/EC of 28 November 2006 on the
                  common system of value added tax, <br />
                  ((i) to (iii) together,{' '}
                </span>
                <b>“VAT Law”</b>
                <span>). </span>
                <br />
              </ol>
              <span>
                8.2. Words and expressions used herein which are not defined in
                the Customer Terms but which have a defined meaning in VAT Law,
                have the same meaning as in the VAT Law.
              </span>
              <br />
              <span>
                8.3. Unless otherwise specified, all amounts payable by You for
                the Services will be subject to applicable VAT in accordance
                with the VAT Law, as amended from time to time.
              </span>
              <br />
              <span>
                8.4. RouteBuddies UK shall issue such VAT invoices to You for
                the Transport Services and any Additional Costs payable pursuant
                to clause 6.2.{' '}
              </span>
              <br />
              <br />
              <ol>
                <li>
                  <h3>BEHAVIOUR</h3>
                </li>
              </ol>
              <span>
                9.1. While providing the Transport Services, the Group may
                require You to not smoke or consume any alcohol, tobacco or
                illicit substances in or near any Group, misbehave in or near
                any Group, distract the Group, act in violation of Applicable
                Laws, or otherwise act in any way which a Group (acting
                reasonably) considers will risk the safety of You, the Group or
                any other person in the Group or on the roads.
              </span>
              <br />
              <span>
                9.2. If You breach clause 9.1, RouteBuddies may immediately
                suspend the Services and/or Your use of the Application
                (including Your Account).{' '}
              </span>
              <br />
              <br />
              <ol>
                <li>
                  <h3>FEEDBACK, ISSUES AND COMPLAINTS</h3>
                </li>
              </ol>
              <br />
              <span>
                10.1. You may rate a Group Member, and You must be fair,
                accurate and non-defamatory while leaving complaints, comments,
                feedback, testimonials or reviews on or about Group Members or
                the Services.
              </span>
              <br />
              <span>
                10.2. All issues, complaints, opinions, suggestions, questions
                and feedback while accessing the Services should be notified to
                RouteBuddies UK via e-mail or communicated to RouteBuddies UK
                through the Application or by contacting its call centre.
              </span>
              <br />
              <span>
                10.3. You must report any issue You have with the Application or
                Services to RouteBuddies UK as soon as possible of the relevant
                issue occurring, so that RouteBuddies may address such issue
                promptly.
              </span>
              <br />
              <span>
                10.4. RouteBuddies UK will endeavour to respond to You within 2
                (two) Business Days after You report an issue. RouteBuddies UK
                notes that issues are generally resolved based on the
                seriousness of the issue, and as such certain issues may be
                prioritised over others. RouteBuddies UK is under a legal duty
                to provide the Services in conformity with this contract but may
                not be able to resolve all issues.
              </span>
              <br />
              <span>
                10.5. If You forget or lose any luggage or property in a Group
                during a Journey, you should contact RouteBuddies UK as soon as
                possible (and in any event within 24 (twenty-four) hours), and
                RouteBuddies UK will try to locate the items. If Your luggage or
                property is found, RouteBuddies UK will contact You and advise
                You of how You will be able to collect Your luggage or property.
                Unless otherwise agreed (in RouteBuddies UK’s sole and absolute
                discretion), RouteBuddies UK will not deliver any luggage or
                property items to You. In no event will RouteBuddies be liable
                for any loss or damage to Your personal effects and luggage.{' '}
              </span>
              <br />
              <br />
              <ol>
                <li>
                  <h3>PRIVACY</h3>
                </li>
              </ol>
              <span>
                11.1. RouteBuddies collects, stores, processes and transfers
                personal information (including sensitive financial information)
                in compliance with the Privacy Policy and any applicable
                statutes and regulations relating to the protection of personal
                data.{' '}
              </span>
              <br />
              <br />
              <ol>
                <li>
                  <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
                </li>
              </ol>
              <br />
              <span>
                12.1. The Application and all associated intellectual property
                rights (“
              </span>
              <b>RouteBuddies IP</b>
              <span>
                ”) remain the property of RouteBuddies or its third-party
                licensors. Except as expressly stated, nothing in these Customer
                Terms grants You any rights in or related to the RouteBuddies
                IP, and all rights not expressly granted to You are reserved by
                RouteBuddies.
              </span>
              <br />
              <span>12.2. You must not: </span>
              <br />
              <ol>
                <span>
                  (i) reverse engineer, decompile or otherwise endeavour to
                  obtain the source code in the Application (save to the extent
                  that You cannot be prohibited from so doing under applicable
                  law);
                </span>
                <br />
                <span>
                  (ii) copy, reproduce, modify, create derivative works or
                  attempt to derive the composition or underlying information,
                  structure or ideas of, any RouteBuddies IP;
                </span>
                <br />
                <span>
                  (iii) breach, disable, tamper with, or develop or use (or
                  attempt) any workaround for any security measure provided in
                  the Application;
                </span>
                <br />
                <span>
                  (iv) use any RouteBuddies IP in a way that infringes or
                  misappropriates a third party’s intellectual property rights
                  or moral rights;
                </span>
                <br />
                <span>
                  (v) distribute, disclose or allow use of any RouteBuddies IP
                  by any third party in any format, through any time sharing
                  service, service bureau, network or by any other means;
                </span>
                <br />
                <span>
                  (vi) merge or combine any RouteBuddies IP with any other
                  technology not provided by RouteBuddies; (vii) remove any
                  proprietary notice language on any copies of any RouteBuddies
                  IP; or
                  <br />
                  (vii) resort to any fraudulent activity on the Application or
                  Website.
                </span>
                <br />
                <br />
                <span>
                  12.3. Subject to Your compliance with these Customer Terms,
                  RouteBuddies UK grants You a limited, non exclusive, personal,
                  non-transferable licence during the term of these Customer
                  Terms to use and access the Application on any Device that You
                  own or control and to run the Application with your use of the
                  Services solely for Your own personal use (including for the
                  processing of Your payments).
                </span>
                <br />
                <span>
                  12.4. You are solely responsible for any notes, messages,
                  e-mails, billboard postings, photos, drawings, profiles,
                  opinions, ideas, images, videos, audio files or other
                  materials or information posted or transmitted through the
                  Application or Device by or on Your behalf (“
                </span>
                <b>Your Content</b>
                <span>
                  ”). You must ensure that Your Content is not unlawful and does
                  not infringe any third party’s rights (including intellectual
                  property rights), and You must not:{' '}
                </span>
                <br />
                <ol>
                  <span>
                    (i) publish, post, upload, distribute or disseminate any
                    inappropriate, profane, defamatory, infringing, obscene,
                    indecent or unlawful topic, blasphemous, pornographic,
                    libellous, invasive of another’s privacy, hateful, or
                    racially, ethnically objectionable, disparaging, or
                    otherwise unlawful material or information, or any material
                    relating to or encouraging money laundering or gambling;
                  </span>
                  <br />
                  <span>
                    (ii) upload files that contain software or other material
                    protected by intellectual property laws (or by rights of
                    privacy or publicity) unless You own or control the rights
                    thereto or have received all necessary consents;
                  </span>
                  <br />
                  <span>
                    (iii) upload files that contain viruses, corrupt files, or
                    any other similar software or programs that may damage the
                    operation of another computer or electronic device;
                  </span>
                  <br />
                  <span>
                    (iv) except as expressly permitted by RouteBuddies,
                    advertise or offer to sell or buy any goods or services for
                    any business purpose;
                  </span>
                  <br />
                  <span>
                    (v) conduct or forward surveys, contests, pyramid schemes or
                    chain letters;
                  </span>
                  <br />
                  <span>
                    (vi) download any file posted by another user that You know,
                    or reasonably should know, cannot be legally distributed in
                    such manner; <br />
                    (vii) falsify or delete any author attributions, legal or
                    other proper notices or proprietary designations or labels
                    of the origin or source of software or other material
                    contained in a file that is uploaded; <br />
                    (viii) deceive or mislead the addressee about the origin of
                    a message or communicate any information which is grossly
                    offensive or menacing in nature; <br />
                    (ix) restrict or inhibit any other user (including Groups
                    and other customers) from using and enjoying the
                    Application; or <br />
                    (x) harvest or otherwise collect information about others,
                    including e-mail addresses, without their consent.
                  </span>
                  <br />
                  <span>
                    12.5. RouteBuddies has no obligation or policy to monitor
                    Your access to or use of the Application, or Your Content,
                    but You hereby provide Your consent for RouteBuddies to do
                    so, to the extent permitted by Applicable Law, for the
                    purpose of providing and operating the Application and
                    enabling RouteBuddies UK to provide the Services, or to
                    improve the Application or the Services from time to time,
                    or, to ensure Your compliance with these Customer Terms, and
                    to comply with Applicable Law or the order or requirement of
                    a court, administrative agency or other government body.
                    RouteBuddies may, however,
                  </span>
                  <br />
                  <span>
                    investigate and prosecute (and refer to law enforcement
                    agencies) any actual or suspected breach of clause 12.4 by
                    You to the fullest extent permitted by Applicable Law.
                    RouteBuddies may at any time and without prior notice remove
                    or disable access to any of Your Content which RouteBuddies
                    considers (acting reasonably) to be in violation of these
                    Customer Terms.
                  </span>
                  <br />
                  <span>
                    12.6. Except for the Registration Data or any other data
                    submitted by You during the use of the Service (
                  </span>
                  <b>“Permitted Information”</b>
                  <span>
                    ), You should not send any confidential or proprietary
                    information to RouteBuddies (whether on the Application or
                    otherwise). Except for the Permitted Information, and
                    subject to RouteBuddies handling Your personal information
                    in accordance with the Privacy Policy, any of Your Content
                    will not be considered confidential or proprietary.{' '}
                  </span>
                  <br />
                  <br />
                  <ol>
                    <li>
                      <h3>INDEMNITY</h3>
                    </li>
                  </ol>
                  <span>
                    13.1. To the maximum extent permitted by law, You will
                    indemnify and hold harmless RouteBuddies UK and its related
                    bodies corporate (and their respective directors, officers,
                    employees, agents and representatives) (“
                  </span>
                  <b>Indemnified Parties</b>
                  <span>
                    ”) from and against any losses, damages, liability, claims,
                    costs, penalty and expenses (including, reasonable legal
                    fees) incurred by the Indemnified Parties arising out of or
                    in connection with any:
                  </span>
                  <br />
                  <ol>
                    <span>(i) breach by You of any Applicable Law; </span>
                    <br />
                    <span>
                      (ii) misrepresentation or fraudulent, dishonest, unlawful
                      or negligent act or omission of You;
                    </span>
                    <br />
                    <span>
                      (iii) personal injury (including sickness and death) or
                      property damage caused or contributed to by You in
                      connection with any Journey (including in connection with
                      any Group accident involving the Group Members); or
                    </span>
                    <br />
                    <span>
                      (iv) claim by a Group or any other third party caused by
                      any of Your acts or omissions.
                    </span>
                  </ol>
                  <br />
                  <br />

                  <ol>
                    <li>
                      <h3>LIABILITY</h3>
                    </li>
                  </ol>
                  <br />
                  <span>
                    14.1. Nothing in these Customer Terms excludes or limits
                    RouteBuddies’s liability for death or personal injury
                    arising from negligence, liability for fraud or fraudulent
                    misrepresentation, or any other liability which cannot be
                    excluded or limited under Applicable Law. Nothing in these
                    Customer Terms affects Your statutory rights.
                  </span>
                  <br />
                  <span>
                    14.2. Subject to clause 14.1, to the extent permitted by
                    applicable law, RouteBuddies shall not be liable to You in
                    any manner whether such liability arises in contract (by way
                    of indemnity or otherwise), in tort, including negligence,
                    for misrepresentation, breach of statutory duty, restitution
                    or otherwise, for:
                  </span>
                  <br />
                  <ol>
                    <span>
                      (i) any request for a Journey contained in a Booking
                      Services Request, which has not been accepted by
                      RouteBuddies UK;{' '}
                    </span>
                    <br />
                    <span>
                      (ii) any theft or loss of Your or any other person’s
                      property in connection with the Services or any Booking
                      Services Request accepted by RouteBuddies UK;
                    </span>
                    <br />
                    <span>
                      (iii) any loss, damage, costs, expenses or liability
                      suffered by You or anyone other than You in connection
                      with Your use of the Application or outside the Journey;
                    </span>
                    <br />
                    <span>
                      (iv) any loss, damage, costs, expenses or liability
                      suffered by You or anyone other than You at any point
                      other than the duration of the Journey;
                    </span>
                    <br />
                    <span>
                      (v) any loss that You may incur as a result of someone
                      else using Your Account, either with or without Your
                      knowledge;
                    </span>
                    &ZeroWidthSpace;&ZeroWidthSpace;
                    <br />
                    <span>
                      (vi) any loss of profits, loss of revenue, loss of
                      opportunity, loss of business or employment in relation to
                      You, Your employer or any other person (whether or not
                      such loss could be reasonably expected to flow from our
                      negligence or breach of these Customer Terms);
                    </span>
                    <br />
                    <span>
                      (vii) any loss of goodwill by You, Your employer or any
                      other person;
                    </span>
                    <br />
                    <span>
                      (viii) any indirect, incidental, special exemplary,
                      punitive or consequential loss, costs, expenses or
                      liability or loss of data suffered by You, Your employer
                      or any other person; or
                    </span>
                    <br />
                    <span>
                      (ix) any other losses, costs, expenses or liability that
                      You suffer in connection with the Services, save to the
                      extent that RouteBuddies fails to perform its obligations
                      to You to the standard of a diligent and professional
                      provider of the relevant Services.
                    </span>
                  </ol>
                  <br />
                  <span>
                    14.3. The Services and the Application are provided on an
                    “as is” and “as available” basis, and, to the fullest extent
                    permitted by Applicable Laws, RouteBuddies does not make any
                    promises, whether express or implied, with respect to the
                    Services, or the content or functionality of the
                    Application. RouteBuddies shall not be liable for delay or
                    failure in performance resulting from causes beyond
                    RouteBuddies’s reasonable control.
                  </span>

                  <br />

                  <span>
                    14.4. The limitations and exclusions of liability in this
                    clause 14 apply regardless of the basis on which such
                    liability arises, whether in contract, breach of warranty,
                    tort (including negligence), in equity, under statute, or on
                    any other basis.
                  </span>
                  <br />
                  <span>
                    14.5. Subject to clause 14.1, RouteBuddies UK’s total
                    aggregate liability to You for all losses, costs, expenses
                    or liabilities You suffer in connection with these Customer
                    Terms or as a result of using the Services (including in
                    respect of a Journey) shall not exceed:{' '}
                  </span>
                  <br />
                  <ol>
                    <span>
                      (i) subject to clause 14.5(ii), twice the value of the
                      Total Service Fee for all claims made by You in respect of
                      a Journey; or
                    </span>
                    <br />
                    <span>
                      (ii) for all claims made by You in connection with these
                      Customer Terms or as a result of using the Services
                      (including in respect of a Journey), £100 in aggregate.
                    </span>
                    <br />
                  </ol>
                  <span>
                    14.6. Nothing in this clause shall exclude RouteBuddies’s
                    liability, that by law, may not be limited or excluded.
                  </span>
                </ol>
                <br />
                <br />

                <ol>
                  <li>
                    <h3>FORCE MAJEURE</h3>
                  </li>
                </ol>
                <br />
                <span>
                  15.1. Any delay in or failure to perform any obligations by a
                  Party under these Customer Terms will not constitute a breach
                  of these Customer Terms to the extent caused by acts of any
                  government authorities, acts of God, outbreak of a pandemic or
                  epidemic, fire, flood, explosion, riots, war, rebellion,
                  insurrection or other event beyond the reasonable control of
                  that Party (
                </span>
                <b>“Force Majeure”</b>
                <span>). </span>
                <br />
                <br />

                <ol>
                  <li>
                    <h3>TERMINATION</h3>
                  </li>
                </ol>
                <br />
                <span>
                  16.1. These Customer Terms shall continue until such time as
                  they are terminated in accordance with this clause 16.
                </span>
                <br />
                <span>
                  16.2. You may terminate these Customer Terms at any time by
                  closing Your Account or uninstalling the Application. You can
                  close Your Account at any time by following the instructions
                  on the Application.
                </span>
                <br />
                <span>
                  16.3. RouteBuddies may terminate these Customer Terms with
                  immediate effect upon notice to You if: <br />
                  <ol>
                    (i) You fail to comply with any Applicable Law;
                    <br />
                    <span>
                      (ii) You cease to satisfactorily provide Registration
                      Data;
                    </span>
                    <br />
                    <span>
                      (iii) You commit a breach (other than a trivial or
                      inconsequential breach) of these Customer Terms that is
                      not capable of remedy or (if capable of remedy) is not
                      remedied within 4 (four) Business Days after RouteBuddies
                      notifies You of the breach, or
                    </span>
                    <br />
                    <span>
                      (iv) RouteBuddies UK ceases to offer Services in the UK.
                    </span>
                    <br />
                  </ol>
                  <span>
                    16.4. Upon termination of these Customer Terms for any
                    reason:{' '}
                  </span>
                  <br />
                  <ol>
                    <span>
                      (i) Your rights to use the Application will cease
                      immediately, Your registration and Your Account will cease
                      to apply, and RouteBuddies may block Your access to the
                      Application;
                    </span>
                    <br />
                    <span>
                      (ii) RouteBuddies will charge You all amounts due and
                      owing at the date of termination in accordance with clause
                      6; and
                    </span>
                    <br />
                    <span>
                      (iii) the Parties must cease acting in a manner that would
                      imply a continuing relationship between the Parties.
                    </span>
                    <br />
                  </ol>
                  <span>
                    16.5. Clauses 11 (Privacy), 12 (Intellectual Property
                    Rights), 14 (Liability), 17 (Notice), and 21 (Amendments to
                    These Customer Terms) will survive termination of these
                    Customer Terms together with any other terms which by their
                    nature do so.
                  </span>
                  <br />
                  <span>
                    16.6. Termination of these Customer Terms will not prejudice
                    any rights of the Parties that may have accrued prior to
                    such termination.{' '}
                  </span>
                  <br />
                  <br />
                  <ol>
                    <li>
                      <h3>NOTICE</h3>
                    </li>
                  </ol>
                  <span>
                    Any notices, requests and other communications required or
                    permitted under these Customer Terms must be in writing and
                    sent to the recipient Party as follows (as amended to time
                    by the recipient Party by notice to the other Party):
                  </span>
                  <br />
                  <ol>
                    <span>
                      (ii) to RouteBuddies UK by email to:
                      contact@routebuddies.com; and
                    </span>
                    <br />
                    <span>
                      (iii) to You by email or text message (SMS) to the email
                      address or mobile number (as applicable) specified in the
                      Account.
                    </span>
                  </ol>
                  <br />
                  <br />
                  <ol>
                    <li>
                      <h3>ENTIRE AGREEMENT</h3>
                    </li>
                  </ol>
                  <br />
                  <span>
                    18.1. These Customer Terms constitute the entire agreement
                    between the Parties in connection with, and will supersede
                    all previous communications (either oral or written) between
                    the Parties with respect to the subject matter of these
                    Customer Terms, and no agreement or understanding varying or
                    extending the same will be binding on either Party unless
                    arising out of the specific provisions of these Customer
                    Terms.
                  </span>
                  <br />
                  <span>
                    18.2. No Party has entered into these Customer Terms in
                    reliance on any term or statement other than the terms
                    expressly set out herein, provided that this clause 18.2
                    shall not apply to any fraudulent misrepresentation.{' '}
                  </span>
                  <br />
                  <br />
                  <ol>
                    <li>
                      <h3>RELATIONSHIP OF THE PARTIES</h3>
                    </li>
                  </ol>
                  <span>
                    Nothing in these Customer Terms is intended to constitute a
                    fiduciary relationship or an agency, joint venture,
                    partnership, employment or trust between You, RouteBuddies
                    UK and/or RouteBuddies NL as a result of use of the
                    Services.{' '}
                  </span>
                  <br />
                  <br />
                  <ol>
                    <li>
                      <h3>GOVERNING LAW AND DISPUTE RESOLUTION</h3>
                    </li>
                  </ol>
                  <br />
                  <span>
                    20.1. Any dispute arising out of or in connection with these
                    Customer Terms (including any question regarding its
                    existence, validity or termination) shall be referred to and
                    finally resolved by arbitration. The arbitration shall be:
                    (a) held in accordance with the LCIA Rules; (b) held and
                    seated in London; and (c) conducted in English by a single
                    arbitrator agreed between the Parties (or, failing
                    agreement, appointed by the LCIA). To the maximum extent
                    permitted by law, the arbitrator’s award will be final and
                    binding on the Parties.
                  </span>
                  <br />
                  <span>
                    20.2. These Customer Terms will be governed by and construed
                    in accordance with the laws of England and Wales.{' '}
                  </span>
                  <br />
                  <br />
                  <ol>
                    <li>
                      <h3>AMENDMENTS TO THESE CUSTOMER TERMS</h3>
                    </li>
                  </ol>
                  <br />
                  <span>
                    21.1. RouteBuddies may amend these Customer Terms and shall
                    keep you updated regarding such amendments.
                  </span>
                  <br />
                  <span>
                    21.2. You will be required to confirm Your acceptance of the
                    amendments referred to in clause 21.1. However, if You do
                    not agree to any such amendments, you may terminate these
                    Customer Terms in accordance with clause 16.2 at any time
                    prior to such amendments coming into effect.{' '}
                  </span>
                  <br />
                  <br />
                  <ol>
                    <li>
                      <h3>MISCELLANEOUS</h3>
                    </li>
                  </ol>
                  <br />
                  <span>
                    22.1. Assignment: You may not assign or transfer any of Your
                    rights, interests or obligations under these Customer Terms
                    to any third party without the prior written consent of
                    RouteBuddies. RouteBuddies may assign its rights and
                    interests under these Customer Terms to any person
                    whosoever. We will let You know if we plan to do this.
                  </span>
                  <br />
                  <span>
                    22.2. Waiver: Either Party may exercise a right, power or
                    remedy at its discretion and separately or concurrently with
                    another right, power or remedy. No failure or delay on the
                    part of either Party exercising any right, power or
                    privilege under these Customer Terms will operate as a
                    waiver thereof, nor will any single or partial exercise of
                    any right, power or privilege under these Customer Terms
                    preclude any other or further exercise thereof or the
                    exercise of any other rights, powers or privileges by such
                    Party.
                  </span>
                  <br />
                  <span>
                    22.3. Severability: Any provision that is prohibited or
                    unenforceable in any jurisdiction will, as to such
                    jurisdiction, be ineffective to the extent of such
                    prohibition or unenforceability without invalidating the
                    remaining provisions or affecting the validity or
                    enforceability of such provision in any other jurisdiction.
                  </span>
                  <br />
                  <span>
                    22.4. Rights cumulative: Subject to any express provision in
                    these Customer Terms to the contrary, the rights, powers or
                    remedies of a Party under these Customer Terms are
                    cumulative and in addition to, and do not exclude or limit,
                    any right, power or remedy in any other part of these
                    Customer Terms or otherwise provided at law or in equity.
                  </span>
                </span>
              </ol>
            </span>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
