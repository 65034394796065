import { Grid } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import NavBar from '../components/NavBar';
import '../../index.css';
import { Helmet } from 'react-helmet';
export function PrivacyPolicy({ theme }: any) {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <Grid container justifyContent={'center'}>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>
      <NavBar theme={theme} navigate={navigate} loggedIn={loggedIn} />

      <Grid
        item
        xs={12}
        style={{
          marginTop: '118px',
          padding: '20px',
        }}
      >
        <header>
          <h1>Privacy Policy for RouteBuddies</h1>
          <p>Last Updated: 05/12/2023</p>
        </header>

        <section>
          <h2>1. Introduction</h2>
          <p>
            Welcome to RouteBuddies! This Privacy Policy outlines how we
            collect, use, disclose, and protect your information when you use
            our safety app. By accessing or using our app, you agree to the
            terms of this Privacy Policy.
          </p>
        </section>

        <section>
          <h2>2. Information We Collect</h2>

          <article>
            <h3>2.1 User-Provided Information</h3>
            <p>
              a. <strong>Account Registration:</strong> When you register for an
              account, we collect your first name, last name, email address, and
              password.
            </p>
            <p>
              b. <strong>Identity Verification:</strong> For enhanced security,
              users can voluntarily submit their ID document and a selfie for
              manual review. Images of ID documents are removed within 30 days.
            </p>
          </article>

          <article>
            <h3>2.2 Automatically Collected Information</h3>
            <p>
              a. <strong>Usage Data:</strong> We collect information about your
              interactions with the app, such as posts, interactions with
              groups, and app usage patterns.
            </p>
            <p>
              b. <strong>Device Information:</strong> We collect device
              information, including device type, operating system, and unique
              device identifiers.
            </p>
            <p>
              c. <strong>Location Data:</strong> If you enable location
              services, we may collect and process your location data to provide
              location-based features.
            </p>
          </article>
        </section>

        <section>
          <h2>3. How We Use Your Information</h2>
          <p>We use the collected information for the following purposes:</p>
          <p>
            a. <strong>Account Management:</strong> To create and manage user
            accounts.
          </p>
          <p>
            b. <strong>Identity Verification:</strong> To verify user identity
            for enhanced security.
          </p>
          <p>
            c. <strong>Community Engagement:</strong> To facilitate interactions
            within walking groups and communities.
          </p>
          <p>
            d. <strong>App Improvement:</strong> To analyze usage patterns and
            enhance the functionality of the app.
          </p>
        </section>
      </Grid>
    </Grid>
  );
}
