import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Fade,
  IconButton,
  Slide,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

// Imports; Material UI
import {
  ArrowBack,
  ChevronRight,
  Favorite,
  MoreVert,
  Share,
} from '@mui/icons-material';

import { Grid, useTheme } from '@mui/material';

import NavBar from '../components/NavBar';
import { useNavigate, useParams } from 'react-router';
import '../../index.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { primaryColor } from '../../assets/styles/styles';
import { Helmet } from 'react-helmet';
import { PageType, setCurrentPage } from '../../store/slices/general.slice';
import { useDispatch } from 'react-redux';
import MediaCard from '../components/MediaCard';

export default function News({}: any) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { blogId } = useParams();

  const [loggedIn, setLoggedIn] = useState(false);

  const dispatch = useDispatch();
  const [fadeAnimation, setFadeAnimation] = useState(false);
  const [slidenimation, setSlideAnimation] = useState(false);

  useEffect(() => {
    dispatch(setCurrentPage(PageType.NEWS));
    setFadeAnimation(true);
    setSlideAnimation(true);
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <NavBar theme={theme} navigate={navigate} loggedIn={loggedIn} />
      <Grid
        xs={12}
        style={{
          height: '80px',
          backgroundImage: `url(${'/static/login-bground.jpg'})`,
        }}
      />
      <Grid
        item
        container
        xs={12}
        justifyContent={'center'}
        style={{ marginBottom: 100 }}
      >
        <>
          <Fade in={fadeAnimation} timeout={500}>
            <Grid
              item
              container
              xs={10}
              justifyContent={'flex-start'}
              flexDirection={'column'}
              style={{ borderBottom: '0.5px solid grey', marginBottom: 15 }}
            >
              <Typography
                variant="h4"
                fontWeight={'bold'}
                fontFamily={'Urbanist'}
                style={{
                  fontFamily: 'UrbanistBold',
                  marginTop: 40,
                  marginBottom: 25,
                  color: primaryColor,
                }}
              >
                Read all about it!{'   📰'}
              </Typography>
            </Grid>
          </Fade>
          <Slide
            direction="left"
            in={slidenimation}
            timeout={500}
            easing={{ enter: theme.transitions.easing.easeInOut }}
            onEntered={() => {
              setFadeAnimation(true);
            }}
          >
            <Grid
              item
              container
              xs={10}
              justifyContent={'flex-start'}
              spacing={2}
            >
              <Grid item container xs={12} sm={6} md={4}>
                <MediaCard
                  imgSrc={
                    'https://i2-prod.walesonline.co.uk/incoming/article26210611.ece/ALTERNATES/s615d/0_ALR_WOL_100223buddiesJPG.jpg'
                  }
                  source={'Falmouth Packet'}
                  title="RouteBuddies safety app launched by Cornwall entrepreneur"
                  desc="A Cornish entrepreneur has developed a unique app aimed at reducing street harassment."
                  onClick={() => {
                    window.location.href =
                      'https://www.falmouthpacket.co.uk/news/23969875.routebuddies-safety-app-launched-cornwall-entrepreneur/';
                  }}
                />
              </Grid>
              <Grid item container xs={12} sm={6} md={4}>
                <MediaCard
                  imgSrc={
                    'https://i2-prod.walesonline.co.uk/incoming/article26210611.ece/ALTERNATES/s615d/0_ALR_WOL_100223buddiesJPG.jpg'
                  }
                  source={'Business News Wales'}
                  title="A Cardiff Entrepreneur Launches New App to Prevent Street Harassment"
                  desc="A Cardiff-based young entrepreneur is aiming to help reduce street harassment..."
                  onClick={() => {
                    window.location.href =
                      'https://businessnewswales.com/a-cardiff-entrepreneur-launches-new-app-to-prevent-street-harassment/';
                  }}
                />
              </Grid>

              <Grid item container xs={12} sm={6} md={4}>
                <MediaCard
                  imgSrc={'/static/wales247.png'}
                  source={'Wales247'}
                  title="New app to prevent street harassment of women in Cardiff"
                  desc="A 23-year-old entrepreneur has set out to make streets safer with..."
                  onClick={() => {
                    window.location.href =
                      'https://www.wales247.co.uk/new-app-to-prevent-street-harassment-of-women-in-cardiff';
                  }}
                />
              </Grid>
              {/* C UNI PAPER */}
              <Grid item container xs={12} sm={6} md={4}>
                <MediaCard
                  imgSrc={'https://i.imgur.com/Bg1V8wd.png'}
                  source={'Gair Rhydd'}
                  title="'Safety in Numbers' - A new safety app is coming to Cardiff'"
                  desc="A recent Cardiff graduate is using technology to help..."
                  onClick={() => {
                    window.location.href =
                      'https://issuu.com/gairrhydd/docs/gair_rhydd_1183_final_a25c5f57d2b508';
                  }}
                />
              </Grid>

              {/* Our region */}
              <Grid item container xs={12} sm={6} md={4}>
                <MediaCard
                  imgSrc={
                    'https://www.cardiffcapitalregion.wales/wp-content/uploads/2020/11/ccr-cjc-logo-3.png'
                  }
                  source={'Cardiff Capital Region'}
                  title="Our Region In Action"
                  desc="RouteBuddies featured in CCR's Our Region in Action article..."
                  onClick={() => {
                    window.location.href =
                      'https://www.cardiffcapitalregion.wales/news-events/latest-news/our-region-in-action-the-latest-in-innovation-sustainability-inclusion-and-connectivity-13/';
                  }}
                />
              </Grid>

              <Grid item container xs={12} sm={6} md={4}>
                <MediaCard
                  imgSrc={'/static/walesonline.png'}
                  source={'Wales Online'}
                  title="First of its kind app launches in Cardiff to help people feel safe walking home"
                  desc="Jack said he acted after hearing some 'harrowing' stories of street harrassment..."
                  onClick={() => {
                    window.location.href =
                      'https://www.walesonline.co.uk/news/wales-news/first-kind-app-launches-cardiff-26209655';
                  }}
                />
              </Grid>
              <Grid item container xs={12} sm={6} md={4}>
                <MediaCard
                  imgSrc={
                    'https://www.wales247.co.uk/wp-content/uploads/2022/12/Big-Ideas-Wales-Bootcamp-to-Business-event-min-2048x1365.jpg'
                  }
                  source={'Wales247'}
                  title="RouteBuddies wins Best Pitch Competition"
                  desc="Best Pitch was presented to Jack Blundell, founder of Routebuddies, a Cardi..."
                  onClick={() => {
                    window.location.href =
                      'https://www.wales247.co.uk/young-entrepreneurs-bring-wales-future-business-ideas-to-life-at-bootcamp';
                  }}
                />
              </Grid>
            </Grid>
          </Slide>
        </>
      </Grid>
    </>
  );
}
