import * as React from 'react';
import Slider, { SliderProps } from '@mui/material/Slider';
import { alpha, styled } from '@mui/material/styles';
import {
  Button,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { Close, Search } from '@mui/icons-material';

const TextFieldDarker = styled(TextField)<TextFieldProps>(({ theme }) => ({
  width: '100%',
  maxWidth: 350,
  color: theme.palette.success.main,
  '& .MuiSlider-thumb': {
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
    },
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
    },
  },
  border: 'none',
  backgroundColor: 'rgba(224,224,224,0.19)',
  borderRadius: 30,
}));

export default function DarkTextField({
  placeholder,
  value,
  onChange,
  style,
}: TextFieldProps) {
  return (
    <TextFieldDarker
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      style={style}
      InputProps={{
        style: {
          borderRadius: 30,
          height: 50,
        },
        endAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}
