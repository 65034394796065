import { CircularProgress, Container, Modal, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import NavBar from '../components/NavBar';
import { useNavigate } from 'react-router';
import { watchAuthState } from '../../services/login_services';
import UserCard from '../components/UserCard';
import { getAllUsers } from '../../services/report_services';
import UserInfo from '../components/UserInfo';
import { Helmet } from 'react-helmet';

export default function Users({ theme, mapboxgl }: any) {
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(false);

  //Unchecked users
  const [allUsers, setAllUsers] = useState<any>(null);
  const [tempAllUsers, setTempAllUsers] = useState<any>(null);
  const [allUsersLoading, setAllUsersLoading] = useState(true);

  const [selectedUser, setSelectedUser] = useState(null);

  //Check if authenticated
  useEffect(() => {
    watchAuthState(navigate, 'other', setLoggedIn);
  }, []);

  useEffect(() => {
    if (loggedIn) {
      getAllUsers(setAllUsers, setTempAllUsers, setAllUsersLoading);
    }

    return function cleanup() {
      setAllUsers(null);
    };
  }, [loggedIn]);

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>
      <NavBar
        theme={theme}
        navigate={navigate}
        currentPage={'users'}
        loggedIn={loggedIn}
      />

      <Container
        style={{
          backgroundColor: theme.palette.secondary.main,
          height: 'calc(100vh - 48px)',
          width: '100vw',
          marginTop: '48px',
          padding: 15,
        }}
      >
        <Grid
          container
          sx={{ marginTop: 5, color: 'grey' }}
          flexDirection={'column'}
        >
          <Grid container item flexDirection={'column'} xs={12}>
            <Grid item container xs={12} justifyContent={'space-between'}>
              <Typography
                color={'inherit'}
                variant="h6"
                style={{ height: 30, marginBottom: 10 }}
              >
                Users
              </Typography>
              <Grid container item xs={7} justifyContent={'flex-end'}>
                <TextField
                  variant={'outlined'}
                  placeholder={'Search reports, users, dates...'}
                  onChange={(val: any) => {
                    if (val.target.value.length === 0) {
                      setAllUsers(tempAllUsers);
                    } else {
                      let reducedUsers = tempAllUsers.filter((user: any) => {
                        return (
                          user.firstName
                            .toLowerCase()
                            .includes(val.target.value.toLowerCase()) ||
                          user.lastName
                            .toLowerCase()
                            .includes(val.target.value.toLowerCase())
                        );
                      });
                      setAllUsers(reducedUsers);
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={2}>
              {allUsersLoading ? (
                <CircularProgress />
              ) : allUsers === null ? (
                <Grid item xs={6}>
                  <img
                    src="/static/happy.svg"
                    style={{ width: '200px', margin: '15px' }}
                    alt={'No recent users to show'}
                  />
                  <Typography>No users to show.</Typography>
                </Grid>
              ) : (
                allUsers
                  .filter((val: any) => val !== null)
                  .map((user: any) => {
                    return (
                      <Grid item xs={4}>
                        <UserCard
                          theme={theme}
                          user={user}
                          setSelectedUser={setSelectedUser}
                        />
                      </Grid>
                    );
                  })
              )}
            </Grid>
          </Grid>
        </Grid>

        <Modal
          open={selectedUser !== null}
          onClose={() => {
            setSelectedUser(null);
          }}
          aria-labelledby="View User"
          aria-describedby="Learn more about this user."
        >
          <Grid
            container
            xs={12}
            sx={{ height: '100vh' }}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Grid
              item
              container
              xs={10}
              sx={{ backgroundColor: 'white', margin: 'auto', padding: '35px' }}
            >
              <UserInfo
                theme={theme}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                mapboxgl={mapboxgl}
              />
            </Grid>
          </Grid>
        </Modal>
      </Container>
    </>
  );
}
