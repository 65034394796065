import { TextField } from '@mui/material';
import { forwardRef } from 'react';

const styles = {
  input: {
    backgroundColor: '#fff',
  },
};

const phoneInput = (props: any, ref: any) => {
  return (
    <TextField
      {...props}
      InputProps={{
        className: styles.input,
      }}
      inputRef={ref}
      fullWidth
      label="Phone Number"
      variant="outlined"
      name="phone"
    />
  );
};
export default forwardRef(phoneInput);
