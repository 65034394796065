import { createTheme } from '@mui/material';
import { setOptions } from 'react-chartjs-2/dist/utils';

const primaryColor: string = '#cd0438';
const primaryLightColor: string = process.env.REACT_APP_PRIMARY_LIGHT
  ? process.env.REACT_APP_PRIMARY_LIGHT
  : '#cd0438';
const secondaryColor: string = process.env.REACT_APP_SECONDARY_COLOR
  ? process.env.REACT_APP_SECONDARY_COLOR
  : '#fcfcfc';
const ternaryColor: string = process.env.REACT_APP_TERNARY_COLOR
  ? process.env.REACT_APP_TERNARY_COLOR
  : 'rgba(0,0,0,0.8)';
const primaryText: string = process.env.REACT_APP_TEXT_PRIMARY
  ? process.env.REACT_APP_TEXT_PRIMARY
  : '#fcfcfc';
const secondaryText: string = process.env.REACT_APP_TEXT_SECONDARY
  ? process.env.REACT_APP_TEXT_SECONDARY
  : '#cd0438';

const AppTheme: any = createTheme({
  typography: {
    body1: {
      fontFamily: 'Urbanist',
      fontWeight: '500',
    },
    body2: {
      fontFamily: 'Urbanist',
      fontWeight: '500',
    },
    h1: {
      fontFamily: 'Urbanist',
    },
    h2: {
      fontFamily: 'Urbanist',
    },
    h3: {
      fontFamily: 'Urbanist',
    },
    h4: {
      fontFamily: 'Urbanist',
    },
    h5: {
      fontFamily: 'Urbanist',
    },
    h6: {
      fontFamily: 'Urbanist',
    },
  },
  palette: {
    text: {
      primary: ternaryColor,
      secondary: secondaryText,
    },
    primary: {
      main: primaryColor,
      light: secondaryColor,
      dark: '#95042a',
    },
    secondary: {
      main: secondaryColor,
      light: secondaryColor,
      dark: 'rgba(0,0,0,0.1)',
    },
    background: {
      default: secondaryColor,
      paper: secondaryColor,
    },
  },
});

export default AppTheme;
