import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { watchAuthState } from '../../../services/login_services';
import { useAppDispatch } from '../../../store/store';
import { Helmet } from 'react-helmet';
import { PageType, setCurrentPage } from '../../../store/slices/general.slice';
import { useSelector } from 'react-redux';
import { UserActivitiesGrid } from '../../components/community_admin/DataGrid';
import { Add } from '@mui/icons-material';
import '../../../index.css';
import { JourneyAppModel } from '../../../models/GroupModel';
import { getAllAdminActivities } from '../../../store/thunks/admin.thunks';

export function RouteBuddiesAdminActivites() {
  const [currentInfoSection, setCurrentInfoSection] = useState(1);
  const [loggedIn, setLoggedIn] = useState(false);
  const { currentPage } = useSelector((state: any) => state.GeneralSlice);
  const {
    selectedCommunity,
    allAdminActivities,
    allAdminActivitiesLoading,
    allAdminActivitiesError,
  } = useSelector((state: any) => state.AdminSlice);
  const { userList, userListLoading, userListError } = useSelector(
    (state: any) => state.EnterpriseUserSlice
  );

  const { requestList, requestListLoading, requestListError } = useSelector(
    (state: any) => state.EnterpriseRequestSlice
  );

  const [searchValue, setSearchValue] = useState('');

  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  useEffect(() => {
    watchAuthState(navigate, 'other', setLoggedIn);
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage(PageType.ADMIN_ACTIVITIES));
  }, []);

  useEffect(() => {}, [allAdminActivities]);
  useEffect(() => {
    if (currentPage === PageType.ADMIN_ACTIVITIES) {
      dispatch(getAllAdminActivities());
    } else {
    }
  }, [selectedCommunity, currentPage]);

  return (
    <Grid container item xs={12}>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>

      <Grid
        container
        item
        xs={12}
        spacing={2}
        sx={{
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 2,
          flex: 1,
        }}
        alignItems={'center'}
      >
        <Grid
          item
          container
          xs={12}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
        >
          {!allAdminActivities || allAdminActivitiesLoading ? (
            <CircularProgress />
          ) : allAdminActivities.length > 0 ? (
            <Grid item container>
              <Grid
                item
                container
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                  paddingBottom: '5px',
                  margin: 0,
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight={'bold'}
                  style={{
                    fontSize: 12,
                    color: 'rgba(0,0,0,0.7)',
                    paddingBottom: 2,
                  }}
                >
                  ALL ACTIVITIES
                </Typography>
                <Button
                  endIcon={<Add />}
                  color={'primary'}
                  sx={{ padding: 0.5 }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate('/admin/activities/new');
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight={'bold'}
                    style={{
                      fontSize: 12,
                      paddingRight: 3,
                    }}
                  >
                    CREATE
                  </Typography>
                </Button>
              </Grid>
              <UserActivitiesGrid
                rows={allAdminActivities.map((val: JourneyAppModel) => {
                  return {
                    ...val,
                    id: val.repeatData
                      ? `${val.groupId}-${val.repeatId}`
                      : val.groupId,
                  };
                })}
              />
            </Grid>
          ) : (
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              spacing={2}
            >
              <Grid item>
                <img src={'/static/walking.svg'} style={{ maxWidth: 200 }} />
              </Grid>
              <Grid item>
                <Typography style={{ textAlign: 'center', maxWidth: 380 }}>
                  Safety in numbers is our jam. Create activities and they'll
                  appear here.
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate('/enterprise/activities/new');
                    dispatch(setCurrentPage(PageType.ADMIN_NEW_ACTIVITY));
                  }}
                >
                  Create Activity
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
