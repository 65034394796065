import { Fade, Grid, Slide, Typography, useTheme } from '@mui/material';
import NavBar from '../components/NavBar';
import { useTools } from '../../services/general_services';
import { useEffect, useState } from 'react';
import { PageType, setCurrentPage } from '../../store/slices/general.slice';
import { TypeAnimation } from 'react-type-animation';
import { primaryColor } from '../../assets/styles/styles';
import { RoundedButton } from '../components/RoundedButton';

export function Commmunities() {
  const theme = useTheme();
  const { dispatch, navigate } = useTools();

  useEffect(() => {
    dispatch(setCurrentPage(PageType.ENTERPRISE));
  }, []);

  const [initialAnimationStart, setInitialAnimation] = useState(false);
  const [fadeAnimation, setFadeAnimation] = useState(false);

  useEffect(() => {
    setInitialAnimation(true);
  }, []);

  return (
    <Grid container item xs={12}>
      <NavBar theme={theme} navigate={navigate} loggedIn={false} />
      <Grid
        xs={12}
        item
        style={{
          height: '80px',
          backgroundImage: `url(${'/static/login-bground.jpg'})`,
        }}
      />

      <Grid
        container
        flexDirection={'row'}
        sx={{
          maxWidth: '100vw',
        }}
      >
        <Grid
          item
          container
          sx={{
            backgroundImage: `url(${'/static/login-bground.jpg'})`,
            backgroundSize: 'cover',
            height: { xs: `100vh`, sm: 'calc(100vh - 100px)' },
          }}
          xs={6}
          justifyContent={'center'}
        ></Grid>
        {/* Section 1 - Intro*/}
        <Grid
          container
          flexDirection={'row'}
          style={{
            backgroundColor: 'white',
          }}
          justifyContent={'center'}
        >
          <Grid
            item
            container
            sx={{
              backgroundImage: `url(${'/static/login-bground.jpg'})`,
              backgroundSize: 'cover',
              height: { xs: `100vh`, sm: 'calc(100vh - 100px)' },
            }}
            xs={6}
            justifyContent={'center'}
          >
            <Grid
              item
              container
              md={9}
              sx={{
                height: {
                  xs: `100vh`,
                  md: `auto`,
                },
                minHeight: '80vh',

                marginBottom: {
                  xs: 5,
                  sm: 0,
                },
              }}
              style={{
                overflow: 'visible',
                // height: 'Calc(100vh - 160px)',
              }}
            >
              {/* Text */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{ padding: { xs: '25px', md: '10px' } }}
                style={{
                  height: 'inherit',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Fade
                  in={fadeAnimation}
                  easing={{ enter: theme.transitions.easing.easeInOut }}
                  timeout={500}
                >
                  <div>
                    <TypeAnimation
                      sequence={[
                        'Keep your people safe.',
                        3000,
                        'Make your community accessible',
                        3000,
                        'Provide Unparalleled Support',
                        3000,
                        () => {},
                      ]}
                      wrapper="div"
                      cursor={true}
                      repeat={Infinity}
                      style={{
                        fontFamily: 'UrbanistBold',
                        fontWeight: 800,
                        fontSize: '2.125rem',
                        lineHeight: 1.235,
                        letterSpacing: '0.00735em',
                        color: 'white',
                      }}
                    />

                    <Typography
                      variant={'body2'}
                      color={'white'}
                      style={{
                        fontFamily: 'Urbanist',
                        paddingTop: '10px',
                        fontSize: '17px',
                        paddingBottom: '20px',
                      }}
                    >
                      Empower your community with the safety app like no other.
                    </Typography>

                    <RoundedButton
                      defaultColor={'white'}
                      defaultBackgroundColor={primaryColor}
                      hoverColor={primaryColor}
                      hoverBackgroundColor={'white'}
                      onClick={() => {}}
                      width={200}
                    >
                      Request Quote
                    </RoundedButton>
                  </div>
                </Fade>
              </Grid>
              <Grid xs={6} sm={12} md={6}></Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={6}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                height: { xs: 'auto', md: '200' },
                display: { xs: 'block', md: 'none' },
              }}
            ></Grid>
          </Grid>

          <Grid xs={6} sm={12} md={6}></Grid>
          {/* image */}
          <Grid
            item
            xs={6}
            sm={12}
            md={6}
            justifyContent={'center'}
            sx={{
              height: { xs: 'auto', md: '200' },
              display: { xs: 'none', sm: 'none', md: 'block' },
            }}
          >
            <Slide
              direction="left"
              in={initialAnimationStart}
              timeout={500}
              easing={{ enter: theme.transitions.easing.easeInOut }}
              onEntered={() => {
                setFadeAnimation(true);
              }}
            >
              <Grid
                sx={{
                  width: { xs: '100%', sm: '300px', md: '370px', lg: '360px' },
                  position: { xs: 'relative', md: 'absolute' },
                  top: 40,
                }}
              >
                <img
                  src={'/static/phone.svg'}
                  style={{
                    width: '100%',
                    marginTop: 100,
                    zIndex: 100,
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxHeight: '80vh',
                  }}
                />
              </Grid>
            </Slide>
          </Grid>
        </Grid>

        <Grid item container xs={12}></Grid>
      </Grid>
    </Grid>
  );
}
