import { Grid, IconButton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { NavigationButton } from './NavigationButton';
import { useTools } from '../../../services/general_services';
import {
  Add,
  Close,
  FileDownload,
  Home,
  Lock,
  NordicWalking,
  People,
  Person,
  Report,
} from '@mui/icons-material';
import { isMobile } from 'react-device-detect';
import { primaryColor, styles } from '../../../assets/styles/styles';
import { PageType, setCurrentPage } from '../../../store/slices/general.slice';
import { BorderLine } from '../BorderLine';

export function AdminSideNavBar({ setShowNav }: { setShowNav: Function }) {
  const { currentPage } = useSelector((state: any) => state.GeneralSlice);

  const { userData } = useSelector((state: any) => state.EnterpriseSlice);

  const { navigate, dispatch } = useTools();

  return (
    <Grid
      container
      item
      xs={12}
      style={styles.navbar}
      alignContent={'flex-start'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Grid
        item
        container
        xs={10}
        sx={{ display: { xs: 'flex', sm: 'none' } }}
        justifyContent={'flex-end'}
      >
        <IconButton
          onClick={() => {
            setShowNav(false);
          }}
        >
          <Close style={{ color: 'white' }} />
        </IconButton>
      </Grid>
      <Grid item container>
        {/* Image of user */}
        <Grid
          item
          container
          xs={12}
          justifyContent={'center'}
          alignItems={'center'}
          style={{
            paddingBottom: 10,
            ...styles.sidePadding,
          }}
          spacing={2}
        >
          {/* Circle Profile Pic */}
          <Grid container item xs={4} justifyContent={'flex-end'}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 55,
                height: 55,
                border: '2px solid white',
                borderRadius: 32,
              }}
            >
              <div
                style={{
                  width: 45,
                  height: 45,
                  borderRadius: 25,
                  backgroundImage: `url(${
                    userData.selfieImageUrl
                      ? userData.selfieImageUrl
                      : '/static/avatar.svg'
                  }`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            </div>
          </Grid>

          {/* Profile Information */}
          <Grid item xs={8} flexDirection={'column'}>
            <Typography
              variant={'h6'}
              style={{ color: 'white', lineHeight: 1.2 }}
            >
              {userData.firstName}
            </Typography>
            <Typography
              variant={'body1'}
              style={{
                color: 'rgba(255,255,255, 0.7)',
                fontFamily: 'Urbanist',
                fontWeight: 200,
                fontSize: 15,
              }}
            >
              RouteBuddies Admin
            </Typography>
          </Grid>
        </Grid>

        <BorderLine />
        <Grid item container xs={12} justifyContent={'center'}>
          <Grid
            item
            container
            xs={10}
            sx={{
              paddingBottom: 2,
            }}
          >
            <Typography
              variant="h6"
              fontWeight={'bold'}
              style={{ fontSize: 12, color: 'rgba(255,255,255,0.7)' }}
            >
              MAIN MENU
            </Typography>
          </Grid>
        </Grid>

        {/* Navigation buttons */}
        <Grid item container xs={12}>
          <NavigationButton
            page={currentPage}
            buttonPage={PageType.ADMIN_DASHBOARD}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate('/admin/dashboard');
              dispatch(setCurrentPage(PageType.ADMIN_DASHBOARD));

              if (isMobile) {
                setShowNav(false);
              }
            }}
            icon={<Home style={{ color: 'white' }} />}
            hoveredIcon={<Home style={{ color: primaryColor }} />}
            text={'Dashboard'}
          />
          <NavigationButton
            page={currentPage}
            buttonPage={PageType.ADMIN_COMMUNITIES}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate('/admin/communities');
              dispatch(setCurrentPage(PageType.ADMIN_COMMUNITIES));

              if (isMobile) {
                setShowNav(false);
              }
            }}
            icon={<People style={{ color: 'white' }} />}
            hoveredIcon={<People style={{ color: primaryColor }} />}
            text={'Communities'}
          />

          <NavigationButton
            page={currentPage}
            buttonPage={PageType.ADMIN_USERS}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate('/admin/members');
              dispatch(setCurrentPage(PageType.ADMIN_USERS));
              if (isMobile) {
                setShowNav(false);
              }
            }}
            icon={<People style={{ color: 'white' }} />}
            hoveredIcon={<People style={{ color: primaryColor }} />}
            text={'Members'}
          />

          <NavigationButton
            page={currentPage}
            buttonPage={PageType.ADMIN_ACTIVITIES}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate('/admin/activities');
              dispatch(setCurrentPage(PageType.ADMIN_ACTIVITIES));
              if (isMobile) {
                setShowNav(false);
              }
            }}
            icon={<NordicWalking style={{ color: 'white' }} />}
            hoveredIcon={<NordicWalking style={{ color: primaryColor }} />}
            text={'Activities'}
          />

          <NavigationButton
            page={currentPage}
            buttonPage={PageType.ADMIN_NEW_ACTIVITY}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate('/admin/activities/new');
              dispatch(setCurrentPage(PageType.ADMIN_NEW_ACTIVITY));
              if (isMobile) {
                setShowNav(false);
              }
            }}
            icon={<Add style={{ color: 'white' }} />}
            hoveredIcon={<Add style={{ color: primaryColor }} />}
            text={'New Activity'}
          />
          <NavigationButton
            page={currentPage}
            buttonPage={PageType.ADMIN_NEW_ADMINS}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate('/admin/community_admins/new');
              dispatch(setCurrentPage(PageType.ADMIN_NEW_ADMINS));
              if (isMobile) {
                setShowNav(false);
              }
            }}
            icon={<Lock style={{ color: 'white' }} />}
            hoveredIcon={<Lock style={{ color: primaryColor }} />}
            text={'New Admin'}
          />
          <NavigationButton
            page={currentPage}
            buttonPage={PageType.ADMIN_NEW_ADMINS}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate('/admin/files/new');
              dispatch(setCurrentPage(PageType.ADMIN_NEW_ADMINS));
              if (isMobile) {
                setShowNav(false);
              }
            }}
            icon={<FileDownload style={{ color: 'white' }} />}
            hoveredIcon={<FileDownload style={{ color: primaryColor }} />}
            text={'New File'}
          />
          {/* 
          <NavigationButton
            page={currentPage}
            buttonPage={PageType.ADMIN_REPORTS}
            onClick={() => {
              window.scrollTo(0, 0)
navigate('/admin/reports');
              dispatch(setCurrentPage(PageType.ADMIN_REPORTS));

              if (isMobile) {
                setShowNav(false);
              }
            }}
            icon={<Report style={{ color: 'white' }} />}
            hoveredIcon={<Report style={{ color: primaryColor }} />}
            text={'Reports'}
          /> */}
        </Grid>
      </Grid>

      <Grid item container xs={12} justifyContent={'center'}>
        <BorderLine />
        <Grid item xs={10}>
          <Typography
            variant="h6"
            fontSize={14}
            style={{ color: 'rgba(255,255,255,0.7)' }}
          >
            @2024 RouteBuddies Limited
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
