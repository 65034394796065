import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { IconButton, Tooltip, Grid, Avatar, Icon, ListItemIcon, Divider, ListItemText, Menu, MenuItem, MenuList } from "@mui/material";
import {
  AutoGraphRounded,
  CheckRounded,
  ChevronRightRounded,
  CloseRounded,
  DoneRounded,
  InfoRounded,
  KeyboardArrowDownRounded,
  MoreVertRounded,
  PaletteRounded,
} from "@mui/icons-material";
import { getReportWarning } from "../../services/report_services";
import moment from "moment";

export default function WarningCard({ warning, theme, setReportResponseState, responseAvailable }: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      item
      xs={12}
      boxShadow={3}
      style={{ borderRadius: 10, padding: "5px 10px", marginBottom: 10 }}
    >
      <Grid item xs={11}>
        <Typography variant={"h6"} fontStyle={{ fontSize: "14px" }}>
          Warning sent to{" "}
          {warning.warnedUserRole === "reporter" ? "reporting" : "reported"}{" "}
          user:
        </Typography>
      </Grid>
      <Grid item xs={1}>
        {warning.seen ? (
          <Tooltip title={"Seen by user"}>
            <CheckRounded style={{ color: "green" }} />
          </Tooltip>
        ) : (
          <Tooltip title={"Unseen by user"}>
            <CloseRounded style={{ color: theme.palette.primary.main }} />
          </Tooltip>
        )}
      </Grid>
      <Grid container item xs={12} sm={8} alignItems={"center"}>
        <Tooltip title={"Warning creation date"}>
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.primary.dark,
              fontSize: 12,
            }}
          >
            {moment(new Date(warning.dateTime)).format("LLLL")}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <Tooltip title={"Warning message sent to user"}>
          <Typography
            variant={"body1"}
            sx={{
              fontSize: 11,
              fontStyle: "italic",
            }}
          >
            "{warning.message}"
          </Typography>
        </Tooltip>
      </Grid>

      {warning.type !== "general" && (
        <>
          <Grid item xs={11} sx={{ marginTop: "20px !important" }}>
            <Typography variant={"h6"} fontStyle={{ fontSize: "14px" }}>
              Response:
            </Typography>
          </Grid>
          <Grid item xs={1} sx={{ marginTop: "20px !important" }}>
            {warning.responseTime ? (
              <Tooltip title={"Responded by user"}>
                <CheckRounded style={{ color: "green" }} />
              </Tooltip>
            ) : (
              <Tooltip title={"Not responded yet"}>
                <CloseRounded style={{ color: theme.palette.primary.main }} />
              </Tooltip>
            )}
          </Grid>
          {warning.responseTime && (
            <Grid item xs={12} sm={8} alignItems={"center"}>
              <Tooltip title={"Response date"}>
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.primary.dark,
                    fontSize: 12,
                  }}
                >
                  {moment(new Date(warning.responseTime.dateTime)).format(
                    "LLLL"
                  )}
                </Typography>
              </Tooltip>
            </Grid>
          )}
          {warning.responseText && (
            <Grid item xs={12}>
              <Tooltip title="Response message sent to RouteBuddies">
                <Typography
                  variant={"body1"}
                  sx={{
                    fontSize: 11,
                    fontStyle: "italic",
                  }}
                >
                  "{warning.responseText}"
                </Typography>
              </Tooltip>
            </Grid>
          )}
          {warning.responseImageUrl && (
            <Grid item xs={12}>
              <Typography variant={'h6'}>Response Image</Typography>
            <Tooltip title="Response image sent to RouteBuddies">
                <img src={warning.responseImageUrl} style={{width: '300px'}}/>
              </Tooltip>
              </Grid>
          )}
          {/* Insert response images here */}
          
          {((warning.type === "request_info" || warning.type === 'request_pics') && !responseAvailable) && (
            <>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                variant="outlined"
              >
                Respond
                <KeyboardArrowDownRounded />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "Respond to report",
                }}
              >
                <MenuList
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 5,
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setReportResponseState(0);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <DoneRounded fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      Accept report & ban reported user.
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setReportResponseState(1);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <DoneRounded fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      Accept report & send reported user a warning.
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setReportResponseState(2);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <DoneRounded fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Accept report & do nothing.</ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      setReportResponseState(3);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <CloseRounded fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      Decline report & send reporter a warning.
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setReportResponseState(4);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <CloseRounded fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Decline report & do nothing.</ListItemText>
                  </MenuItem>
                  </MenuList>
              </Menu>
            </>
          )}
        </>
      )}
    </Grid>
  );
}
