import { Close, StarRounded } from '@mui/icons-material';
import { CircularProgress, Grid, IconButton, Modal } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { getUserReports } from '../../services/report_services';
import ReportCard from './ReportCard';
import ReportInfo from './ReportInfo';
export default function UserInfo({
  theme,
  selectedUser,
  setSelectedUser,
  mapboxgl,
}: any) {
  function randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const [date, setDate] = useState('');
  const [allReports, setAllReports] = useState<any>([]);
  const [allReportsLoading, setAllReportsLoading] = useState(true);
  const [selectedReport, setSelectedReport] = useState<any>(null);

  useEffect(() => {
    let day: any = randomIntFromInterval(1, 28);
    let month = randomIntFromInterval(1, 2);

    if (day.toString().length === 1) {
      day = '0' + day;
    }
    let dateNew = day + '-0' + month + '-2022';
    setDate(dateNew);
    getUserReports(selectedUser.id, setAllReports, setAllReportsLoading);
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        {/* Close button */}
        <Grid
          container
          item
          xs={12}
          justifyContent={'flex-end'}
          style={{ height: '40px' }}
        >
          <IconButton
            aria-label="Close modal"
            component="span"
            onClick={() => setSelectedUser(null)}
          >
            <Close />
          </IconButton>
        </Grid>
        {/* Left image panel */}
        <Grid
          container
          item
          xs={12}
          md={4}
          flexDirection={'column'}
          alignContent={'center'}
        >
          <img src={selectedUser.selfieImageUrl} style={{ width: '100%' }} />
        </Grid>

        {/* If user has not clicked 'Buy ticket' show information */}
        <Grid
          item
          container
          xs={12}
          md={8}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          style={{ height: 'min-content' }}
        >
          <Grid container item xs={12} alignItems={'center'}>
            <Grid item xs={6}>
              <Typography
                variant={'h4'}
              >{`${selectedUser.firstName} ${selectedUser.lastName}`}</Typography>
            </Grid>
            <Grid item container xs={6} alignItems={'center'}>
              <StarRounded style={{ color: 'gold' }} />
              <Typography variant={'body2'}>{selectedUser.rating}</Typography>
            </Grid>
          </Grid>

          {selectedUser.university && (
            <Grid container item xs={12}>
              <Typography variant={'h6'}>{selectedUser.university}</Typography>
            </Grid>
          )}
          <Grid container item xs={12}>
            <Typography variant={'body2'}>Joined since {date}</Typography>
          </Grid>

          <Grid container item flexDirection={'column'} xs={12} spacing={2}>
            {allReports !== null && allReports.length !== 0 && (
              <Grid container item xs={12} justifyContent={'space-between'}>
                <Typography
                  color={'inherit'}
                  variant="h6"
                  style={{ height: 30, marginBottom: 10 }}
                >
                  All reports
                </Typography>
              </Grid>
            )}

            <Grid
              container
              item
              xs={12}
              spacing={2}
              style={{ maxHeight: 350, overflowY: 'scroll' }}
            >
              {allReportsLoading ? (
                <CircularProgress color="primary" />
              ) : allReports === null ? (
                <Grid item xs={6}>
                  <img
                    src="/static/happy.svg"
                    style={{ width: '200px' }}
                    alt={'No reports to show'}
                  />
                  <Typography>No reports to show.</Typography>
                </Grid>
              ) : (
                allReports.map((report: any) => {
                  return (
                    <Grid item xs={6}>
                      {report !== null && (
                        <ReportCard
                          theme={theme}
                          report={report.report}
                          reported_user={report.reported_user}
                          user={report.user}
                          setSelectedReport={setSelectedReport}
                        />
                      )}
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Grid>
        </Grid>
        <Modal
          open={selectedReport !== null}
          onClose={() => {
            setSelectedReport(null);
          }}
          aria-labelledby="View Report"
          aria-describedby="Learn more about this report."
        >
          <Grid
            container
            xs={12}
            sx={{ height: '100vh' }}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Grid
              item
              container
              xs={10}
              sx={{ backgroundColor: 'white', margin: 'auto', padding: '35px' }}
            >
              <ReportInfo
                theme={theme}
                selectedReport={selectedReport}
                setSelectedReport={setSelectedReport}
                mapboxgl={mapboxgl}
              />
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </>
  );
}
