import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import {
  AdminPanelSettingsRounded,
  BusinessCenterRounded,
  CarRentalRounded,
  DirectionsCar,
  PeopleRounded,
} from '@mui/icons-material';
import { useTools } from '../../services/general_services';
import { primaryColor } from '../../assets/styles/styles';
import { RoundedButton } from './RoundedButton';

export default function Features({ home }: any) {
  const { theme } = useTools();
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index: number) => {
    console.log('Setting to,', index);
    setSelectedItemIndex(index);
    setSelectedFeature(items[index]);
  };

  const items = [
    {
      icon: (color: any) => {
        return <AdminPanelSettingsRounded style={{ color: color }} />;
      },

      title: 'Become a Guardian',
      description:
        'Our SOS feature allows you to request or provide assistance from/to your community members.',
      imageLight: 'url("/static/sos-support.jpg")',
      imageDark: 'url("/static/sos-support.jpg")',
    },
    {
      icon: (color: any) => {
        return <BusinessCenterRounded style={{ color: color }} />;
      },
      title: 'Explore Together',
      description:
        'Adventure the world together, with the ability to plan group activities with ease.',
      imageLight: 'url("/static/enterprise.jpg")',
      imageDark: 'url("/static/enterprise.jpg")',
    },
    {
      icon: (color: any) => {
        return <PeopleRounded style={{ color: color }} />;
      },
      title: 'And More',
      description:
        'Our bespoke tools are designed to drive real street safety change within communities.',
      imageLight: 'url("/static/women-community.png")',
      imageDark: 'url("/static/women-community.png")',
    },
  ];

  const [selectedFeature, setSelectedFeature] = React.useState(items[0]);

  return (
    <Grid container sx={{ backgroundColor: primaryColor }}>
      <Container id="features" sx={{ py: { xs: 8, sm: 10 } }}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <div>
              <Typography component="h2" variant="h4" color="white">
                How it works.
              </Typography>
              <Typography
                variant="body1"
                color="white"
                sx={{ mb: { xs: 2, sm: 4 } }}
              >
                RouteBuddies is here to help communities support eachother on
                the go!
              </Typography>
            </div>
            <Grid
              container
              item
              gap={1}
              sx={{ display: { xs: 'auto', sm: 'none' }, marginBottom: 3 }}
            >
              {items.map(({ title }, index) => (
                <Chip
                  key={index}
                  label={title}
                  onClick={() => handleItemClick(index)}
                  sx={{
                    borderColor: theme => {
                      if (theme.palette.mode === 'light') {
                        return selectedItemIndex === index ? primaryColor : '';
                      }
                      return selectedItemIndex === index ? primaryColor : '';
                    },
                    background: theme => {
                      if (theme.palette.mode === 'light') {
                        return selectedItemIndex === index ? 'none' : '';
                      }
                      return selectedItemIndex === index ? 'none' : '';
                    },
                    backgroundColor: selectedItemIndex === index ? 'white' : '',
                    '& .MuiChip-label': {
                      color:
                        selectedItemIndex === index ? primaryColor : 'white',
                    },
                  }}
                />
              ))}
            </Grid>
            <Box
              component={Card}
              variant="outlined"
              sx={{
                display: { xs: 'auto', sm: 'none' },
                margin: 0,
              }}
            >
              <Box
                sx={{
                  backgroundImage: theme =>
                    theme.palette.mode === 'light'
                      ? items[selectedItemIndex].imageLight
                      : items[selectedItemIndex].imageDark,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  height: '100%',
                }}
              />
              <Grid container>
                <Grid
                  item
                  container
                  xs={2}
                  alignContent={'center'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  {selectedFeature.icon(primaryColor)}
                </Grid>
                <Grid item container xs={10}>
                  <Box sx={{ px: 2, pb: 2 }}>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        marginTop: { xs: 2, sm: 0 },
                        color: primaryColor,
                      }}
                    >
                      {selectedFeature.title}
                    </Typography>
                    <Typography
                      color="red"
                      variant="body2"
                      sx={{ my: 0.5, color: primaryColor }}
                    >
                      {selectedFeature.description}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
              useFlexGap
              sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
            >
              {items.map(({ icon, title, description }, index) => (
                <Button
                  key={index}
                  onClick={() => handleItemClick(index)}
                  sx={{
                    p: 3,
                    height: 'fit-content',
                    width: '100%',
                    backgroundColor:
                      selectedItemIndex === index ? 'white' : 'transparent',
                    borderColor:
                      selectedItemIndex === index ? 'white' : 'white',
                    borderStyle: 'solid',
                    borderWidth: 3,
                    '& .MuiButtonBase-root:hover': {
                      backgroundColor: 'primary.light',
                    },
                    '&:hover': {
                      backgroundColor:
                        selectedItemIndex === index ? '#fff' : primaryColor,
                      color: '#3c52b2',
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      textAlign: 'left',
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: { md: 'center' },
                      gap: 2.5,
                    }}
                  >
                    <Box
                      sx={{
                        color: theme => {
                          if (theme.palette.mode === 'light') {
                            return selectedItemIndex === index
                              ? primaryColor
                              : 'grey.300';
                          }
                          return selectedItemIndex === index
                            ? 'white'
                            : 'grey.700';
                        },
                      }}
                    >
                      {icon(
                        selectedItemIndex === index
                          ? primaryColor
                          : 'rgba(240,240,240,0.6)'
                      )}
                    </Box>
                    <div>
                      <Typography
                        color={
                          selectedItemIndex === index ? primaryColor : 'white'
                        }
                        variant="body2"
                        fontWeight="bold"
                      >
                        {title}
                      </Typography>
                      <Typography
                        color={
                          selectedItemIndex === index ? primaryColor : 'white'
                        }
                        variant="body2"
                        sx={{ my: 0.5, textTransform: 'none' }}
                      >
                        {description}
                      </Typography>
                    </div>
                  </Box>
                </Button>
              ))}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: { xs: 'none', sm: 'flex' },
              width: '100%',
              border: 0,
            }}
          >
            <Card
              variant="outlined"
              sx={{
                height: '100%',
                width: '100%',
                display: { xs: 'none', sm: 'flex' },
                pointerEvents: 'none',
                border: 0,
              }}
            >
              <Box
                sx={{
                  m: 'auto',
                  height: '100%',
                  width: '100%',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  border: 0,
                  backgroundImage: theme =>
                    theme.palette.mode === 'light'
                      ? items[selectedItemIndex].imageLight
                      : items[selectedItemIndex].imageDark,
                }}
              />
            </Card>
          </Grid>

          <Grid item container justifyContent={'center'}>
            <RoundedButton
              defaultColor={'white'}
              defaultBackgroundColor={primaryColor}
              hoverColor={primaryColor}
              hoverBackgroundColor={'white'}
              onClick={() => {
                window.location.href =
                  'https://routebuddies.com/?appTrigger=true';
              }}
              width={190}
            >
              Get the app
            </RoundedButton>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
