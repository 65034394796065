import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserDataModel from '../../models/UserDataModel';
import { CommunityModel } from '../../models/CommunityModel';
import {
  CommentAppModel,
  PostAppModel,
  PostModel,
} from '../../models/PostModel';
import { ReportAppModel, ReportModel } from '../../models/ReportModel';
import {
  getAdminStatus,
  getCommunityAdminStatus,
} from '../thunks/general.thunks';
import {
  getAllCommunityActivities,
  getCommunityActivityById,
  getCommunityPosts,
  getUpcomingCommunityActivities,
  updateComment,
} from '../thunks/community.thunks';
import { Pause } from '@mui/icons-material';
import { getUserData } from '../thunks/enterprise.users.thunks';
import { JourneyAppModel } from '../../models/GroupModel';
import { CoalitionModel } from '../../models/CoalitionModel';
import { getCommunityCoalitions } from '../thunks/enterprise.coalition.thunks';
import { EventAppModel, EventModel } from '../../models/EventModel';
import { getCommunityEvents } from '../thunks/enterprise.events.thunks';

/**
 * modalHeight - Height of the information modal being shown during a journey - Used to display properly.
 * reportUser - Data of a user from a group selected to be reported.
 * page - The current page a user is on - PageType.HOME - PageType.JOURNEYS etc.
 */
interface IProps {
  permitted: boolean;
  userData: UserDataModel | undefined;
  userDataLoading: boolean;
  userDataError: boolean;
  allCommunities: CommunityModel[];
  allCommunitiesLoading: boolean;
  allCommunitiesError: boolean;
  allCoalitions: CoalitionModel[];
  allCoalitionsLoading: boolean;
  allCoalitionsError: boolean;
  selectedCommunity: CommunityModel | undefined;
  communityPosts: PostAppModel[];
  communityPostsLoading: boolean;
  communityPostsError: boolean;
  communityReports: ReportAppModel[];
  communityReportsLoading: boolean;
  communityReportsError: boolean;
  loginErrorMessage: string;
  upcomingCommunityActivities: JourneyAppModel[];
  upcomingCommunityActivitiesLoading: boolean;
  upcomingCommunityActivitiesError: boolean;
  allCommunityActivities: JourneyAppModel[];
  allCommunityActivitiesLoading: boolean;
  allCommunityActivitiesError: boolean;
  selectedActivity: JourneyAppModel | undefined;
  allActivitiesEndDate: Date | undefined;
  upcomingActivitiesEndDate: Date | undefined;
  selectedPost: PostAppModel | undefined;
  allEvents: EventAppModel[];
  allEventsLoading: boolean;
  allEventsError: boolean;
}
const enterpriseInitialState: IProps = {
  permitted: false,
  userData: undefined,
  userDataLoading: false,
  userDataError: false,
  allCommunities: [],
  allCommunitiesLoading: false,
  allCommunitiesError: false,
  selectedCommunity: undefined,
  allCoalitions: [],
  allCoalitionsLoading: false,
  allCoalitionsError: false,
  communityPosts: [],
  communityPostsLoading: false,
  communityPostsError: false,
  communityReports: [],
  communityReportsLoading: false,
  communityReportsError: false,
  loginErrorMessage: '',

  upcomingCommunityActivities: [],
  upcomingCommunityActivitiesLoading: false,
  upcomingCommunityActivitiesError: false,
  allCommunityActivities: [],
  allCommunityActivitiesLoading: false,
  allCommunityActivitiesError: false,
  selectedActivity: undefined,
  allActivitiesEndDate: undefined,
  upcomingActivitiesEndDate: undefined,
  selectedPost: undefined,
  allEvents: [],
  allEventsError: false,
  allEventsLoading: false,
};

const enterpriseSlice = createSlice({
  name: 'enterprise_data',
  initialState: enterpriseInitialState,
  reducers: {
    // Setters
    setUserData: (
      state: IProps,
      { payload }: PayloadAction<UserDataModel | undefined>
    ) => {
      state.userData = payload;
    },
    setallCommunities: (
      state: IProps,
      { payload }: PayloadAction<CommunityModel[]>
    ) => {
      state.allCommunities = payload;
    },
    setCommunityPosts: (
      state: IProps,
      { payload }: PayloadAction<PostAppModel[]>
    ) => {
      state.communityPosts = payload;
    },
    setCommunityReports: (
      state: IProps,
      { payload }: PayloadAction<ReportAppModel[]>
    ) => {
      state.communityReports = payload;
    },
    setLoginErrorMessage: (
      state: IProps,
      { payload }: PayloadAction<string>
    ) => {
      state.loginErrorMessage = payload;
    },
    setUpcomingCommunityActivities: (
      state: IProps,
      { payload }: PayloadAction<JourneyAppModel[]>
    ) => {
      state.upcomingCommunityActivities = payload;
    },
    setSelectedActivity: (
      state: IProps,
      { payload }: PayloadAction<JourneyAppModel | undefined>
    ) => {
      state.selectedActivity = payload;
    },
    setSelectedCommunity: (
      state: IProps,
      { payload }: PayloadAction<CommunityModel | undefined>
    ) => {
      state.selectedCommunity = payload;
    },
    setAllActivitiesEndDate: (
      state: IProps,
      { payload }: PayloadAction<Date | undefined>
    ) => {
      state.allActivitiesEndDate = payload;
    },
    setUpcomingActivitiesEndDate: (
      state: IProps,
      { payload }: PayloadAction<Date | undefined>
    ) => {
      state.upcomingActivitiesEndDate = payload;
    },
    setSelectedPost: (
      state: IProps,
      { payload }: PayloadAction<PostAppModel | undefined>
    ) => {
      state.selectedPost = payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(
      getAdminStatus.fulfilled,
      (
        state: IProps,
        { payload }: PayloadAction<CommunityModel[] | undefined>
      ) => {
        if (payload && payload.length > 0) {
          state.permitted = true;
          state.allCommunities = payload;

          state.selectedCommunity = payload[0];
        } else {
          state.loginErrorMessage =
            'You are not permitted to access the admin pages of RouteBuddies. This attempt has been logged in our system.';
        }
      }
    );
    builder.addCase(
      getCommunityAdminStatus.fulfilled,
      (
        state: IProps,
        { payload }: PayloadAction<CommunityModel[] | undefined>
      ) => {
        if (payload && payload.length > 0) {
          state.permitted = true;
          state.allCommunities = payload;

          state.selectedCommunity = payload[0];
        } else {
          state.loginErrorMessage =
            'You are not permitted to login to the enterprise edition of RouteBuddies, please contact sales to open an enterprise account.';
        }
      }
    );
    builder.addCase(getCommunityPosts.rejected, (state: IProps) => {
      state.communityPostsLoading = false;
      state.communityPostsError = true;
    });
    builder.addCase(getCommunityPosts.pending, (state: IProps) => {
      state.communityPostsLoading = true;
      state.communityPostsError = false;
    });
    builder.addCase(
      getCommunityPosts.fulfilled,
      (state: IProps, { payload }: PayloadAction<PostAppModel[]>) => {
        state.communityPostsError = false;
        state.communityPostsLoading = false;

        if (payload) {
          let ordered_posts = payload.sort(
            (a: PostAppModel, b: PostAppModel) => {
              // @ts-ignore
              return new Date(b.creationDate) - new Date(a.creationDate);
            }
          );

          state.communityPosts = ordered_posts;
        }
      }
    );
    builder.addCase(getUserData.rejected, (state: IProps) => {
      state.userDataLoading = false;
      state.userDataError = true;
    });
    builder.addCase(getUserData.pending, (state: IProps) => {
      state.userDataLoading = true;
      state.userDataError = false;
    });
    builder.addCase(
      getUserData.fulfilled,
      (
        state: IProps,
        { payload }: PayloadAction<UserDataModel | undefined>
      ) => {
        state.userDataLoading = false;
        state.userDataError = false;

        if (payload) {
          state.userData = payload;
        }
      }
    );
    builder.addCase(getCommunityCoalitions.rejected, (state: IProps) => {
      state.allCoalitionsLoading = false;
      state.allCoalitionsError = true;
    });
    builder.addCase(getCommunityCoalitions.pending, (state: IProps) => {
      state.allCoalitionsLoading = true;
      state.allCoalitionsError = false;
    });
    builder.addCase(
      getCommunityCoalitions.fulfilled,
      (
        state: IProps,
        { payload }: PayloadAction<CoalitionModel[] | undefined>
      ) => {
        state.allCoalitionsLoading = false;
        state.allCoalitionsError = false;

        if (payload) {
          state.allCoalitions = payload;
        }
      }
    );
    builder.addCase(getCommunityEvents.rejected, (state: IProps) => {
      state.allEventsLoading = false;
      state.allEventsError = true;
    });
    builder.addCase(getCommunityEvents.pending, (state: IProps) => {
      state.allEventsLoading = true;
      state.allEventsError = false;
    });
    builder.addCase(
      getCommunityEvents.fulfilled,
      (
        state: IProps,
        { payload }: PayloadAction<EventAppModel[] | undefined>
      ) => {
        state.allEventsLoading = false;
        state.allEventsError = false;

        if (payload) {
          state.allEvents = payload;
        }
      }
    );

    builder.addCase(
      getCommunityActivityById.fulfilled,
      (state: IProps, { payload }: PayloadAction<JourneyAppModel>) => {
        if (payload) {
          state.selectedActivity = payload;
        }
      }
    );

    builder.addCase(
      getUpcomingCommunityActivities.rejected,
      (state: IProps) => {
        state.upcomingCommunityActivitiesLoading = false;
        state.upcomingCommunityActivitiesError = true;
      }
    );
    builder.addCase(getUpcomingCommunityActivities.pending, (state: IProps) => {
      state.upcomingCommunityActivitiesLoading = true;
      state.upcomingCommunityActivitiesError = false;
    });
    builder.addCase(
      getUpcomingCommunityActivities.fulfilled,
      (
        state: IProps,
        {
          payload,
        }: PayloadAction<
          { endDate: Date; activities: JourneyAppModel[] } | undefined
        >
      ) => {
        state.upcomingCommunityActivitiesError = false;
        state.upcomingCommunityActivitiesLoading = false;
        if (payload) {
          state.upcomingCommunityActivities = payload.activities.sort(function (
            a,
            b
          ) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            // @ts-ignore
            return new Date(a.date) - new Date(b.date);
          });
        } else {
          state.upcomingCommunityActivities = [];
        }
      }
    );

    builder.addCase(getAllCommunityActivities.rejected, (state: IProps) => {
      state.allCommunityActivitiesLoading = false;
      state.allCommunityActivitiesError = true;
    });
    builder.addCase(getAllCommunityActivities.pending, (state: IProps) => {
      state.allCommunityActivitiesLoading = true;
      state.allCommunityActivitiesError = false;
    });
    builder.addCase(
      getAllCommunityActivities.fulfilled,
      (
        state: IProps,
        {
          payload,
        }: PayloadAction<
          { activities: JourneyAppModel[]; endDate: Date } | undefined
        >
      ) => {
        state.allCommunityActivitiesError = false;
        state.allCommunityActivitiesLoading = false;

        if (payload) {
          state.allCommunityActivities = payload.activities.sort(function (
            a,
            b
          ) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            // @ts-ignore
            return new Date(b.date) - new Date(a.date);
          });

          state.allActivitiesEndDate = payload.endDate;
        } else {
          console.log('empty!!!!');
          state.allCommunityActivities = [];
        }
      }
    );
  },
});

export const {
  setCommunityPosts,
  setallCommunities,
  setCommunityReports,
  setUserData,
  setSelectedActivity,
  setLoginErrorMessage,
  setUpcomingCommunityActivities,
  setAllActivitiesEndDate,
  setUpcomingActivitiesEndDate,
  setSelectedCommunity,
  setSelectedPost,
} = enterpriseSlice.actions;

export default enterpriseSlice.reducer;
