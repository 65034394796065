import { KeyboardArrowRight, MoreVertRounded } from '@mui/icons-material';
import { Card, CardHeader, Grid, IconButton } from '@mui/material';
import { red } from '@mui/material/colors';
import { EventAppModel, EventModel } from '../../../models/EventModel';
import {
  getReadableEventType,
  useTools,
} from '../../../services/general_services';
import { EventType } from '../../../models/EventEnum';
import { useDispatch } from 'react-redux';
import {
  setSelectedActivity,
  setSelectedPost,
} from '../../../store/slices/enterprise.slice';
import { Navigate } from 'react-router';
import { primaryColor } from '../../../assets/styles/styles';
import moment from 'moment';

export function EventCard({ event }: { event: EventAppModel }) {
  const { readable, icon } = getReadableEventType(event.eventType);

  const { navigate, dispatch } = useTools();
  function onPress() {
    if (
      event.eventType === EventType.ACTIVITY_CLOSED ||
      event.eventType === EventType.ACTIVITY_CREATED ||
      event.eventType === EventType.ACTIVITY_FINISHED ||
      event.eventType === EventType.ACTIVITY_LEFT ||
      event.eventType === EventType.ACTIVITY_JOINED
    ) {
      navigate(`/enterprise/activities/${event.correspondingId}`);
    } else if (
      event.eventType === EventType.POST_CREATED ||
      event.eventType === EventType.POST_DELETED ||
      event.eventType === EventType.POST_COMMENT_CREATED ||
      event.eventType === EventType.POST_COMMENT_DELETED
    ) {
      navigate(`/enterprise/posts/${event.correspondingId}`);
    }
  }
  return (
    <Card
      onClick={onPress}
      style={{
        cursor: 'pointer',
        alignContent: 'center',
        marginBottom: '10px',
      }}
    >
      <CardHeader
        avatar={
          <Grid
            item
            sx={{
              height: '50px',
              width: '50px',
              backgroundColor: primaryColor,
              borderRadius: 50,
            }}
            justifyContent={'center'}
            alignItems={'center'}
            container
          >
            {icon}
          </Grid>
        }
        action={
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <IconButton aria-label="settings">
              <KeyboardArrowRight />
            </IconButton>
          </div>
        }
        titleTypographyProps={{
          style: {
            fontWeight: 'bold',
            fontFamily: 'Urbanist',
            fontSize: 18,
          },
        }}
        title={event.user.firstName + ' ' + event.user.lastName}
        subheader={`${readable} - ${moment(event.dateTime)
          .utc()
          .format('DD/MM/YY HH:mma')}`}
      />
    </Card>
  );
}
