import { EventType } from '../models/EventEnum';
import { EventModel } from '../models/EventModel';
import { CreatorEnum } from '../models/GroupModel';
import { db } from './firebase_services';
import { getCurrentDateTime } from './home_services';

export function createEvent(
  userId: string,
  communityId: string,
  communityType: CreatorEnum,
  eventType: EventType,
  correspondingId: string
) {
  let event: EventModel = {
    userId,
    communityId,
    communityType,
    eventType,
    dateTime: getCurrentDateTime(),
    correspondingId,
  };

  if (communityType === CreatorEnum.COMMUNITY) {
    db.ref('/new_data/community_data/events/' + communityId).push(event);
  } else if (communityType === CreatorEnum.COALITION) {
    db.ref('/new_data/coalition_data/events/' + communityId).push(event);
  }
}
