import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Modal,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { watchAuthState } from '../../../services/login_services';
import { Add, Error, People } from '@mui/icons-material';
import { useAppDispatch } from '../../../store/store';
import { Helmet } from 'react-helmet';
import { InfoPanel } from '../../components/InfoPanel';
import { PageType, setCurrentPage } from '../../../store/slices/general.slice';
import { primaryColor } from '../../../assets/styles/styles';
import { useSelector } from 'react-redux';
import { UserActivitiesGrid } from '../../components/community_admin/DataGrid';
import { CreatorEnum, JourneyModel } from '../../../models/GroupModel';
import { toast } from 'react-toastify';
import { db } from '../../../services/firebase_services';
import {
  checkValidEmail,
  getCurrentDateTime,
} from '../../../services/home_services';
import { EventCard } from '../../components/community_admin/EventCard';
import { EventType } from '../../../models/EventEnum';
import { EventAppModel } from '../../../models/EventModel';

export function CommunityAdminHome() {
  const [currentInfoSection, setCurrentInfoSection] = useState(1);
  const [loggedIn, setLoggedIn] = useState(false);
  const {
    permitted,
    allCommunities,
    selectedCommunity,
    upcomingCommunityActivities,
    upcomingCommunityActivitiesLoading,
    upcomingCommunityActivitiesError,
    allEvents,
    allEventsLoading,
    allEventsError,
    userData,
  } = useSelector((state: any) => state.EnterpriseSlice);

  const [showInviteModal, setShowInviteModal] = useState(false);

  const { userList, userListLoading } = useSelector(
    (state: any) => state.EnterpriseUserSlice
  );
  const { requestList } = useSelector(
    (state: any) => state.EnterpriseRequestSlice
  );

  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [adminEmails, setAdminEmails] = useState<string[]>([]);
  const [adminEmailsError, setAdminEmailsError] = useState('');

  useEffect(() => {
    watchAuthState(navigate, 'other', setLoggedIn);
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage(PageType.ENTERPRISE_DASHBOARD));
  }, []);

  return (
    <Grid container item xs={12} sx={{ paddingTop: 0 }}>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>

      <Modal
        open={showInviteModal}
        onClose={() => {
          setShowInviteModal(false);
          setAdminEmails([]);
          setAdminEmailsError('');
        }}
        aria-labelledby="Invite members"
        aria-describedby="Send an email invite directly to your team."
      >
        <Grid
          container
          xs={12}
          sx={{ height: '100vh' }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid
            item
            container
            xs={10}
            sx={{
              backgroundColor: 'white',
              margin: 'auto',
              padding: '35px',
              borderRadius: '10px',
            }}
          >
            <Grid container flexDirection={'column'}>
              {/* Title */}
              <Grid
                item
                container
                flexDirection={'column'}
                style={{
                  borderBottom: '0.5px solid rgba(200,200,200,0.4)',
                }}
              >
                <Typography
                  variant={'h4'}
                  fontWeight={'800'}
                  sx={{ marginBottom: 1 }}
                >
                  Invite Member(s)
                </Typography>
                <Typography
                  variant={'body1'}
                  fontWeight={'400'}
                  style={{ marginBottom: '15px' }}
                >
                  Ready to grow your community? Just type in your members email
                  addresses to invite them!
                </Typography>
              </Grid>

              <Grid
                item
                container
                justifyContent={'flex-end'}
                spacing={2}
                paddingTop={5}
              >
                <Grid item container flexDirection={'column'}>
                  <Typography
                    variant="h6"
                    fontWeight={'bold'}
                    style={{ fontSize: 12, color: 'rgba(0,0,0,0.7)' }}
                  >
                    MEMBER EMAIL(S)*
                  </Typography>

                  <FormControl fullWidth>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      multiple={true}
                      options={[]}
                      value={adminEmails}
                      sx={{ flex: 1, border: 'none' }}
                      freeSolo
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Enter Email(s)"
                        />
                      )}
                      onChange={(event: any, newValue: string[]) => {
                        if (newValue !== null) {
                          setAdminEmails(newValue);
                        }
                      }}
                    />
                  </FormControl>

                  {adminEmailsError !== '' && (
                    <Typography
                      variant={'body2'}
                      color={'primary'}
                      style={{ marginBottom: '10px' }}
                    >
                      {adminEmailsError}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setShowInviteModal(false);
                      setAdminEmails([]);
                      setAdminEmailsError('');
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>{' '}
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    disabled={adminEmails.length === 0}
                    onClick={() => {
                      let numErrors = 0;
                      if (adminEmails.length === 0) {
                        numErrors += 1;
                        setAdminEmailsError(
                          'You must enter at least one email. (Type & press "Enter")'
                        );
                      } else {
                        for (let i = 0; i < adminEmails.length; i++) {
                          let email = adminEmails[i];

                          if (email === '') {
                            setAdminEmailsError(`Emails must not be empty.`);
                            numErrors += 1;
                          } else if (email.length > 40) {
                            setAdminEmailsError(
                              'Emails must be less than 40 characters.'
                            );
                            numErrors += 1;
                          } else if (!checkValidEmail(email)) {
                            setAdminEmailsError(
                              'Email(s) provided are invalid.'
                            );
                            numErrors += 1;
                          } else {
                            setAdminEmailsError('');
                          }
                        }
                      }

                      if (numErrors === 0) {
                        setShowInviteModal(false);

                        for (let i = 0; i < adminEmails.length; i++) {
                          let email = adminEmails[i];
                          toast.info(
                            email +
                              ' has been invited, look out for their request.'
                          );

                          db.ref('/new_data/email_send').push({
                            type: 'COMMUNITY_INVITE',
                            emailTo: email,
                            emailFrom: 'noreply@routebuddies.com',
                            data: {
                              date: getCurrentDateTime(),
                              communityId: selectedCommunity.communityId,
                              communityName: selectedCommunity.name,
                              name:
                                userData.firstName + ' ' + userData.lastName,
                            },
                          });
                        }
                        setAdminEmails([]);
                        setAdminEmailsError('');
                      }
                    }}
                  >
                    Invite
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      {upcomingCommunityActivitiesLoading || userListLoading ? (
        <Grid container item justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          spacing={2}
          sx={{
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 0,
            paddingBottom: 2,
          }}
          alignItems={'flex-start'}
        >
          <Grid item container xs={12} alignItems={'flex-start'}>
            <Grid item container xs={6} sm={4} lg={3}>
              <InfoPanel
                backgroundColor={'rgba(60,200,255,0.2)'}
                icon={
                  <People fontSize={'large'} style={{ color: '#217dce' }} />
                }
                color={'#217dce'}
                title={'Members'}
                value={userList ? userList.length : 0}
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate('/enterprise/members');
                }}
                xs={12}
              />
            </Grid>
            <Grid item container xs={6} sm={4} lg={3}>
              <InfoPanel
                backgroundColor={'rgba(255,0,0,0.4)'}
                icon={<Error fontSize={'large'} style={{ color: '#cd0438' }} />}
                color={'#cd0438'}
                title={'Requests'}
                value={requestList ? requestList.length : 0}
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate('/enterprise/members');
                }}
                xs={12}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            sm={8}
            lg={8}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
            paddingRight={2}
          >
            <Grid
              item
              container
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{
                paddingBottom: '5px',
                margin: 0,
              }}
            >
              <Typography
                variant="h6"
                fontWeight={'bold'}
                style={{
                  fontSize: 12,
                  color: 'rgba(0,0,0,0.7)',
                  paddingBottom: 2,
                }}
              >
                UPCOMING ACTIVITIES
              </Typography>
              <Button
                endIcon={<Add />}
                color={'primary'}
                sx={{ padding: 0.5 }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate('/enterprise/activities/new');
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight={'bold'}
                  style={{
                    fontSize: 12,
                    paddingRight: 3,
                  }}
                >
                  CREATE
                </Typography>
              </Button>
            </Grid>

            {upcomingCommunityActivities &&
              upcomingCommunityActivities.length > 0 && (
                <Grid container>
                  <UserActivitiesGrid
                    rows={upcomingCommunityActivities.map(
                      (val: JourneyModel) => {
                        return {
                          ...val,
                          id: val.repeatData
                            ? `${val.groupId}-${val.repeatId}`
                            : val.groupId,
                        };
                      }
                    )}
                  />
                </Grid>
              )}

            {upcomingCommunityActivities &&
              upcomingCommunityActivities.length === 0 && (
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                  spacing={4}
                  marginBottom={15}
                  paddingTop={10}
                >
                  <Grid item>
                    <img src={'/static/go.svg'} style={{ maxWidth: 200 }} />
                  </Grid>
                  <Grid item>
                    <Typography>
                      No activities currently set up -{' '}
                      <a
                        style={{ cursor: 'pointer', color: primaryColor }}
                        onClick={() => {
                          window.scrollTo(0, 0);
                          navigate('/enterprise/activities/new');
                          dispatch(
                            setCurrentPage(PageType.ENTERPRISE_NEW_ACTIVITY)
                          );
                        }}
                      >
                        Create One
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              )}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={4}
            lg={4}
            sx={{
              borderLeft: '0.5px solid rgba(0,0,0,0.4)',
              minHeight: '500px',
            }}
            paddingLeft={2}
          >
            <Typography
              variant="h6"
              fontWeight={'bold'}
              style={{
                fontSize: 12,
                color: 'rgba(0,0,0,0.7)',
                paddingBottom: 2,
                marginBottom: '10px',
              }}
            >
              EVENTS:
            </Typography>

            <Grid
              item
              container
              xs={12}
              sx={{ height: '500px', overflow: 'scroll', marginTop: '10px' }}
              justifyContent={'flex-start'}
            >
              <div style={{ height: 'max-content', flexDirection: 'column' }}>
                {allEvents &&
                  allEvents.map((val: EventAppModel) => {
                    return <EventCard event={val} />;
                  })}
              </div>
            </Grid>

            {allEvents.length === 0 && (
              <Grid
                item
                container
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                marginBottom={15}
                spacing={4}
                paddingTop={10}
              >
                <Grid item>
                  <img src={'/static/events.svg'} style={{ maxWidth: '70%' }} />
                </Grid>
                <Grid item>
                  <Typography>
                    No events currently logged. Events are automatically logged.
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
