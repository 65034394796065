import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import {
  AttachFile,
  Cancel,
  ChevronLeft,
  Close,
  Delete,
  Edit,
  Launch,
  Margin,
  Phone,
} from '@mui/icons-material';
import moment, { isDate } from 'moment';
import {
  getUserById,
  getUserJourneysForCommunity,
  isUserCommunityAdmin,
} from '../../../store/thunks/enterprise.users.thunks';
import { useTools } from '../../../services/general_services';
import { auth, db } from '../../../services/firebase_services';
import { primaryColor, styles } from '../../../assets/styles/styles';
import { PageType, setCurrentPage } from '../../../store/slices/general.slice';
import { CreatorType, PostAppModel, PostType } from '../../../models/PostModel';
import { UserActivitiesGrid } from './DataGrid';
import { CommunityModel } from '../../../models/CommunityModel';
import { setSelectedUser } from '../../../store/slices/enterprise.users.slice';

export function ViewUserScreen() {
  const { dispatch, navigate } = useTools();

  // UserDataModel Type
  const { selectedUser, selectedUserAdmin, selectedUserJourneys } = useSelector(
    (state: any) => state.EnterpriseUserSlice
  );
  const { selectedCommunity } = useSelector(
    (state: any) => state.EnterpriseSlice
  );
  const { id } = useParams();

  const [initialized, setInitialized] = useState(false);
  const [confirmBanUser, setConfirmBanUser] = useState(false);

  const [showDocments, setShowDocuments] = useState(false);

  const [userBanned, setUserBanned] = useState(false);

  const generateStars = (rating: number) => {
    const totalStars = 5;
    const roundedRating = Math.round(rating / 0.5) * 0.5;
    const wholeStars = Math.floor(roundedRating);
    const h_Stars = (roundedRating - wholeStars) * 2;

    const stars = [...Array(wholeStars)].map((_, i) => (
      <StarIcon style={{ color: '#F1C644', marginRight: '15px' }} />
    ));
    const halfStars = [...Array(h_Stars)].map((_, i) => (
      <StarHalfIcon style={{ color: '#F1C644', marginRight: '15px' }} />
    ));
    const greyStars = [...Array(totalStars - (wholeStars + h_Stars))].map(
      (_, i) => (
        <StarOutlineIcon style={{ color: '#F1C644', marginRight: '15px' }} />
      )
    );

    return [...stars, ...halfStars, ...greyStars];
  };

  const communityDataProfile = [
    {
      name: 'Cardiff Uni',
      imageUrl: '/static/avatar.svg',
    },
    {
      name: 'Cardiff Uni',
      imageUrl: '/static/avatar.svg',
    },
    {
      name: 'USW',
      imageUrl: '/static/avatar.svg',
    },
    {
      name: 'Cardiff Uni',
      imageUrl: '/static/avatar.svg',
    },
    {
      name: 'Cardiff Uni',
      imageUrl: '/static/avatar.svg',
    },
    {
      name: 'USW',
      imageUrl: '/static/avatar.svg',
    },
    {
      name: 'Cardiff Uni',
      imageUrl: '/static/avatar.svg',
    },
    {
      name: 'Cardiff Uni',
      imageUrl: '/static/avatar.svg',
    },
    {
      name: 'USW',
      imageUrl: '/static/avatar.svg',
    },
  ];

  useEffect(() => {
    if (selectedUser && selectedCommunity && auth.currentUser) {
      dispatch(
        isUserCommunityAdmin({
          userId: auth.currentUser!.uid,
          communityId: selectedCommunity.communityId,
        })
      );
      dispatch(
        getUserJourneysForCommunity({
          userId: auth.currentUser.uid,
          communityId: selectedCommunity.communityId,
        })
      );
    }
  }, [selectedUser, auth, selectedCommunity]);

  function banUser() {
    if (id) {
      db.ref('/new_data/community_data/banned_users/' + id).set(true);
      setConfirmBanUser(false);
      setUserBanned(true);
    }
  }
  function unbanUser() {
    if (id) {
      db.ref('/new_data/banned_users/' + id).remove();
      setUserBanned(false);
    }
  }

  useEffect(() => {
    if (!selectedUser && !initialized && id) {
      dispatch(getUserById({ id: id }));
      setInitialized(true);
      dispatch(setCurrentPage(PageType.ENTERPRISE_USERS));
    }
  }, []);

  return (
    <Grid container xs={12} item>
      <Grid
        container
        item
        xs={12}
        sx={{ padding: 2, height: 'calc(100% - 80px)' }}
        justifyContent={'center'}
        alignItems={'flex-start'}
      >
        <Grid item container xs={12} padding={0}>
          <Button
            onClick={() => {
              window.scrollTo(0, 0);
              navigate('/enterprise/members');
              dispatch(setSelectedUser(undefined));
            }}
            variant={'text'}
            sx={{ position: 'relative', left: 0, marginBottom: 2 }}
          >
            <ChevronLeft />
            Back to users
          </Button>
        </Grid>

        {selectedUser ? (
          <Grid item container>
            {/* Desktop/Tablet */}
            <Grid
              item
              container
              xs={12}
              style={{ borderRadius: 5 }}
              justifyContent={'flex-start'}
              gap={'20px'}
              sx={{ padding: '20px', display: { xs: 'none', sm: 'flex' } }}
              spacing={1}
            >
              <Grid
                item
                container
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: 175,
                }}
              >
                <Grid
                  item
                  container
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Grid
                    item
                    boxShadow={1}
                    sx={{
                      backgroundImage: `url("${
                        selectedUser.selfieImageUrl
                          ? selectedUser.selfieImageUrl
                          : '/static/avatar.svg'
                      }")`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      height: 175,
                      width: 175,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                sm={6}
                md={5}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'flex-start',
                  gap: '10px',
                }}
                spacing={1}
              >
                <Grid item xs={12} sx={{ height: 'fit-content' }}>
                  <Typography
                    variant="h4"
                    fontWeight={700}
                  >{`${selectedUser.firstName} ${selectedUser.lastName}`}</Typography>
                  <Typography variant="body1" sx={{ color: primaryColor }}>
                    {selectedUserAdmin ? 'Community Admin' : 'Community Member'}
                  </Typography>

                  <Grid
                    item
                    container
                    xs={12}
                    flexDirection={'row'}
                    alignItems={'center'}
                    sx={{ height: 'fit-content', marginTop: '15px' }}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="body1">Rating</Typography>
                    </Grid>

                    <Grid item container xs={6}>
                      <Typography
                        variant="body1"
                        fontSize={12}
                        color="rgba(0,0,0,0.6)"
                      >
                        ({selectedUser.rating.toFixed(2)} Stars)
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      flexDirection={'row'}
                      sx={{ marginTop: '10px' }}
                    >
                      {generateStars(selectedUser.rating)}
                    </Grid>
                  </Grid>
                </Grid>
                <svg
                  height={0.3}
                  width={'100%'}
                  style={{
                    backgroundColor: 'rgba(0,0,0,0.2)',
                  }}
                />
                {/* <Grid item xs={12} flexDirection={'row'}> */}

                <Grid
                  item
                  container
                  xs={12}
                  flexDirection={'row'}
                  // justifyContent={'space-between'}
                  sx={{ display: 'flex' }}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                      About
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 700,
                        display: 'flex',
                      }}
                    >
                      Email Address
                    </Typography>
                    <Link
                      variant="body2"
                      // sx={{ color: 'blue' }}
                      onClick={() => {
                        window.location.href = 'mailto:' + selectedUser.email;
                      }}
                    >
                      {selectedUser.email}
                    </Link>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                      Gender
                    </Typography>
                    <Typography variant="body2">
                      {selectedUser.gender}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                      Sex
                    </Typography>
                    <Typography variant="body2">{selectedUser.sex}</Typography>
                  </Grid>
                </Grid>

                {/* </Grid> */}
              </Grid>
              <Grid item justifyContent={'flex-end'}>
                <Grid
                  item
                  container
                  style={{
                    width: '180px',
                  }}
                  justifyContent={'flex-end'}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: userBanned ? primaryColor : 'red',
                      height: '45px',
                    }}
                    onClick={() => {
                      if (userBanned) {
                        unbanUser();
                      } else {
                        setConfirmBanUser(true);
                      }
                    }}
                  >
                    {userBanned ? 'Unban User' : 'Ban User'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {/* Mobile */}
            <Grid
              item
              container
              xs={12}
              gap={'5px'}
              sx={{ display: { xs: 'flex', sm: 'none' } }}
              spacing={1}
            >
              <Grid
                item
                container
                xs={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Grid
                  item
                  container
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Grid
                    item
                    boxShadow={1}
                    sx={{
                      backgroundImage: `url("${
                        selectedUser.selfieImageUrl
                          ? selectedUser.selfieImageUrl
                          : '/static/avatar.svg'
                      }")`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      height: 250,
                      width: 250,
                      margin: 2,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    sx={{ paddingTop: '20px', fontWeight: 800 }}
                  >{`${selectedUser.firstName} ${selectedUser.lastName}`}</Typography>
                  <Typography variant="body1" sx={{ color: primaryColor }}>
                    Community Member
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} flexDirection={'row'}>
                <Typography variant="h6" sx={{ marginBottom: '5px' }}>
                  Rating
                </Typography>
                <Grid item xs={12} flexDirection={'row'}>
                  {generateStars(selectedUser.rating)}
                </Grid>
              </Grid>

              <svg
                height={0.3}
                width={'100%'}
                style={{
                  backgroundColor: 'rgba(0,0,0,0.2)',
                }}
              />
              <Grid
                item
                container
                xs={12}
                flexDirection={'row'}
                // justifyContent={'space-between'}
                sx={{ display: 'flex' }}
                spacing={2}
              >
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                    About
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 700,
                      display: 'flex',
                    }}
                  >
                    Email Address
                  </Typography>
                  <Link
                    variant="body2"
                    // sx={{ color: 'blue' }}
                    onClick={() => {
                      window.location.href = 'mailto:' + selectedUser.email;
                    }}
                  >
                    {selectedUser.email}
                  </Link>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: 700 }}>
                    Gender
                  </Typography>
                  <Typography variant="body2">{selectedUser.gender}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: 700 }}>
                    Sex
                  </Typography>
                  <Typography variant="body2">{selectedUser.sex}</Typography>
                </Grid>
              </Grid>

              <svg
                height={0.3}
                width={'100%'}
                style={{
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  marginTop: '10px',
                }}
              />
            </Grid>

            {/*User Activity*/}

            {/* <a style={{ fontSize: '3rem' }}>{testData[0].id}</a> */}

            <Grid item container xs={12}>
              <Typography
                variant={'h6'}
                sx={{ marginTop: '10px', marginBottom: '10px' }}
              >
                Activities Joined
              </Typography>
            </Grid>
            <Card sx={{ width: '100%', padding: 0 }}>
              <UserActivitiesGrid
                rows={selectedUserJourneys ? selectedUserJourneys : []}
              />
            </Card>

            {/* Modals */}
            <Fade in={confirmBanUser}>
              <Grid
                sx={{
                  ...styles.modalBackdrop,
                }}
                container
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Grid item container xs={11} sm={5} sx={{ ...styles.modal }}>
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ height: 40 }}
                    justifyContent={'space-between'}
                  >
                    <Typography variant={'h6'} fontWeight={700}>
                      Are you sure?
                    </Typography>

                    <IconButton
                      onClick={() => {
                        setConfirmBanUser(false);
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid item container xs={12} justifyContent={'center'}>
                    <img
                      src="/static/rubbish.svg"
                      width={250}
                      style={{ margin: 10 }}
                    />
                  </Grid>
                  <Grid item container xs={12} justifyContent={'center'}>
                    <Typography variant="body1">
                      If you ban this user, they will be unable to access the
                      Routebuddies application, and any associated Routebuddies
                      databases.
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent={'flex-end'}
                    spacing={2}
                    sx={{ marginTop: 3 }}
                  >
                    <Button
                      variant={'contained'}
                      startIcon={<Cancel />}
                      style={{ backgroundColor: 'grey', marginRight: 5 }}
                      onClick={() => {
                        setConfirmBanUser(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant={'contained'}
                      startIcon={<Delete />}
                      onClick={() => {
                        banUser();
                      }}
                      style={{ backgroundColor: 'red', zIndex: 1 }}
                    >
                      Ban User
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>

            <Fade in={showDocments}>
              <Grid
                sx={{
                  ...styles.modalBackdrop,
                }}
                container
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Grid item container xs={5} sx={{ ...styles.modal }}>
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ height: 40 }}
                    justifyContent={'space-between'}
                  >
                    <Typography variant={'h6'}>Documents</Typography>

                    <IconButton
                      onClick={() => {
                        setShowDocuments(false);
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid item container xs={12} justifyContent={'center'}>
                    {selectedUser &&
                      selectedUser.documents &&
                      selectedUser.documents.map((val: any, index: number) => {
                        return (
                          <Grid
                            container
                            item
                            xs={12}
                            spacing={2}
                            alignItems={'center'}
                            justifyContent={'center'}
                            sx={{ height: 48 }}
                            key={index}
                          >
                            <Grid
                              item
                              container
                              xs={7}
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <Typography variant={'h6'} fontSize={13}>
                                {val.name}{' '}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              container
                              xs={3}
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <Typography variant={'body1'} fontSize={13}>
                                {val.type}
                              </Typography>
                            </Grid>
                            <Grid item container xs={1}>
                              <IconButton
                                style={{ zIndex: 10 }}
                                onClick={() => {
                                  window
                                    .open(val.downloadUrl, '_blank')
                                    ?.focus();
                                }}
                              >
                                <Launch />
                              </IconButton>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent={'flex-end'}
                    spacing={2}
                    sx={{ marginTop: 3 }}
                  >
                    <Button
                      variant={'contained'}
                      startIcon={<Cancel />}
                      style={{ backgroundColor: 'grey', marginRight: 5 }}
                      onClick={() => {
                        setShowDocuments(false);
                      }}
                    >
                      Close Documents
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        ) : (
          <Typography>Nothing here</Typography>
        )}
      </Grid>
    </Grid>
  );
}
