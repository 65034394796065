import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Tooltip, Grid, Avatar } from '@mui/material';

export default function ReportCard({
  theme,
  report,
  reported_user,
  user,
  setSelectedReport,
}: any) {
  return (
    <Tooltip title="View Report">
      <Card
        sx={{
          height: '100%',
          cursor: 'pointer',
          backgroundColor: theme.palette.secondary.main,
        }}
        onClick={() => {
          setSelectedReport({ report, reported_user, user });
        }}
      >
        <div style={{ color: theme.palette.primary.main }}>
          <Grid
            container
            item
            xs={12}
            alignItems={'center'}
            style={
              report.stage === 1
                ? { backgroundColor: '#d67d00', height: '100%' }
                : report.stage === 2 &&
                  report.responseStage >= 0 &&
                  report.responseStage < 3
                ? { backgroundColor: '#00a80b', height: '100%' }
                : report.stage === 2 &&
                  report.responseStage >= 3 &&
                  report.responseStage < 5
                ? {
                    backgroundColor: theme.palette.primary.main,
                    height: '100%',
                  }
                : report.stage === 3
                ? { backgroundColor: '#0097a8', height: '100%' }
                : { backgroundColor: '#0097a8', height: '100%' }
            }
          >
            {/* 1 = no response */}
            {report.stage === 1 && (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.primary.light,
                  fontSize: 12,
                  padding: '2px 5px',
                  borderRadius: 10,
                }}
              >
                Response Required
              </Typography>
            )}
            {/* 2 = denied */}
            {report.stage === 2 && (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.primary.light,
                  fontSize: 12,
                  padding: '2px 5px',
                  borderRadius: 10,
                }}
              >
                {report.responseStage === 0
                  ? 'Accepted - User banned.'
                  : report.responseStage === 1
                  ? 'Accepted - Warning sent to reported user'
                  : report.responseStage === 2
                  ? 'Accepted - No further action.'
                  : report.responseStage === 3
                  ? 'Declined - Warning sent to reporting user.'
                  : report.responseStage === 4 &&
                    'Declined - No further action.'}
              </Typography>
            )}
            {/* 3 = info */}
            {report.stage === 3 && (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.primary.light,
                  fontSize: 12,
                  padding: '2px 5px',
                  borderRadius: 10,
                }}
              >
                {report.responseStage === 5
                  ? 'Info Requested'
                  : 'Pics requested'}
              </Typography>
            )}

            {report.stage2 && (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.primary.light,
                  fontSize: 12,
                  padding: '2px 5px',
                  borderRadius: 10,
                }}
              >
                {report.response2Stage === 0
                  ? ' -> Accepted - User banned.'
                  : report.response2Stage === 1
                  ? ' -> Accepted - Warning sent to reported user'
                  : report.response2Stage === 2
                  ? ' -> Accepted - No further action.'
                  : report.response2Stage === 3
                  ? ' -> Declined - Warning sent to reporting user.'
                  : report.response2Stage === 4 &&
                    ' -> Declined - No further action.'}{' '}
              </Typography>
            )}
          </Grid>
        </div>
        <CardContent>
          <Grid container justifyContent={'space-between'}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              color={theme.palette.text.main}
            >
              {reported_user.firstName + ' ' + reported_user.lastName}
            </Typography>
            <Avatar
              alt={reported_user.firstName + ' ' + reported_user.lastName}
              src={reported_user.selfieImageUrl}
            />
          </Grid>
          <Typography gutterBottom variant="body1" component="div">
            {report.date}
          </Typography>
          <Typography variant="body2">{report.description}</Typography>
        </CardContent>
        <div className={'flex-grow'} />
      </Card>
    </Tooltip>
  );
}
