import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { auth } from '../../services/firebase_services';
import { useAppDispatch } from '../../store/store';
import { getAdminStatus } from '../../store/thunks/general.thunks';
import { LoginScreen } from '../pages/LoginScreen';
import { getUserData } from '../../store/thunks/enterprise.users.thunks';
import {
  logoutUser,
  watchCommunityAuthState,
} from '../../services/login_services';
import { MenuOpen, Logout, Menu } from '@mui/icons-material';
import theme from '../../assets/styles/theme';
import { SideNavBar } from './navbar/SideNavBar';
import { isMobile } from 'react-device-detect';
import { AdminSideNavBar } from './navbar/AdminSideNavBar';
import { ToastContainer } from 'react-toastify';

export function AdminContainer({ children }: any) {
  const scrollRef = useRef<any>();
  const { admin, adminLoading, currentPage } = useSelector(
    (state: any) => state.GeneralSlice
  );
  const { userData, userDataLoading } = useSelector(
    (state: any) => state.EnterpriseSlice
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showNav, setShowNav] = useState(!isMobile);
  useEffect(() => {
    watchCommunityAuthState(() => {
      if (auth.currentUser) {
        dispatch(getAdminStatus({ id: auth.currentUser.uid }));
        dispatch(getUserData({ id: auth.currentUser.uid }));
      }
    });
  }, []);

  // Scroll to top on new page
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [currentPage]);
  return (
    <>
      <ToastContainer />
      {adminLoading || userDataLoading ? (
        <Grid
          container
          sx={{ height: '100vh' }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <CircularProgress />
        </Grid>
      ) : admin && userData ? (
        <Grid
          container
          justifyContent={'flex-end'}
          sx={{
            backgroundColor: '#cd0438',
            height: '100vh',
            overflow: 'hidden',
          }}
        >
          {showNav && (
            <Grid
              item
              container
              xs={showNav ? 12 : 0}
              sm={showNav ? 4 : 0}
              md={showNav ? 3 : 0}
            >
              <Slide timeout={300} in={showNav} direction="right">
                <div>
                  <AdminSideNavBar setShowNav={setShowNav} />
                </div>
              </Slide>
            </Grid>
          )}

          <Grid
            container
            item
            xs={showNav ? 0 : 12}
            sm={showNav ? 8 : 12}
            md={showNav ? 9 : 12}
            sx={{
              backgroundColor: '#101010',
              paddingTop: 2,
              paddingBottom: 2,
              paddingRight: 1,
              minHeight: '100vh',
              overflowY: 'scroll',
              transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            }}
          >
            <Grid
              container
              item
              xs={12}
              component={'div'}
              ref={scrollRef}
              sx={{
                backgroundColor: '#fff',
                borderRadius: 5,
                overflow: 'scroll',
                padding: 2,
                maxHeight: '95vh',
              }}
              flexDirection={'row'}
            >
              <Grid item container xs={12}>
                <Grid item container xs={4} sm={4} alignItems={'center'}>
                  <IconButton
                    onClick={() => {
                      setShowNav(!showNav);
                    }}
                  >
                    {showNav ? <MenuOpen /> : <Menu />}
                  </IconButton>

                  <Typography
                    variant="h5"
                    fontWeight={'100'}
                    sx={{
                      paddingLeft: 2,
                      fontStyle: 'italic',
                      display: { xs: 'none', md: 'flex' },
                    }}
                  >
                    Hello, {userData.firstName} 👋
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={8}
                  sm={8}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                  spacing={1}
                >
                  <Grid
                    container
                    item
                    xs={10}
                    alignItems={'center'}
                    alignContent={'center'}
                    justifyContent={'flex-end'}
                  >
                    {userData ? (
                      <Grid item>
                        <Avatar
                          style={{
                            width: '28px',
                            height: '28px',
                            marginRight: 10,
                          }}
                          alt={userData.firstName[0]}
                          src={userData.selfieImageUrl}
                        />
                      </Grid>
                    ) : (
                      <Grid item>
                        <CircularProgress />
                      </Grid>
                    )}
                    <Grid item>
                      <Typography variant="h6" fontSize={17}>
                        {userData && userData.firstName}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => {
                        logoutUser(navigate);
                      }}
                    >
                      <Logout />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sx={{
                  height: '100%',
                  paddingTop: 0,
                }}
                alignItems={'flex-start'}
              >
                {children}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <LoginScreen loginPage={false} />
      )}
    </>
  );
}
